import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Badge, Dialog, Grid, LinearProgress } from '@mui/material'
import { orderBy, compact } from 'lodash'
import AgriAction from '../agri/actions/AgriAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoDeclarationWithStats from '../agri/dto/enquete/DtoDeclarationWithStats'
import DtoEnquete from '../agri/dto/enquete/DtoEnquete'
import { formatMilliers } from '../../../utils/StringUtil'
import { hasValue } from '../../../utils/NumberUtil'
import DtoUser from '../account/dto/DtoUser'
import DtoFile from '../referencials/documents/dto/DtoFile'
import DtoPicture from '../referencials/documents/dto/DtoPicture'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import { DialogContentMUI, DialogTitleMUI } from '../components/styled/dialog'
import { MainButton } from '../components/styled/buttons'
import DtoDeclaration from '../agri/dto/enquete/DtoDeclaration'
import StyledCard from '../components/cards/StyledCard'
import { mainColor } from '../components/styled/theme'
import { ReactComponent as DownloadLogo } from '../../../ressources/static/svg/Download.svg'
import { ReactComponent as CloseLogo } from '../../../ressources/static/svg/Fermer.svg'
import { sieauTooltip } from '../../../utils/FormUtils'

class MyDeclarations extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            openModalExport: false,
            idDeclaration: null,
            year: null,
            title: null,
            exportNotifications: false,
        }
    }

    componentDidMount() {
        const { exploitation } = this.props
        if (exploitation.idExploitation) {
            this.getDeclarations(exploitation)
        } else {
            this.props.fetchExploitation().then(() => {
                const { exploitation: myExploitation } = this.props
                this.getDeclarations(myExploitation)
                this.props.fetchDeclarationByExploitationId(exploitation.idExploitation)
            })
        }
    }

    getDeclarations = (exploitation) => {
        this.props.fetchDeclarationsByExploitationId(exploitation.idExploitation).then(() => {
            this.setState({ dataLoaded: true })
        })
    }

    showDeclarationItem = (declarationsExploitation) => {
        const { surveys, accountUser } = this.props
        return compact(orderBy(declarationsExploitation, 'year', 'desc').map((d) => {
            const surveyDeclaration = surveys.find((s) => s.idSurvey === d.idSurvey) || {}
            if (([1, 3].includes(surveyDeclaration.statusCode) && (d.statusCode >= 3)) || accountUser.isAdmin === '1') {
                const volumes = d.link_volumes.length ? d.link_volumes : []
                const allocatedVolumeFiltered = volumes.filter((v) => hasValue(v.attributedVolume)).map((v) => v.attributedVolume)
                const allocatedVolume = allocatedVolumeFiltered.length ? Math.round(allocatedVolumeFiltered.reduce((a, b) => a + b)) : null
                const authorizedVolumeFiltered = volumes.filter((v) => hasValue(v.authorizedVolume)).map((v) => v.authorizedVolume)
                const authorizedVolume = authorizedVolumeFiltered.length ? Math.round(authorizedVolumeFiltered.reduce((a, b) => a + b)) : null
                const title = `${i18n.declaration} ${surveyDeclaration.year}`
                return (
                    <Grid key={d.idDeclaration} item xs={12} md={3} style={{ padding: '0 10' }}>
                        <StyledCard
                            subTitle={(<>
                                {title}
                                <DownloadLogo
                                    className='clickable'
                                    {...sieauTooltip(i18n.download)}
                                    style={{ height: 25 }}
                                    onClick={() => this.setState({
                                        openModalExport: true,
                                        title,
                                        idDeclaration: d.idDeclaration,
                                        year: surveyDeclaration.year,
                                        exportNotifications: surveyDeclaration.exportNotifications,
                                    })}
                                />
                            </>)}
                            styleContainer={{ padding: '5 0' }}
                            styleContent={{ padding: 0 }}
                            styleSubTitle={{ borderTop: '1px solid black', display: 'flex', justifyContent: 'space-between' }}
                        >
                            <Grid container style={{ padding: 10 }}>
                                <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.pointsDeclared}</Grid>
                                <Grid item xs={6} className='bold'>{d.nbPts}</Grid>
                            </Grid>
                            <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                                <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.requestedVolume}</Grid>
                                <Grid item xs={6} className='bold'>{hasValue(d.prevVolume) ? `${formatMilliers(d.prevVolume)} m3` : i18n.unknown}</Grid>
                            </Grid>
                            <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                                <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.allocatedVolume}</Grid>
                                <Grid item xs={6} className='bold'>{hasValue(allocatedVolume) ? `${formatMilliers(allocatedVolume)} m3` : i18n.unknown}</Grid>
                            </Grid>
                            <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                                <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.authorizedVolume}</Grid>
                                <Grid item xs={6} className='bold'>{hasValue(authorizedVolume) ? `${formatMilliers(authorizedVolume)} m3` : i18n.unknown}</Grid>
                            </Grid>
                        </StyledCard>
                    </Grid>
                )
            }
            return null
        }))
    }

    getModalExport = () => {
        const { openModalExport, title, idDeclaration, year, exportNotifications } = this.state
        const { exploitation } = this.props
        return (
            <Dialog open={openModalExport}>
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <b style={{ padding: 10, fontSize: 21 }}>{title}</b>
                    <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={() => this.setState({ openModalExport: false, title: null, idDeclaration: null, year: null })} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <MainButton
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                        onClick={() => this.props.exportDeclarationFull(idDeclaration)}
                    >
                        {i18n.exportPDFSynthesis}
                        <DownloadLogo fill='white' style={{ paddingLeft: 10, height: 25 }} />
                    </MainButton>
                    {exportNotifications && (
                        <MainButton
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                            onClick={() => this.props.getNotifEdition(exploitation.idExploitation, year)}
                        >
                            {i18n.exportPDFAttribution}
                            <DownloadLogo fill='white' style={{ paddingLeft: 10, height: 25 }} />
                        </MainButton>
                    )}
                </DialogContentMUI>
            </Dialog>
        )
    }

    getLibelle = () => {
        const { declaration } = this.props
        const { lastStep, statusCode } = declaration
        if (statusCode === 3) {
            return i18n.validated
        } else if (statusCode === 4) {
            return <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.validatedByAdminWrap}</span>
        } else if (lastStep > 0 && lastStep <= 5) {
            return i18n.inProgress
        } else if (!lastStep && !statusCode) {
            return i18n.new
        }
        return i18n.new
    }

    onNavigateEnquete = () => {
        const { declaration } = this.props
        const updateDeclaration = {
            ...declaration,
            statusCode: declaration.statusCode || 1,
        }
        this.props.updateDeclaration(updateDeclaration).then(() => {
            this.props.push('/enquete')
        })
    }

    render() {
        const { dataLoaded, openModalExport } = this.state
        const { declarationsExploitation, declaration } = this.props
        return (
            <>
                {declaration?.idDeclaration && (
                    <Grid item xs={11} container justifyContent='center' style={{ padding: '20 10 0 10' }}>
                        <Badge
                            id='bagdeCurrent'
                            badgeContent={this.getLibelle()}
                            color='primary'
                            style={{ width: '95%' }}
                        >
                            <MainButton onClick={() => this.onNavigateEnquete()}>
                                {i18n.doMyDeclaration}
                            </MainButton>
                        </Badge>
                    </Grid>
                )}
                {dataLoaded ? (
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-start'
                        className='padding-top-2'
                    >
                        <Grid item xs={12} className='bold' style={{ padding: 14, margin: '0 10 5 10', backgroundColor: mainColor, color: 'white', fontSize: 21 }}>{i18n.myDeclarations}</Grid>
                        {declarationsExploitation.length ? this.showDeclarationItem(declarationsExploitation) : (
                            <Grid item xs={12} style={{ fontWeight: 'bold', paddingLeft: 10, paddingTop: 30, fontSize: 21, margin: '0 10' }}>
                                {i18n.noDocument}
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <LinearProgress />
                )}
                {openModalExport && this.getModalExport()}
            </>
        )
    }
}

MyDeclarations.propTypes = {
    accountUser: PropTypes.instanceOf(DtoUser),
    fetchExploitation: PropTypes.func,
    fetchDeclarationsByExploitationId: PropTypes.func,
    exportDeclarationFull: PropTypes.func,
    getNotifEdition: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declarationsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationWithStats)),
    surveys: PropTypes.arrayOf(PropTypes.instanceOf(DtoEnquete)),
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    pictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    codesSandre: PropTypes.arrayOf(DtoSandreCode),
    push: PropTypes.func,
    updateDeclaration: PropTypes.func,
    declaration: PropTypes.instanceOf(DtoDeclaration),
    fetchDeclarationByExploitationId: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
        applicationSettings: store.HomeReducer.applicationSettings,
        exploitation: store.AgriReducer.exploitation,
        declarationsExploitation: store.AgriReducer.declarationsExploitation,
        surveys: store.AgriReducer.surveys,
        codesSandre: store.ReferencialReducer.codesSandre,
        pictures: store.FileReducer.pictures,
        files: store.FileReducer.files,
        declaration: store.AgriReducer.declaration,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    fetchDeclarationsByExploitationId: AgriAction.fetchDeclarationsByExploitationId,
    exportDeclarationFull: AgriAction.exportDeclarationFull,
    getNotifEdition: AgriAction.getNotifEdition,
    updateDeclaration: AgriAction.updateDeclaration,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDeclarations)
