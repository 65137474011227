const options = {
    progressBar: true,
    preventDuplicates: true,
    hideDuration: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'slideDown',
    hideMethod: 'slideUp'
}

const ToastrAction = {
    success(message, forceShow, timeOut) {
        window.toastr.options = { ...options, timeOut }
        if (!window.location.href.includes('/login') || forceShow) {
            window.toastr.success(message, '')
        }
    },
    info(message, forceShow, timeOut, title) {
        window.toastr.options = { ...options, timeOut }
        if (!window.location.href.includes('/login') || forceShow) {
            window.toastr.info(message, title)
        }
    },
    warning(message, forceShow, timeOut) {
        window.toastr.options = { ...options, timeOut }
        if (!window.location.href.includes('/login') || forceShow) {
            window.toastr.warning(message, '')
        }
    },
    error(message, forceShow, timeOut) {
        window.toastr.options = { ...options, timeOut }
        if (!window.location.href.includes('/login') || forceShow) {
            window.toastr.error(message, '')
        }
    },
}

export default ToastrAction
