import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid, Icon, IconButton, InputAdornment, LinearProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import { compact, sortBy } from 'lodash'
import { CMS_PATH } from '../../conf/basepath'
import { MainButton } from '../online/components/styled/buttons'
import DtoCMSEvent from '../online/cms/dto/DtoCMSEvent'
import packageJson from '../../../package.json'
import AccountAction from '../online/account/actions/AccountAction'
import DtoUser from '../online/account/dto/DtoUser'
import ModalUpdatePassword from '../online/account/components/ModalUpdatePassword'
import ToastrAction from '../online/components/toasters/ToastrAction'
import { InputRow } from '../online/components/styled/inputs'
import { isRunningApp } from '../../utils/LocalStorageUtils'
import { managerBlue } from '../online/components/styled/theme'
import { MEDEAU_LOGIN, MEDEAU_TOKEN } from './constants/HomeConstants'
import ModalCGU from './components/ModalCGU'
import HomeAction from './actions/HomeAction'
import CmsAction from '../online/cms/actions/CmsAction'
import { getSettingInt } from '../../utils/FormUtils'
import defaultHeader from '../../ressources/static/media/login_header.jpg'
import defaultLogo from '../../ressources/static/media/iryqua.png'

const styles = () => ({
    logoTop: {
        textAlign: 'center',
        width: '100%',
        height: '15%',
    },
    img: {
        height: '100%',
        width: 'auto',
    },
})

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            login: localStorage.getItem(MEDEAU_LOGIN) || '',
            password: '',
            openModal: false,
            dataLoaded: false,
            loginError: false,
            passwordError: false,
            openModalPassword: false,
            images: JSON.parse(localStorage.getItem('IRYQUA_images')) || [],
            cmsCGUDate: 0,
            newMdp: '',
            mdpConfirmation: '',
            settingsLoaded: false,
            backgroundUrl: localStorage.getItem('IRYQUA_backgroundUrl') || defaultHeader,
            logoUrl: localStorage.getItem('IRYQUA_logoUrl') || defaultLogo,
            loading: false,
            showPassword: false,
        }
    }

    componentDidMount() {
        const { applicationSettings, cmsEvents } = this.props
        if (!cmsEvents.length) {
            this.props.fetchCMSEvents().then(() => {
                if (!applicationSettings.length) {
                    this.props.fetchApplicationSettings().then(() => this.setLogos())
                } else {
                    this.setLogos()
                }
            })
        } else {
            this.setLogos()
        }
        this.getCGU()
    }

    setLogos = () => {
        const { applicationSettings, cmsEvents } = this.props
        const applicationName = applicationSettings.find(({ parameter }) => parameter === 'applicationName') || {}
        if (applicationName.value) {
            document.title = applicationName.value
        }
        const cmsHomeId = getSettingInt(applicationSettings, 'iryquaCmsAccueil')
        const cmsHome = cmsEvents.find((c) => c.id === cmsHomeId)
        const logoUrl = cmsHome?.document?.length ? CMS_PATH + cmsHome.document[0]?.name : ''
        const cmsBackgroundId = getSettingInt(applicationSettings, 'iryquaCmsFondAccueil')
        const cmsBackground = cmsEvents.find((c) => c.id === cmsBackgroundId)
        const backgroundUrl = cmsBackground?.document?.length ? CMS_PATH + cmsBackground.document[0]?.name : ''
        const idCategPartenaires = getSettingInt(applicationSettings, 'iryquaCategorieCmsPartenaires')
        const partenaires = cmsEvents.filter((c) => c.idCategory === idCategPartenaires)
        const images = compact(sortBy(partenaires, 'subtitle').map((c) => {
            if (c?.document[0]) {
                return {
                    link: c.link,
                    docName: CMS_PATH + c.document[0].name,
                }
            }
            return null
        }))
        localStorage.removeItem('IRYQUA_backgroundUrl')
        localStorage.removeItem('IRYQUA_logoUrl')
        localStorage.removeItem('IRYQUA_images')
        localStorage.setItem('IRYQUA_backgroundUrl', backgroundUrl)
        localStorage.setItem('IRYQUA_logoUrl', logoUrl)
        localStorage.setItem('IRYQUA_images', JSON.stringify(images))
        this.setState({
            backgroundUrl,
            logoUrl,
            settingsLoaded: true,
            images,
        })
    }

    getCGU = () => {
        this.props.getCGUFromCms().then(() => {
            const { cmsCGU } = this.props
            const cmsCGUDate = Math.max(cmsCGU.map(({ updateDate }) => updateDate), 0)
            this.setState({
                cmsCGUDate,
                dataLoaded: true,
            })
        })
    }

    handleChangeValue = (value) => {
        this.setState(value)
    }

    toggleModalPassword = () => {
        const { openModalPassword } = this.state
        this.setState({ openModalPassword: !openModalPassword })
    }

    toggleModalCGU = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onUpdatePassword = () => {
        const { newMdp, mdpConfirmation, login } = this.state
        const { accountUser, applicationSettings } = this.props
        if (
            newMdp !== null &&
            newMdp.length &&
            mdpConfirmation !== null &&
            mdpConfirmation.length
        ) {
            if (newMdp === mdpConfirmation) {
                const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                if (regex.test(newMdp)) {
                    if (newMdp !== login) {
                        this.props.updatePassword(newMdp, accountUser.resetPassword).then(() => {
                            this.props.login(login, newMdp).then(() => {
                                this.toggleModalPassword()
                                this.checkCGU()
                            })
                        })
                    } else {
                        ToastrAction.error('Le mot de passe doit être différent de celui qui vous a été fourni', true)
                    }
                } else {
                    ToastrAction.error(regexHelp, true)
                }
            } else {
                ToastrAction.error('Les mots de passe ne sont pas identiques', true)
            }
        }
    }

    onSubmit = () => {
        const { login, password, loading } = this.state
        if (!login) {
            this.setState({ loginError: true })
        }
        if (!password) {
            this.setState({ passwordError: true })
        }
        if (password && login && !loading) {
            this.setState({ loading: true })
            this.props.login(login, password).then(() => {
                this.setState({ loading: false })
                this.props.fetchUser(login).then(() => {
                    const { accountUser } = this.props
                    if (!accountUser.resetPassword) {
                        this.checkCGU()
                    } else if (accountUser.resetPassword.length > 4) {
                        this.setState({ openModalPassword: true })
                    } else {
                        this.props.logout()
                        ToastrAction.warning('Une demande de changement de mot de passe est en cours sur ce compte. Si vous n\'en êtes pas à l\'origine, veuillez contacter votre administrateur.', true)
                    }
                })
            })
        }
    }

    checkCGU = () => {
        const { login } = this.state
        this.props.getDateValidCGU(login).then(() => {
            const { dateValidCgu, accountUser } = this.props
            const { cmsCGUDate } = this.state
            const dateUser = new Date(dateValidCgu)
            const dateCGU = new Date(cmsCGUDate)
            if (localStorage.getItem(MEDEAU_TOKEN)) {
                if (dateUser.getTime() < dateCGU.getTime()) {
                    this.toggleModalCGU()
                } else if (!dateUser.getTime()) {
                    this.toggleModalCGU()
                } else if (accountUser.isAdmin === '1' || accountUser.metadata === '1') {
                    this.props.push('/admin')
                } else {
                    this.props.push('/home')
                }
            }
        })
    }

    sendDateValidCGU = () => {
        const { login } = this.state
        const { accountUser } = this.props
        const eventType = 'CGU'
        const module = 'CGU'
        const date = new Date()
        const postDateCGU = { login, eventType, module, version: date }
        this.props.sendDateCgu(postDateCGU).then(() => {
            this.toggleModalCGU()
            if (accountUser.isAdmin === '1' || accountUser.metadata === '1') {
                this.props.push('/admin')
            } else {
                this.props.push('/home')
            }
        })
    }

    onRefuseCGU = () => {
        this.props.logout()
        this.toggleModalCGU()
    }

    openWebSite = (link) => {
        if (!isRunningApp() && link) {
            window.open(link, '_blank')
        }
    }

    getLogoPartenaires = () => {
        const { classes } = this.props
        const { images } = this.state
        // const size = images.length % 2 === 0 ? 6 : 4
        return images.map((i, index) => {
            const img = (
                <img
                    src={i.docName}
                    alt={i.link}
                    className={`${classes.img} clickable`}
                    onClick={() => this.openWebSite(i.link)}
                    style={{
                        height: '100%',
                        width: 'auto',
                        maxWidth: '100%',
                    }}
                />
            )
            return (
                <Grid item key={index} style={{ height: '10vw', minHeight: '40%' }}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        style={{ height: '100%' }}
                    >
                        {img}
                    </Grid>
                </Grid>
            )
        })
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onSubmit()
        }
    }

    render() {
        const { classes, cmsCGU } = this.props
        const { login,
            password,
            openModal,
            dataLoaded,
            settingsLoaded,
            passwordError,
            loginError,
            openModalPassword,
            backgroundUrl,
            logoUrl,
            loading,
            showPassword,
        } = this.state
        return (
            <div
                style={{
                    height: '100%',
                    margin: '-70px 0',
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', height: '15%' }}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='center'
                        alignItems='flex-end'
                        style={{
                            backgroundImage: `url(${backgroundUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: 'calc(100% - 20px)',
                            boxShadow: 'cyan 0px 0px 50px -10px',
                            borderRadius: '0 0 5px 5px',
                        }}
                    />
                </div>
                <Grid className={classes.logoTop}>
                    <img src={logoUrl} alt='' width='190px' />
                    <br />
                    <span>v{packageJson.version}</span>
                </Grid>
                <Grid
                    container
                    className='overflowY'
                    direction='row'
                    justifyContent='space-evenly'
                    alignItems='center'
                    style={{ height: '40%', padding: '0 20px' }}
                >
                    <Grid item xs={12} md={3}>
                        <InputRow
                            id='login'
                            label={i18n.id}
                            type='text'
                            value={login}
                            onChange={(e) => this.handleChangeValue({ login: e.target.value, loginError: false })}
                            variant='outlined'
                            error={loginError}
                            helperText={loginError && i18n.fillField}
                            onKeyDown={(e) => this.onKeyDown(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputRow
                            id='login'
                            label={i18n.password}
                            type={showPassword ? 'test' : 'password'}
                            value={password}
                            onChange={(e) => this.handleChangeValue({ password: e.target.value, passwordError: false })}
                            variant='outlined'
                            error={passwordError}
                            helperText={passwordError && i18n.fillField}
                            onKeyDown={(e) => this.onKeyDown(e)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={() => this.setState({ showPassword: !showPassword })}
                                            onMouseDown={(e) => e.preventDefault()}>
                                            <Icon>{showPassword ? 'visibility_off' : 'visibility'}</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {!loading ? (
                            <MainButton onClick={this.onSubmit}>
                                {i18n.connect}
                            </MainButton>
                        ) : (
                            <LinearProgress />
                        )}
                    </Grid>
                    <Grid item xs={12} container justifyContent='center'>
                        <span className='clickable' onClick={() => this.props.push('/reset')} style={{ textAlign: 'center', color: managerBlue }}>
                            {i18n.forgetPassword}
                        </span>
                    </Grid>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '30%', }}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        style={{
                            borderRadius: '5px 5px 0 0',
                            width: 'calc(100% - 20px)',
                            overflowY: 'auto',
                            backgroundColor: 'white',
                            boxShadow: 'cyan 0px 0px 50px -10px',
                        }}
                    >
                        {this.getLogoPartenaires()}
                    </Grid>
                </div>
                {dataLoaded && (
                    <ModalCGU
                        open={openModal}
                        cgu={cmsCGU}
                        onRefuse={this.onRefuseCGU}
                        onValidate={this.sendDateValidCGU}
                    />
                )}
                {settingsLoaded && openModalPassword && (
                    <ModalUpdatePassword
                        open={openModalPassword}
                        toggleModal={this.toggleModalPassword}
                        handleChangeNewMdp={(e) => this.handleChangeValue({ newMdp: e.target.value })}
                        handleChangeConfirmation={(e) => this.handleChangeValue({ mdpConfirmation: e.target.value })}
                        onSavePassword={this.onUpdatePassword}
                        firstLogin
                    />
                )}
            </div>
        )
    }
}

const mapDispatchToProps = {
    login: HomeAction.login,
    logout: HomeAction.logout,
    setDefaultPath: HomeAction.setDefaultPath,
    getDateValidCGU: HomeAction.getDateValidCGU,
    sendDateCgu: HomeAction.sendDateCgu,
    getCGUFromCms: HomeAction.getCGUFromCms,
    updatePassword: HomeAction.updatePassword,
    fetchUser: AccountAction.fetchUser,
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    fetchCMSHome: HomeAction.fetchCMSHome,
    fetchCMSEvents: CmsAction.fetchCMSEvents,
    push,
}

const mapStateToProps = (store) => {
    return {
        dateValidCgu: store.HomeReducer.dateValidCgu,
        cmsCGU: store.HomeReducer.cmsCGU,
        accountUser: store.AccountReducer.accountUser,
        applicationSettings: store.HomeReducer.applicationSettings,
        cmsEvents: store.CmsReducer.cmsEvents,
    }
}

Login.propTypes = {
    push: PropTypes.func,
    login: PropTypes.func,
    logout: PropTypes.func,
    setDefaultPath: PropTypes.func,
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    getDateValidCGU: PropTypes.func,
    sendDateCgu: PropTypes.func,
    dateValidCgu: PropTypes.string,
    getCGUFromCms: PropTypes.func,
    fetchCMSLogo: PropTypes.func,
    fetchUser: PropTypes.func,
    updatePassword: PropTypes.func,
    fetchApplicationSettings: PropTypes.func,
    fetchCMSEvents: PropTypes.func,
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    accountUser: PropTypes.instanceOf(DtoUser),
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    cmsCGU: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))
