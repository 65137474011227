/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import {
    Grid,
    Icon,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    Select,
    MenuItem,
    FormGroup,
    Checkbox,
    FormHelperText,
} from '@mui/material'
import { orderBy, sortBy } from 'lodash'
import { connect } from 'react-redux'
import { MainButton, OrangeButton, RedButton } from '../../../components/styled/buttons'
import { HelpTooltip, SubTitleCard } from '../../../components/styled/texts'
import { FormControlRow, InputRow } from '../../../components/styled/inputs'
import DtoCulture from '../../../referencials/dto/DtoCulture'
import DtoCulturesFamily from '../../../referencials/dto/DtoCulturesFamily'
import DtoUsageDeclaration from '../../../agri/dto/enquete/DtoUsageDeclaration'
import ToastrAction from '../../../components/toasters/ToastrAction'
import DtoSandreCode from '../../../referencials/dto/DtoSandreCode'
import DtoInstallation from '../../../referencials/installations/dto/DtoInstallation'
import AgriAction from '../../../agri/actions/AgriAction'
import DtoSamplingPointDecla from '../../../agri/dto/exploitation/DtoSamplingPointDecla'
import DtoUsageCategory from '../../../referencials/dto/DtoUsageCategory'
import DtoExploitation from '../../../agri/dto/exploitation/DtoExploitation'
import { hasValue, round } from '../../../../../utils/NumberUtil'
import DtoDeclaration from '../../../agri/dto/enquete/DtoDeclaration'
import DtoCMSEvent from '../../../cms/dto/DtoCMSEvent'
import { formatMilliers } from '../../../../../utils/StringUtil'
import { getSettingInt } from '../../../../../utils/FormUtils'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../../components/styled/dialog'
import { isLargeWindow } from '../../../../../utils/LocalStorageUtils'
import { ReactComponent as CloseLogo } from '../../../../../ressources/static/svg/Fermer.svg'
import moment from 'moment'
import DtoManagementUnit from '../../../referencials/dto/DtoManagementUnit'
import { StyledFieldSet, StyledLegend } from '../../../components/styled/fieldset'
import { mainRed } from '../../../components/styled/theme'

const RESUME_STEP = 0
const USAGE_STEP = 1
const CULTURE_VOLUME_STEP = 2
const IRRIG_PERIOD_STEP = 3
const GROUND_COMMENT_STEP = 4
const REQUEST_STEP = 5
const ADJUST_STEP = 6

class ModalUsage extends Component {
    constructor(props) {
        super(props)
        const volumesMonths = props?.usage?.volumesMonths?.split(';') || []
        this.state = {
            step: props.usage ? RESUME_STEP : USAGE_STEP,
            newUsage: !props.usage,
            errors: [],
            usage: props.usage ? {
                ...new DtoUsageDeclaration(props.usage),
                month01: volumesMonths[0],
                month02: volumesMonths[1],
                month03: volumesMonths[2],
                month04: volumesMonths[3],
                month05: volumesMonths[4],
                month06: volumesMonths[5],
                month07: volumesMonths[6],
                month08: volumesMonths[7],
                month09: volumesMonths[8],
                month10: volumesMonths[9],
                month11: volumesMonths[10],
                month12: volumesMonths[11],
            } : new DtoUsageDeclaration({
                idUsage: 7,
                comment: '',
                typeIrrigation: null,
            }),
            usages: props.usages || [],
            openHelp: false,
            ajustEstim: false,
        }
    }

    previousStep = () => {
        const {
            usagesCategories,
            currentYear,
        } = this.props
        const { step, usage } = this.state
        const usagesCategory = usagesCategories.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        if (currentYear && !usagesCategory.isCulture && step === 3) {
            this.setState({ step: step - 2, openHelp: false })
        } else {
            this.setState({ step: step - 1, openHelp: false })
        }
    }

    showVolumeInput = (usageCat) => {
        const { countersId } = this.props
        if (usageCat.volumeRealEntry) {
            if (!usageCat.showVolumeIfCounter || (usageCat.showVolumeIfCounter && countersId.length)) {
                return true
            }
            return false
        }
        return false
    }

    nextStep = () => {
        const {
            usagesCategories,
            installation,
            currentYear,
            surveys,
            declaration,
            usages,
            usage: usageInitial,
        } = this.props
        const { step, usage, newUsage } = this.state
        const usagesCategory = usagesCategories.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        const enquete = surveys.find((s) => s.idSurvey === declaration.idSurvey) || {}
        if (this.checkStep(step, usage, usagesCategory)) {
            if ((!newUsage && usageInitial.idUsage === usage.idUsage)
                || (usagesCategory.multipleEntry || usages.filter(({ idUsage }) => idUsage === usagesCategory.idUsage).length < 1)) {
                if (currentYear && !usagesCategory.isCulture && step === 1 && !this.showVolumeInput(usagesCategory)) {
                    this.setState({ step: step + 2 })
                } else if (step === 5 && !currentYear && usagesCategory.calculAuto) {
                    this.props
                        .calculVolumeUsage({
                            usage: {
                                ...usage,
                                area: round(usage.area, 4),
                                idInstallation: installation.id,
                                requestedYear: enquete.year,
                            },
                            uge: installation.subManagementCode || installation.managementCode,
                        })
                        .then((result) => {
                            const volume = isNaN(parseInt(result)) ? 0 : result
                            if (hasValue(usage.requestedYearVolume)) {
                                if (!usage.adjustedVolume && usage.requestedYearVolume === volume) {
                                    this.handleChange('requestedYearVolume', volume)
                                }
                                this.handleChange('adjustedVolume', usage.requestedYearVolume !== volume)
                            } else {
                                this.handleChange('requestedYearVolume', volume)
                                this.handleChange('adjustedVolume', false)
                            }
                            this.setState({ step: step + 1, volume, ajustEstim: false })
                        })
                } else {
                    this.setState({ step: step + 1 })
                }
                this.setState({ errors: [], openHelp: false })
            } else {
                ToastrAction.warning('Catégorie limitée à une saisie')
            }
        } else {
            ToastrAction.error(i18n.fillAllFields)
        }
    }

    checkStep = (step, usage, usagesCategory) => {
        const { cultures } = this.props
        const culture = cultures.find((c) => c.id === usage.idCulture)
        const idFamille = culture ? culture.idFamille : usage.idFamille
        const newErrors = []
        switch (step) {
            case USAGE_STEP:
                return true
            case CULTURE_VOLUME_STEP:
                if (usagesCategory?.isCulture || usagesCategory?.simplifiedCulture) {
                    if (!idFamille) {
                        newErrors.push('idFamille')
                    }
                    if (!usage.idCulture) {
                        newErrors.push('idCulture')
                    }
                    if (newErrors.length) {
                        this.setState({ errors: newErrors })
                        return false
                    }
                    return true
                }
                if (!usage.requestedYearVolume || usage.requestedYearVolume <= 0) {
                    this.setState({ errors: ['requestedYearVolume'] })
                    return false
                }
                return true
            case IRRIG_PERIOD_STEP:
                if (usagesCategory?.simplifiedCulture) {
                    return true
                }
                if (usagesCategory?.isCulture) {
                    if (!usage.irrigationType) {
                        newErrors.push('irrigationType')
                    }
                    if (!usage.irrigationMode) {
                        newErrors.push('irrigationMode')
                    }
                    if (newErrors.length) {
                        this.setState({ errors: newErrors })
                        return false
                    }
                    return true
                }
                if (!usage.link_periodes.length) {
                    this.setState({ errors: ['link_periodes'] })
                    return false
                } else if (usage.link_periodes.find((p) => p.idPeriode === 2 && !p.idNbPeriode)) {
                    this.setState({ errors: ['link_periodes_checkboxes'] })
                    return false
                }
                return true
            case GROUND_COMMENT_STEP:
                if (!usage.area || usage.area <= 0) {
                    newErrors.push('area')
                }
                if (isNaN(parseInt(usage.groundType))) {
                    newErrors.push('groundType')
                }
                if (isNaN(parseInt(usage.groundDepth))) {
                    newErrors.push('groundDepth')
                }
                if (!usage.nbRotation) {
                    newErrors.push('nbRotation')
                }
                if (newErrors.length) {
                    this.setState({ errors: newErrors })
                    return false
                }
                return true
            case REQUEST_STEP:
                if (isNaN(parseInt(usage.requestPrelevement))) {
                    this.setState({ errors: ['requestPrelevement'] })
                    return false
                }
                return true
            default:
                return true
        }
    }

    handleChange = (key, value) => {
        const { usagesCategories, point, declaration } = this.props
        const { usage } = this.state
        const usagesCategory = usagesCategories.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        const usageReset = usagesCategory.calculAuto ? {
            requestedYearVolume: null,
            lowWaterVolume: null,
            adjustedVolume: false,
        } : {}
        switch (key) {
            case 'requestedYearVolume': case 'adjustedVolume': case 'comment':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                    },
                    ajustEstim: false,
                })
                break
            case 'idUsage':
                const link_periodes = value === 3 && !usage?.link_periodes?.length ? [{
                    idInstallation: point.idInstallation,
                    idSurvey: declaration.idSurvey,
                    idUsage: usage.idProvisionalUsage || -1,
                    idPeriode: 2,
                    idNbPeriode: 1,
                }, {
                    idInstallation: point.idInstallation,
                    idSurvey: declaration.idSurvey,
                    idUsage: usage.idProvisionalUsage || -1,
                    idPeriode: 2,
                    idNbPeriode: 2,
                }] : usage.link_periodes
                this.setState({
                    usage: {
                        ...new DtoUsageDeclaration({}),
                        idProvisionalUsage: usage.idProvisionalUsage,
                        [key]: value,
                        ...usageReset,
                        link_periodes,
                    },
                    ajustEstim: false,
                })
                break
            case 'idFamille':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        idCulture: null,
                        ...usageReset,
                        nbRotation: 1
                    },
                    ajustEstim: false,
                })
                break
            case 'idCulture':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        ...usageReset,
                        nbRotation: 1
                    },
                    ajustEstim: false,
                })
                break
            case 'irrigationType':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        irrigationMode: null,
                        ...usageReset,
                    },
                    ajustEstim: false,
                })
                break
            default:
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        ...usageReset,
                    },
                    ajustEstim: false,
                })
                break
        }
    }

    handleChangePeriode = (select, code, usage) => {
        const { point, declaration } = this.props
        let newUsagePeriodes = []
        if (select) {
            newUsagePeriodes.push({
                idInstallation: point.idInstallation,
                idSurvey: declaration.idSurvey,
                idUsage: usage.idProvisionalUsage || -1,
                idPeriode: code,
                idNbPeriode: null,
            })
        } else {
            if (usage.link_periodes.find((p) => p.idNbPeriode === code)) {
                newUsagePeriodes = [
                    ...usage.link_periodes,
                ]
                newUsagePeriodes = newUsagePeriodes.filter((p) => p.idNbPeriode !== null && p.idNbPeriode !== code)
            } else {
                newUsagePeriodes = [
                    ...usage.link_periodes,
                    {
                        idInstallation: point.idInstallation,
                        idSurvey: declaration.idSurvey,
                        idUsage: usage.idProvisionalUsage || -1,
                        idPeriode: 2,
                        idNbPeriode: code,
                    }
                ]
                newUsagePeriodes = newUsagePeriodes.filter((p) => p.idNbPeriode !== null)
            }
            if (!newUsagePeriodes.length) {
                newUsagePeriodes.push({
                    idInstallation: point.idInstallation,
                    idSurvey: declaration.idSurvey,
                    idUsage: usage.idProvisionalUsage || -1,
                    idPeriode: 2,
                    idNbPeriode: null,
                })
            }
        }
        this.handleChange('link_periodes', newUsagePeriodes)
    }

    getHeader = (usagesCategory, usage) => {
        const { culturesFamilies, cultures, codesSandre, modesIrrigations, currentYear } = this.props
        const { step } = this.state

        const typesIrrig = codesSandre.filter((code) => code.field === 'IRRIGATION.CATEGORIES')
        const typesSols = codesSandre.filter((code) => code.field === 'USAGES.TYPES_SOLS')
        const profondeursSol = codesSandre.filter((code) => code.field === 'USAGES.PROFONDEUR_SOLS')
        const requestsPrelevement = codesSandre.filter((code) => code.field === 'USAGES.TYPES')

        const culture = cultures.find((c) => c.id === usage.idCulture)
        const culturesFamily = culturesFamilies.find((cF) => cF.id === culture?.idFamille)
        const irrigationMode = modesIrrigations.find((iM) => iM.id === usage.irrigationMode)
        const irrigationType = typesIrrig.find((iT) => iT.code === usage.irrigationType)
        const groundType = typesSols.find((gT) => gT.code === usage.groundType)
        const groundDepth = profondeursSol.find((gD) => gD.code === usage.groundDepth)
        const prelevementRequest = requestsPrelevement.find(
            (pR) => pR.code === usage.requestPrelevement,
        )

        return (
            <b>
                <span>{`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}</span>
                <br />
                {culturesFamily && culture && (
                    <>
                        <span>
                            {culturesFamily.name} / {culture?.name}
                        </span>
                        <br />
                    </>
                )}
                {irrigationType && irrigationMode && (
                    <>
                        <span>
                            {irrigationType.name} / {irrigationMode.name}
                        </span>
                        <br />
                    </>
                )}
                {hasValue(usage.area) && groundType && groundDepth && usage.nbRotation && (
                    <>
                        <span>
                            {`${usage.area} ha / ${groundType.name} / ${groundDepth.name} / ${usage.nbRotation} RC`}
                        </span>
                        <br />
                    </>
                )}
                {step === 5 && prelevementRequest && (
                    <>
                        <span>{prelevementRequest.name}</span>
                        <br />
                    </>
                )}
                {!currentYear && step === 0 && usage.requestedYearVolume && (
                    <p>
                        <b>{`${i18n.indicativeSimu} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}</b>
                    </p>
                )}
            </b>
        )
    }

    getSelectedPeriod = (idPeriode, usagePeriodes) => {
        return !!usagePeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    getTotal = (usage) => {
        const getValue = (value) => {
            return hasValue(value) ? parseFloat(value) : 0
        }
        return getValue(usage.month01) + getValue(usage.month02) + getValue(usage.month03) + getValue(usage.month04) +
        getValue(usage.month05) + getValue(usage.month06) + getValue(usage.month07) + getValue(usage.month08) +
        getValue(usage.month09) + getValue(usage.month10) + getValue(usage.month11) + getValue(usage.month12)
    }

    onChangeLowWater = (usage, value, firstMonth, lastMonth) => {
        if (usage.month01 || usage.month02 || usage.month03 || usage.month04 || usage.month05 || usage.month06 || usage.month07 || usage.month08 || usage.month09 || usage.month10 || usage.month11 || usage.month12) {
            this.handleChange(
                'lowWaterVolume',
                parseInt(value),
            )
            return
        }
        const nbMonth = (lastMonth - firstMonth) + 1
        const valueByMonth = value / nbMonth
        const usageUpdated = {
            ...usage,
            lowWaterVolume: value,
            month01: (firstMonth <= 1 && lastMonth >= 1) ? valueByMonth : undefined,
            month02: (firstMonth <= 2 && lastMonth >= 2) ? valueByMonth : undefined,
            month03: (firstMonth <= 3 && lastMonth >= 3) ? valueByMonth : undefined,
            month04: (firstMonth <= 4 && lastMonth >= 4) ? valueByMonth : undefined,
            month05: (firstMonth <= 5 && lastMonth >= 5) ? valueByMonth : undefined,
            month06: (firstMonth <= 6 && lastMonth >= 6) ? valueByMonth : undefined,
            month07: (firstMonth <= 7 && lastMonth >= 7) ? valueByMonth : undefined,
            month08: (firstMonth <= 8 && lastMonth >= 8) ? valueByMonth : undefined,
            month09: (firstMonth <= 9 && lastMonth >= 9) ? valueByMonth : undefined,
            month10: (firstMonth <= 10 && lastMonth >= 10) ? valueByMonth : undefined,
            month11: (firstMonth <= 11 && lastMonth >= 11) ? valueByMonth : undefined,
            month12: (firstMonth <= 12 && lastMonth >= 12) ? valueByMonth : undefined,
        }
        this.setState({ usage: usageUpdated })
    }

    getResumeStep = (usagesCategory, usage) => {
        const { currentYear } = this.props
        return (
            <>
                <h2 id='modal-title'>Usage</h2>
                <p>
                    <b>{`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}</b>
                </p>
                {(!currentYear || this.showVolumeInput(usagesCategory)) &&
                    <p>
                        <b>{`${i18n.askedLabel} : ${formatMilliers(usage.requestedYearVolume) || 0} m3 (${i18n.etiage} : ${hasValue(usage.lowWaterVolume) ? usage.lowWaterVolume : i18n.unknown})`}</b>
                    </p>
                }
            </>
        )
    }

    getUsagesListStep = (usage) => {
        const { usagesCategories } = this.props
        const agriUsagesCat = sortBy(usagesCategories.filter((uCat) => uCat.category === 1), 'order')
        const nonAgriUsagesCat = sortBy(usagesCategories.filter((uCat) => uCat.category === 2), 'order')
        return (
            <RadioGroup
                name='idUsage'
                value={usage.idUsage}
                onChange={(event) =>
                    this.handleChange('idUsage', parseInt(event.target.value))
                }
            >
                <SubTitleCard bold left>
                    {i18n.agri}
                </SubTitleCard>
                {agriUsagesCat.map((uCat) => {
                    return (
                        <FormControlLabel
                            value={uCat.idUsage}
                            control={<Radio />}
                            label={uCat.description}
                        />
                    )
                })}
                <SubTitleCard bold left>
                    {i18n.nonAgri}
                </SubTitleCard>
                {nonAgriUsagesCat.map((uCat) => {
                    return (
                        <FormControlLabel
                            value={uCat.idUsage}
                            control={<Radio />}
                            label={uCat.description}
                        />
                    )
                })}
            </RadioGroup>
        )
    }

    getCultureStep = (usagesCategory, usage) => {
        const { culturesFamilies, cultures } = this.props
        const { errors } = this.state
        const culture = cultures.find((c) => c.id === usage.idCulture)
        const idFamille = culture ? culture.idFamille : usage.idFamille
        return (
            <>
                {this.getHeader(usagesCategory, usage)}
                <div style={{ margin: '30px 0' }}>
                    <FormControlRow variant='outlined'>
                        <InputLabel>{i18n.culturesFamily}</InputLabel>
                        <Select
                            id='familyCulture'
                            label={i18n.culturesFamily}
                            onChange={(event) =>
                                this.handleChange(
                                    'idFamille',
                                    parseInt(event.target.value),
                                )
                            }
                            value={idFamille}
                            error={errors.find((e) => e === 'idFamille')}
                        >
                            {orderBy(culturesFamilies, 'name').map((cF) => {
                                return (
                                    <MenuItem
                                        value={cF.id}
                                        style={{ maxWidth: '100%' }}
                                    >
                                        {cF.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControlRow>
                    <FormControlRow variant='outlined'>
                        <InputLabel>{i18n.culture}</InputLabel>
                        <Select
                            id='culture'
                            label={i18n.culture}
                            onChange={(event) =>
                                this.handleChange(
                                    'idCulture',
                                    parseInt(event.target.value),
                                )
                            }
                            value={usage.idCulture}
                            error={errors.find((e) => e === 'idCulture')}
                        >
                            {orderBy(cultures.filter((c) => c.idFamille === idFamille && (c.status !== 2 || (usage.idCulture && usage.idCulture === c.id))), 'name')
                                .map((c) => {
                                    return <MenuItem value={c.id}>{c.name}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControlRow>
                </div>
            </>
        )
    }

    getIrrigationCultureStep = (usagesCategory, usage, required) => {
        const { codesSandre, modesIrrigations } = this.props
        const { errors } = this.state
        const typesIrrig = codesSandre.filter((code) => code.field === 'IRRIGATION.CATEGORIES')
        return (
            <>
                {this.getHeader(usagesCategory, usage)}
                <div style={{ margin: '30px 0' }}>
                    <FormControlRow variant='outlined'>
                        <InputLabel>{i18n.mainTypeIrrigation}</InputLabel>
                        <Select
                            id='irrigationType'
                            label={i18n.mainTypeIrrigation}
                            value={usage.irrigationType}
                            onChange={(event) =>
                                this.handleChange(
                                    'irrigationType',
                                    parseInt(event.target.value),
                                )
                            }
                            error={errors.find((e) => e === 'irrigationType')}
                            required={required}
                        >
                            {orderBy(typesIrrig, 'name').map((type) => {
                                return (
                                    <MenuItem
                                        value={type.code}
                                        style={{ maxWidth: '100%' }}
                                    >
                                        {type.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControlRow>
                    <FormControlRow variant='outlined'>
                        <InputLabel>{i18n.modeIrrigation}</InputLabel>
                        <Select
                            id='irrigationMode'
                            label={i18n.modeIrrigation}
                            value={usage.irrigationMode}
                            onChange={(event) =>
                                this.handleChange(
                                    'irrigationMode',
                                    parseInt(event.target.value),
                                )
                            }
                            error={errors.find((e) => e === 'irrigationMode')}
                            required={required}
                        >
                            {orderBy(modesIrrigations.filter((mode) => mode.categoryId === usage.irrigationType), 'name')
                                .map((mode) => {
                                    return (
                                        <MenuItem value={mode.id}>{mode.name}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControlRow>
                </div>
            </>
        )
    }

    getContent = (step, usage) => {
        const {
            cultures,
            codesSandre,
            usagesCategories,
            currentYear,
            applicationSettings,
            managementUnits,
            installation,
        } = this.props
        const { errors, ajustEstim, volume } = this.state
        const usagesCategory = usagesCategories.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        const culture = cultures.find((c) => c.id === usage.idCulture)

        if (usagesCategory?.simplifiedCulture) {
            switch (step) {
                case RESUME_STEP:
                    return this.getResumeStep(usagesCategory, usage)
                default: case USAGE_STEP:
                    return this.getUsagesListStep(usage)
                case CULTURE_VOLUME_STEP:
                    return this.getCultureStep(usagesCategory, usage)
                case IRRIG_PERIOD_STEP:
                    return this.getIrrigationCultureStep(usagesCategory, usage, false)
                case GROUND_COMMENT_STEP:
                    return (
                        <>
                            <p>
                                <b>{`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}</b>
                            </p>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputRow
                                        item
                                        id='area'
                                        label={i18n.surface}
                                        type='number'
                                        inputProps={{ min: 0 }}
                                        value={usage.area}
                                        onChange={(event) =>
                                            this.handleChange('area', event.target.value)
                                        }
                                        variant='outlined'
                                        error={errors.find((e) => e === 'area')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputRow
                                        item
                                        id='requestedYearVolume'
                                        label={i18n.volume}
                                        type='number'
                                        value={usage.requestedYearVolume}
                                        inputProps={{ min: 0 }}
                                        onChange={(event) =>
                                            this.handleChange(
                                                'requestedYearVolume',
                                                parseInt(event.target.value),
                                                )
                                        }
                                        error={errors.find((e) => e === 'requestedYearVolume')}
                                        variant='outlined'
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )
            }
        }

        if (usagesCategory?.isCulture) {
            switch (step) {
                case RESUME_STEP:
                    return this.getResumeStep(usagesCategory, usage)
                default: case USAGE_STEP:
                    return this.getUsagesListStep(usage)
                case CULTURE_VOLUME_STEP:
                    return this.getCultureStep(usagesCategory, usage)
                case IRRIG_PERIOD_STEP:
                    return this.getIrrigationCultureStep(usagesCategory, usage, false)
                case GROUND_COMMENT_STEP:
                    const typesSols = codesSandre.filter((code) => code.field === 'USAGES.TYPES_SOLS')
                    const profondeursSol = codesSandre.filter((code) => code.field === 'USAGES.PROFONDEUR_SOLS')
                    const nbRotationsCultMax = getSettingInt(applicationSettings, 'nbRotationsCultMax') || 6
                    const optionRotationsCult = []
                    for (let i = 1; i <= nbRotationsCultMax; i++) {
                        optionRotationsCult.push(String(i))
                    }
                    return (
                        <>
                            {this.getHeader(usagesCategory, usage)}
                            <div style={{ margin: '30px 0' }}>
                                <InputRow
                                    item
                                    id='area'
                                    label={i18n.surface}
                                    type='number'
                                    inputProps={{ min: 0 }}
                                    value={usage.area}
                                    onChange={(event) =>
                                        this.handleChange('area', event.target.value)
                                    }
                                    variant='outlined'
                                    error={errors.find((e) => e === 'area')}
                                />
                                <FormControlRow variant='outlined'>
                                    <InputLabel>{i18n.typeSol}</InputLabel>
                                    <Select
                                        id='groundType'
                                        label={i18n.typeSol}
                                        value={usage.groundType}
                                        onChange={(event) =>
                                            this.handleChange('groundType', event.target.value)
                                        }
                                        error={errors.find((e) => e === 'groundType')}
                                    >
                                        {orderBy(typesSols, 'name').map((type) => {
                                            return (
                                                <MenuItem
                                                    value={type.code}
                                                    style={{ maxWidth: '100%' }}
                                                >
                                                    {type.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControlRow>
                                <FormControlRow variant='outlined'>
                                    <InputLabel>{i18n.profondeurSol}</InputLabel>
                                    <Select
                                        id='groundDepth'
                                        label={i18n.profondeurSol}
                                        value={usage.groundDepth}
                                        onChange={(event) =>
                                            this.handleChange('groundDepth', event.target.value)
                                        }
                                        error={errors.find((e) => e === 'groundDepth')}
                                    >
                                        {orderBy(profondeursSol, 'name').map((type) => {
                                            return (
                                                <MenuItem
                                                    value={type.code}
                                                    style={{ maxWidth: '100%' }}
                                                >
                                                    {type.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControlRow>
                                <FormControlRow variant='outlined'>
                                    <InputLabel>{i18n.nbRotationsCult}</InputLabel>
                                    <Select
                                        id='nbRotation'
                                        label={i18n.nbRotationsCult}
                                        defaultValue={1}
                                        value={usage.nbRotation}
                                        onChange={(event) =>
                                            this.handleChange(
                                                'nbRotation',
                                                parseInt(event.target.value),
                                            )
                                        }
                                        disabled={culture.limitCropRotations}
                                        error={errors.find((e) => e === 'nbRotation')}
                                    >
                                        {optionRotationsCult.map((v) => <MenuItem value={v}>{v}</MenuItem>)}
                                    </Select>
                                </FormControlRow>
                            </div>
                        </>
                    )
                case REQUEST_STEP:
                    const requestsPrelevement = codesSandre.filter((code) => code.field === 'USAGES.TYPES')
                    return (
                        <>
                            {this.getHeader(usagesCategory, usage)}
                            <RadioGroup
                                name='requestPrelevement'
                                value={usage.requestPrelevement}
                                onChange={(event) =>
                                    this.handleChange(
                                        'requestPrelevement',
                                        parseInt(event.target.value),
                                    )
                                }
                                style={{ margin: '30px 0' }}
                                error={errors.find((e) => e === 'requestPrelevement')}
                            >
                                <SubTitleCard bold left>
                                    {i18n.requestPrelevement}
                                </SubTitleCard>
                                {requestsPrelevement.map((type) => {
                                    return (
                                        <FormControlLabel
                                            value={type.code}
                                            control={<Radio />}
                                            label={type.name}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </>
                    )
                case ADJUST_STEP:
                    return (
                        <>
                            <div style={{ height: 'fit-content' }}>
                                {this.getHeader(usagesCategory, usage)}
                                {usagesCategory.calculAuto ? (
                                    <>
                                        <p>
                                            {!currentYear ? (
                                                <>
                                                    <b>{`${i18n.indicativeSimu} : ${formatMilliers((ajustEstim || usage.adjustedVolume) ? volume : usage.requestedYearVolume) || 0} m3`}</b>
                                                    {!(ajustEstim || usage.adjustedVolume) ? (
                                                        <OrangeButton
                                                            onClick={() => this.setState({ ajustEstim: true })}
                                                            margintop
                                                        >
                                                            {i18n.ajustEstim}
                                                        </OrangeButton>
                                                    ) : (
                                                        <>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={6}>
                                                                    <MainButton
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                usage:
                                                                                {
                                                                                    ...usage,
                                                                                    requestedYearVolume: (usage.requestedYearVolume - Math.trunc(volume / 10)),
                                                                                    adjustedVolume: (usage.requestedYearVolume - Math.trunc(volume / 10)) !== usage.requestedYearVolume,
                                                                                }
                                                                            })
                                                                        }}
                                                                        disabled={(usage.requestedYearVolume - Math.trunc(volume / 10)) < 0}
                                                                        margintop
                                                                    >
                                                                        Enlever 10 %
                                                                    </MainButton>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <MainButton
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                usage:
                                                                                {
                                                                                    ...usage,
                                                                                    requestedYearVolume: (usage.requestedYearVolume + Math.trunc(volume / 10)),
                                                                                    adjustedVolume: (usage.requestedYearVolume + Math.trunc(volume / 10)) !== usage.requestedYearVolume,
                                                                                }
                                                                            })
                                                                        }}
                                                                        margintop
                                                                    >
                                                                        Ajouter 10 %
                                                                    </MainButton>
                                                                </Grid>
                                                            </Grid>
                                                            <p style={{ color: 'red' }}>
                                                                <b>{`${i18n.ajustedSimu} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}</b>
                                                            </p>
                                                        </>
                                                    )}
                                                </>
                                            ) : ''}
                                        </p>
                                    </>
                                ) : (
                                    <div className='padding-top-2'>
                                        <InputRow
                                            item
                                            id='requestedYearVolume'
                                            label={i18n.volume}
                                            type='number'
                                            value={usage.requestedYearVolume}
                                            inputProps={{ min: 0 }}
                                            onChange={(event) =>
                                                this.handleChange(
                                                    'requestedYearVolume',
                                                    parseInt(event.target.value),
                                                )
                                            }
                                            error={errors.find((e) => e === 'requestedYearVolume')}
                                            variant='outlined'
                                        />
                                        <InputRow
                                            item
                                            id='lowWaterVolume'
                                            label={i18n.lowWaterVolume}
                                            type='number'
                                            value={usage.lowWaterVolume}
                                            inputProps={{ min: 0 }}
                                            onChange={(event) =>
                                                this.handleChange(
                                                    'lowWaterVolume',
                                                    parseInt(event.target.value),
                                                )
                                            }
                                            variant='outlined'
                                        />
                                    </div>
                                )}
                            </div>
                            <InputRow
                                item
                                id='comment'
                                type='text'
                                variant='outlined'
                                placeholder={!currentYear ? usagesCategory.comment : 'Je laisse un commentaire si besoin.'}
                                value={usage.comment}
                                onChange={(event) => this.handleChange('comment', event.target.value)}
                                multiline
                                rows={10}
                                rowsMax={Infinity}
                                InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                            />
                        </>
                    )
            }
        }

        switch (step) {
            case RESUME_STEP:
                return this.getResumeStep(usagesCategory, usage)
            default: case USAGE_STEP:
                return this.getUsagesListStep(usage)
            case CULTURE_VOLUME_STEP:
                const uge = managementUnits.find((m) => m.managementCode === (installation.subManagementCode || installation.managementCode)) || {}
                const firstMonth = uge.lowWaterStartDate ? moment(uge.lowWaterStartDate).month() + 1 : 1
                const lastMonth = uge.lowWaterEndDate ? moment(uge.lowWaterEndDate).month() + 1 : 12
                const total = this.getTotal(usage) || 0
                return (
                    <>
                        <p>
                            <b>{`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}</b>
                        </p>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <InputRow
                                    item
                                    id='requestedYearVolume'
                                    label={i18n.volume}
                                    type='number'
                                    value={usage.requestedYearVolume}
                                    inputProps={{ min: 0 }}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'requestedYearVolume',
                                            parseInt(event.target.value),
                                            )
                                    }
                                    error={errors.find((e) => e === 'requestedYearVolume')}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputRow
                                    item
                                    id='lowWaterVolume'
                                    label={i18n.lowWaterVolume}
                                    type='number'
                                    value={usage.lowWaterVolume}
                                    inputProps={{ min: 0 }}
                                    onChange={(e) => this.onChangeLowWater(usage, parseInt(e.target.value), firstMonth, lastMonth)}
                                    variant='outlined'
                                    error={(usage.lowWaterVolume || 0) !== total}
                                    sx={{
                                      '& .Mui-error': { '-webkit-text-fill-color': `${mainRed} !important` },
                                    }}
                                />
                            </Grid>
                            {!currentYear && (
                                <StyledFieldSet className='margin-1'>
                                    <StyledLegend>{i18n.volumeByMonth}</StyledLegend>
                                    <Grid container spacing={1}>
                                        {firstMonth <= 1 && lastMonth >= 1 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month01'
                                                    label={i18n.januaryShort}
                                                    type='number'
                                                    value={usage.month01}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month01', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        {firstMonth <= 2 && lastMonth >= 2 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month02'
                                                    label={i18n.februaryShort}
                                                    type='number'
                                                    value={usage.month02}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month02', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        {firstMonth <= 3 && lastMonth >= 3 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month03'
                                                    label={i18n.marchShort}
                                                    type='number'
                                                    value={usage.month03}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month03', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        {firstMonth <= 4 && lastMonth >= 4 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month04'
                                                    label={i18n.aprilShort}
                                                    type='number'
                                                    value={usage.month04}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month04', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        {firstMonth <= 5 && lastMonth >= 5 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month05'
                                                    label={i18n.mayShort}
                                                    type='number'
                                                    value={usage.month05}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month05', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        {firstMonth <= 6 && lastMonth >= 6 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month06'
                                                    label={i18n.juneShort}
                                                    type='number'
                                                    value={usage.month06}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month06', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        {firstMonth <= 7 && lastMonth >= 7 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month07'
                                                    label={i18n.julyShort}
                                                    type='number'
                                                    value={usage.month07}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month07', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        {firstMonth <= 8 && lastMonth >= 8 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month08'
                                                    label={i18n.augustShort}
                                                    type='number'
                                                    value={usage.month08}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month08', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        {firstMonth <= 9 && lastMonth >= 9 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month09'
                                                    label={i18n.septemberShort}
                                                    type='number'
                                                    value={usage.month09}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month09', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        {firstMonth <= 10 && lastMonth >= 10 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month10'
                                                    label={i18n.octoberShort}
                                                    type='number'
                                                    value={usage.month10}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month10', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        {firstMonth <= 11 && lastMonth >= 11 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month11'
                                                    label={i18n.novemberShort}
                                                    type='number'
                                                    value={usage.month11}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month11', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        {firstMonth <= 12 && lastMonth >= 12 && (
                                            <Grid item xs={12} md={1}>
                                                <InputRow
                                                    id='month12'
                                                    label={i18n.decemberShort}
                                                    type='number'
                                                    value={usage.month12}
                                                    inputProps={{ min: 0 }}
                                                    onChange={e => this.handleChange('month12', parseFloat(e.target.value))}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={1}>
                                            <InputRow
                                                id='totalMonths'
                                                label={i18n.total}
                                                value={total}
                                                disabled
                                                variant='outlined'
                                                error={(usage.lowWaterVolume || 0) !== total}
                                                sx={{
                                                  '& .Mui-error .Mui-disabled': { '-webkit-text-fill-color': `${mainRed} !important`, borderColor: `${mainRed} !important` },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </StyledFieldSet>
                            )}
                        </Grid>
                    </>
                )
            case IRRIG_PERIOD_STEP:
                const usagesPeriodes = codesSandre.filter((code) => code.field === 'USAGES.PERIODES')
                const usagesPeriodesDetails = codesSandre.filter((code) => code.field === 'USAGES.PERIODES_DETAILS')
                const usageSaisonnal = usage.link_periodes.find((p) => p.idPeriode === 2)
                const usageAnnuel = usage.link_periodes.find((p) => p.idPeriode === 1)
                return (
                    <>
                        <p>
                            <b>{`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}</b>
                        </p>
                        {!currentYear &&
                            <p>
                                <b>{`${i18n.askedLabel} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}</b>
                            </p>
                        }
                        <FormControlRow variant='outlined'>
                            <InputLabel>{i18n.usePeriod}</InputLabel>
                            <Select
                                id='usePeriod'
                                label={i18n.usePeriod}
                                value={usageSaisonnal ? 2 : usageAnnuel ? 1 : null}
                                onChange={(e) => this.handleChangePeriode(true, e.target.value, usage)}
                                error={errors.find((e) => e === 'link_periodes')}
                            >
                                {usagesPeriodes.map((p) => <MenuItem value={p.code}>{p.name}</MenuItem>)}
                            </Select>
                        </FormControlRow>
                        <FormGroup>
                            {usageSaisonnal && usagesPeriodesDetails.map((p) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={p.code}
                                            checked={this.getSelectedPeriod(p.code, usage.link_periodes)}
                                            onChange={(e) => this.handleChangePeriode(false, parseInt(e.target.value), usage)}
                                        />
                                    }
                                    label={p.name}
                                />
                            ))}
                            {errors.find((e) => e === 'link_periodes_checkboxes') && (
                                <FormHelperText style={{ color: 'red' }}>{i18n.noneOptionSelected}</FormHelperText>
                            )}
                        </FormGroup>
                    </>
                )
            case GROUND_COMMENT_STEP:
                return (
                    <>
                        <div style={{ height: 'fit-content' }}>
                            <p>
                                <b>{`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}</b>
                            </p>
                            {!currentYear &&
                                <p>
                                    <b>{`${i18n.askedLabel} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}</b>
                                </p>
                            }
                        </div>
                        <InputRow
                            item
                            id='comment'
                            type='text'
                            variant='outlined'
                            placeholder={usagesCategory.comment || 'Je laisse un commentaire si besoin.'}
                            value={usage.comment}
                            onChange={(event) => this.handleChange('comment', event.target.value)}
                            multiline
                            rows={10}
                            rowsMax={Infinity}
                            style={{ minHeight: '65%' }}
                            InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                        />
                    </>
                )
        }
    }

    onDelete = () => {
        const { usages, usage } = this.state
        const newUsages = usages.filter((u) => u.idProvisionalUsage !== usage.idProvisionalUsage)
        this.props.onDelete(newUsages)
    }

    onSave = () => {
        const { usages, usage } = this.state
        const { installation, exploitation, declaration, currentYear, surveys } = this.props
        const enquete = surveys.find((s) => s.idSurvey === declaration.idSurvey) || {}
        const formattedUsage = {
            ...usage,
            area: usage.idCulture ? round(usage.area, 4) : null,
            nbRotation: usage.idCulture ? usage.nbRotation : null,
            requestPrelevement: usage.idCulture ? usage.requestPrelevement : null,
            currentYear: usage.currentYear || currentYear,
            idExploitation: exploitation.idExploitation,
            idInstallation: installation.id,
            idSurvey: declaration.idSurvey,
            idProvisionalUsage: usage.idProvisionalUsage || null,
            requestedYear: enquete.year,
            volumesMonths: `${usage.month01 || ''};${usage.month02 || ''};${usage.month03 || ''};${usage.month04 || ''};${usage.month05 || ''};${usage.month06 || ''};${usage.month07 || ''};${usage.month08 || ''};${usage.month09 || ''};${usage.month10 || ''};${usage.month11 || ''};${usage.month12 || ''}`,
        }
        if (usage.idProvisionalUsage) {
            const newUsages = usages.filter(
                (u) => u.idProvisionalUsage !== usage.idProvisionalUsage,
            )
            this.props.onSave([
                ...newUsages,
                formattedUsage,
            ])
        } else {
            this.props.onSave([
                ...usages,
                formattedUsage,
            ])
        }
    }

    onClose = () => {
        this.props.onCancel()
    }

    toggleHelp = () => {
        const { openHelp } = this.state
        this.setState({ openHelp: !openHelp })
    }

    getContentHelp = (step, usage) => {
        const { cms, usagesCategories } = this.props
        const usagesCategory = usagesCategories.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        let cmsFind = {}
        switch (step) {
            case USAGE_STEP:
                cmsFind = cms.find((c) => c.id === 30)
                return cmsFind ? cmsFind.comment : ''
            case CULTURE_VOLUME_STEP:
                if (usagesCategory.isCulture) {
                    cmsFind = cms.find((c) => c.id === 31)
                    return cmsFind ? cmsFind.comment : ''
                }
                cmsFind = cms.find((c) => c.id === 36)
                return cmsFind ? cmsFind.comment : ''
            case IRRIG_PERIOD_STEP:
                if (usagesCategory.isCulture) {
                    cmsFind = cms.find((c) => c.id === 31)
                    return cmsFind ? cmsFind.comment : ''
                }
                cmsFind = cms.find((c) => c.id === 37)
                return cmsFind ? cmsFind.comment : ''
            case GROUND_COMMENT_STEP:
                if (usagesCategory.isCulture) {
                    cmsFind = cms.find((c) => c.id === 32)
                    return cmsFind ? cmsFind.comment : ''
                }
                cmsFind = cms.find((c) => c.id === 35)
                return cmsFind ? cmsFind.comment : ''
            case REQUEST_STEP:
                cmsFind = cms.find((c) => c.id === 33)
                return cmsFind ? cmsFind.comment : ''
            case ADJUST_STEP:
                cmsFind = cms.find((c) => c.id === 34)
                return cmsFind ? cmsFind.comment : ''
            default:
                return ''
        }
    }

    render() {
        const { step, usage, openHelp } = this.state
        const { open, usagesCategories } = this.props
        const usageCat = usagesCategories.find((uCat) => uCat.idUsage === usage.idUsage)

        return (
            <DialogMUI open={open}>
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <b style={{ padding: 10, fontSize: 21 }}>{i18n.newUse}</b>
                        <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={this.onClose} />
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI>
                    {this.getContent(step, usage)}
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        {!step ? (
                            <>
                                <RedButton
                                    onClick={this.onDelete}
                                >
                                    {i18n.deleteUsage}
                                </RedButton>
                                <MainButton
                                    onClick={this.nextStep}
                                >
                                    {i18n.changeUsage}
                                </MainButton>
                                <MainButton
                                    reverse='true'
                                    onClick={this.onClose}
                                >
                                    {i18n.cancel}
                                </MainButton>
                            </>
                        ) : (
                            <>
                                <Grid item xs={4}>
                                    {step !== 1 && (
                                        <MainButton
                                            onClick={this.previousStep}
                                        >
                                            <Icon>keyboard_arrow_left</Icon>
                                            {i18n.previous}
                                        </MainButton>
                                    )}
                                </Grid>
                                <HelpTooltip
                                    title={(<div dangerouslySetInnerHTML={{ __html: this.getContentHelp(step, usage) }} />)}
                                    open={openHelp}
                                    placement='top'
                                    arrow
                                    disableHoverListener
                                >
                                    <Grid item xs={3}>
                                        <MainButton onClick={this.toggleHelp}>
                                            {isLargeWindow() ? i18n.help : <Icon>help</Icon>}
                                        </MainButton>
                                    </Grid>
                                </HelpTooltip>
                                <Grid item xs={4}>
                                    {(usageCat.isCulture && step !== 6) ||
                                        (!usageCat.isCulture && step !== 4) ? (
                                        <MainButton
                                            onClick={this.nextStep}
                                        >
                                            {i18n.next}
                                            <Icon>keyboard_arrow_right</Icon>
                                        </MainButton>
                                    ) : (
                                        <MainButton
                                            onClick={this.onSave}
                                        >
                                            {i18n.validate}
                                        </MainButton>
                                    )}
                                </Grid>
                                <MainButton
                                    reverse='true'
                                    onClick={this.onClose}
                                    marginTop
                                >
                                    {i18n.cancel}
                                </MainButton>
                            </>
                        )}
                    </Grid>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }
}

ModalUsage.propTypes = {
    usage: PropTypes.instanceOf(PropTypes.shape({})),
    point: PropTypes.instanceOf(DtoSamplingPointDecla),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageDeclaration)),
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    calculVolumeUsage: PropTypes.func,
    open: PropTypes.bool,
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    modesIrrigations: PropTypes.arrayOf(PropTypes.instanceOf()),
    usagesCategories: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageCategory)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    currentYear: PropTypes.bool,
    surveys: PropTypes.arrayOf(PropTypes.shape({})),
    countersId: PropTypes.arrayOf(PropTypes.number),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
        codesSandre: store.ReferencialReducer.codesSandre,
        cultures: store.ReferencialReducer.cultures,
        culturesFamilies: store.ReferencialReducer.culturesFamilies,
        usagesCategories: store.ReferencialReducer.usagesCategories,
        modesIrrigations: store.AgriReducer.modesIrrigations,
        installation: store.InstallationsReducer.installation,
        installations: store.InstallationsReducer.installations,
        cms: store.CmsReducer.cms,
        surveys: store.AgriReducer.surveys,
        applicationSettings: store.HomeReducer.applicationSettings,
        managementUnits: store.ReferencialReducer.managementUnits,
    }
}

const mapDispatchToProps = {
    calculVolumeUsage: AgriAction.calculVolumeUsage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUsage)
