'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_CULTURE,
    RECEIVE_CULTURES,
    RECEIVE_CULTURES_FAMILY,
    RECEIVE_CULTURES_FAMILIES,
    RECEIVE_CONTRIBUTOR,
    RECEIVE_CONTRIBUTORS,
    RECEIVE_USAGE,
    RECEIVE_USAGES,
    RECEIVE_SANDRE_CODE,
    RECEIVE_SANDRE_CODES_FIELD,
    RECEIVE_SANDRE_CODES,
    RECEIVE_MANAGEMENT_UNITS,
    RECEIVE_ALL_WATERSHEDS,
    RECEIVE_ALL_BOOKMARKS,
    RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS,
} from '../constants/ReferencialConstants'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import ToastrAction from '../../components/toasters/ToastrAction'
import LogAction from '../../../../utils/log/actions/LogAction'
import { RECEIVE_TANKS_TYPES } from '../../agri/constants/AgriConstants'

const ReferencialAction = {

    receiveManagementUnitsRestrictions: (managementUnitsRestrictions) => {
        return { type: RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS, managementUnitsRestrictions }
    },
    fetchManagementUnitsRestrictions: () => dispatch => {
        return fetch(ApplicationConf.referencial.managementUnitsRestrictions(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(json => {
                dispatch(ReferencialAction.receiveManagementUnitsRestrictions(json))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.restrictions} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.restrictions))
            })
    },

    receiveAllBookmarks(bookmarks) {
        return { type: RECEIVE_ALL_BOOKMARKS, bookmarks }
    },
    promiseBookmarks() {
        return fetch(ApplicationConf.user.getBookmarks(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },
    fetchBookmarks: () => dispatch => {
        return ReferencialAction.promiseBookmarks()
            .then(json => {
                dispatch(ReferencialAction.receiveAllBookmarks(json))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.bookmarks} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.bookmarks))
            })
    },

    createBookmark(bookmark) {
        return dispatch => {
            return fetch(ApplicationConf.user.setBookmark(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(bookmark),
            })
                .then(checkAuth)
                .then(() => {
                    return dispatch(ReferencialAction.fetchBookmarks())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.bookmarks} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.bookmarks))
                })
        }
    },

    deleteBookmark(bookmark) {
        return dispatch => {
            return fetch(ApplicationConf.user.setBookmark(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(bookmark),
            })
                .then(checkAuth)
                .then(() => {
                    return dispatch(ReferencialAction.fetchBookmarks())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.bookmarks} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.bookmarks))
                })
        }
    },

    receiveWatersheds(watersheds) {
        return { type: RECEIVE_ALL_WATERSHEDS, watersheds }
    },
    promiseWatersheds() {
        return fetch(ApplicationConf.referencial.watersheds(), {
            method: 'GET',
            headers: getAuthorization(),

        })
            .then(checkAuth)
    },
    fetchWatersheds() {
        return (dispatch) => {
            return ReferencialAction.promiseWatersheds()
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveWatersheds(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watersheds} : ${err}`))
                    ToastrAction.error(i18n.fetchError + i18n.watersheds)
                })
        }
    },

    receiveTanksTypes(tanksTypes) {
        return { type: RECEIVE_TANKS_TYPES, tanksTypes }
    },

    fetchTanksTypes() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.tanksTypes(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json) => {
                    dispatch(ReferencialAction.receiveTanksTypes(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.detentionType} : ${err}`)
                })
        }
    },

    receiveCulture(culture) {
        return { type: RECEIVE_CULTURE, culture }
    },

    fetchCulture(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.culture(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    checkError(json)
                    dispatch(ReferencialAction.receiveCulture(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.culture} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.culture)
                })
        }
    },

    receiveCultures(cultures) {
        return { type: RECEIVE_CULTURES, cultures }
    },

    promiseCultures() {
        return fetch(ApplicationConf.referencial.cultures(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchCultures() {
        return (dispatch) => {
            return ReferencialAction.promiseCultures()
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveCultures(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.cultures} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.cultures)
                })
        }
    },

    receiveCulturesFamily(culturesFamily) {
        return { type: RECEIVE_CULTURES_FAMILY, culturesFamily }
    },

    fetchCultureFamily(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.culturesFamily(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    checkError(json)
                    dispatch(ReferencialAction.receiveCulturesFamily(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.culturesFamily} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.culturesFamily)
                })
        }
    },

    receiveCulturesFamilies(culturesFamilies) {
        return { type: RECEIVE_CULTURES_FAMILIES, culturesFamilies }
    },

    promiseCulturesFamilies() {
        return fetch(ApplicationConf.referencial.culturesFamilies(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchCulturesFamilies() {
        return (dispatch) => {
            return ReferencialAction.promiseCulturesFamilies()
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveCulturesFamilies(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.culturesFamilies} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.culturesFamilies)
                })
        }
    },

    receiveContributor(contributor) {
        return { type: RECEIVE_CONTRIBUTOR, contributor }
    },

    fetchContributor(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contributor(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkError)
                .then(getJson)
                .then((json = {}) => {
                    if (json.id) {
                        dispatch(ReferencialAction.receiveContributor(json))
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.contributor} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.contributor)
                })
        }
    },

    receiveContributors(contributors) {
        return { type: RECEIVE_CONTRIBUTORS, contributors }
    },

    promiseContributors() {
        return fetch(ApplicationConf.referencial.contributors(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchContributors() {
        return (dispatch) => {
            return ReferencialAction.promiseContributors()
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveContributors(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.contributors} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.contributors)
                })
        }
    },

    receiveUsage(usage) {
        return { type: RECEIVE_USAGE, usage }
    },

    fetchUsage(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.usage(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    checkError(json)
                    dispatch(ReferencialAction.receiveUsage(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.usage} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.usage)
                })
        }
    },

    receiveUsages(usages) {
        return { type: RECEIVE_USAGES, usages }
    },

    promiseUsages() {
        return fetch(ApplicationConf.referencial.usages(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchUsages() {
        return (dispatch) => {
            return ReferencialAction.promiseUsages()
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveUsages(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.usages} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.usages)
                })
        }
    },

    receiveCodeSandre(sandreCode) {
        return { type: RECEIVE_SANDRE_CODE, sandreCode }
    },

    fetchCodeSandre(field, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.sandreCode(field, code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    checkError(json)
                    dispatch(ReferencialAction.receiveCodeSandre(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.codeSandre} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.codeSandre)
                })
        }
    },

    receiveCodesSandre(codesSandre) {
        return { type: RECEIVE_SANDRE_CODES, codesSandre }
    },

    promiseCodesSandre() {
        return fetch(ApplicationConf.referencial.sandreCodes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchCodesSandre() {
        return (dispatch) => {
            return ReferencialAction.promiseCodesSandre()
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveCodesSandre(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.codesSandre} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.codesSandre)
                })
        }
    },

    receiveCodesSandreByField(codesSandre) {
        return { type: RECEIVE_SANDRE_CODES_FIELD, codesSandre }
    },

    promiseCodesSandreByField(field) {
        return fetch(ApplicationConf.referencial.sandreCodes(field), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchCodesSandreByField(field) {
        return (dispatch) => {
            return ReferencialAction.promiseCodesSandreByField(field)
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveCodesSandreByField(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.codesSandre} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.codesSandre)
                })
        }
    },

    receiveManagementUnits(managementUnits) {
        return { type: RECEIVE_MANAGEMENT_UNITS, managementUnits }
    },

    promiseManagementUnits() {
        return fetch(ApplicationConf.referencial.managementUnits(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchManagementUnits() {
        return (dispatch) => {
            return ReferencialAction.promiseManagementUnits()
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveManagementUnits(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.managementUnits} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.managementUnits)
                })
        }
    },
}

export default ReferencialAction
