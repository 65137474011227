import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid, Icon, LinearProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoCity from '../referencials/city/dto/DtoCity'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import HomeAction from '../../offline/actions/HomeAction'
import { MainButton } from '../components/styled/buttons'
import PointsCard from '../components/cards/PointsCard'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import DtoManagementUnitRestriction from '../referencials/dto/DtoManagementUnitRestriction'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import moment from 'moment'
import { hasValue } from '../../../utils/NumberUtil'
import { compact, omit, round, uniqWith } from 'lodash'
import { getDistance } from 'ol/sphere'
import { getColorAndLabelWaterTurn, getRestrictionIcon, getWaterLeftIcon } from '../../../utils/AgriUtils'
import { ReactComponent as CycleLogo } from '../../../ressources/static/svg/Meteo_CycleIrrigation.svg'
import { ReactComponent as SoleilLogo } from '../../../ressources/static/svg/Meteo_Soleil.svg'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import DtoWaterTurnsSlot from '../agri/dto/DtoWaterTurnsSlot'
import DtoDroughtSectorRestriction from '../agri/dto/DtoDroughtSectorRestriction'
import { getSettingInt } from '../../../utils/FormUtils'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import AgriAction from '../agri/actions/AgriAction'

const styles = () => ({
    title: {
        backgroundColor: '#ccc',
        fontWeight: 'bold',
        color: 'black',
        borderTop: '1px solid grey',
        borderBottom: '1px solid grey',
        padding: '1em 0',
        textAlign: 'center',
    },
    paper: {
        backgroundColor: '#dfdfdf',
        borderBottom: '1px solid grey',
        padding: '8px 25px',
        cursor: 'pointer',
    },
    listItem: {
        borderBottom: '1px solid grey',
        padding: '8px 16px',
    },
})

class PointsHisto extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.props.fetchManagementUnitsRestrictions()
        this.props.fetchDroughtSectorsRestrictions()
        this.props.setTitle([
            {
                title: i18n.historical,
                href: '/account',
            },
        ])
    }

    getPoints = () => {
        const { exploitation, installations, citiesIndex, exploitationVolumes, managementUnitsRestrictions, applicationSettings, droughtSectorsRestrictions, waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots } = this.props
        const year = moment().year()
        return compact((exploitation.link_samplingsHisto || []).map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const volumeAuth = exploitationVolumes.filter((v) => v.askedYear === year && v.idInstallation === point.id && hasValue(v.authorizedVolume)).reduce((acc, v) => acc + v.authorizedVolume, 0)
                const volumeConsumed = uniqWith(exploitation.link_conso_real.filter((c) => c.idInstallation === point.id && c.year === moment().year()).map(c => omit(c, 'idInstallation')), (a, b) => a.idMat === b.idMat && a.year === b.year && a.volume === b.volume).reduce((acc, v) => acc + (v.volume || 0), 0)
                const percentLeft = ((volumeAuth - volumeConsumed) / volumeAuth) * 100
                const city = citiesIndex[point.townCode] || {}
                const long = localStorage.getItem('LONG')
                const lat = localStorage.getItem('LAT')
                const { color, label } = getColorAndLabelWaterTurn(waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots, point)
                const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
                const restriction = (() => {
                    if (typeRestriction === 1) {
                        return droughtSectorsRestrictions.find((dsR) => dsR.idSector === point.droughtSector && point.sampleType === dsR.resourceType) || { idRestriction: -1 }
                    }
                    return managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.subManagementCode && point.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.managementCode && point.sampleType === ugeR.resourceType) || { idRestriction: -1 }
                })()
                const waterTurnsRestriction = waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)
                const restrictionLabel = waterTurnsRestriction?.label || i18n.normale
                const restrictionColor = waterTurnsRestriction?.color || '#00AF64'
                return {
                    ...point,
                    cityName: city.name,
                    distance: point.x && point.y && point.projection && lat && long ? getDistance({
                        projection: 16,
                        x: long,
                        y: lat
                    }, point) : null,
                    footerContent: (
                        <Grid container justifyContent='space-between'>
                            <Grid item xs={2.5} container direction='column' alignItems='flex-start' className='padding-left-1'>
                                {getWaterLeftIcon(round(percentLeft), { height: 30, paddingBottom: 8 })}
                                <b>{volumeAuth ? `${percentLeft <= 0 ? 0 : round(percentLeft)}%` : ''}</b>
                            </Grid>
                            {/* <Grid item xs>{this.getLastChronicle(point)}</Grid> */}
                            <Grid item xs={5} container direction='column' alignItems='flex-start'>
                                {getRestrictionIcon(restriction.idRestriction, { height: 30 }, restrictionColor)}
                                <b className='padding-top-2' style={{ color: restrictionColor }}>{restrictionLabel}</b>
                            </Grid>
                            <Grid item xs={2} container justifyContent='center'><SoleilLogo fill='black' style={{ height: 35 }} /></Grid>
                            <Grid item xs={2.5} container direction='column' alignItems='flex-end'><CycleLogo fill={color} style={{ height: 35, paddingBottom: 8 }} /><b style={{ color }}>{label}</b></Grid>
                        </Grid>
                    )
                }
            }
            return null
        }))
    }

    render() {
        const {
            cities = [],
            installations = [],
        } = this.props

        if (cities.length && installations.length) {
            return (
                <>
                    <div style={{ padding: '5 10' }}>
                        <PointsCard
                            pointsUsed={this.getPoints()}
                            pointsNotUsed={[]}
                            pointsDeleted={[]}
                            onClickPoint={(p) => this.props.push(`/consommation/point/${p.id}`)}
                        />
                    </div>
                    <Grid container direction='column' alignItems='stretch' style={{ padding: '5 10' }}>
                        <Grid item>
                            <MainButton
                                reverse='true'
                                onClick={() => this.props.push('/account')}
                                margintop
                            >
                                <Icon>keyboard_arrow_left</Icon>
                                {i18n.myFolder}
                            </MainButton>
                        </Grid>
                    </Grid>
                </>
            )
        }
        return <LinearProgress />
    }
}

PointsHisto.propTypes = {
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    push: PropTypes.func,
    setTitle: PropTypes.func,
    map: PropTypes.bool,
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    managementUnitsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnitRestriction)),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
    droughtSectorsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoDroughtSectorRestriction)),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    fetchManagementUnitsRestrictions: PropTypes.func,
    fetchDroughtSectorsRestrictions: PropTypes.func,
}

PointsHisto.defaultProps = {
    editable: false,
}

const mapStateToProps = (store) => {
    return {
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchManagementUnitsRestrictions: ReferencialAction.fetchManagementUnitsRestrictions,
    fetchDroughtSectorsRestrictions: AgriAction.fetchDroughtSectorsRestrictions,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PointsHisto))
