import React from 'react'
import Tab from '@mui/material/Tab'
import { withStyles } from '@mui/styles'
import { mainColor } from './theme'

export const TabStyle = withStyles((theme) => ({
    root: {
        width: (props) => (props.width || 'auto'),
        maxWidth: 'none',
        padding: 10,
        textTransform: 'none',
        backgroundColor: mainColor,
        color: '#484848',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1.2rem',
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Roboto'].join(','),
        '&:hover': {
            opacity: 1,
        },
    },
    tab: {
        padding: '0.5rem',
        fontFamily: 'Open Sans',
        fontSize: '2rem',
        backgroundColor: 'grey',
        color: 'black',
        '&:hover': {
            opacity: 1,
        },
    },
    selected: {},
}))((props) => <Tab {...props} />)
