import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from '../../components/toasters/ToastrAction'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization } from '../../../../utils/ActionUtils'
import {
    RECEIVE_ACCOUNT,
    RECEIVE_ACCOUNT_HABILITATION,
    RECEIVE_ACCOUNT_STATIONS,
    RECEIVE_ACCOUNT_STATION_STATISTICS,
    RECEIVE_ACCOUNT_STATISTICS,
    RECEIVE_ACCOUNT_USAGE_STATISTICS,
    RECEIVE_ACCOUNT_USER_KEYFIGURES,
    RECEIVE_ACCOUNT_USER_SETTINGS,
    SAVED_USER,
    RECEIVE_ACCOUNT_USER_CMS_STATISTICS,
} from '../constants/AccountConstants'
import LogAction from '../../../../utils/log/actions/LogAction'

const AccountAction = {
    receiveUser(accountUser) {
        return {
            type: RECEIVE_ACCOUNT,
            accountUser: accountUser,
        }
    },

    savedUser(user) {
        return {
            type: SAVED_USER,
            user: user,
        }
    },

    fetchUserData(login) {
        return dispatch => Promise.all([
            AccountAction.promiseUser(login),
            AccountAction.promiseAccountHabilitations(login),
            AccountAction.promiseAccountUserKeyfigure(login),
            AccountAction.promiseAccountUserSettings(),
            AccountAction.promiseAccountStatistics(login),
            AccountAction.promiseAccountStationStatistics(login),
            AccountAction.promiseAccountUsageStatistics(login),
            AccountAction.promiseAccountStations(login),
            AccountAction.promiseAccountUserCmsStatistic(login),
        ]).then(jsonTab => {
            dispatch(AccountAction.receiveUser(jsonTab[0]))
            dispatch(AccountAction.receiveAccountHabilitation(jsonTab[1]))
            dispatch(AccountAction.receiveAccountUserKeyfigures(jsonTab[2]))
            dispatch(AccountAction.receiveAccountUserSettings(jsonTab[3]))
            dispatch(AccountAction.receiveAccountStatistics(jsonTab[4]))
            dispatch(AccountAction.receiveAccountStationStatistics(jsonTab[5]))
            dispatch(AccountAction.receiveAccountUsageStatistics(jsonTab[6]))
            dispatch(AccountAction.receiveAccountStations(jsonTab[7]))
            dispatch(AccountAction.receiveUserCmsStatistic(jsonTab[8]))
        })
    },

    promiseUser(login) {
        return fetch(ApplicationConf.user.get(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchUser(login) {
        return (dispatch) => {
            return AccountAction.promiseUser(login)
                .then((json) => {
                    dispatch(AccountAction.receiveUser(json))
                    dispatch(AccountAction.fetchAccountHabilitations(json.login))
                    dispatch(AccountAction.fetchAccountUserKeyfigure(json.login))
                    dispatch(AccountAction.fetchAccountUserSettings())
                    dispatch(AccountAction.fetchAccountStatistics(json.login))
                    dispatch(AccountAction.fetchAccountStationStatistics(json.login))
                    dispatch(AccountAction.fetchAccountUsageStatistics(json.login))
                    dispatch(AccountAction.fetchAccountStations(json.login))
                    dispatch(AccountAction.fetchAccountUserCmsStatistic(json.login))
                })
        }
    },

    updateUser(user) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.put(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(user)
            }).then(() => {
                dispatch(AccountAction.fetchUser(user.login))
            })
        }
    },

    receiveUserCmsStatistic(accountUserCmsStatistics) {
        return { type: RECEIVE_ACCOUNT_USER_CMS_STATISTICS, accountUserCmsStatistics }
    },

    promiseAccountUserCmsStatistic(login) {
        return fetch(ApplicationConf.user.getCmsStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountUserCmsStatistic(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountUserCmsStatistic(login)
                .then((json) => {
                    dispatch(AccountAction.receiveUserCmsStatistic(json))
                })
        }
    },

    receiveAccountUserKeyfigures(accountUserKeyFigures) {
        return {
            type: RECEIVE_ACCOUNT_USER_KEYFIGURES,
            accountUserKeyFigures: accountUserKeyFigures,
        }
    },

    promiseAccountUserKeyfigure(login) {
        return fetch(ApplicationConf.user.getKeyFigures(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountUserKeyfigure(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountUserKeyfigure(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountUserKeyfigures(json))
                })
        }
    },

    fetchAccountCGUDate(login) {
        return () => {
            return fetch(ApplicationConf.user.getUserCGUDate(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(checkError)
                .catch(() => {
                    ToastrAction.error(i18n.errorGetUserInfo)
                })
        }
    },
    receiveAccountHabilitation(accountHabilitations) {
        return { type: RECEIVE_ACCOUNT_HABILITATION, accountHabilitations: accountHabilitations }
    },

    promiseAccountHabilitations(login) {
        return fetch(ApplicationConf.user.habilitations(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountHabilitations(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountHabilitations(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountHabilitation(json))
                })
        }
    },
    receiveAccountStatistics(accountStatistics) {
        return { type: RECEIVE_ACCOUNT_STATISTICS, accountStatistics: accountStatistics }
    },

    promiseAccountStatistics(login) {
        return fetch(ApplicationConf.user.getStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountStatistics(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountStatistics(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountStatistics(json))
                })
        }
    },

    receiveAccountStationStatistics(accountStationStatistics) {
        return {
            type: RECEIVE_ACCOUNT_STATION_STATISTICS,
            accountStationStatistics: accountStationStatistics,
        }
    },

    promiseAccountStationStatistics(login) {
        return fetch(ApplicationConf.user.getStationStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountStationStatistics(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountStationStatistics(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountStationStatistics(json))
                })
        }
    },

    receiveAccountUsageStatistics(accountUsageStatistics) {
        return {
            type: RECEIVE_ACCOUNT_USAGE_STATISTICS,
            accountUsageStatistics: accountUsageStatistics,
        }
    },

    promiseAccountUsageStatistics(login) {
        return fetch(ApplicationConf.user.getUsageStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountUsageStatistics(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountUsageStatistics(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountUsageStatistics(json))
                })
        }
    },

    receiveAccountStations(accountStations) {
        return { type: RECEIVE_ACCOUNT_STATIONS, accountStations: accountStations }
    },

    promiseAccountStations(login) {
        return fetch(ApplicationConf.user.userStations(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountStations(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountStations(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountStations(json))
                })
        }
    },

    updateAccountCGUStatistic(object) {
        return () => {
            return fetch(ApplicationConf.user.postUserCGUDate(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(object),
            })
        }
    },
    receiveAccountUserSettings(accountUserSettings) {
        return { type: RECEIVE_ACCOUNT_USER_SETTINGS, accountUserSettings: accountUserSettings }
    },

    promiseAccountUserSettings() {
        return fetch(ApplicationConf.user.settings(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
    },

    fetchAccountUserSettings(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountUserSettings(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountUserSettings(json))
                })
        }
    },

    changePassword(password) {
        return () => {
            return fetch(ApplicationConf.user.changePassword(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(password),
            })
                .then((json) => {
                    if (json.status === 200) {
                        ToastrAction.success(i18n.elementUpdateSuccess, true)
                    } else {
                        ToastrAction.error(i18n.updateError + i18n.password, true)
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.password} : ${err}`)
                })
        }
    },
}

export default AccountAction
