import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Card, Grid, Icon } from '@mui/material'
import { MainButton } from '../components/styled/buttons'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import { formatDate } from '../../../utils/DateUtil'
import { isRunningApp } from '../../../utils/LocalStorageUtils'
import { CMS_PATH } from '../../../conf/basepath'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../components/styled/dialog'
import { ReactComponent as CloseLogo } from '../../../ressources/static/svg/Fermer.svg'

export default function ModalActualities({ open, toggleModal, cms = [] }) {
    const [actu, setActu] = React.useState(0)

    const handleClose = () => {
        toggleModal()
    }

    const getIcon = (status) => {
        switch (status) {
            case 2:
                return 'create'
            case 3:
                return 'folderOpen'
            default:
                return 'create'
        }
    }

    const openDocument = (document) => {
        window.open(CMS_PATH + document.name, '_system')
    }

    const openWebSite = (link) => {
        if (!isRunningApp() && link) {
            window.open(link, '_blank')
        }
    }

    const getPanel = () => {
        const actuality = cms[actu] || {}
        return (
            <>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid item className='padding-2'>
                        <span id='actu-title' style={{ fontSize: '1.5em' }}>{actuality.title}</span><br />
                        <span id='actu-description' className='italic' style={{ display: 'flex' }}>{formatDate(actuality.updateDate)}{actuality.status !== 1 && <Icon>{getIcon(actuality.status)}</Icon>}</span>
                    </Grid>
                    {!!actuality?.document?.length && (
                        <Icon
                            className='clickable'
                            onClick={() => openDocument(actuality.document[0])}
                            fontSize='large'
                        >
                            insert_drive_file
                        </Icon>
                    )}
                </Grid>
                <p id='actu-subtitle'>
                    {actuality.subtitle}
                </p>
                <div dangerouslySetInnerHTML={{ __html: actuality.comment || '' }} />
                <p id='actu-footer' className='italic'>{actuality.footerContent}</p>
                <p
                    className='blue-text'
                    onClick={() => openWebSite(actuality.link)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {actuality.link}
                    <Icon>launch</Icon>
                </p>
            </>
        )
    }

    const prevActu = () => {
        setActu(actu - 1)
    }

    const nextActu = () => {
        setActu(actu + 1)
    }

    return (
        <DialogMUI
            open={open}
        >
            <DialogTitleMUI className='blod' style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: 0 }}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <b style={{ padding: 10, fontSize: 21 }}>{i18n.actualities}</b>
                    <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={handleClose} />
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ padding: 8 }}>
                <Card className='overflowY padding-2'>
                    {getPanel()}
                </Card>
            </DialogContentMUI>
            {cms.length > 1 && (
                <DialogActionsMUI>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Grid item xs={4.5}>
                            <MainButton
                                onClick={prevActu}
                                disabled={!actu}
                            >
                                <Icon>keyboard_arrow_left</Icon>
                                {i18n.previous}
                            </MainButton>
                        </Grid>
                        <Grid item xs={4.5}>
                            <MainButton
                                onClick={nextActu}
                                disabled={actu === cms.length - 1}
                            >
                                {i18n.next}
                                <Icon>keyboard_arrow_right</Icon>
                            </MainButton>
                        </Grid>
                    </Grid>
                </DialogActionsMUI>
            )}
        </DialogMUI>
    )
}

ModalActualities.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
}
