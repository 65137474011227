'use strict'

import { FULL_RESET } from '../../../offline/constants/HomeConstants'
import { RECEIVE_TANKS_TYPES } from '../../agri/constants/AgriConstants'
import DtoAgriTanksType from '../../agri/dto/DtoAgriTanksType'
import {
    RECEIVE_CULTURE,
    RECEIVE_CULTURES,
    RECEIVE_CULTURES_FAMILY,
    RECEIVE_CULTURES_FAMILIES,
    RECEIVE_CONTRIBUTOR,
    RECEIVE_CONTRIBUTORS,
    RECEIVE_USAGE,
    RECEIVE_USAGES,
    RECEIVE_SANDRE_CODE,
    RECEIVE_SANDRE_CODES,
    RECEIVE_SANDRE_CODES_FIELD,
    RECEIVE_MANAGEMENT_UNITS,
    RECEIVE_ALL_WATERSHEDS,
    RECEIVE_ALL_BOOKMARKS,
    RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS,
} from '../constants/ReferencialConstants'
import DtoCulture from '../dto/DtoCulture'
import DtoCulturesFamily from '../dto/DtoCulturesFamily'
import DtoManagementUnit from '../dto/DtoManagementUnit'
import DtoManagementUnitRestriction from '../dto/DtoManagementUnitRestriction'
import DtoSandreCode from '../dto/DtoSandreCode'

export const store = {
    culture: {},
    cultures: [],
    culturesFamily: {},
    culturesFamilies: [],
    contributor: {},
    contributors: [],
    usage: {},
    usagesCategories: [],
    codeSandre: {},
    codesSandre: [],
    codesSandreByField: [],
    managementUnits: [],
    managementUnitsRestrictions: [],
    agriTanksTypes: [],
    watersheds: [],
    bookmarks: [],
}

export function ReferencialReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS:
            return {
                ...state,
                managementUnitsRestrictions: action.managementUnitsRestrictions.map((d) => new DtoManagementUnitRestriction(d)),
            }
        case RECEIVE_ALL_BOOKMARKS:
            return {
                ...state,
                bookmarks: action.bookmarks || [],
            }
        case RECEIVE_ALL_WATERSHEDS:
            return {
                ...state,
                watersheds: action.watersheds || [],
            }
        case RECEIVE_TANKS_TYPES:
            return {
                ...state,
                agriTanksTypes: action.tanksTypes.map(t => new DtoAgriTanksType(t)),
            }
        case RECEIVE_CULTURE:
            return {
                ...state,
                culture: new DtoCulture(action.culture),
            }
        case RECEIVE_CULTURES:
            return {
                ...state,
                cultures: action.cultures.map((c) => new DtoCulture(c)),
            }
        case RECEIVE_CULTURES_FAMILY:
            return {
                ...state,
                culturesFamily: new DtoCulturesFamily(action.culturesFamily),
            }
        case RECEIVE_CULTURES_FAMILIES:
            return {
                ...state,
                culturesFamilies: action.culturesFamilies.map((cF) => new DtoCulturesFamily(cF)),
            }
        case RECEIVE_CONTRIBUTOR:
            return {
                ...state,
                contributor: action.contributor,
            }
        case RECEIVE_CONTRIBUTORS:
            return {
                ...state,
                contributors: action.contributors,
            }
        case RECEIVE_USAGE:
            return {
                ...state,
                usage: action.usage,
            }
        case RECEIVE_USAGES:
            return {
                ...state,
                usagesCategories: action.usages,
            }
        case RECEIVE_SANDRE_CODE:
            return {
                ...state,
                codeSandre: new DtoSandreCode(action.codeSandre),
            }
        case RECEIVE_SANDRE_CODES:
            return {
                ...state,
                codesSandre: action.codesSandre.map((c) => new DtoSandreCode(c)),
            }
        case RECEIVE_SANDRE_CODES_FIELD:
            return {
                ...state,
                codesSandreByField: action.codesSandre.map((c) => new DtoSandreCode(c)),
            }
        case RECEIVE_MANAGEMENT_UNITS:
            return {
                ...state,
                managementUnits: action.managementUnits.map((u) => new DtoManagementUnit(u)),
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
