module.exports = {
    LOGIN_FAIL: 'LOGIN_FAIL',
    ADD_TOKEN: 'ADD_TOKEN',
    REMOVE_TOKEN: 'REMOVE_TOKEN',
    ACTU_TO_HIDE: 'ACTU_TO_HIDE',
    LAST_TIME_HOME: 'LAST_TIME_HOME',
    MEDEAU_TOKEN: 'medeau-token',
    MEDEAU_LOGIN: 'medeau-login',
    PATH: 'path',
    DISCONNECTED: 'DISCONNECTED',
    RECEIVE_CURRENT_LOCATION: 'RECEIVE_CURRENT_LOCATION',
    RECEIVE_ALL_USERS: 'RECEIVE_ALL_USERS',
    RECEIVE_ALL_USERS_LIGHT: 'RECEIVE_ALL_USERS_LIGHT',
    ACTION: 'ACTION',
    MENU: 'MENU',
    RECEIVE_WATCHID: 'RECEIVE_WATCHID',
    CREDENTIALS: 'CREDENTIALS',
    POPUP: 'POPUP',
    STATION_RANGE: 'STATION_RANGE',
    // DEFAULT_PATH: 'psp.integration.aquasys.fr',
    // CONTENT_PATH: 'https://psp.integration.aquasys.fr/contents/',
    // CMS_PATH: 'https://psp.integration.aquasys.fr/contents/CMS/',
    STATIONS_FILTER: 'STATIONS_FILTER',
    CAMPAIGN_FILTER: 'CAMPAIGN_FILTER',
    SELECTED_TYPE: 'SELECTED_TYPE',
    SELECTED_LIST_MODE: 'SELECTED_LIST_MODE',
    TAB_SELECTED: 'TAB_SELECTED',
    EDIT_AUTH: 'EDIT_AUTH',
    EDIT_AUTHS: { authorized: 'authorized', unauthorized: 'unauthorized' },
    ENTRY_POINT: 'ENTRY_POINT',
    MAP_OPTION: 'MAP_OPTION',
    LOCAL: 'LOCAL',
    ALL_MAP_OPTION: ['LOCAL'],
    FULL_RESET: 'FULL_RESET',
    RESET_AGRI: 'RESET_AGRI',
    TITLE: 'TITLE',
    MENU_RESET: 'MENU_RESET',
    LOGIN: 'LOGIN',
    RECEIVE_ALL_CGU: 'RECEIVE_ALL_CGU',
    RECEIVE_DATE_VALID_CGU: 'RECEIVE_DATE_VALID_CGU',
    RECEIVE_PARTENAIRE_CMS: 'RECEIVE_PARTENAIRE_CMS',
    RECEIVE_CGU_CMS: 'RECEIVE_CGU_CMS',
    RECEIVE_CMS_HOME: 'RECEIVE_CMS_HOME',
    RECEIVE_CMS_LOGO: 'RECEIVE_CMS_LOGO',
    RECEIVE_APPLICATION_SETTINGS: 'RECEIVE_APPLICATION_SETTINGS',
    RECEIVE_ARRESTS: 'RECEIVE_ARRESTS',
    TOKEN_MEDEAU_LOGIN:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MDE1ODcyMzc3OTgsImxvZ2luIjoibWVkZWF1IiwiY29ubmVjdGlvbiI6IjIwMjAtMTAtMDEgMTE6MjA6MzciLCJsaWNlbnNlIjpbIlNJRyIsIlNJQURNIiwiU0lQIiwiU0lIIiwiU0lNQVQiLCJTSVEiLCJTSUVTIl19.MtQxgmnsWfRz1iXLj-0TR7f3SKz3YfQO2K-AUgHA8jU',
}
