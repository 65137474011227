/* eslint-disable no-process-env */
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { connectRouter } from 'connected-react-router'
import { createHashHistory } from 'history'
import { HomeReducer, store as HomeReducerStore } from '../pages/offline/reducers/HomeReducer'
import {
    AccountReducer,
    store as AccountReducerStore,
} from '../pages/online/account/reducers/AccountReducer'
import {
    ContactReducer,
    store as ContactReducerStore,
} from '../pages/online/contact/reducers/ContactReducer'
import {
    CityReducer,
    store as CityReducerStore,
} from '../pages/online/referencials/city/reducers/CityReducer'
import {
    InstallationsReducer,
    store as InstallationsReducerStore,
} from '../pages/online/referencials/installations/reducers/InstallationsReducer'
import {
    ReferencialReducer,
    store as ReferencialReducerStore,
} from '../pages/online/referencials/reducers/ReferencialReducer'
import {
    MaterielReducer,
    store as MaterielReducerStore,
} from '../pages/online/referencials/materiels/reducers/MaterielReducer'
import { AgriReducer, store as AgriReducerStore } from '../pages/online/agri/reducers/AgriReducer'
import { CmsReducer, store as CmsReducerStore } from '../pages/online/cms/reducers/CmsReducer'
import { FileReducer, store as FileReducerStore } from '../pages/online/referencials/documents/reducers/FileReducer'

export const history = createHashHistory()

const middlewares = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV !== 'production') {
    middlewares.push(logger)
}

const enhancer = applyMiddleware(...middlewares)

const initialStore = {
    HomeReducer: HomeReducerStore,
    ContactReducer: ContactReducerStore,
    AccountReducer: AccountReducerStore,
    CityReducer: CityReducerStore,
    InstallationsReducer: InstallationsReducerStore,
    ReferencialReducer: ReferencialReducerStore,
    AgriReducer: AgriReducerStore,
    CmsReducer: CmsReducerStore,
    MaterielReducer: MaterielReducerStore,
    FileReducer: FileReducerStore,
}

const appReducers = combineReducers({
    HomeReducer,
    ContactReducer,
    AccountReducer,
    CityReducer,
    InstallationsReducer,
    ReferencialReducer,
    AgriReducer,
    CmsReducer,
    MaterielReducer,
    FileReducer,
    router: connectRouter(history),
})

const rootReducer = (state, action) => {
    if (action.type === 'RESET_ALL_STORE') {
        const { applicationSettings } = state.HomeReducer
        const homeReducer = {
            ...initialStore.HomeReducer,
            applicationSettings,
        }
        return {
            ...state,
            ...initialStore,
            HomeReducer: homeReducer,
        }
    }
    return appReducers(state, action)
}

const AppStore = createStore(rootReducer, initialStore, enhancer)

export default AppStore
