/* eslint-disable camelcase */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, Icon, LinearProgress } from '@mui/material'
import { compact, groupBy, keys, orderBy, uniqBy } from 'lodash'
import moment from 'moment'
import { MainButton } from '../../../components/styled/buttons'
import DtoDeclaration from '../../../agri/dto/enquete/DtoDeclaration'
import AgriAction from '../../../agri/actions/AgriAction'
import { formatMilliers } from '../../../../../utils/StringUtil'
import ModalSynthese from '../modals/ModalSynthese'
import ModalNothingDeclared from '../modals/ModalNothingDeclared'
import DtoTankDeclaration from '../../../agri/dto/enquete/DtoTankDeclaration'
import DtoAgriTanksType from '../../../agri/dto/DtoAgriTanksType'
import { getSetting, getSettingInt, sieauTooltip } from '../../../../../utils/FormUtils'
import DtoEnquete from '../../../agri/dto/enquete/DtoEnquete'
import { InputRow } from '../../../components/styled/inputs'
import DtoVariousMateriel from '../../../referencials/installations/dto/DtoVariousMateriel'
import DtoExploitation from '../../../agri/dto/exploitation/DtoExploitation'
import { hasValue } from '../../../../../utils/NumberUtil'
import { CHRONICLES_CONSTANTS, POINT_STATUS_DECLARATION } from '../../../agri/constants/AgriConstants'
import ModalWarnNoConso from '../modals/ModalWarnNoConso'
import DtoInstallation from '../../../referencials/installations/dto/DtoInstallation'
import { isLargeWindow } from '../../../../../utils/LocalStorageUtils'
import DtoIntervenant from '../../../referencials/dto/DtoIntervenant'

class StepValidation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: false,
            openModalNothingDeclared: false,
            declaration: props.declaration,
            openModalNoConso: false,
            instsWithoutIndex: [],
            volumesPoints: [],
        }
    }

    componentDidMount() {
        const { declaration, agriTanksTypes, applicationSettings, survey } = this.props
        const minVolumeEligibilityTank = getSettingInt(applicationSettings, 'minVolumeEligibilityTank')
        let totalVolumeAsked = 0
        let nbUsagesReal = 0
        let nbUsagesPrevi = 0
        let totalTanks = 0
        const idsTanks = []
        this.props.calculSurveyExploitationVolumes(survey.idSurvey, declaration.idExploitation).then(() => {
            const volumesPoints = declaration.link_declarationInstallation.map((point) => {
                nbUsagesReal = nbUsagesReal + (point.link_usagesCurrent && point.link_usagesCurrent.length || 0)
                nbUsagesPrevi = nbUsagesPrevi + (point.link_usages && point.link_usages.length || 0)
                const pointExpoit = (declaration.link_exploitationInstallation || []).find((p) => p.idInstallation === point.idInstallation) || {}
                if (point.stateCode === 1) {
                    const totalTanksPoint = (pointExpoit.link_sampleTanks || []).map((retenue) => {
                        if (!idsTanks.includes(retenue.idTank)) {
                            const updatedRetenue = point.link_sampleTanks.find((o) => o.idTank === retenue.idTank)
                            const retenueDecla = updatedRetenue ? {
                                ...retenue,
                                ...new DtoTankDeclaration(updatedRetenue),
                            } : retenue
                            const type = agriTanksTypes.find((t) => t.id === retenueDecla.tankType) || {}
                            idsTanks.push(retenue.idTank)
                            return type.eligibility && retenueDecla.capacity >= minVolumeEligibilityTank ? (retenueDecla.capacity / 100 * (type.percent || 100)) : 0
                        }
                        return 0
                    }).reduce((acc, v) => acc + v, 0)
                    totalTanks = totalTanks + totalTanksPoint
                }
                const volumeAsked = point.link_usages.reduce((acc, usage) => acc + (isNaN(parseInt(usage.requestedYearVolume)) ? 0 : usage.requestedYearVolume), 0)
                const lowWaterVolumeAsked = point.link_usages.reduce((acc, usage) => acc + (isNaN(parseInt(usage.lowWaterVolume)) ? 0 : usage.lowWaterVolume), 0)
                totalVolumeAsked = totalVolumeAsked + volumeAsked
                return { id: point.idInstallation, volumeAsked, lowWaterVolumeAsked, nbUsages: point.link_usages.length }
            })
            const usedPoints = declaration.link_declarationInstallation.filter((p) => p.stateCode === 1)
            if (usedPoints.length === 0 && totalVolumeAsked === 0 && getSettingInt(survey.link_params, 'step3') === 1) {
                this.setState({ openModalNothingDeclared: true })
            }
            this.setState({ volumesPoints, dataLoaded: true })
        })
    }

    toggleModal = () => {
        this.setState(({ openModal }) => ({ openModal: !openModal }))
    }

    getStatusLabel = (code) => {
        switch (code) {
            case POINT_STATUS_DECLARATION.USED:
                return i18n.usedLabel
            case POINT_STATUS_DECLARATION.REMOVED:
                return i18n.closedLabel
            case POINT_STATUS_DECLARATION.ADJOURNED:
                return i18n.adjournLabel
            default:
                return i18n.unknown
        }
    }

    getMateriels = (idInstallation) => {
        const { declaration, variousMateriels, exploitation } = this.props
        const link_equipments = declaration.link_declarationInstallation.find((p) => p.idInstallation === idInstallation).link_equipments || []
        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode !== 'd')
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd')
        const deletedCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode === 'd')
        const exploitationPumps = declaration.link_exploitationMateriel.filter((m) => m.siteType === 7 &&
            m.siteCode === idInstallation &&
            !deletedMats.find((mLink) => m.idVarious === mLink.idMat)
        ) || []
        const mergedPumpsIds = uniqBy([
            ...exploitationPumps.map((m) => m.idVarious),
            ...newMats.map((m) => m.idMat)
        ], obj => obj)
        const exploitationCountersId = compact(mergedPumpsIds.flatMap((pumpId) => {
            const pumpSitu = declaration.link_exploitationMateriel.filter((situ) => situ.siteType === 8 &&
                situ.siteCode === pumpId &&
                !deletedCounters.find((mLink) => pumpId === mLink.idMat)
            )
            if (pumpSitu) {
                return pumpSitu.map((s) => s.idVarious)
            }
            return null
        }))
        const newCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode !== 'd' && mergedPumpsIds.includes(mLink.idMat))
        const mergedCountersIds = uniqBy([
            ...exploitationCountersId,
            ...newCounters.map((m) => m.idMatAttachment)
        ], obj => obj)
        const materiels = variousMateriels.filter((m) => [...mergedPumpsIds, ...mergedCountersIds].includes(m.id) &&
            (!hasValue(m.administrator) || (m.administrator === exploitation.operatorCode) || m.isShared),
        )
        return materiels
    }

    onClickValidateDeclaration = () => {
        const { readMode, survey } = this.props
        const { declaration } = this.state
        if (readMode) {
            this.props.push('/home')
        } else {
            const instsToCheck = declaration.link_declarationInstallation.filter((i) => [1, 3].includes(i.stateCode))
            const instsWithoutIndex = compact(instsToCheck.map((i) => {
                if (!i.link_usagesCurrent?.length) {
                    return i.idInstallation
                }
                const materiels = this.getMateriels(i.idInstallation)
                const matsIds = materiels.map((m) => m.id)
                const chroniclesByMat = groupBy(declaration.link_chronicles.filter((c) => matsIds.includes(c.idMat)), 'idMat')
                const indexsFoundForYear = compact(keys(chroniclesByMat).map((matId) => {
                    const lastIndex = orderBy(chroniclesByMat[matId], 'measureDate', 'desc')[0]
                    if (lastIndex && moment(lastIndex.measureDate).year() >= (survey.surveyType === 2 ? survey.year : survey.year - 1)) {
                        return true
                    }
                    return undefined
                }))
                if (indexsFoundForYear.length === 0) {
                    return i.idInstallation
                }
                return undefined
            }))
            if (instsWithoutIndex.length && getSettingInt(survey.link_params, 'consos') === 1 && getSettingInt(survey.link_params, 'step3') === 1) {
                this.setState({ openModalNoConso: true, instsWithoutIndex })
            } else {
                this.validateDeclaration()
            }
        }
    }

    validateDeclaration = () => {
        const { declaration } = this.state
        this.props.updateDeclaration({ ...declaration, lastStep: 4, statusCode: 3 }).then(() => {
            this.toggleModal()
        })
    }

    onComment = (value) => {
        const { declaration } = this.state
        const updateDeclaration = {
            ...declaration,
            comment: value,
        }
        this.setState({ declaration: updateDeclaration })
    }

    getEstimConso = (materielsIds, linkPoint) => {
        const { survey, exploitation } = this.props
        const chroniclesMat = exploitation.link_chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM && materielsIds.includes(c.idMat) && moment(c.measureDate).year() >= survey.year - 1 && (!linkPoint.startDate || c.measureDate >= linkPoint.startDate) && (!linkPoint.endDate || c.measureDate < linkPoint.endDate))
        const groupedByMat = Object.values(groupBy(chroniclesMat, 'idMat'))
        return groupedByMat.map((chroniques) => {
            return chroniques.reduce((acc, c) => acc + c.value, 0) || 0
        }).reduce((acc, c) => acc + c, 0)
    }

    render() {
        const {
            openModal,
            openModalNothingDeclared,
            dataLoaded,
            openModalNoConso,
            instsWithoutIndex,
            volumesPoints,
        } = this.state
        const { declaration, survey, readMode, installations, exploitation, surveyVolumesExploitation, contributors } = this.props
        const date = survey.endDate ? new Date(survey.endDate).toLocaleString() : ''

        if (!dataLoaded) {
            return <LinearProgress />
        }

        return (
            <>
                <Grid container direction='column' alignItems='center' style={{ padding: '15 15 70 15' }}>
                    {isLargeWindow() && (
                        <>
                            <Grid item container className='bold' style={{ borderTop: '1px solid black' }}>
                                <Grid item xs={1.5} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                    {i18n.equipmentsNum}
                                </Grid>
                                <Grid item xs={1.5} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                    {i18n.samplingPointNum}
                                </Grid>
                                <Grid item xs={2} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                    {i18n.pointLocation}
                                </Grid>
                                <Grid item xs={7} container style={{ borderRight: '1px solid black' }}>
                                    <Grid item xs={12/7*3} className='padding-1' style={{ borderBottom: '1px solid black' }}>
                                        {`${i18n.consumptionReport} ${survey.surveyType === 2 ? survey.year : survey.year - 1}`}
                                    </Grid>
                                    <Grid item xs={12/7*4} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                        {`${i18n.previ} ${survey.year}`}
                                    </Grid>
                                    <Grid item xs={12/7} className='padding-1' style={{ borderBottom: '1px solid black' }}>
                                        {i18n.estimatedVolume}
                                    </Grid>
                                    <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                        {i18n.yearly}
                                    </Grid>
                                    <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                        {i18n.etiage}
                                    </Grid>
                                    <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                        {i18n.pointStatus}
                                    </Grid>
                                    <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                        {i18n.usesNumber}
                                    </Grid>
                                    <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                        {i18n.yearVolume}
                                    </Grid>
                                    <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                        {i18n.lowWaterVolumeLabel}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {declaration.link_declarationInstallation.map((dI) => {
                                const inst = installations.find((i) => i.id === dI.idInstallation)
                                const volumesConso = surveyVolumesExploitation.find((v) => v.idInstallation === dI.idInstallation) || {}
                                const volumes = volumesPoints.find((v) => v.id === dI.idInstallation)
                                const materiels = this.getMateriels(dI.idInstallation)
                                const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === dI.idInstallation) || {}
                                const estimConso = this.getEstimConso(materiels.map((m) => m.id), linkPoint)
                                return (
                                    <Grid item container>
                                        <Grid item xs={1.5} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                            {materiels.map((m, i) => {
                                                const shared = hasValue(m.administrator) && (m.administrator !== exploitation.operatorCode) && m.isShared
                                                return (
                                                    <Grid container alignItems='center'>
                                                        {m.reference}
                                                        {shared && (
                                                            <Icon {...sieauTooltip(`${i18n.shared} (${contributors.find(c => c.id === m.administrator)?.name})`)} sx={{ marginLeft: '5px' }}>people</Icon>
                                                        )}
                                                        {i !== (materiels.length - 1) && ', '}
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        <Grid item xs={1.5} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                            {inst.code}
                                        </Grid>
                                        <Grid item xs={2} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            {inst.location}
                                        </Grid>
                                        <Grid item xs={1} className='padding-1' style={{ borderBottom: '1px solid black' }}>
                                            {formatMilliers(estimConso)}
                                        </Grid>
                                        <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                            {formatMilliers(volumesConso?.total)}
                                        </Grid>
                                        <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                            {formatMilliers(volumesConso?.lowWater)}
                                        </Grid>
                                        <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                            {this.getStatusLabel(dI.stateCode)}
                                        </Grid>
                                        <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                            {volumes.nbUsages}
                                        </Grid>
                                        <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                            {formatMilliers(volumes.volumeAsked)}
                                        </Grid>
                                        <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            {formatMilliers(volumes.lowWaterVolumeAsked)}
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </>
                    )}
                    {(getSetting(survey.link_params, 'step3') || '1') !== '0' && (
                        <Grid item style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
                            <MainButton onClick={() => this.props.onGetBack(3)} disabled={readMode}>
                                {i18n.edit}
                            </MainButton>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <p style={{ textAlign: 'center' }}>
                            Cliquez ci-dessous pour enregistrer votre déclaration. Elle reste modifiable jusqu'au {date}.
                        </p>
                    </Grid>
                    <InputRow
                        item
                        id='comment'
                        type='text'
                        variant='outlined'
                        placeholder='Commentaire'
                        value={declaration.comment}
                        onChange={(e) => this.onComment(e.target.value)}
                        multiline
                        rows={10}
                        rowsMax={Infinity}
                        style={{ minHeight: '35%' }}
                        InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                        disabled={readMode}
                    />
                </Grid>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-evenly'
                    alignItems='center'
                    style={{
                        position: 'fixed',
                        bottom: '70px',
                        height: '70px',
                        left: '0',
                        backgroundColor: 'white',
                        borderTop: 'solid 1px grey',
                        zIndex: '1000',
                        padding: '5px 0',
                    }}
                >
                    <Grid item xs={11} md={9}>
                        <MainButton onClick={this.onClickValidateDeclaration}>
                            {readMode ? i18n.backHome : i18n.validDeclaration}
                        </MainButton>
                    </Grid>
                </Grid>
                {openModal && (
                    <ModalSynthese
                        open={openModal}
                        toggleModal={() => this.setState({ openModal: false })}
                        push={() => this.props.push('/home')}
                    />
                )}
                {openModalNoConso && (
                    <ModalWarnNoConso
                        open={openModalNoConso}
                        toggleModal={() => this.setState({ openModalNoConso: false })}
                        onValidateDeclaration={this.validateDeclaration}
                        instsWithoutIndex={instsWithoutIndex}
                    />
                )}
                {openModalNothingDeclared && (
                    <ModalNothingDeclared
                        open={openModalNothingDeclared}
                        toggleModal={() => this.setState({ openModalNothingDeclared: false })}
                        push={() => this.props.onGetBack(3)}
                    />
                )}
            </>
        )
    }
}

StepValidation.propTypes = {
    toggleEdit: PropTypes.func,
    updateDeclaration: PropTypes.func,
    calculSurveyExploitationVolumes: PropTypes.func,
    push: PropTypes.func,
    onGetBack: PropTypes.func,
    readMode: PropTypes.bool,
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    surveyVolumesExploitation: PropTypes.arrayOf(PropTypes.shape({})),
    survey: PropTypes.instanceOf(DtoEnquete),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
}

const mapStateToProps = (store) => {
    return {
        survey: store.AgriReducer.survey,
        agriTanksTypes: store.ReferencialReducer.agriTanksTypes,
        applicationSettings: store.HomeReducer.applicationSettings,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        surveyVolumesExploitation: store.AgriReducer.surveyVolumesExploitation,
        contributors: store.ReferencialReducer.contributors,
    }
}

const mapDispatchToProps = {
    updateDeclaration: AgriAction.updateDeclaration,
    calculSurveyExploitationVolumes: AgriAction.calculSurveyExploitationVolumes,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(StepValidation)
