/* eslint-disable camelcase */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { makeStyles } from '@mui/styles'
import { Grid, Checkbox, DialogContent } from '@mui/material'
import { connect } from 'react-redux'
import { MainButton } from '../../../components/styled/buttons'
import DtoInstallation from '../../../referencials/installations/dto/DtoInstallation'
import DtoDeclaration from '../../../agri/dto/enquete/DtoDeclaration'
import DtoExploitation from '../../../agri/dto/exploitation/DtoExploitation'
import { DialogActionsMUI, DialogMUI, DialogTitleMUI } from '../../../components/styled/dialog'
import Accordeon from '../../../components/Accordeon'
import { ReactComponent as CloseLogo } from '../../../../../ressources/static/svg/Fermer.svg'

const useStyles = makeStyles(() => ({
    headList: {
        backgroundColor: '#dfdfdf',
        borderBottom: '1px solid grey',
        padding: '8px 25px',
    },
    collapse: {
        width: '100%',
    },
    gridItem: {
        borderBottom: '1px solid grey',
        padding: '5px 10px',
    },
    paper: {
        backgroundColor: '#dfdfdf',
        borderBottom: '1px solid grey',
    },
}))

function ModalAddPoint({
    open,
    toggleModal,
    addPoint,
    selectedPoints,
    installationPoints,
    declaration,
    citiesIndex,
}) {
    const classes = useStyles()
    const [listPoints, setListPoints] = React.useState(selectedPoints)
    const noUsePoints = installationPoints.filter((p) => p.status === 2 || !p.status)
    // const deletePoints = installationPoints.filter((p) => p.status === 3)

    const handleAddPoint = (p) => {
        if (listPoints.find((point) => point.idInstallation === p.id)) {
            const newPoints = listPoints.filter((point) => point.idInstallation !== p.id)
            setListPoints(newPoints)
        } else {
            const newPoints = [
                ...listPoints,
                {
                    idSurvey: declaration.idSurvey,
                    idExploitation: declaration.idExploitation,
                    idInstallation: p.id,
                    stateCode: 1,
                    link_usages: [],
                }
            ]
            setListPoints(newPoints)
        }
    }

    const cancelAndClose = () => {
        setListPoints(selectedPoints)
        addPoint(selectedPoints)
        toggleModal()
    }

    const saveAndClose = () => {
        addPoint(listPoints)
        toggleModal()
    }

    const isSelected = (point) => {
        return listPoints.find((p) => p.idInstallation === point.id)
    }

    const getAccordeon = (title, content, openDefault) => (
        <Accordeon
            title={title}
            expanded={openDefault}
        >
            {content}
        </Accordeon>
    )

    const item = (city, point) => (
        <Grid item xs={10}>
            <b>{city ? city.name : ''}</b><br />
            <span>{point.location || ''}</span><br />
            <span>{`${point.code || ''} ${`- ${point.parcel || ''} ${point.section || ''}${point.name ? ` - ${point.name}` : ''}`}`}</span><br />
        </Grid>
    )

    const pointsUsed = useMemo(() => selectedPoints.filter((point) => point.stateCode !== 2 && point.stateCode !== 3), [selectedPoints])
    const pointsNotUsed = useMemo(() => noUsePoints.filter((p) => !selectedPoints.find((point) => point.idInstallation === p.id)), [noUsePoints, selectedPoints])
    const pointsAbandonned = useMemo(() => selectedPoints.filter((point) => point.stateCode === 2), [selectedPoints])
    const pointsAdjourned = useMemo(() => selectedPoints.filter((point) => point.stateCode === 3), [selectedPoints])

    return (
        <DialogMUI open={open}>
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <b style={{ padding: 10, fontSize: 21 }}>{i18n.addPoints}</b>
                <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={cancelAndClose} />
            </DialogTitleMUI>
            <DialogContent style={{ padding: 0 }}>
                {getAccordeon((
                    <Grid>
                        <span style={{ fontSize: '1.7rem' }}>{pointsUsed.length}</span>
                        {pointsUsed.length > 1
                            ? ` ${i18n.usedPoints}`
                            : ` ${i18n.usedPoint}`}
                    </Grid>), (
                    <Grid container>
                        {installationPoints.map((p) => {
                            if (pointsUsed.find((point) => point.idInstallation === p.id)) {
                                const city = citiesIndex[p.townCode]
                                return (
                                    <Grid item xs={12} key={p.code} container className={classes.gridItem}>
                                        {item(city, p)}
                                    </Grid>
                                )
                            }
                            return ''
                        })}
                    </Grid>
                ), true)}
                {getAccordeon((
                    <Grid>
                        <span style={{ fontSize: '1.7rem' }}>{pointsAdjourned.length}</span>
                        {pointsAdjourned.length > 1 ? ' Points ajournés' : ' Point ajourné'}
                    </Grid>
                ), (
                    <Grid container>
                        {installationPoints.map((p) => {
                            if (pointsAdjourned.find((point) => point.idInstallation === p.id)) {
                                const city = citiesIndex[p.townCode]
                                return (
                                    <Grid item xs={12} key={p.code} container className={classes.gridItem}>
                                        {item(city, p)}
                                    </Grid>
                                )
                            }
                            return ''
                        })}
                    </Grid>
                ), true)}
                {getAccordeon((
                    <Grid>
                        <span style={{ fontSize: '1.7rem' }}>{pointsNotUsed.length}</span>
                        {pointsNotUsed.length > 1 ? ` ${i18n.noUsedPoints}` : ` ${i18n.noUsedPoint}`}
                    </Grid>
                ), (
                    <Grid container>
                        {pointsNotUsed.map((p) => {
                            const city = citiesIndex[p.townCode]
                            return (
                                <Grid item xs={12} key={p.code} container className={classes.gridItem}>
                                    {item(city, p)}
                                    <Grid item xs={2} style={{ display: 'flex' }}>
                                        <Checkbox
                                            color='default'
                                            checked={isSelected(p)}
                                            onChange={() => handleAddPoint(p)}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                ), true)}
                {getAccordeon((
                    <Grid>
                        <span style={{ fontSize: '1.7rem' }}>{pointsAbandonned.length}</span>
                        {pointsAbandonned.length > 1
                            ? ` ${i18n.abandonnedPoints}`
                            : ` ${i18n.abandonnedPoint}`}
                    </Grid>
                ), (
                    <Grid container>
                        {installationPoints.map((p) => {
                            if (pointsAbandonned.find((point) => point.idInstallation === p.id)) {
                                const city = citiesIndex[p.townCode]
                                return (
                                    <Grid item xs={12} key={p.code} container className={classes.gridItem}>
                                        {item(city, p)}
                                    </Grid>
                                )
                            }
                            return ''
                        })}
                    </Grid>
                ))}
            </DialogContent>
            <DialogActionsMUI>
                <MainButton onClick={saveAndClose}>
                    {i18n.declareUsed}
                </MainButton>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

ModalAddPoint.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    addPoint: PropTypes.func.isRequired,
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation).isRequired,
    installationPoints: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
    selectedPoints: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
}

const mapStateToProps = (store) => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

export default connect(mapStateToProps)(ModalAddPoint)