'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_CONTACT,
    RECEIVE_CHAT,
    RECEIVE_MESSAGE,
    RECEIVE_MESSAGES_OF,
    RECEIVE_CONTACTS,
} from '../constants/ContactConstants'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { checkAuth, getAuthorization, checkError, getJson } from '../../../../utils/ActionUtils'
import ToastrAction from '../../components/toasters/ToastrAction'
import LogAction from '../../../../utils/log/actions/LogAction'


const ContactAction = {
    receiveContact(contact) {
        return { type: RECEIVE_CONTACT, contact }
    },

    fetchContact(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contact(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkError)
                .then(getJson)
                .then((json = {}) => {
                    if (json.id) {
                        dispatch(ContactAction.receiveContact(json))
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.contact} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.contact)
                })
        }
    },

    receiveContacts(contacts) {
        return { type: RECEIVE_CONTACTS, contacts }
    },

    promiseContacts() {
        return fetch(ApplicationConf.referencial.contacts(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchContacts() {
        return (dispatch) => {
            return ContactAction.promiseContacts()
                .then((json = []) => {
                    dispatch(ContactAction.receiveContacts(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.contacts} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.contacts)
                })
        }
    },

    updateContact(contact) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contacts(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(contact),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    if (json.update === 1) {
                        dispatch(ContactAction.fetchContact(contact.id))
                        ToastrAction.success(i18n.elementUpdateSuccess)
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.contact} : ${err}`)
                })
        }
    },

    createContact(contact) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contacts(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(contact),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    if (json.insert === 1) {
                        dispatch(ContactAction.fetchContact(json.id))
                        return json.id
                    }
                    throw new Error()
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.createError + i18n.contact} : ${err}`)
                })
        }
    },

    getChat(login, dest) {
        return (dispatch) => {
            return fetch(ApplicationConf.contact.getChat(login, dest), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json) => {
                    dispatch(ContactAction.receiveChat(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    getMessagesOf(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.contact.getMessagesOf(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json) => {
                    dispatch(ContactAction.receiveMessagesOf(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    getMessage(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.contact.getMessage(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json) => {
                    dispatch(ContactAction.receiveMessage(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    sendMessage(userMessage) {
        return () => {
            return fetch(ApplicationConf.contact.sendMessage(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(userMessage),
            })
                .then(checkAuth)
                .then((json) => {
                    if (json.id) {
                        return json.id
                    }
                    throw new Error()
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.createError} : ${err}`)
                })
        }
    },

    defConv(userMessageDest) {
        return () => {
            return fetch(ApplicationConf.contact.defConv(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(userMessageDest),
            })
                .then(checkAuth)
                .catch((err) => {
                    LogAction.logError(`${i18n.createError} : ${err}`)
                })
        }
    },

    receiveChat(chat) {
        return { type: RECEIVE_CHAT, chat }
    },

    receiveMessagesOf(listOfMessages) {
        return { type: RECEIVE_MESSAGES_OF, listOfMessages }
    },

    receiveMessage(message) {
        return { type: RECEIVE_MESSAGE, message }
    },
}

export default ContactAction
