import { Badge } from '@mui/material'
import { styled } from '@mui/system'
import { mainColor } from './theme'

export const StyledBadge = styled(Badge)(
    ({ ...props }) => {
        return {
            '& .MuiBadge-badge': {
                backgroundColor: props.badgeColor ?? '#44b700',
                color: mainColor,
            },
        }
    }
)