import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import AgriAction from '../agri/actions/AgriAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoDeclarationWithStats from '../agri/dto/enquete/DtoDeclarationWithStats'
import DtoEnquete from '../agri/dto/enquete/DtoEnquete'
import DtoUser from '../account/dto/DtoUser'
import { getFileNatureLabel, getTypeSandreCode } from '../../../utils/FileUtils'
import { getSandreList } from '../../../utils/StoreUtils'
import DtoFile from '../referencials/documents/dto/DtoFile'
import DtoPicture from '../referencials/documents/dto/DtoPicture'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import CardFile from './CardFile'
import { mainColor } from '../components/styled/theme'

class MyDocuments extends Component {
    render() {
        const { pictures, files, codesSandre } = this.props
        const data = [...pictures, ...files].filter((d) => d.active === '1').map(fp => {
            return { ...fp, type: getFileNatureLabel(fp.name, getSandreList(codesSandre, getTypeSandreCode(fp.fileType))) }
        })
        return (
            <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='flex-start'
                className='padding-top-2'
            >
                <Grid item xs={12} className='bold' style={{ padding: 14, backgroundColor: mainColor, color: 'white', fontSize: 21, margin: '0 10 5 10' }}>{i18n.myDocuments}</Grid>
                {data.length ? (
                    <>
                        {data.map((d) => (
                            <Grid item xs={12} md={3} style={{ padding: '0 10' }}>
                                <CardFile file={d} />
                            </Grid>
                        ))}
                    </>
                ) : (
                    <Grid item xs={12} style={{ fontWeight: 'bold', paddingLeft: 10, paddingTop: 30, fontSize: 21 }}>
                        {i18n.noDocument}
                    </Grid>
                )}
            </Grid>
        )
    }
}

MyDocuments.propTypes = {
    accountUser: PropTypes.instanceOf(DtoUser),
    fetchExploitation: PropTypes.func,
    fetchDeclarationsByExploitationId: PropTypes.func,
    exportDeclarationFull: PropTypes.func,
    getNotifEdition: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declarationsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationWithStats)),
    surveys: PropTypes.arrayOf(PropTypes.instanceOf(DtoEnquete)),
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    pictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    codesSandre: PropTypes.arrayOf(DtoSandreCode),
    push: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
        applicationSettings: store.HomeReducer.applicationSettings,
        exploitation: store.AgriReducer.exploitation,
        declarationsExploitation: store.AgriReducer.declarationsExploitation,
        surveys: store.AgriReducer.surveys,
        codesSandre: store.ReferencialReducer.codesSandre,
        pictures: store.FileReducer.pictures,
        files: store.FileReducer.files,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    fetchDeclarationsByExploitationId: AgriAction.fetchDeclarationsByExploitationId,
    exportDeclarationFull: AgriAction.exportDeclarationFull,
    getNotifEdition: AgriAction.getNotifEdition,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDocuments)
