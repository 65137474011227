import { compact, orderBy } from 'lodash'

const isObject = (object) => {
    return object !== null && typeof object === 'object'
}

const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
        return false
    }

    for (const key of keys1) {
        const val1 = object1[key]
        const val2 = object2[key]
        const areObjects = isObject(val1) && isObject(val2)
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false
        }
    }

    return true
}

const isEmpty = (object) => {
    return !Object.values(object).some(x => (x !== null && x !== undefined && x !== ''))
}

const orderPointsList = (pointsList) => {
    return orderBy(compact(pointsList), ['townCode', 'cityName', 'parcel', 'section', 'code'])
}

const sortById = (a, b) => {
    const idA = a.id
    const idB = b.id

    let comparison = 0
    if (idA > idB) {
        comparison = 1
    } else if (idA < idB) {
        comparison = -1
    }
    return comparison
}

const sortByCode = (a, b) => {
    const codeA = a.code
    const codeB = b.code

    let comparison = 0
    if (codeA > codeB) {
        comparison = 1
    } else if (codeA < codeB) {
        comparison = -1
    }
    return comparison
}

export { isObject, deepEqual, isEmpty, orderPointsList, sortById, sortByCode }
