'use strict'
import {
    CITIES,
    CITY,
    RECEIVE_CITY_CEDEX,
    RESET_CITY,
    RECEIVE_COUNTRIES,
    RECEIVE_CITIES_HISTO,
} from '../constants/CityConstants'
import DtoCity from '../dto/DtoCity'
import DtoCityCedex from '../dto/DtoCityCedex'
import DtoCountry from '../dto/DtoCountry'
import DtoCityHisto from '../dto/DtoCityHisto'
import { createIndex } from '../../../../../utils/StoreUtils'

export const store = {
    city: {},
    cities: [],
    cityCedex: [],
    countries: [],
    citiesHisto: [],
    citiesIndex: {},
}

export function CityReducer(state = {}, action) {
    switch (action.type) {
        case CITY:
            return {
                ...state,
                city: new DtoCity(action.city),
            }
        case CITIES:
            const citiesDto = action.cities.map((city) => new DtoCity(city))
            const cities = citiesDto.filter((city) => city.id)
            return {
                ...state,
                cities,
                citiesIndex: createIndex(cities),
            }
        case RECEIVE_CITY_CEDEX:
            return {
                ...state,
                cityCedex: action.cityCedex.map((cityCedex) => new DtoCityCedex(cityCedex)),
            }
        case RESET_CITY:
            return {
                ...state,
                city: {},
            }
        case RECEIVE_COUNTRIES:
            return {
                ...state,
                countries: action.countries.map((c) => new DtoCountry(c)),
            }
        case RECEIVE_CITIES_HISTO:
            return {
                ...state,
                citiesHisto: action.citiesHisto.map((c) => new DtoCityHisto(c)),
            }
        default:
            return state
    }
}
