/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, Icon } from '@mui/material'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { groupBy, orderBy } from 'lodash'
import { MainButton } from '../components/styled/buttons'
import { InputRow } from '../components/styled/inputs'
import DtoSamplingPointDecla from '../agri/dto/exploitation/DtoSamplingPointDecla'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import { HelpTooltip } from '../components/styled/texts'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import ToastrAction from '../components/toasters/ToastrAction'
import { formatMilliers } from '../../../utils/StringUtil'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import DtoVariousMatType from '../referencials/installations/dto/DtoVariousMatType'
import DtoMatChronicle from '../referencials/installations/dto/DtoMatChronicle'
import { formatDate } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../components/styled/dialog'
import { ReactComponent as CloseLogo } from '../../../ressources/static/svg/Fermer.svg'
import { ReactComponent as DeleteLogo } from '../../../ressources/static/svg/Poubelle.svg'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import StyledCard from '../components/cards/StyledCard'
import moment from 'moment'
import { CHRONICLES_CONSTANTS } from '../agri/constants/AgriConstants'
import { getEvolValue } from '../../../utils/AgriUtils'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'

const styles = () => ({
    item: {
        padding: '8px !important',
        display: 'flex',
        alignItems: 'center',
        color: 'black',
        fontWeight: 'bold',
    }
})

const STEP_MATERIEL = 0
const STEP_TYPE = 1
const STEP_CHRONICLE = 2

class ModalConso extends Component {
    constructor(props) {
        super(props)
        const allMaterialsIds = [...(props.pumpsIds || []), ...(props.countersIds || [])]
        this.state = {
            chronicles: props.chronicles || [],
            consoType: props.consoType,
            step: props.selectedMaterial ? STEP_CHRONICLE : STEP_MATERIEL,
            errors: [],
            openHelp: false,
            selectedMaterial: props.selectedMaterial || (allMaterialsIds.length === 1 ? allMaterialsIds[0] : null),
            measureDate: moment().format('YYYY-MM-DD'),
            endDate: null,
            value: null,
        }
    }

    previousStep = () => {
        const { step } = this.state
        this.setState({ step: step - 1, openHelp: false })
    }

    nextStep = () => {
        const { step } = this.state
        if (this.checkStep(step)) {
            this.setState({ step: step + 1, openHelp: false })
        }
    }

    checkStep = (step) => {
        const { consoType, measureDate, endDate, value, selectedMaterial } = this.state
        const newErrors = []
        switch (step) {
            case STEP_MATERIEL:
                if (!selectedMaterial) {
                    ToastrAction.error('Veuillez sélectionner un équipement')
                    return false
                }
                this.setState({ errors: newErrors })
                return true
            case STEP_TYPE:
                if (!hasValue(consoType)) {
                    ToastrAction.error('Veuillez sélectionner un type de saisie')
                    return false
                }
                if (consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM) {
                    ToastrAction.warning('Attention, estimations de m3 à saisir sur les matériels EP-XXXX (pompes) uniquement svp. \nSaisies sur les matériels DC-XXXX (compteurs) ne concernant que les relevés d\'index des compteurs d\'eau (volumétriques).')
                }
                this.setState({ errors: newErrors })
                return true
            case STEP_CHRONICLE:
                if (!measureDate) {
                    newErrors.push('measureDate')
                }
                if (isNaN(parseInt(value))) {
                    newErrors.push('value')
                }
                if (consoType === 1 && !endDate) {
                    newErrors.push('endDate')
                }
                if (newErrors.length) {
                    this.setState({ errors: newErrors })
                    return false
                }
                this.setState({ errors: newErrors })
                return true
            default:
                this.setState({ errors: newErrors })
                return true
        }
    }

    setSelectedMat = (materiel) => {
        this.setState({
            selectedMaterial: materiel.id,
            consoType: materiel.materielType === 10 ? CHRONICLES_CONSTANTS.TYPE_INDEX : CHRONICLES_CONSTANTS.TYPE_ESTIM,
            measureDate: null,
            endDate: null,
            value: null,
        }, () => {
            const { step } = this.state
            this.setState({ step: step + 1, openHelp: false })
        })
    }

    getCard = (color = 'white', title, elements = [], footer) => (
        <StyledCard
            subTitle={title}
            styleSubTitle={{ backgroundColor: color }}
            styleContainer={{ padding: 0, borderTop: '1px solid black' }}
            styleContent={{ padding: 0 }}
        >
            {elements.map((e, i) => (
                <Grid container style={{ padding: 10, borderTop: i === 0 ? '' : '1px solid black', backgroundColor: color }}>
                    <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{e.title}</Grid>
                    <Grid item xs={6} className='bold'>{e.value || '-'}</Grid>
                </Grid>
            ))}
            {footer}
        </StyledCard>
    )

    getPumps = () => {
        const { variousMateriels, variousMatSituations, pumpsIds, codesSandre, variousMatTypes } = this.props
        const { selectedMaterial } = this.state
        return pumpsIds.map((pumpId) => {
            const pompeSituation = variousMatSituations.find((m) => m.siteType === 8 && m.siteCode === pumpId)
            const pompeMat = variousMateriels.find((m) => m.id === pumpId) || {}
            const pompe = pompeMat.pump || {}
            const pompeInfos = {
                ...pompeSituation,
                ...pompeMat,
                ...pompe,
            }
            const pumpNature = variousMatTypes.find((t) => t.materielType === pompeInfos.materielType)
            const pumpCategory = codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === pompeInfos.mobilityCode)
            const pumpType = codesSandre.find((c) => c.field === 'POMPES.TYPE' && c.code === pompeInfos.pumpType)
            const color = selectedMaterial === pompeInfos.id ? '#CCDCE4' : 'white'
            return (
                <Grid item xs={12} md={3} className='clickable padding-top-2' key={pompeInfos.id} onClick={() => this.setSelectedMat(pompeInfos)}>
                    {this.getCard(color, `${i18n.id} : ${pompeInfos.reference || ''}`, [{
                        title: i18n.name,
                        value: pompeInfos?.name,
                    }, {
                        title: i18n.nature,
                        value: pumpNature?.name,
                    }, {
                        title: i18n.categ,
                        value: pumpCategory?.name,
                    }, {
                        title: i18n.type,
                        value: pumpType?.name,
                    }, {
                        title: i18n.debitMax,
                        value: pompeInfos.maxFlow ? `${pompeInfos.maxFlow} m3/h` : '-',
                    }, {
                        title: i18n.debitFonctionnement,
                        value: pompeInfos.operatingFlow ? `${pompeInfos.operatingFlow} m3/h` : '-',
                    }])}
                </Grid>
            )
        })
    }

    getCounters = () => {
        const { variousMateriels, countersIds, variousMatTypes } = this.props
        const { selectedMaterial } = this.state
        return countersIds.map((counterId) => {
            const matFind = variousMateriels.find((c) => c.id === counterId) || {}
            const counter = matFind.counter || {}
            const compteurInfos = {
                ...matFind,
                ...counter,
            }
            const counterType = variousMatTypes.find((t) => t.materielType === compteurInfos.materielType)
            const color = selectedMaterial === compteurInfos.id ? '#CCDCE4' : 'white'
            return (
                <Grid item xs={12} md={3} className='clickable padding-top-2' key={compteurInfos.id} onClick={() => this.setSelectedMat(compteurInfos)}>
                    {this.getCard(color, `${i18n.id} : ${compteurInfos.reference || ''}`, [{
                        title: i18n.name,
                        value: compteurInfos?.name,
                    }, {
                        title: i18n.type,
                        value: counterType?.name,
                    }, {
                        title: i18n.coefLecture,
                        value: compteurInfos?.readingCoefficient,
                    }, {
                        title: i18n.marque,
                        value: compteurInfos?.brand,
                    }, {
                        title: i18n.poseYear,
                        value: compteurInfos?.installationYear,
                    }, {
                        title: i18n.revisionDate,
                        value: compteurInfos?.revisionDate,
                    }])}
                </Grid>
            )
        })
    }

    handleChange = (key, value) => {
        if (key === 'consoType') {
            this.setState({ measureDate: null, endDate: null, value: null })
        }
        this.setState({ [key]: value })
    }

    getChronicles = (readingCoefficient = 1) => {
        const { classes } = this.props
        const { chronicles, consoType, selectedMaterial } = this.state
        const filteredChronicles = chronicles.filter((c) => c.idMat === selectedMaterial && c.mode !== 'd' && c.measureType === consoType)
        const orderedChronicles = orderBy(filteredChronicles, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
        return (
            <Grid
                container
                alignItems='center'
                justifyContent='space-between'
                style={{ marginTop: '16px' }}
            >
                {consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? (
                    <>
                        <Grid item className='italic' xs={3} style={{ textAlign: 'start' }}>
                            {i18n.startDate }
                        </Grid>
                        <Grid item className='italic' xs={3} style={{ textAlign: 'start' }}>
                            {i18n.endDate }
                        </Grid>
                        <Grid item className='italic' xs={3} style={{ textAlign: 'end' }}>
                            {i18n.estimateM3 }
                        </Grid>
                        <Grid item className='italic' xs={2} style={{ textAlign: 'end' }}>
                            {'Cumul en m3'}
                        </Grid>
                        <Grid item className='italic' xs={1} style={{ textAlign: 'end' }} />
                    </>
                ) : (
                    <>
                        <Grid item className='italic' xs={3} style={{ textAlign: 'start' }}>
                            {i18n.statementDate}
                        </Grid>
                        <Grid item className='italic' xs={3} style={{ textAlign: 'end' }}>
                            {i18n.index}
                        </Grid>
                        <Grid item className='italic' xs={4} style={{ textAlign: 'end' }}>
                            {`${i18n.evolution} en m3`}
                        </Grid>
                        <Grid item className='italic' xs={1} style={{ textAlign: 'end' }} />
                    </>
                )}
                {orderedChronicles.map((chronicle, i) => {
                    const valueEvol = i !== filteredChronicles.length - 1 ? getEvolValue(consoType, orderedChronicles, chronicle, i) : null
                    if (consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM) {
                        return (
                            <>
                                {i === 0 && <Grid item className xs={12} style={{ borderBottom: '1px solid black' }} />}
                                <Grid item xs={3} className='bold' style={{ textAlign: 'start', paddingLeft: 0 }}>
                                    {formatDate(chronicle.measureDate)}
                                </Grid>
                                <Grid item xs={3} className='bold' style={{ textAlign: 'start' }}>
                                    {chronicle.endDate ? formatDate(chronicle.endDate) : ''}
                                </Grid>
                                <Grid item xs={3} className='bold' style={{ textAlign: 'end' }}>
                                    {`${formatMilliers(chronicle.value) || 0} m3`}
                                </Grid>
                                <Grid item xs={2} className='bold' style={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                    {hasValue(valueEvol) ? formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0)) : ''}
                                </Grid>
                                <Grid item xs={1} className={classes.item}>
                                    <DeleteLogo className='clickable' fill='red' style={{ height: 25 }} onClick={() => this.removeChronicle(chronicle.idChronicle)} />
                                </Grid>
                                <Grid item className xs={12} style={{ borderBottom: '1px solid black' }} />
                            </>
                        )
                    }
                    return (
                        <>
                            {i === 0 && <Grid item className xs={12} style={{ borderBottom: '1px solid black' }} />}
                            <Grid item xs={3} className='bold' style={{ textAlign: 'start', paddingLeft: 0 }}>
                                {formatDate(chronicle.measureDate)}
                            </Grid>
                            <Grid item xs={3} className='bold' style={{ textAlign: 'end' }}>
                                {formatMilliers(chronicle.value) || 0}
                            </Grid>
                            <Grid item xs={4} className='bold' style={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                            </Grid>
                            <Grid item xs={1} className={classes.item}>
                                <DeleteLogo className='clickable' fill='red' style={{ height: 25 }} onClick={() => this.removeChronicle(chronicle.idChronicle)} />
                            </Grid>
                            <Grid item className xs={12} style={{ borderBottom: '1px solid black' }} />
                        </>
                    )
                })}
            </Grid>
        )
    }

    removeChronicle = (idChronicle) => {
        const { chronicles } = this.state
        const newChronicles = [
            ...chronicles.filter((c) => c.idChronicle !== idChronicle),
            {
                ...chronicles.find((c) => c.idChronicle === idChronicle),
                mode: 'd',
            }
        ]
        this.setState({ chronicles: newChronicles })
    }

    addChronicle = () => {
        const {
            measureDate,
            endDate,
            value,
            chronicles,
            consoType,
            selectedMaterial,
        } = this.state
        if (this.checkStep(2)) {
            const id = chronicles.length ? Math.max(...chronicles.map(c => c.idChronicle)) + 1 : 0
            this.setState({
                chronicles: [
                    ...chronicles,
                    {
                        matType: 'divers',
                        idMat: selectedMaterial,
                        measureType: consoType,
                        measureDate: new Date(measureDate).getTime(),
                        value: value,
                        endDate: consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? new Date(endDate).getTime() : null,
                        idChronicle: id,
                        new: true,
                    }
                ],
                measureDate: consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? endDate : '',
                value: '',
                endDate: '',
            })
        }
    }

    getContent = (step) => {
        const { variousMateriels } = this.props
        const { errors, consoType, measureDate, endDate, value, selectedMaterial } = this.state
        const matFind = variousMateriels.find((mat) => mat.id === selectedMaterial) || {}
        switch (step) {
            case STEP_MATERIEL:
                return (
                    <>
                        <h3>
                            {i18n.selectMaterial}
                        </h3>
                        <Grid container spacing={1} alignItems='stretch'>
                            {[...this.getPumps(), ...this.getCounters()]}
                        </Grid>
                    </>
                )
            case STEP_TYPE:
                return (
                    <>
                        <h3>
                            {i18n.selectEntryType}<br />
                        </h3>
                        {matFind.materielType !== 10 && (
                            <Grid item xs={12} md={3} className='clickable'>
                                <StyledCard
                                    subTitle='Saisir une estimation en m3'
                                    styleSubTitle={{ border: '', backgroundColor: consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? '#CCDCE4' : 'white' }}
                                    styleContainer={{ padding: 0, borderTop: '1px solid black' }}
                                    styleContent={{ paddingTop: 0, backgroundColor: consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? '#CCDCE4' : 'white' }}
                                    onClick={() => {
                                        this.handleChange('consoType', CHRONICLES_CONSTANTS.TYPE_ESTIM)
                                        this.setState({ step: step + 1, openHelp: false })
                                    }}
                                >
                                    <b style={{ color: 'grey' }}>Pas de moyen de comptage</b>
                                </StyledCard>
                            </Grid>
                        )}
                        <Grid item xs={12} md={3} className='clickable padding-top-2'>
                            <StyledCard
                                subTitle='Saisir un index'
                                styleSubTitle={{ border: '', backgroundColor: consoType === CHRONICLES_CONSTANTS.TYPE_INDEX ? '#CCDCE4' : 'white' }}
                                styleContainer={{ padding: 0, borderTop: '1px solid black' }}
                                styleContent={{ paddingTop: 0, backgroundColor: consoType === CHRONICLES_CONSTANTS.TYPE_INDEX ? '#CCDCE4' : 'white' }}
                                onClick={() => {
                                    this.handleChange('consoType', CHRONICLES_CONSTANTS.TYPE_INDEX)
                                    this.setState({ step: step + 1, openHelp: false })
                                }}
                            >
                                <b style={{ color: 'grey' }}>Compteur d'eau</b>
                            </StyledCard>
                        </Grid>
                    </>
                )
            case STEP_CHRONICLE:
                return (
                    <>
                        <h3>
                            {consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.seizureEstim : i18n.seizureIndex}<br />
                            {consoType === CHRONICLES_CONSTANTS.TYPE_INDEX ? `${i18n.coefLecture} : ${(matFind.counter || {}).readingCoefficient || ''}` : ''}
                        </h3>
                        <StyledCard styleContainer={{ padding: 0, borderTop: '1px solid black' }}>
                            <InputRow
                                id='startDate'
                                label={consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                                type='date'
                                required
                                variant='outlined'
                                value={measureDate}
                                inputProps={{
                                    max: endDate || '9999-12-31'
                                }}
                                onChange={(e) => this.handleChange('measureDate', e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={errors.find((e) => e === 'measureDate')}
                            />
                            {consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM && (
                                <InputRow
                                    id='endDate'
                                    label={i18n.endDate}
                                    type='date'
                                    inputProps={{
                                        min: measureDate,
                                        max: '9999-12-31'
                                    }}
                                    required
                                    variant='outlined'
                                    value={endDate}
                                    onChange={(e) => this.handleChange('endDate', e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={errors.find((e) => e === 'endDate')}
                                />
                            )}
                            <InputRow
                                id='value'
                                label={consoType === 1 ? i18n.estim : i18n.index}
                                type='number'
                                value={value}
                                onChange={(e) => this.handleChange('value', parseInt(e.target.value))}
                                variant='outlined'
                                required
                                error={errors.find((e) => e === 'value')}
                            />
                            <MainButton onClick={() => this.addChronicle()}>
                                {i18n.add}
                            </MainButton>
                        </StyledCard>
                        {this.getChronicles(matFind?.counter?.readingCoefficient || 1)}
                    </>
                )
            default:
                return ''
        }
    }

    formatListChronicles = (chronicles) => {
        return chronicles.map((c) => {
            if (c.new) {
                return {
                    ...c,
                    idChronicle: null,
                }
            }
            return c
        })
    }

    onSave = () => {
        const { chronicles } = this.state
        const groupedChronicles = groupBy(chronicles, 'idMat')
        this.props.onSave(this.formatListChronicles(chronicles))
        if (Object.keys(groupedChronicles).find((idMat) => groupedChronicles[idMat].filter((c) => c.measureType === 2 && c.mode !== 'd').length === 1)) {
            ToastrAction.warning('L\'un des matériels n\'a qu\'un seul index de saisie. Minimum 2 index doivent être renseignés pour pouvoir calculer votre consommation', null, 10000)
        }
    }

    // onSave = () => {
    //     const { chronicles, consoType } = this.state
    //     if (consoType === CHRONICLES_CONSTANTS.TYPE_INDEX) {
    //         // const filteredChronicles = chronicles.filter((c) => c.idMat === idCounterSelected)
    //         // if (filteredChronicles.length != 1) {
    //         const formattedChronicles = this.formatListChronicles(chronicles)
    //         this.props.onSave(formattedChronicles)
    //         // } else {
    //         //     ToastrAction.error('Veuillez renseigner au moins 2 index avant de valider.')
    //         // }
    //     } else {
    //         // const filteredChronicles = chronicles.filter((c) => c.idMat === idPumpSelected)
    //         // if (filteredChronicles.length >= 0) {
    //         const formattedChronicles = this.formatListChronicles(chronicles)
    //         this.props.onSave(formattedChronicles)
    //         // } else {
    //         //     ToastrAction.error('Veuillez renseigner au moins 1 estimation avant de valider.')
    //         // }
    //     }
    // }

    onCancel = () => {
        this.setState({
            chronicles: [],
            measureDate: '',
            value: '',
            endDate: '',
        })
        this.props.onCancel()
    }

    toggleHelp = () => {
        const { openHelp } = this.state
        this.setState({ openHelp: !openHelp })
    }

    getContentHelp = () => {
        const { cms } = this.props
        const cmsFind = cms.find((c) => c.id === 40) || {}
        return cmsFind.comment || ''
    }

    render() {
        const { step, openHelp, selectedMaterial } = this.state
        const { open, installation, variousMateriels } = this.props
        const matFind = variousMateriels.find((mat) => mat.id === selectedMaterial)

        return (
            <DialogMUI open={open}>
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <b style={{ padding: 10, fontSize: 21 }}>{i18n.newConso}</b>
                        <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={this.onCancel} />
                        {installation && <Grid item xs={12} style={{ borderTop: '1px solid white', padding: 10 }}>{`${i18n.point} ${installation.code}`}</Grid>}
                        {matFind && step !== STEP_MATERIEL && <Grid item xs={12} style={{ borderTop: '1px solid white', padding: 10 }}>{matFind.reference}</Grid>}
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI style={{ paddingTop: 8, paddingBottom: 16 }}>
                    {this.getContent(step)}
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Grid item xs={4}>
                            {step !== STEP_MATERIEL && (
                                <MainButton onClick={this.previousStep}>
                                    <Icon>keyboard_arrow_left</Icon>
                                    {i18n.previous}
                                </MainButton>
                            )}
                        </Grid>
                        <HelpTooltip
                            title={(<div dangerouslySetInnerHTML={{ __html: this.getContentHelp() }} />)}
                            open={openHelp}
                            placement='top'
                            arrow
                            disableHoverListener
                        >
                            <Grid item xs={3}>
                                <MainButton onClick={this.toggleHelp}>
                                    {isLargeWindow() ? i18n.help : <Icon>help</Icon>}
                                </MainButton>
                            </Grid>
                        </HelpTooltip>
                        <Grid item xs={4}>
                            {step !== STEP_CHRONICLE ? (
                                <MainButton onClick={this.nextStep}>
                                    {i18n.next}
                                    <Icon>keyboard_arrow_right</Icon>
                                </MainButton>
                            ) : (
                                <MainButton onClick={this.onSave}>
                                    {i18n.validate}
                                </MainButton>
                            )}
                        </Grid>
                    </Grid>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }
}

ModalConso.propTypes = {
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    point: PropTypes.instanceOf(DtoSamplingPointDecla),
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installation: PropTypes.instanceOf(DtoInstallation),
    materiels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    variousMatTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatType)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    chronicles: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatChronicle)),
    pumpsIds: PropTypes.arrayOf(PropTypes.number),
    countersIds: PropTypes.arrayOf(PropTypes.number),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    selectedMaterial: PropTypes.number,
    consoType: PropTypes.number,
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
        cms: store.CmsReducer.cms,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ModalConso))
