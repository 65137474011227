import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { MainButton } from '../../../components/styled/buttons'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from '../../../components/styled/dialog'
import { ReactComponent as CloseLogo } from '../../../../../ressources/static/svg/Fermer.svg'

export default function ModalSynthese({ open, toggleModal, push }) {
    const handleClose = () => {
        push()
        toggleModal()
    }

    return (
        <Dialog open={open}>
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <b style={{ padding: 10, fontSize: 21 }}>Déclaration terminée</b>
                    <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={handleClose} />
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <p id='modal-text'>
                    La synthèse de votre déclaration est disponible dans "Mes documents".
                </p>
                <p id='modal-description' className='italic'>Pour tout renseignement, veuillez contacter votre administrateur.</p>
            </DialogContentMUI>
            <DialogActionsMUI>
                <MainButton reverse='true' onClick={handleClose}>
                    {i18n.close}
                </MainButton>
            </DialogActionsMUI>
        </Dialog>
    )
}

ModalSynthese.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
}
