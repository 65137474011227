/* eslint-disable quotes */
import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import {
    MEDEAU_TOKEN,
    CREDENTIALS,
    STATION_RANGE,
    PATH,
    TOKEN_MEDEAU_LOGIN,
} from '../pages/offline/constants/HomeConstants'
import AppStore from '../store/AppStore'
import HomeAction from '../pages/offline/actions/HomeAction'
import ToastrAction from '../pages/online/components/toasters/ToastrAction'
import LogAction from './log/actions/LogAction'
import { removeNullKeys } from './StoreUtils'

const codes = {
    200: (res) => res,
    201: (res) => res,
    403: () => {
        AppStore.dispatch(push('/login'))
        localStorage.removeItem(MEDEAU_TOKEN)
        throw new Error('Not Authorized')
    },
    404: () => {
        throw new Error('404 Not Found')
    },
    409: () => {
        throw new Error('409 Conflict')
    },
    429: () => {
        ToastrAction.error(i18n.error429, 'forceShow')
        throw new Error('429 Too Many Requests')
    },
    500: () => {
        throw new Error('500 error')
    }
}

const removeToken = () => {
    localStorage.removeItem(MEDEAU_TOKEN)
}

const checkError = (json, errorCodeManagement = {}) => {
    if (json.error && json.error !== 200 && errorCodeManagement[json.error.toString()]) {
        return errorCodeManagement[json.error.toString()]()
    } else if (json.error && json.error !== 200) {
        throw new Error(json.error)
    }
    return json
}

const resetCredentials = () => {
    localStorage.removeItem(MEDEAU_TOKEN)
    localStorage.removeItem(CREDENTIALS)
    AppStore.dispatch(push('/login'))
}

const getLoginPassword = () => {
    const credentials = localStorage.getItem(CREDENTIALS)
    return credentials ? atob(credentials).split(':') : null
}

const reconnect = () => {
    const credentials = getLoginPassword()
    const token = localStorage.getItem(MEDEAU_TOKEN)
    if (credentials && token) {
        removeToken()
        AppStore.dispatch(HomeAction.login(credentials[0], credentials[1]))
    } else {
        resetCredentials()
    }
}

const getJson = function (response) {
    if (response) {
        return response.json()
    }
    LogAction.logError(i18n.fetchError)
    return {}
}

const checkAuth = (response) => {
    switch (response.status) {
        case 200:
            return response.json()
        case 401:
            resetCredentials()
            throw new Error("Vous n'êtes pas autorisé à vous connecter")
        case 403:
            reconnect()
            throw new Error('Une autre session a été ouverte avec votre compte')
        case 404:
            return null
        case 500:
            return new Error()
        default:
            throw new Error("Vous n'êtes pas autorisé à vous connecter")
    }
}

const catchError = (err) => {
    LogAction.logError(err.message)
}

const checkStatus = (obj, response) => {
    const code = Object.keys(obj).find((element) => `${response.status}` === `${element}`)
    if (!code) {
        throw new Error(`Unhandled Error during fetch ${response.status}`)
    }
    return obj[code](response)
}

const checkAuthV2 = (response, overrideStatus = {}) => {
    return checkStatus({
        ...codes,
        ...overrideStatus
    }, response)
}

const getAuthorization = () => ({
    Authorization: `Bearer ${localStorage.getItem(MEDEAU_TOKEN)}`,
    Module: 'MEDEAU',
})

const getAuthorizationLogin = () => ({
    Authorization: TOKEN_MEDEAU_LOGIN,
    Module: 'MEDEAU'
})

const getBrutPath = () => {
    return localStorage.getItem(PATH)
}

const getPath = () => {
    const path = getBrutPath()
    if (path) {
        return `https://${path}/api/`
    }
    throw new Error('Not Found')
}

const getCMSFilePath = () => {
    const path = getBrutPath()
    return `https://${path}/contents/CMS/`
}

const getLocalApi = () => {
    return 'http://localhost:8000/'
}

const getPayload = () => {
    const token = localStorage.getItem(MEDEAU_TOKEN)
    if (token && token !== 'undefined') {
        return atob(token.split('.')[1])
    }
    return ''
}

const getServer = () => {
    return localStorage.getItem(PATH)
}

const getStationRange = () => {
    return localStorage.getItem(STATION_RANGE)
}

const genericPromise = (url, method = 'GET', body = null, overrideStatus = {}) => {
    return fetch(url, removeNullKeys({
        method,
        headers: getAuthorization(),
        body: body ? JSON.stringify(body) : null,
    }))
        .then(r => checkAuth(r, overrideStatus))
        .then(checkError)
}

const genericFetch = (promise, action, cb = () => {}) => {
    return (dispatch) => {
        return promise
            .then((json=[]) => {
                dispatch({ type: action, data: json })
                cb()
                return json
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                ToastrAction.error(i18n.fetchError)
            })
    }
}

export {
    checkAuth,
    checkAuthV2,
    checkStatus,
    checkError,
    getAuthorization,
    getPayload,
    getPath,
    getBrutPath,
    getCMSFilePath,
    removeToken,
    catchError,
    getLoginPassword,
    resetCredentials,
    getServer,
    getStationRange,
    getLocalApi,
    getJson,
    getAuthorizationLogin,
    genericPromise,
    genericFetch,
}
