import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, Icon, InputLabel, Select, MenuItem, styled } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { connect } from 'react-redux'
import { sortBy } from 'lodash'
import DtoContact from '../../referencials/dto/DtoContact'
import { InputRow, FormControlRow } from '../styled/inputs'
import DtoCity from '../../referencials/city/dto/DtoCity'
import { isValidEmail } from '../../../../utils/FormUtils'
import ToastrAction from '../toasters/ToastrAction'
import { isEmpty } from '../../../../utils/ObjectUtils'
import { formatPhone } from '../../../../utils/StringUtil'
import EditableCard from './EditableCard'
import StyledCard from './StyledCard'

const IconRow = styled(Icon)({
    marginRight: '5px',
})

const Container = styled(Grid)({
    margin: '8 0',
})

class ContactCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contact: props.contact,
            errors: [],
        }
    }

    onChange = (key, value) => {
        this.setState(({ contact }) => ({
            contact: {
                ...contact,
                [key]: value === '' ? undefined : value,
            }
        }))
    }

    onChangeCP = (value) => {
        const { cities } = this.props
        const citiesFiltered = cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(String(value || ''))))
        if (citiesFiltered.length === 1) {
            const city = citiesFiltered[0].code
            this.setState(({ contact }) => ({
                contact: {
                    ...contact,
                    cityCode: city === '' ? undefined : city,
                    postalCode: value === '' ? undefined : value,
                    postalBox: value === '' ? undefined : value,
                }
            }))
        } else {
            this.onChange('postalCode', value)
        }
    }

    onChangeCity = (value) => {
        const { citiesIndex } = this.props
        const city = citiesIndex[value] || {}
        if (city.link_postalCode && city.link_postalCode.length) {
            this.setState(({ contact }) => ({
                contact: {
                    ...contact,
                    postalCode: city.link_postalCode[0],
                    postalBox: city.link_postalCode[0],
                    cityCode: value === '' ? undefined : value,
                }
            }))
        } else {
            this.onChange('cityCode', value)
        }
    }

    onCancel = () => {
        const { contact } = this.props
        this.setState({ contact })
        this.props.onCancel()
    }

    onSave = () => {
        const { contact } = this.state
        if (this.checkForm()) {
            this.props.onSave(contact)
        } else {
            ToastrAction.error(i18n.fillAllFields)
        }
    }

    checkForm = () => {
        const { hideAddress } = this.props
        const { contact } = this.state
        const newErrors = []
        if (!hideAddress) {
            if (!contact.name) {
                newErrors.push('name')
            }
            if (!contact.postalCode) {
                newErrors.push('postalCode')
            }
            if (!contact.cityCode) {
                newErrors.push('cityCode')
            }
        }
        if (contact.phoneTel && contact.phoneTel.length !== 10) {
            newErrors.push('phoneTel')
        }
        if (contact.mobile && contact.mobile.length !== 10) {
            newErrors.push('mobile')
        }
        if (contact.desktopTel && contact.desktopTel.length !== 10) {
            newErrors.push('desktopTel')
        }
        if (contact.email && !isValidEmail(contact.email)) {
            newErrors.push('email')
        }
        if (newErrors.length) {
            this.setState({ errors: newErrors })
            return false
        }
        this.setState({ errors: [] })
        return true
    }

    getSelectedItem = () => {
        const { contact } = this.state
        const { citiesIndex } = this.props
        const city = citiesIndex[contact.cityCode]
        return city || {}
    }

    getContent = (editMode, contact, selectType, hideAddress) => {
        const { errors } = this.state
        const { cities } = this.props
        const city = this.getSelectedItem()
        const citiesFiltered = sortBy(cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(String(contact.postalCode || '')))), 'name')
        if (editMode) {
            return (
                <>
                    {selectType && (
                        <FormControlRow variant='outlined'>
                            <InputLabel>{i18n.type}</InputLabel>
                            <Select id='type' label={i18n.type}>
                                <MenuItem value={1}>Particulier</MenuItem>
                                <MenuItem value={2}>Raison sociale</MenuItem>
                            </Select>
                        </FormControlRow>
                    )}
                    {!hideAddress ? (
                        <>
                            <InputRow
                                item
                                id='name'
                                label={i18n.name}
                                type='text'
                                value={contact.name}
                                onChange={(e) => this.onChange('name', e.target.value)}
                                variant='outlined'
                                required
                                error={errors.find((e) => e === 'name')}
                            />
                            <InputRow
                                item
                                id='address'
                                label={i18n.address}
                                type='text'
                                value={contact.road}
                                onChange={(e) => this.onChange('road', e.target.value)}
                                variant='outlined'
                            />
                            <InputRow
                                item
                                id='addressComplement'
                                label={i18n.complement}
                                type='text'
                                value={contact.addressComplement}
                                onChange={(e) => this.onChange('addressComplement', e.target.value)}
                                variant='outlined'
                            />
                            <InputRow
                                item
                                id='postalCode'
                                label={i18n.postalCode}
                                type='number'
                                InputProps={{ inputProps: { min: 0, max: 99999 } }}
                                value={contact.postalCode}
                                onChange={(e) => this.onChangeCP(e.target.value)}
                                variant='outlined'
                                required
                                error={errors.find((e) => e === 'postalCode')}
                            />
                            <Autocomplete
                                id='city'
                                options={citiesFiltered}
                                getOptionLabel={(option) =>
                                    option.name ? option.name : ''
                                }
                                value={this.getSelectedItem()}
                                onChange={(e, value) =>
                                    this.onChangeCity(value ? value.code : '')
                                }
                                noOptionsText='Pas de correspondance'
                                renderInput={(params) => (
                                    <InputRow
                                        {...params}
                                        label={i18n.city}
                                        variant='outlined'
                                        required
                                        error={errors.find((e) => e === 'cityCode')}
                                    />
                                )}
                            />
                        </>
                    ) : (
                        <>
                            <p>{contact.name}</p>
                            <p>{contact.address}</p>
                            <p>{contact.addressComplement}</p>
                            <p>{`${contact.postalCode} ${city ? city.name : ''}`}</p>
                        </>
                    )}
                    <InputRow
                        item
                        id='phone'
                        label={i18n.phoneTel}
                        type='text'
                        value={contact.phoneTel}
                        onChange={(e) => this.onChange('phoneTel', e.target.value)}
                        variant='outlined'
                        // required
                        error={errors.find((e) => e === 'phoneTel')}
                        helperText={errors.find((e) => e === 'phoneTel') ? '10 chiffres obligatoires' : null}
                    />
                    <InputRow
                        item
                        id='mobilePhone'
                        label={i18n.portable}
                        type='text'
                        value={contact.mobile}
                        onChange={(e) => this.onChange('mobile', e.target.value)}
                        variant='outlined'
                        error={errors.find((e) => e === 'mobile')}
                        helperText={errors.find((e) => e === 'mobile') ? '10 chiffres obligatoires' : null}
                    />
                    <InputRow
                        item
                        id='fax'
                        label={i18n.fax}
                        type='text'
                        value={contact.desktopTel}
                        onChange={(e) => this.onChange('desktopTel', e.target.value)}
                        variant='outlined'
                        error={errors.find((e) => e === 'desktopTel')}
                        helperText={errors.find((e) => e === 'desktopTel') ? '10 chiffres obligatoires' : null}
                    />
                    <InputRow
                        item
                        id='email'
                        label={i18n.email}
                        type='text'
                        value={contact.email}
                        onChange={(e) => this.onChange('email', e.target.value)}
                        variant='outlined'
                        // required
                        error={errors.find((e) => e === 'email')}
                        helperText={errors.find((e) => e === 'email') ? 'Adresse non valide' : null}
                    />
                </>
            )
        }
        return (
            isEmpty(contact) ? (
                <p>{i18n.noInformation}</p>
            ) : (
                <>
                    <Container>{contact.name}</Container>
                    <Container>{contact.address}</Container>
                    <Container>{contact.addressComplement}</Container>
                    <Container>{`${contact.postalCode || ''} ${city && city.name ? city.name || '' : contact.city || ''}`}</Container>
                    {contact.phoneTel && (
                        <Container container alignItems='center'>
                            <IconRow>phone</IconRow>
                            <span>{contact.phoneTel ? formatPhone(contact.phoneTel) : ''}</span>
                        </Container>
                    )}
                    {contact.mobile && (
                        <Container container alignItems='center'>
                            <IconRow>smartphone</IconRow>
                            <span>{contact.mobile ? formatPhone(contact.mobile) : ''}</span>
                        </Container>
                    )}
                    {contact.desktopTel && (
                        <Container container alignItems='center'>
                            <IconRow>print</IconRow>
                            <span>{contact.desktopTel ? formatPhone(contact.desktopTel) : ''}</span>
                        </Container>
                    )}
                    {contact.email && (
                        <Container container alignItems='center'>
                            <IconRow>mail_outlined</IconRow>
                            <span>{contact.email}</span>
                        </Container>
                    )}
                </>
            )
        )
    }

    render() {
        const { title, editTitle, editMode, selectType, hideIcon, hideAddress, accordeon, expanded, style, cities, styleContainer, newStyle, subTitle } = this.props
        const { contact } = this.state
        if (cities.length) {
            if (newStyle) {
                const city = this.getSelectedItem()
                return (
                    <StyledCard
                        title={title}
                        subTitle={subTitle}
                        color='white'
                        styleContainer={{ padding: '5 10' }}
                        styleContent={{ padding: 0 }}
                        styleTitle={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}
                        styleSubTitle={{ borderTop: '1px solid black' }}
                        showIcon={!hideIcon}
                        toggleEditMode={this.props.toggleEditMode}
                    >
                        <Grid container style={{ padding: 10 }}>
                            <Grid item xs={4} className='bold' style={{ color: 'grey' }}>{i18n.name}</Grid>
                            <Grid item xs={8} className='bold'>{contact?.name || '-'}</Grid>
                        </Grid>
                        <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                            <Grid item xs={4} className='bold' style={{ color: 'grey' }}>{i18n.address}</Grid>
                            <Grid item xs={8} className='bold'>{contact?.address || '-'}</Grid>
                        </Grid>
                        <Grid container style={{ padding: 10 }}>
                            <Grid item xs={4} className='bold' style={{ color: 'grey' }}>{i18n.complement}</Grid>
                            <Grid item xs={8} className='bold'>{contact?.addressComplement || '-'}</Grid>
                        </Grid>
                        <Grid container style={{ padding: 10 }}>
                            <Grid item xs={4} className='bold' style={{ color: 'grey' }}>{i18n.city}</Grid>
                            <Grid item xs={4} className='bold'>{`${contact.postalCode || ''} ${city && city.name ? city.name || '' : contact.city || ''}`}</Grid>
                        </Grid>
                        <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                            <Grid item xs={4} className='bold' style={{ color: 'grey' }}>{i18n.phoneTel}</Grid>
                            <Grid item xs={8} className='bold'>{contact?.phoneTel ? formatPhone(contact.phoneTel) : '-'}</Grid>
                        </Grid>
                        <Grid container style={{ padding: 10 }}>
                            <Grid item xs={4} className='bold' style={{ color: 'grey' }}>{i18n.portable}</Grid>
                            <Grid item xs={8} className='bold'>{contact?.mobile ? formatPhone(contact.mobile) : '-'}</Grid>
                        </Grid>
                        <Grid container style={{ padding: 10 }}>
                            <Grid item xs={4} className='bold' style={{ color: 'grey' }}>{i18n.desktopTel}</Grid>
                            <Grid item xs={8} className='bold'>{contact?.desktopTel ? formatPhone(contact.desktopTel) : '-'}</Grid>
                        </Grid>
                        <Grid container style={{ padding: 10 }}>
                            <Grid item xs={4} className='bold' style={{ color: 'grey' }}>{i18n.email}</Grid>
                            <Grid item xs={8} className='bold'>{contact?.email || '-'}</Grid>
                        </Grid>
                    </StyledCard>
                )
            }
            return (
                <EditableCard
                    title={title}
                    editTitle={editTitle}
                    editMode={editMode}
                    toggleEditMode={this.props.toggleEditMode}
                    onSave={this.onSave}
                    onCancel={this.onCancel}
                    hideIcon={hideIcon}
                    accordeon={accordeon}
                    expanded={expanded}
                    style={style}
                    styleContainer={styleContainer}
                >
                    {this.getContent(editMode, contact, selectType, hideAddress)}
                </EditableCard>
            )
        }
        return ''
    }
}

ContactCard.propTypes = {
    contact: PropTypes.instanceOf(DtoContact).isRequired,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    editTitle: PropTypes.string,
    editMode: PropTypes.bool.isRequired,
    toggleEditMode: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    selectType: PropTypes.bool,
    accordeon: PropTypes.bool,
    expanded: PropTypes.bool,
    hideIcon: PropTypes.bool,
    hideAddress: PropTypes.bool,
    newStyle: PropTypes.bool,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    style: PropTypes.instanceOf(PropTypes.shape({})),
    styleContainer: PropTypes.instanceOf(PropTypes.shape({})),
}

ContactCard.defaultProps = {
    editable: false,
}

const mapStateToProps = (store) => {
    return {
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

export default connect(mapStateToProps)(ContactCard)
