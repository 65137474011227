import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Accordion, AccordionSummary, AccordionDetails, Icon, Grid } from '@mui/material'

const useStyles = makeStyles(() => ({
    headAccordion: {
        backgroundColor: 'white',
        width: 'auto',
        padding: 8,
        display: 'flex',
        justifyContent: 'left',
        fontWeight: 'bold',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        height: 30,
    },
    detailsAccordion: {
        padding: '0 10',
        borderBottom: '1px solid black',
    }
}))

export default function Accordeon({
    title,
    disabled,
    expanded,
    children,
    headStyle = {},
    showIcon = false,
    icon = 'edit',
    onClickIcon = () => { },
}) {
    const classes = useStyles()

    const onClickAccordeonIcon = (event) => {
        event.stopPropagation()
        onClickIcon()
    }

    return (
        <Accordion
            disabled={disabled}
            defaultExpanded={expanded}
            disableGutters
            style={{ boxShadow: 'none' }}
            TransitionProps={{ unmountOnExit: true, timeout: 250 }}
        >
            <AccordionSummary
                expandIcon={<Icon style={{ color: 'black', border: '1px solid black', borderRadius: '50%' }}>expand_more</Icon>}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.headAccordion}
                style={headStyle}
            >
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Grid item xs={10}>
                        {title}
                    </Grid>
                    {showIcon && (
                        <Grid item className='clickable' onClick={(event) => onClickAccordeonIcon(event)}>
                            <Icon>{icon}</Icon>
                        </Grid>
                    )}
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.detailsAccordion}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

Accordeon.propTypes = {
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    expanded: PropTypes.bool,
    showIcon: PropTypes.bool,
    icon: PropTypes.string,
    onClickIcon: PropTypes.func,
    children: PropTypes.element,
    headStyle: PropTypes.instanceOf(PropTypes.shape({})),
}