import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { Container } from '../../../components/styled/grid'
import { InputRow } from '../../../components/styled/inputs'
import { hasValue } from '../../../../../utils/NumberUtil'
import { MainButton } from '../../../components/styled/buttons'
import DtoVariousMateriel from '../../installations/dto/DtoVariousMateriel'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../../components/styled/dialog'
import { Grid } from '@mui/material'
import { ReactComponent as CloseLogo } from '../../../../../ressources/static/svg/Fermer.svg'
import { getDate } from '../../../../../utils/DateUtil'

const ModalEditCounter = ({
    open,
    toggleModal,
    onSave = () => { },
    onChange = () => { },
    counter = {},
}) => {
    const handleClose = () => {
        toggleModal()
    }

    const onClickButton = () => {
        toggleModal()
        onSave()
    }

    const {
        variousMatTypes,
    } = useSelector(store => ({
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
    }), shallowEqual)

    const counterType = useMemo(() => variousMatTypes.find((t) => t.materielType === counter.materielType), [counter])

    return (
        <DialogMUI
            maxWidth='md'
            open={open}
        >
            <DialogTitleMUI className='blod' style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: 0 }}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <b style={{ padding: 10, fontSize: 21 }}>{i18n.changeInfos}</b>
                    <CloseLogo fill='white' className='clickable' style={{ padding: 10, height: 30 }} onClick={handleClose} />
                    <Grid item xs={12} style={{ borderTop: '1px solid white', padding: 10 }}>{counter?.reference}</Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Container direction='column'>
                    <InputRow
                        item
                        id='name'
                        label={i18n.name}
                        type='text'
                        value={counter.name}
                        onChange={(e) => onChange({ name: e.target.value })}
                        variant='outlined'
                    />
                    <p>{i18n.type} : {counterType ? counterType.name : ''}</p>
                    <p>{i18n.marque} : {counter.brand || ''}</p>
                    <p>{i18n.reference} : {counter.serialNumber || ''}</p>
                    <p>{i18n.coefLecture} : {counter.readingCoefficient || ''}</p>
                    <p>{i18n.installationDate} : {getDate(counter.installationDate) || ''}</p>
                    <p>{i18n.revisionDate} : {getDate(counter.revisionDate) || ''}</p>
                    <p>{i18n.tauxAffectation} : {hasValue(counter.assignmentRate) ? `${counter.assignmentRate}%` : ''}</p>
                    <p>{i18n.sharedDispositif} : {counter.isShared ? i18n.yes : i18n.no}</p>
                    <p>{i18n.forRent} : {counter.rent ? i18n.yes : i18n.no}</p>
                    <p>{i18n.comment} : {counter.comment}</p>
                </Container>
            </DialogContentMUI>
            <DialogActionsMUI>
                <MainButton onClick={onClickButton}>
                    {i18n.validate}
                </MainButton>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

ModalEditCounter.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    counter: PropTypes.instanceOf(DtoVariousMateriel),
}

export default ModalEditCounter