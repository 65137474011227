import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import NavBar from './NavBar'
import ModalNewEntry from '../consommation/ModalNewEntry'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import { hasValue } from '../../../utils/NumberUtil'
import { lightColor, mainColor } from './styled/theme'
import { ReactComponent as HomeLogo } from '../../../ressources/static/svg/Home.svg'
import { ReactComponent as PointsLogo } from '../../../ressources/static/svg/Pointdeau.svg'
import { ReactComponent as DeclareLogo } from '../../../ressources/static/svg/Declarer.svg'
import { ReactComponent as MessagesLogo } from '../../../ressources/static/svg/Messages.svg'
import { ReactComponent as AccountLogo } from '../../../ressources/static/svg/Compte.svg'
import { compact, orderBy } from 'lodash'
import DtoUser from '../account/dto/DtoUser'
import DtoAccountStatistic from '../account/dto/DtoAccountStatistic'
import { StyledBadge } from './styled/badges'
import { LAST_TIME_HOME } from '../../offline/constants/HomeConstants'
import SieauTooltip from './styled/SieauTooltip'
import { sieauTooltip } from '../../../utils/FormUtils'
import { isIOS } from 'react-device-detect'

class App extends Component {
    // eslint-disable-next-line react/state-in-constructor
    state = {
        openModalEntry: false,
    }

    componentDidMount() {
        if (window.location.href.split('#')[1] === '/') {
            this.props.push('/home')
        }
    }

    componentDidUpdate() {
        if (window.location.href.split('#')[1] === '/') {
            this.props.push('/home')
        }
    }

    push = (path) => {
        this.props.push(path)
        window.scrollTo(0, 0)
    }

    isActive = (path) => {
        return window.location.href.split('#')[1].startsWith(path)
    }

    render() {
        const { openModalEntry } = this.state
        const { exploitation, accountUser, accountStatistics, actualities } = this.props
        const hasExploit = hasValue(exploitation?.idExploitation)
        const orderedStats = orderBy(accountStatistics.filter((s) => s.eventType === 'LOGIN'), 'statisticDate', 'desc')
        const lastConnection = orderedStats.length >= 2 ? orderedStats[1] : orderedStats[0]
        const actuNotSeen = !lastConnection ? [...actualities] : compact(actualities.map((c) => {
            if (localStorage.getItem(LAST_TIME_HOME) < c.updateDate) {
                if (c.status === 1 || accountUser.isAdmin === '1') {
                    return c
                }
            }
            return null
        }))
        const actuToShow = orderBy(actuNotSeen, 'updateDate')

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <NavBar />
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{
                        zIndex: '1000',
                        position: 'fixed',
                        bottom: '0',
                        height: '70px',
                        backgroundColor: mainColor,
                        padding: isIOS ? '0 20 15 20' : '0 20',
                        borderRadius: '5px 5px 0 0',
                        width: 'calc(100% - 20px)',
                        boxShadow: 'cyan 0px 0px 50px -10px',
                    }}
                >
                    <Grid item className='clickable' {...sieauTooltip(i18n.actualities)}>
                        <StyledBadge badgeContent={actuToShow.length} badgeColor={this.isActive('/home') ? 'white' : lightColor}>
                            <HomeLogo onClick={() => this.push('/home')} fill={this.isActive('/home') ? 'white' : lightColor} style={{ height: 30 }} />
                        </StyledBadge>
                    </Grid>
                    <Grid item className={hasExploit ? 'clickable' : ''} {...sieauTooltip(i18n.myPoints)}>
                        <PointsLogo onClick={hasExploit ? () => this.push('/consommation') : () => {}} fill={this.isActive('/consommation') ? 'white' : (hasExploit ? lightColor : 'grey')} style={{ height: 30 }} />
                    </Grid>
                    <Grid item className={hasExploit ? 'clickable' : ''} {...sieauTooltip(i18n.newEntry)}>
                        <DeclareLogo onClick={hasExploit ? () => this.setState({ openModalEntry: true }) : () => {}} fill={hasExploit ? lightColor : 'grey'} style={{ height: 30 }} />
                    </Grid>
                    <Grid item className='clickable' {...sieauTooltip(i18n.messaging)}>
                        <StyledBadge badgeContent={null} badgeColor={this.isActive('/contact') ? 'white' : lightColor}>
                            <MessagesLogo onClick={() => this.push('/contact')} fill={this.isActive('/contact') ? 'white' : lightColor} style={{ height: 25 }} />
                        </StyledBadge>
                    </Grid>
                    <Grid item className='clickable' {...sieauTooltip(i18n.myAccount)}>
                        <AccountLogo onClick={() => this.push('/account')} fill={this.isActive('/account') || this.isActive('/exploitation') ? 'white' : lightColor} style={{ height: 30 }} />
                    </Grid>
                </Grid>
                {openModalEntry && (
                    <ModalNewEntry
                        open={openModalEntry}
                        onClose={() => this.setState({ openModalEntry: false })}
                    />
                )}
                <SieauTooltip />
            </div>
        )
    }
}

App.propTypes = {
    cmsLogo: PropTypes.instanceOf(DtoCMSEvent),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    accountUser: PropTypes.instanceOf(DtoUser),
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountStatistic)),
    actualities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    push: PropTypes.func,
    match: PropTypes.shape({}),
    classes: PropTypes.any,
}

const mapStateToProps = (store) => {
    return {
        cmsLogo: store.HomeReducer.cmsLogo,
        exploitation: store.AgriReducer.exploitation,
        accountUser: store.AccountReducer.accountUser,
        accountStatistics: store.AccountReducer.accountStatistics,
        actualities: store.CmsReducer.actualities,
    }
}

const mapDispatchToProps = {
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
