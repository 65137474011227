import fetch from 'isomorphic-fetch'
import ApplicationConf from '../../../conf/ApplicationConf'
import { getAuthorization, getAuthorizationLogin } from '../../ActionUtils'
import { path } from '../../../conf/basepath'

const LogAction = {
    logError: (message = '') => () => {
        console.error(`log : ${message}`)
        if (!path.includes('localhost')) {
            return fetch(ApplicationConf.log.error(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    message,
                    service: 'AGRIMOBILE_MEDEAU',
                    class: (window.location.href || document.URL).split('?_k')[0],
                    version: process.envs.VERSION,
                    function: '',
                })
            })
        }
        return undefined
    },
    logInfo: (message = '') => () => fetch(ApplicationConf.log.info(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify({
            message,
            service: 'AGRIMOBILE_MEDEAU',
            class: (window.location.href || document.URL).split('?_k')[0],
            version: process.envs.VERSION,
            function: '',
        })
    }),
    logDebug: (message = '') => () => fetch(ApplicationConf.log.debug(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify({
            message,
            service: 'AGRIMOBILE_MEDEAU',
            class: (window.location.href || document.URL).split('?_k')[0],
            version: process.envs.VERSION,
            function: '',
        })
    }),
    logJournal: (user, comment, descr) => () => fetch(ApplicationConf.log.journal(), {
        method: 'POST',
        headers: getAuthorizationLogin(),
        body: JSON.stringify({
            user, // Option[String],
            comment, // Option[String],
            function: descr, // Option[String] = None,
        })
    }),

}

export default LogAction