import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid, Icon } from '@mui/material'
import { withStyles } from '@mui/styles'
import DtoInstallation from '../../referencials/installations/dto/DtoInstallation'
import DtoCity from '../../referencials/city/dto/DtoCity'
// import { Container, Item } from '../styled/grid'
import StyledCard from './StyledCard'
import { getDistanceLabel } from '../../../../utils/mapUtils/CoordinateUtils'
import { ReactComponent as WarningLogo } from '../../../../ressources/static/svg/Avertissement.svg'
import DtoExploitation from '../../agri/dto/exploitation/DtoExploitation'
import { orderBy } from 'lodash'
import moment from 'moment'
import { sieauTooltip } from '../../../../utils/FormUtils'

const styles = () => ({
    title: {
        backgroundColor: '#ccc',
        fontWeight: 'bold',
        color: 'black',
        borderTop: '1px solid grey',
    },
    paper: {
        backgroundColor: '#dfdfdf',
    },
    listItem: {
        padding: '8px 16px',
        cursor: 'pointer',
    },
})

class PointsCard extends Component {
    constructor(props) {
        super(props)
    }

    toggleAccordeon = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    goToDetailsPoint = (id) => {
        this.props.push(`/exploitation/point/${id}`)
    }

    showWarning = (idInstallation) => {
        const { exploitation } = this.props
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === 7 && m.siteCode === idInstallation) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const lastChronicle = orderBy(exploitation.link_chronicles.filter((c) => [...pumpsIds, ...countersIds].includes(c.idMat) && c.measureType === 2), 'measureDate', 'desc')[0]
        if (lastChronicle && moment().diff(moment(lastChronicle.measureDate), 'days') < 7) {
            return false
        }
        return true
    }

    getAccordeon = (points) => {
        const { citiesIndex, bookmarks, onlyFavs } = this.props

        const pointsOrdered = [
            ...points.filter((p) => bookmarks.some((b) => b.stationType === 7 && b.identifiant === `${p.id}`)),
            ...(onlyFavs ? [] : points.filter((p) => !bookmarks.some((b) => b.stationType === 7 && b.identifiant === `${p.id}`))),
        ]
        return (
            <Grid container>
                {pointsOrdered.map((p) => (
                    <StyledCard
                        key={p.id}
                        subTitle={(
                            <Grid container justifyContent='space-between'>
                                <Grid item xs={2.5}>{p.distance ? getDistanceLabel(p.distance) : '-'}{bookmarks.some((b) => b.stationType === 7 && b.identifiant === `${p.id}`) && <Icon>start</Icon>}</Grid>
                                <Grid item xs={8}>{p.name || (p.code ? `${i18n.point} ${p.code || ''}` : '')}<br/><span style={{ fontSize: 14 }}>{`${citiesIndex[p.townCode]?.name || ''}${p.location ? ` - ${p.location || ''}` : ''}`}</span></Grid>
                                <Grid item xs={1.5}>{this.showWarning(p.id) && <WarningLogo {...sieauTooltip(i18n.noIndexSince7Days)} style={{ height: 30 }} />}</Grid>
                            </Grid>
                        )}
                        className='clickable'
                        onClick={this.props.onClickPoint ? () => this.props.onClickPoint(p) : () => this.goToDetailsPoint(p.id)}
                        styleContainer={{ width: '100%', padding: 0, margin: '5 0', borderTop: '1px solid black' }}
                    >
                        {p.footerContent}
                    </StyledCard>
                ))}
            </Grid>
        )
    }

    render() {
        const {
            // classes,
            pointsUsed,
            pointsNotUsed,
            // pointsDeleted,
            cities = [],
            // map,
            style,
            className,
        } = this.props
        // const total = pointsUsed.length + pointsNotUsed.length + pointsDeleted.length

        if (cities.length) {
            return (
                <div className={className} style={{ width: '100%', ...style }}>
                    {/* <Container
                        justifyContent='space-between'
                        alignItems='center'
                        className={classes.title}
                    >
                        <Item />
                        <Item>
                            <p>
                                <b style={{ fontSize: '1.7rem' }}>{total}</b>
                                {total > 1
                                    ? ` ${i18n.pointsPrelevement}`
                                    : ` ${i18n.pointPrelevement}`}
                            </p>
                        </Item>
                        <Item>
                            {map && (
                                <Icon
                                    className='clickable'
                                    onClick={() => this.props.push('/exploitation/map')}
                                    style={{ fontSize: '3rem', paddingRight: 10 }}
                                >
                                    map
                                </Icon>

                            )}
                        </Item>
                    </Container> */}
                    <>
                        {[...pointsUsed, ...pointsNotUsed].length ? this.getAccordeon([...pointsUsed, ...pointsNotUsed], [...pointsUsed, ...pointsNotUsed].length > 1 ? ` ${i18n.usedPoints}` : ` ${i18n.usedPoint}`) : null}
                        {/* {pointsNotUsed.length ? this.getAccordeon(pointsNotUsed, pointsNotUsed.length > 1 ? ` ${i18n.noUsedPoints}` : ` ${i18n.noUsedPoint}`, 'openNoUsedPoints') : null} */}
                        {/* {pointsDeleted.length ? this.getAccordeon(pointsDeleted, pointsDeleted.length > 1 ? ` ${i18n.deletePoints}` : ` ${i18n.deletePoint}`, 'openDeletePoints') : null} */}
                    </>
                </div>
            )
        }
        return ''
    }
}

PointsCard.propTypes = {
    pointsUsed: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
    pointsNotUsed: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
    pointsDeleted: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    style: PropTypes.instanceOf(PropTypes.shape({})),
    className: PropTypes.string,
    push: PropTypes.func,
    onClickPoint: PropTypes.func,
    map: PropTypes.bool,
    onlyFavs: PropTypes.bool,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    bookmarks: PropTypes.arrayOf(PropTypes.shape({})),
}

PointsCard.defaultProps = {
    editable: false,
}

const mapStateToProps = (store) => {
    return {
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        exploitation: store.AgriReducer.exploitation,
        bookmarks: store.ReferencialReducer.bookmarks,
    }
}

const mapDispatchToProps = {
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PointsCard))
