import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import HomeAction from '../pages/offline/actions/HomeAction'

const firebaseConfig = {
    apiKey: 'AIzaSyC9kc24UG1looEoMZLVcf2-NabIsRwva5g',
    authDomain: 'notification-iryqua.firebaseapp.com',
    projectId: 'notification-iryqua',
    storageBucket: 'notification-iryqua.appspot.com',
    messagingSenderId: '728408198338',
    appId: '1:728408198338:web:728e3b2f387e8638003f5b',
    measurementId: 'G-GH5LJG74H1',
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const getTokenFCM = async (save = true) => {
    return getToken(messaging, { vapidKey: 'BEwCFCRrIYrLzPIve-EeMbaARZpSGDdA-rcsk87Qn3qEGoOhHBMMCHBab2FXM1h1avhZo-f15PIChfLFix1uKDw' }).then((currentToken) => {
        if (currentToken) {
            if (save) {
                HomeAction.setDevice(currentToken)
            }
            return currentToken
        }
        // Show permission request UI
        console.error('No registration token available. Request permission to generate one.')
        return undefined
    }).catch((err) => {
        console.error('An error occurred while retrieving token. ', err)
    // ...
    })
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })