import { styled } from '@mui/styles'
import { darkColor } from './theme'

const StyledFieldSet = styled('fieldset')({
    borderRadius: '5px',
    border: 'solid 1px grey',
    padding: '5 8',
    margin: '10 0 0 0',
})

const StyledLegend = styled('legend')({
    backgroundColor: 'white',
    border: 'solid grey 1px',
    borderRadius: '5px',
    padding: '0 10',
    color: darkColor,
    fontWeight: 'bold',
})

export { StyledFieldSet, StyledLegend }