import React from 'react'
import PropTypes from 'prop-types'
import { InputLabel, MenuItem, Select } from '@mui/material'
import { hasValue } from '../../../../utils/NumberUtil'
import { FormControlRow } from '../styled/inputs'

const DEFAULTVALUE = 'nullSelectedValue'

const SimpleSelect = ({
    label,
    required,
    value,
    options = [],
    styleOption = {},
    onChange = () => { },
    keyValue,
    keyName,
    id,
    disabled,
    style,
    styleLabel,
    nullLabel,
    returnNull = true,
    noNullValue,
    integerValue,
    className,
    selectClassName,
    variant = 'outlined',
}) => {
    const onChangeValue = (v) => {
        const valueFound = options.find((o) => (keyValue ? o[keyValue] : o.value) === v) || {}
        const valueSelected = keyValue ? valueFound[keyValue] : valueFound.value
        onChange(integerValue ? hasValue(parseInt(valueSelected)) ? parseInt(valueSelected) : null : valueSelected)
    }

    return (
        <FormControlRow
            id={id}
            variant={variant}
            disabled={disabled}
            required={required}
            className={className}
            style={style}
        >
            <InputLabel style={styleLabel}>{label}</InputLabel>
            <Select
                id={id}
                label={label}
                onChange={(event) => onChangeValue(event.target.value)}
                value={value}
                className={selectClassName}
            >
                {!noNullValue && (
                    <MenuItem value={returnNull ? null : DEFAULTVALUE} style={{ ...styleOption }}>{nullLabel || ''}</MenuItem>
                )}
                {options.map((o) => {
                    return (
                        <MenuItem
                            value={keyValue ? o[keyValue] : o.value}
                            style={{ maxWidth: '100%' }}
                        >
                            {keyName ? o[keyName] : o.name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControlRow>
    )
}

SimpleSelect.propTypes = {
    label: PropTypes.string,
    variant: PropTypes.string,
    value: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
        dephValue: PropTypes.number,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    col: PropTypes.number,
    keyValue: PropTypes.string,
    keyName: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    nullLabel: PropTypes.string,
    returnNull: PropTypes.bool,
    noNullValue: PropTypes.bool,
    integerValue: PropTypes.bool,
    style: PropTypes.shape({}),
    styleLabel: PropTypes.shape({}),
    styleOption: PropTypes.shape({}),
    className: PropTypes.string,
    selectClassName: PropTypes.string,
    required: PropTypes.bool,
}

export default SimpleSelect