import React, { useEffect, useState } from 'react'
import { AppBar, Grid, Tabs } from '@mui/material'
import i18n from 'simple-react-i18n'
import { TabStyle } from '../components/styled/tabs'
import MyExploitation from '../exploitation/MyExploitation'
import MyAccount from './MyAccount'
import MyDocuments from '../documents/MyDocuments'
import MyDeclarations from '../documents/MyDeclarations'
import { useDispatch, useSelector } from 'react-redux'
import { hasValue } from '../../../utils/NumberUtil'
import { ReactComponent as SamplerLogo } from '../../../ressources/static/svg/Irrigateur.svg'
import { ReactComponent as DeclarationsLogo } from '../../../ressources/static/svg/Declarations.svg'
import { ReactComponent as DocumentsLogo } from '../../../ressources/static/svg/Documents.svg'
import { ReactComponent as PreferencesLogo } from '../../../ressources/static/svg/Preferences.svg'
import { lightColor } from '../components/styled/theme'
import HomeAction from '../../offline/actions/HomeAction'
import { sieauTooltip } from '../../../utils/FormUtils'

const PANEL_EXPLOITATION = 1
const PANEL_DECLARATIONS = 2
const PANEL_DOCUMENTS = 3
const PANEL_ACCOUNT = 4

const AccountApp = () => {
    const [panel, setPanel] = useState(PANEL_EXPLOITATION)
    const {
        exploitation,
        contributors,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        contributors: store.ReferencialReducer.contributors,
    }))

    const dispatch = useDispatch()

    useEffect(() => {
        const preleveur = contributors.find((c) => c.id === exploitation.operatorCode)
        dispatch(HomeAction.setTitle([{
            title: preleveur?.name,
            subTitle: exploitation.codification,
            href: '/account',
            style: {
                borderRadius: '',
                borderBottom: '1px solid white',
                boxShadow: '',
            },
        }]))
    }, [contributors, exploitation])


    const hasExploit = hasValue(exploitation?.idExploitation)
    return (
        <div style={{ margin: '0 0' }}>
            <AppBar position='static' style={{ backgroundColor: 'transparent', boxShadow: 'cyan 0px 0px 50px -10px', padding: '0 10' }}>
                <Tabs
                    value={panel}
                    onChange={(e, v) => setPanel(v)}
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                    style={{ borderRadius: '0 0 5px 5px' }}
                >
                    <TabStyle {...sieauTooltip(i18n.myExploit)} icon={<SamplerLogo fill={panel === PANEL_EXPLOITATION ? 'white' : lightColor } style={{ height: 30 }} />} value={PANEL_EXPLOITATION} width='25%' />
                    <TabStyle {...sieauTooltip(i18n.myDeclarations)} icon={<DeclarationsLogo fill={panel === PANEL_DECLARATIONS ? 'white' : lightColor } style={{ height: 30 }} />} value={PANEL_DECLARATIONS} width='25%' />
                    <TabStyle {...sieauTooltip(i18n.myDocuments)} icon={<DocumentsLogo fill={panel === PANEL_DOCUMENTS ? 'white' : lightColor } style={{ height: 30 }} />} value={PANEL_DOCUMENTS} width='25%' />
                    <TabStyle {...sieauTooltip(i18n.settings)} icon={<PreferencesLogo fill={panel === PANEL_ACCOUNT ? 'white' : lightColor } style={{ height: 30 }} />} value={PANEL_ACCOUNT} width='25%' />
                </Tabs>
            </AppBar>
            <Grid
                container
                direction='column'
                justifyContent='space-around'
            >
                {panel === PANEL_EXPLOITATION && <MyExploitation />}
                <>
                    {panel === PANEL_DECLARATIONS && (
                        <>
                            {hasExploit ? <MyDeclarations /> : <b className='padding-top-2' style={{ display: 'flex', justifyContent: 'center' }}>Veuillez sélectionner une exploitation</b> }
                        </>
                    )}
                    {panel === PANEL_DOCUMENTS && (
                        <>
                            {hasExploit ? <MyDocuments /> : <b className='padding-top-2' style={{ display: 'flex', justifyContent: 'center' }}>Veuillez sélectionner une exploitation</b> }
                        </>
                    )}
                </>
                {panel === PANEL_ACCOUNT && <MyAccount />}
            </Grid>
        </div>
    )
}

export default AccountApp