import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid, LinearProgress, Icon, styled, Divider } from '@mui/material'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import { formatPhone, formatSiret } from '../../../utils/StringUtil'
import ContactAction from '../contact/actions/ContactAction'
import DtoContact from '../referencials/dto/DtoContact'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import ToastrAction from '../components/toasters/ToastrAction'
import { MainButton } from '../components/styled/buttons'
import { Item } from '../components/styled/grid'
import DtoUser from '../account/dto/DtoUser'
import { hasValue } from '../../../utils/NumberUtil'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import { ReactComponent as FolderLogo } from '../../../ressources/static/svg/Dossier-2D.svg'
import { mainColor, lightColor } from '../components/styled/theme'
import StyledCard from '../components/cards/StyledCard'
import { isEmpty } from 'lodash'
import { orderPointsList } from '../../../utils/ObjectUtils'
import { getI18nTitleDataLength, getI18nTitleData } from '../../../utils/StringUtil'
import Accordeon from '../components/Accordeon'

const IconRow = styled(Icon)({
    marginRight: '5px',
})

const Container = styled(Grid)({
    margin: '8 8',
})

class MyExploitation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pointsUsed: [],
            pointsNotUsed: [],
            pointsDeleted: [],
            dataLoaded: false,
            pointsLoaded: false,
            contactsLoaded: false,
            preleveur: new DtoIntervenant({}),
            contactsExploitation: [],
        }
    }

    componentDidMount() {
        this.getExploitation()
    }

    getExploitation = () => {
        this.props.fetchExploitation().then(() => {
            const { exploitation, installations, contacts, contributors } = this.props
            if (hasValue(exploitation?.idExploitation)) {
                this.props.fetchDeclarationByExploitationId(exploitation.idExploitation).then((declaration) => {
                    if (declaration && declaration.statusCode !== 4) {
                        ToastrAction.info(i18n.modifSurveyNotShow)
                    }
                })
                if (!installations.length) {
                    this.props.fetchInstallationsByExploitationId(exploitation.idExploitation).then(() => {
                        this.setPoints(exploitation)
                    })
                } else {
                    this.setPoints(exploitation)
                }
                if (!contacts.length) {
                    this.props.fetchContacts().then(() => {
                        this.setContacts(exploitation)
                    })
                } else {
                    this.setContacts(exploitation)
                }
                if (!contributors.length) {
                    this.props.fetchContributors().then(() => {
                        this.setPreleveur(exploitation)
                    })
                } else {
                    this.setPreleveur(exploitation)
                }
            } else {
                this.setState({ dataLoaded: true })
            }
        })
    }

    setPreleveur = (exploitation) => {
        const { contributors } = this.props
        const preleveur = contributors.find((c) => c.id === exploitation.operatorCode)
        this.setState({
            preleveur: preleveur ? preleveur : new DtoIntervenant({}),
            dataLoaded: true,
        })
    }

    setPoints = (exploitation) => {
        const { installations, citiesIndex } = this.props
        const pointsUsed = []
        const pointsNotUsed = []
        const pointsDeleted = []
        exploitation.link_samplings.forEach((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                const pointFormatted = {
                    ...point,
                    cityName: city.name,
                }
                switch (linkPoint.stateCode) {
                    case 1:
                        pointsUsed.push(pointFormatted)
                        break
                    case 2: default:
                        pointsNotUsed.push(pointFormatted)
                        break
                    case 3:
                        pointsDeleted.push(pointFormatted)
                        break
                }
            }
        })
        this.setState({
            pointsUsed: orderPointsList(pointsUsed),
            pointsNotUsed: orderPointsList(pointsNotUsed),
            pointsDeleted: orderPointsList(pointsDeleted),
            pointsLoaded: true
        })
    }

    setContacts = (exploitation) => {
        const { contacts } = this.props
        const contactsExploitation = []
        exploitation.link_contributors.forEach((c) => {
            const contributorFind = contacts.find(
                (contact) => contact.id === c.contactCode,
            )
            if (contributorFind) {
                contactsExploitation.push({
                    ...contributorFind,
                    contributorType: c.contributorType,
                })
            }
        })
        this.setState({
            contactsExploitation: contactsExploitation,
            contactsLoaded: true,
        })
    }

    getListContacts = () => {
        const { contactsExploitation, preleveur } = this.state
        const { codesSandre, contacts } = this.props
        const typesContributors = [{ code: -1, name: i18n.legalRepresentative }, ...codesSandre.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT')]
        let allContacts = [...contactsExploitation]
        if (preleveur) {
            const legalRepresentative = contacts.find((c) => c.id === preleveur.legalRepresentative)
            if (legalRepresentative && !allContacts.find((c) => c.contributorType === -1 || c.id === legalRepresentative.id)) {
                allContacts.unshift({
                    ...legalRepresentative,
                    contributorType: -1,
                })
            }
        }
        return allContacts.map((c) => {
            const type = typesContributors.find((t) => t.code === c.contributorType)
            const city = this.getCity()
            return (
                <Item item xs={12} md={3} style={{ padding: '0 10 10 10' }}>
                    <StyledCard subTitle={type ? type.name : 'Inconnu'} styleContainer={{ padding: 0, borderTop: '1px solid black' }} styleContent={{ padding: 0 }}>
                        {isEmpty(c) ? (
                            <p>{i18n.noInformation}</p>
                        ) : (
                            <>
                                <Container>{c.name}</Container>
                                <Container>{c.address}</Container>
                                <Container>{c.addressComplement}</Container>
                                <Container>{`${c.postalCode || ''} ${city && city.name ? city.name || '' : c.city || ''}`}</Container>
                                <Divider style={{ borderColor: 'black' }} />
                                {c.phoneTel && (
                                    <Container container alignItems='center'>
                                        <IconRow>phone</IconRow>
                                        <span>{c.phoneTel ? formatPhone(c.phoneTel) : ''}</span>
                                    </Container>
                                )}
                                {c.mobile && (
                                    <Container container alignItems='center'>
                                        <IconRow>smartphone</IconRow>
                                        <span>{c.mobile ? formatPhone(c.mobile) : ''}</span>
                                    </Container>
                                )}
                                {c.desktopTel && (
                                    <Container container alignItems='center'>
                                        <IconRow>print</IconRow>
                                        <span>{c.desktopTel ? formatPhone(c.desktopTel) : ''}</span>
                                    </Container>
                                )}
                                {c.email && (
                                    <Container container alignItems='center'>
                                        <IconRow>mail_outlined</IconRow>
                                        <span>{c.email}</span>
                                    </Container>
                                )}
                            </>
                        )}
                    </StyledCard>
                </Item>
            )
        })
    }

    getCity = () => {
        const { citiesIndex } = this.props
        const { intervenant } = this.state
        const city = citiesIndex[intervenant?.cityCode]
        return city || {}
    }

    getSampler = () => {
        const { preleveur } = this.state
        const city = this.getCity()
        return (
            <Item item xs={12} md={3} style={{ padding: '0 10 10 10' }}>
                <StyledCard subTitle={i18n.preleveur} styleContainer={{ padding: 0, borderTop: '1px solid black' }} styleContent={{ padding: 0 }}>
                    {isEmpty(preleveur) ? (
                        <p>{i18n.noInformation}</p>
                    ) : (
                        <>
                            <Container>{preleveur.auteur || ''}</Container>
                            <Container>{preleveur.name || ''}</Container>
                            <Container>{preleveur.road || ''}</Container>
                            <Container>{preleveur.addressComplement || ''}</Container>
                            <Container>{`${preleveur.postalCode || ''} ${city && city.name ? city.name : ''}`}</Container>
                            <Divider style={{ borderColor: 'black' }} />
                            {preleveur.phoneTel && (
                                <Container container alignItems='center'>
                                    <IconRow>phone</IconRow>
                                    <span>{preleveur.phoneTel ? formatPhone(preleveur.phoneTel) : ''}</span>
                                </Container>
                            )}
                            {preleveur.desktopTel && (
                                <Container container alignItems='center'>
                                    <IconRow>phone</IconRow>
                                    <span>{preleveur.desktopTel ? formatPhone(preleveur.desktopTel) : ''}</span>
                                </Container>
                            )}
                            {preleveur.mobile && (
                                <Container container alignItems='center'>
                                    <IconRow>smartphone</IconRow>
                                    <span>{preleveur.mobile ? formatPhone(preleveur.mobile) : ''}</span>
                                </Container>
                            )}
                            {preleveur.fax && (
                                <Container container alignItems='center'>
                                    <IconRow>print</IconRow>
                                    <span>{preleveur.fax ? formatPhone(preleveur.fax) : ''}</span>
                                </Container>
                            )}
                            {preleveur.email && (
                                <Container container alignItems='center'>
                                    <IconRow>mail_outlined</IconRow>
                                    <span>{preleveur.email || ''}</span>
                                </Container>
                            )}
                        </>
                    )}
                </StyledCard>
            </Item>
        )
    }

    render() {
        const { dataLoaded, pointsLoaded, contactsLoaded, preleveur,
            pointsUsed,
            pointsNotUsed,
            pointsDeleted,
        } = this.state
        const { accountUser, exploitation, citiesIndex } = this.props

        const nbPoints = [...pointsUsed, ...pointsNotUsed, ...pointsDeleted].length

        return (
            <Grid
                container
                alignItems='stretch'
                justifyContent='flex-start'
            >
                {(accountUser.isAdmin === '1' || accountUser.metadata === '1') && (
                    <Item xs={12} md={3} style={{ padding: 10 }}>
                        <MainButton onClick={() => this.props.push('/admin')}>
                            <FolderLogo fill={lightColor} className='margin-right-1' style={{ height: 20 }} />
                            {i18n.selectFolder}
                        </MainButton>
                    </Item>
                )}
                {/* {exploitation.idExploitation && exploitationWaterTurns.length ? (
                    <Item xs={12} md={3} className='padding-top-2'>
                        <MainButton onClick={() => this.props.push('/waterTurns')}>
                            {i18n.myWaterTurns}
                        </MainButton>
                    </Item>
                ) : ''} */}
                {dataLoaded ? (
                    <>
                        {hasValue(exploitation?.idExploitation) && (
                            <>
                                <Item xs={12} style={{ padding: '0 10' }}>
                                    <p style={{ borderBottom: '1px solid black' }}>
                                        <b style={{ fontSize: 14 }}>{i18n.id}</b><br />
                                        <div className='bold' style={{ fontSize: 21, padding: '4 0 8 0' }}>{preleveur.internalIdentifier || preleveur.name}</div>
                                    </p>
                                    <p>
                                        <b style={{ fontSize: 14 }}>{i18n.siret}</b><br />
                                        <div className='bold' style={{ fontSize: 21, padding: '4 0 8 0' }}>{formatSiret(preleveur.siret)}</div>
                                    </p>
                                </Item>
                                {this.getSampler()}
                                {contactsLoaded && this.getListContacts()}
                                {pointsLoaded && (
                                    <Item xs={12} style={{ padding: '0 10' }}>
                                        <StyledCard
                                            title={`${nbPoints} ${getI18nTitleDataLength(i18n.pointPrelevement, i18n.pointsPrelevement, nbPoints)}`}
                                            styleTitle={{ backgroundColor: mainColor, fontSize: 21 }}
                                            styleContainer={{ padding: 0 }}
                                            styleContent={{ padding: 0 }}
                                        >
                                            {pointsUsed.length > 0 && (
                                                <Accordeon
                                                    noShadow
                                                    expanded={pointsUsed.length <= 5}
                                                    title={`${pointsUsed.length} ${getI18nTitleData(i18n.usedPoint, i18n.usedPoints, pointsUsed)}`}
                                                    headStyle={{ backgroundColor: 'white', border: 0, borderBottom: '1px solid black' }}
                                                >
                                                    {pointsUsed.map((p) => (
                                                        <Grid container direction='column' className='clickable' key={p.id} onClick={() => this.props.push(`/exploitation/point/${p.id}`)}>
                                                            <Grid item className='bold padding-2' style={{ fontSize: 21 }}>{p.location || ''}</Grid>
                                                            <Grid item className='padding-left-2'>{citiesIndex[p.townCode]?.name || ''}</Grid>
                                                            <Grid item className='padding-2' style={{ borderBottom: '1px solid black' }}>{`${p.code || ''} - ${p.parcel || ''} ${p.section || ''}`}</Grid>
                                                        </Grid>
                                                    ))}
                                                </Accordeon>
                                            )}
                                            {pointsNotUsed.length > 0 && (
                                                <Accordeon
                                                    noShadow
                                                    expanded={pointsNotUsed.length <= 5}
                                                    title={`${pointsNotUsed.length} ${getI18nTitleData(i18n.noUsedPoint, i18n.noUsedPoints, pointsNotUsed)}`}
                                                    headStyle={{ backgroundColor: 'white', border: 0, borderBottom: '1px solid black' }}
                                                >
                                                    {pointsNotUsed.map((p) => (
                                                        <Grid container direction='column' className='clickable' key={p.id} onClick={() => this.props.push(`/exploitation/point/${p.id}`)}>
                                                            <Grid item className='bold padding-2' style={{ fontSize: 21 }}>{p.location || ''}</Grid>
                                                            <Grid item className='padding-left-2'>{citiesIndex[p.townCode]?.name || ''}</Grid>
                                                            <Grid item className='padding-2' style={{ borderBottom: '1px solid black' }}>{`${p.code || ''} - ${p.parcel || ''} ${p.section || ''}`}</Grid>
                                                        </Grid>
                                                    ))}
                                                </Accordeon>
                                            )}
                                            {pointsDeleted.length > 0 && (
                                                <Accordeon
                                                    noShadow
                                                    expanded={pointsDeleted.length <= 5}
                                                    title={`${pointsDeleted.length} ${getI18nTitleData(i18n.deletePoint, i18n.deletePoints, pointsDeleted)}`}
                                                    headStyle={{ backgroundColor: 'white', border: 0, borderBottom: '1px solid black' }}
                                                >
                                                    {pointsDeleted.map((p) => (
                                                        <Grid container direction='column' className='clickable' key={p.id} onClick={() => this.props.push(`/exploitation/point/${p.id}`)}>
                                                            <Grid item className='bold padding-2' style={{ fontSize: 21 }}>{p.location || ''}</Grid>
                                                            <Grid item className='padding-left-2'>{citiesIndex[p.townCode]?.name || ''}</Grid>
                                                            <Grid item className='padding-2' style={{ borderBottom: '1px solid black' }}>{`${p.code || ''} - ${p.parcel || ''} ${p.section || ''}`}</Grid>
                                                        </Grid>
                                                    ))}
                                                </Accordeon>
                                            )}
                                            <Item xs={12} className='padding-2'>
                                                <MainButton
                                                    little
                                                    onClick={() => this.props.push('/exploitation/historical')}
                                                >
                                                    {i18n.accessHistorical}
                                                </MainButton>
                                            </Item>
                                        </StyledCard>
                                    </Item>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <Item xs={12}>
                        <LinearProgress />
                    </Item>
                )}
            </Grid>
        )
    }
}

MyExploitation.propTypes = {
    fetchContact: PropTypes.func,
    fetchContacts: PropTypes.func,
    fetchOperator: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    push: PropTypes.func,
    operator: PropTypes.instanceOf(DtoIntervenant),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoContact)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    accountUser: PropTypes.instanceOf(DtoUser),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
}

const mapStateToProps = (store) => {
    return {
        contacts: store.ContactReducer.contacts,
        operator: store.AgriReducer.operator,
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        codesSandre: store.ReferencialReducer.codesSandre,
        contributors: store.ReferencialReducer.contributors,
        citiesIndex: store.CityReducer.citiesIndex,
        accountUser: store.AccountReducer.accountUser,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
    }
}

const mapDispatchToProps = {
    fetchContacts: ContactAction.fetchContacts,
    fetchOperator: AgriAction.fetchOperator,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    fetchContributors: ReferencialAction.fetchContributors,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyExploitation)
