import {
    RECEIVE_ACCOUNT,
    RECEIVE_ACCOUNT_HABILITATION,
    RECEIVE_ACCOUNT_STATION_STATISTICS,
    RECEIVE_ACCOUNT_STATIONS,
    RECEIVE_ACCOUNT_STATISTICS,
    RECEIVE_ACCOUNT_USAGE_STATISTICS,
    RECEIVE_ACCOUNT_USER_KEYFIGURES,
    RECEIVE_ACCOUNT_USER_SETTINGS,
    SAVED_USER,
    RECEIVE_ACCOUNT_USER_CMS_STATISTICS,
} from '../constants/AccountConstants'
import DtoAccountHabilitation from '../dto/DtoAccountHabilitation'
import KeyFigureDto from '../dto/KeyFigureDto'
import DtoAccountSettings from '../dto/DtoAccountSettings'
import DtoAccountStatistic from '../dto/DtoAccountStatistic'
import DtoAccountStationStatistic from '../dto/DtoAccountStationStatistic'
import DtoAccountUsageStatistic from '../dto/DtoAccountUsageStatistic'
import DtoAccountStation from '../dto/DtoAccountStation'
import DtoUserCmsStatistic from '../dto/DtoUserCmsStatistic'
import DtoUser from '../dto/DtoUser'
import { FULL_RESET } from '../../../offline/constants/HomeConstants'

export const store = {
    accountUser: {},
    accountHabilitations: [],
    accountUserKeyFigures: [],
    accountUserSettings: [],
    accountStatistics: [],
    accountStationStatistics: [],
    accountUsageStatistics: [],
    accountStations: [],
}

export function AccountReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ACCOUNT:
            return { ...state, accountUser: new DtoUser(action.accountUser) }
        case RECEIVE_ACCOUNT_HABILITATION:
            return {
                ...state,
                accountHabilitations: action.accountHabilitations.map(
                    (el) => new DtoAccountHabilitation(el),
                ),
            }
        case RECEIVE_ACCOUNT_USER_CMS_STATISTICS:
            return {
                ...state,
                accountUserCmsStatistics: action.accountUserCmsStatistics.map(
                    (el) => new DtoUserCmsStatistic(el),
                ),
            }
        case RECEIVE_ACCOUNT_USER_KEYFIGURES:
            return {
                ...state,
                accountUserKeyFigures: action.accountUserKeyFigures.map(
                    (el) => new KeyFigureDto(el),
                ),
            }
        case RECEIVE_ACCOUNT_USER_SETTINGS:
            return {
                ...state,
                accountUserSettings: action.accountUserSettings.map(
                    (el) => new DtoAccountSettings(el),
                ),
            }
        case RECEIVE_ACCOUNT_STATISTICS:
            return {
                ...state,
                accountStatistics: action.accountStatistics.map(
                    (el) => new DtoAccountStatistic(el),
                ),
            }
        case RECEIVE_ACCOUNT_STATION_STATISTICS:
            return {
                ...state,
                accountStationStatistics: action.accountStationStatistics.map(
                    (el) => new DtoAccountStationStatistic(el),
                ),
            }
        case RECEIVE_ACCOUNT_USAGE_STATISTICS:
            return {
                ...state,
                accountUsageStatistics: action.accountUsageStatistics.map(
                    (el) => new DtoAccountUsageStatistic(el),
                ),
            }
        case RECEIVE_ACCOUNT_STATIONS:
            return {
                ...state,
                accountStations: action.accountStations.map((el) => new DtoAccountStation(el)),
            }
        case SAVED_USER:
            return {
                ...state,
                user: action.user
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
