'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import AppStore from '../../../../../store/AppStore'
import {
    CITIES,
    CITY,
    RECEIVE_CITY_CEDEX,
    RESET_CITY,
    RECEIVE_COUNTRIES,
    RECEIVE_CITIES_HISTO,
} from '../constants/CityConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../../utils/ActionUtils'
import ToastrAction from '../../../components/toasters/ToastrAction'
import LogAction from '../../../../../utils/log/actions/LogAction'

const CityAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().CityReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    receiveCity(city) {
        return { type: CITY, city: city }
    },

    receiveCities(cities) {
        return { type: CITIES, cities: cities }
    },

    updateCity(city, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveCity(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(city),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.update === 1) {
                        dispatch(CityAction.fetchCity(code))
                        ToastrAction.success(i18n.citySuccessUpdated)
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    LogAction.logError($`${i18n.fetchError}${i18n.city} : ${err}`)
                    ToastrAction.error(`${i18n.updateError}${i18n.city}`)
                })
        }
    },

    createCity(city) {
        return () => {
            return fetch(ApplicationConf.referencial.cities(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(city),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.insert) {
                        ToastrAction.success(i18n.successfulCreation)
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.createError}${i18n.city} : ${err}`)
                    ToastrAction.error(`${i18n.createError}${i18n.city}`)
                })
        }
    },

    deleteCity(code) {
        return () => {
            return fetch(ApplicationConf.referencial.deleteCity(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        ToastrAction.success(i18n.elementDeleteSuccess)
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.deleteError}${i18n.city} : ${err}`)
                    ToastrAction.error(`${i18n.deleteError}${i18n.city}`)
                })
        }
    },

    fetchCity(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.city(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    checkError(json)
                    dispatch(CityAction.receiveCity(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError}${i18n.city} : ${err}`)
                    ToastrAction.error(`${i18n.fetchError}${i18n.city}`)
                })
        }
    },

    promiseCities() {
        return fetch(ApplicationConf.referencial.cities(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },
    fetchCities() {
        return (dispatch) => {
            return CityAction.promiseCities()
                .then((json = []) => {
                    dispatch(CityAction.receiveCities(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError}${i18n.cities} : ${err}`)
                    ToastrAction.error(`${i18n.fetchError}${i18n.cities}`)
                })
        }
    },

    receiveCityCedex(cityCedex) {
        return { type: RECEIVE_CITY_CEDEX, cityCedex }
    },

    fetchCityCedex() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.cityCedex(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(CityAction.receiveCityCedex(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} ${i18n.cedex} : ${err}`)
                    ToastrAction.error(`${i18n.fetchError} ${i18n.cedex}`)
                })
        }
    },

    resetCity() {
        return { type: RESET_CITY }
    },

    fetchSynchroSandreCity() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.updateCity(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        if (json.insert > 0) {
                            dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        } else {
                            ToastrAction.success(`${i18n.updateNumber} : ${json.update}`)
                        }
                        dispatch(this.fetchCities())
                    } else {
                        ToastrAction.success(i18n.noUpdate)
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError}${i18n.city}${err}`)
                    ToastrAction.error(`${i18n.updateError}${i18n.city}`)
                })
        }
    },

    receiveCountries(countries) {
        return { type: RECEIVE_COUNTRIES, countries }
    },

    fetchCountries() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.countries(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json) => {
                    dispatch(CityAction.receiveCountries(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(`${i18n.fetchError} `)
                })
        }
    },

    receiveCitiesHisto(citiesHisto) {
        return { type: RECEIVE_CITIES_HISTO, citiesHisto }
    },

    fetchCitiesHisto() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.citiesHisto(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json) => {
                    dispatch(CityAction.receiveCitiesHisto(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(i18n.fetchError)
                })
        }
    },
}

export default CityAction
