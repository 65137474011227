import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Divider, Grid, LinearProgress } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import DtoContact from '../referencials/dto/DtoContact'
import ToastrAction from '../components/toasters/ToastrAction'
import { MainButton } from '../components/styled/buttons'
import { isValidEmail } from '../../../utils/FormUtils'
import { InputRow } from '../components/styled/inputs'
import { Item } from '../components/styled/grid'
import DtoUser from './dto/DtoUser'
import AccountAction from './actions/AccountAction'
import ModalUpdatePassword from './components/ModalUpdatePassword'
import { lightColor, mainColor } from '../components/styled/theme'
import StyledCard from '../components/cards/StyledCard'
import { getDateAndHour } from '../../../utils/DateUtil'
import { ReactComponent as ExclamationLogo } from '../../../ressources/static/svg/Exclamation.svg'
import { ReactComponent as InterrogationLogo } from '../../../ressources/static/svg/Interrogation.svg'
import { ReactComponent as DeconnexionLogo } from '../../../ressources/static/svg/Deconnexion.svg'

class MyAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            openModal: false,
            isUnvalidEmail: false,
            contact: {},
            name: '',
            phone: '',
            email: '',
            dateValidCgu: '',
            mdp: '',
            newMdp: '',
            mdpConfirmation: '',
        }
    }

    componentDidMount() {
        this.getContact()
        this.getDateCgu()
    }

    handleChangeValue = (value) => {
        this.setState({ ...value })
    }

    getContact = () => {
        const login = getLogin()
        this.props.fetchUser(login).then(() => {
            const { accountUser } = this.props
            if (accountUser.contactCode) {
                this.props.fetchContact(accountUser.contactCode).then(() => {
                    const { contact } = this.props
                    this.setState({
                        dataLoaded: true,
                        contact: contact.id ? contact : new DtoContact({}),
                        name: contact.name || '',
                        phone: contact.phoneTel || '',
                        email: contact.email || '',
                    })
                })
            } else {
                this.createContact(login)
            }
        })
    }

    createContact = (login) => {
        const { accountUser } = this.props
        this.props.createContact({ name: login }).then((contactCode) => {
            this.props.updateUser({ ...accountUser, contactCode, contactName: login })
            this.props.fetchContact(contactCode).then(() => {
                const { contact } = this.props
                this.setState({
                    dataLoaded: true,
                    contact: contact.id ? contact : new DtoContact({}),
                    name: contact.name || '',
                    phone: contact.phoneTel || '',
                    email: contact.email || '',
                })
            })
        })
    }

    getDateCgu = () => {
        const login = getLogin()
        this.props.getDateValidCGU(login).then(() => {
            const { dateValidCgu } = this.props
            this.setState({ dateValidCgu })
        })
    }

    toggleModal = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onSavePassword = () => {
        const { newMdp, mdpConfirmation, mdp } = this.state
        const { applicationSettings } = this.props
        const login = getLogin()
        if (mdp) {
            this.props.login(login, mdp).then(() => {
                if (
                    newMdp !== null &&
                    newMdp.length &&
                    mdpConfirmation !== null &&
                    mdpConfirmation.length
                ) {
                    if (newMdp === mdpConfirmation) {
                        const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                        const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                        if (regex.test(newMdp)) {
                            if (newMdp !== login) {
                                const newMdpEncoding = btoa(unescape(encodeURIComponent(newMdp)))
                                const password = { password: newMdpEncoding }
                                this.props.changePassword(password)
                                this.toggleModal()
                            } else {
                                ToastrAction.error('Le mot de passe doit être différent de celui qui vous a été fourni', true)
                            }
                        } else {
                            ToastrAction.error(regexHelp, true)
                        }
                    } else {
                        ToastrAction.error('Les mots de passe ne sont pas identiques', true)
                    }
                }
            })
        }
    }

    onSubmit = () => {
        const { contact, name, phone, email } = this.state
        const newContact = { ...contact, name, phoneTel: phone, email }
        if (isValidEmail(email)) {
            this.setState({ isUnvalidEmail: false })
            this.props.updateContact(newContact).then(() => {
                this.getContact()
            })
        } else {
            this.setState({ isUnvalidEmail: true })
        }
    }

    onSubmitPassword = () => {
        this.toggleModal()
    }

    render() {
        const login = getLogin()
        const { dataLoaded, openModal, dateValidCgu, name, phone, email, isUnvalidEmail } = this.state

        return (
            <>
                {dataLoaded ? (
                    <Grid container justifyContent='space-between' className='padding-top-2' style={{ padding: 10 }}>
                        <Grid item xs={12} className='bold' style={{ padding: 14, backgroundColor: mainColor, color: 'white', fontSize: 21 }}>
                            <div className='padding-bottom-2'>{i18n.myAccount}</div>
                            <Divider sx={{ borderColor: 'white' }} />
                            <div className='padding-top-2' style={{ fontSize: 14 }}>{login}</div>
                        </Grid>
                        <Grid
                            container
                            direction='column'
                            justifyContent='space-around'
                            className='padding-top-1'
                        >
                            <StyledCard
                                styleContainer={{ padding: '5 0' }}
                                styleContent={{ borderTop: '1px solid black' }}
                            >
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='space-between'
                                    spacing={1}
                                >
                                    <Grid item xs={12} md={6}>
                                        <InputRow
                                            id='compte'
                                            label='Nom'
                                            type='text'
                                            value={name}
                                            onChange={(event) =>
                                                this.handleChangeValue({ name: event.target.value })
                                            }
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputRow
                                            id='compte'
                                            label='Tel'
                                            value={phone}
                                            type='number'
                                            onChange={(event) =>
                                                this.handleChangeValue({ phone: event.target.value })
                                            }
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputRow
                                            id='compte'
                                            label='Email'
                                            type='text'
                                            value={email}
                                            onChange={(event) =>
                                                this.handleChangeValue({ email: event.target.value })
                                            }
                                            variant='outlined'
                                            error={isUnvalidEmail}
                                            helperText={isUnvalidEmail ? 'Adresse non valide' : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MainButton little onClick={this.onSubmit}>
                                            {i18n.save}
                                        </MainButton>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MainButton reverse='true' little onClick={this.onSubmitPassword}>
                                            {i18n.changePassword}
                                        </MainButton>
                                    </Grid>
                                </Grid>
                            </StyledCard>
                        </Grid>
                        <Grid
                            container
                            direction='column'
                            justifyContent='space-around'
                        >
                            <StyledCard
                                subTitle='CGU'
                                hideIcon
                                styleContainer={{ padding: '5 0' }}
                                styleSubTitle={{ borderTop: '1px solid black' }}
                            >
                                <>
                                    <Grid container style={{ padding: 8 }}>
                                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.lastAccept}</Grid>
                                        <Grid item xs={6} className='bold'>{getDateAndHour(dateValidCgu)}</Grid>
                                    </Grid>
                                    <Item>
                                        <MainButton
                                            reverse='true'
                                            onClick={() => this.props.push('/about')}
                                            margintop
                                            little
                                        >
                                            {i18n.consultCGU}
                                        </MainButton>
                                    </Item>
                                </>
                            </StyledCard>
                        </Grid>
                        <Item xs={12} md={3.5} style={{ padding: '5 0' }}>
                            <MainButton style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => this.props.push('/actualities')}>
                                <ExclamationLogo fill={lightColor} style={{ height: 25 }} />
                                {i18n.actualities}
                                <ExclamationLogo fill={lightColor} style={{ height: 25 }} />
                            </MainButton>
                        </Item>
                        <Item xs={12} md={3.5} style={{ padding: '5 0' }}>
                            <MainButton style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => this.props.push('/tutoriel')}>
                                <InterrogationLogo fill={lightColor} style={{ height: 25 }} />
                                {i18n.tuto}
                                <InterrogationLogo fill={lightColor} style={{ height: 25 }} />
                            </MainButton>
                        </Item>
                        <Item xs={12} md={3.5} style={{ padding: '5 0' }}>
                            <MainButton reverse='true' style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => this.props.logout()}>
                                <DeconnexionLogo fill={mainColor} style={{ height: 25 }} />
                                {i18n.disconnect}
                                <DeconnexionLogo fill={mainColor} style={{ height: 25 }} />
                            </MainButton>
                        </Item>
                        <ModalUpdatePassword
                            open={openModal}
                            toggleModal={this.toggleModal}
                            handleChangeMdp={(e) =>
                                this.handleChangeValue({ mdp: e.target.value })
                            }
                            handleChangeNewMdp={(e) =>
                                this.handleChangeValue({ newMdp: e.target.value })
                            }
                            handleChangeConfirmation={(e) =>
                                this.handleChangeValue({ mdpConfirmation: e.target.value })
                            }
                            onSavePassword={this.onSavePassword}
                        />
                    </Grid>
                ) : (
                    <LinearProgress />
                )}
            </>
        )
    }
}

const mapDispatchToProps = {
    fetchUser: AccountAction.fetchUser,
    updateUser: AccountAction.updateUser,
    fetchContact: ContactAction.fetchContact,
    updateContact: ContactAction.updateContact,
    createContact: ContactAction.createContact,
    getDateValidCGU: HomeAction.getDateValidCGU,
    changePassword: AccountAction.changePassword,
    login: HomeAction.login,
    logout: HomeAction.logout,
    push,
}

const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
        contact: store.ContactReducer.contact,
        dateValidCgu: store.HomeReducer.dateValidCgu,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

MyAccount.propTypes = {
    fetchUser: PropTypes.func,
    updateUser: PropTypes.func,
    fetchContact: PropTypes.func,
    updateContact: PropTypes.func,
    createContact: PropTypes.func,
    getDateValidCGU: PropTypes.func,
    changePassword: PropTypes.func,
    login: PropTypes.func,
    logout: PropTypes.func,
    accountUser: PropTypes.instanceOf(DtoUser),
    contact: PropTypes.instanceOf(DtoContact),
    dateValidCgu: PropTypes.array,
    applicationSettings: PropTypes.array,
    push: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)
