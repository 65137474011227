import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, LinearProgress } from '@mui/material'
import DtoDeclaration from '../../../agri/dto/enquete/DtoDeclaration'
import DtoExploitation from '../../../agri/dto/exploitation/DtoExploitation'
import IntervenantCard from '../../../components/cards/IntervenantCard'
import AgriAction from '../../../agri/actions/AgriAction'
import DtoIntervenant from '../../../referencials/dto/DtoIntervenant'
import DtoIntervenantDeclaration from '../../../agri/dto/enquete/DtoIntervenantDeclaration'
import { deepEqual } from '../../../../../utils/ObjectUtils'
import { formatSiret } from '../../../../../utils/StringUtil'
import ReferencialAction from '../../../referencials/actions/ReferencialAction'
import DtoSandreCode from '../../../referencials/dto/DtoSandreCode'

class Etape1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editMode: false,
            dataLoaded: false,
            preleveur: new DtoIntervenant({}),
        }
    }

    componentDidMount() {
        this.getPreleveur()
    }

    getPreleveur = () => {
        this.setState({ dataLoaded: false })
        const { declaration, contributors } = this.props
        this.props.fetchDeclarationContributors(declaration.idSurvey).then(() => {
            if (!contributors.length) {
                this.props.fetchContributors().then(() => {
                    this.setPreleveur()
                })
            } else {
                this.setPreleveur()
            }
        })
    }

    setPreleveur = () => {
        const { contributors, exploitation, declaration, declarationContributors } = this.props
        const originalPreleveur = contributors.find((c) => c.id === exploitation.operatorCode)
        const updatedPreleveur = declarationContributors.find(
            (updateC) =>
                originalPreleveur.id === updateC.idContributor &&
                declaration.idSurvey === updateC.surveyId &&
                updateC.contactType === 1
        )
        const preleveur = {
            ...originalPreleveur,
            ...updatedPreleveur,
        }
        this.setState({
            preleveur,
            dataLoaded: true,
            editMode: false,
        })
    }

    toggleEdit = () => {
        const { editMode } = this.state
        this.setState({ editMode: !editMode })
    }

    onSave = (intervenant) => {
        const { declaration, exploitation, contributors } = this.props
        const preleveur = contributors.find((c) => c.id === exploitation.operatorCode)
        const originalPreleveur = new DtoIntervenantDeclaration({
            ...preleveur,
            idContributor: preleveur.id,
            surveyId: declaration.idSurvey,
        })
        const updatedPreleveur = new DtoIntervenantDeclaration({
            ...intervenant,
            idContributor: intervenant.id,
            surveyId: declaration.idSurvey,
        })
        if (!deepEqual(originalPreleveur, updatedPreleveur)) {
            this.props
                .updateDeclarationContributor(
                    declaration.idSurvey,
                    new DtoIntervenantDeclaration({
                        ...intervenant,
                        idContributor: preleveur.id,
                        surveyId: declaration.idSurvey,
                        contactType: 1,
                    }),
                )
                .then(() => {
                    this.getPreleveur()
                })
        } else {
            this.getPreleveur()
        }
    }

    onCancel = () => {
        this.getPreleveur()
    }

    onChangeInfo = (key, value) => {
        const { preleveur } = this.state
        const updatedPreleveur = {
            ...preleveur,
            [key]: value === '' ? undefined : value,
        }
        this.setState({ preleveur: updatedPreleveur })
    }

    getHeadInfo = () => {
        const { codesSandre } = this.props
        const { preleveur } = this.state
        const typesStructure = codesSandre.filter((c) => c.field === 'INTERVENANTS.TYPE_STRUCTURE')
        if (preleveur) {
            const foundType = typesStructure.find((c) => c.code === preleveur.structureType) || {}
            return (
                <div style={{ padding: '0 16px' }}>
                    <p>
                        {`${i18n.id} : `}
                        <b>{preleveur.internalIdentifier || preleveur.name}</b>
                    </p>
                    <p>
                        {`${i18n.type} : `}
                        <b>{foundType.name}</b>
                    </p>
                    <p>
                        {`${i18n.siret} : `}
                        <b>{formatSiret(preleveur.siret)}</b>
                    </p>
                </div>
            )
        }
        return ''
    }

    render() {
        const { editMode, preleveur, dataLoaded } = this.state
        const { readMode } = this.props
        return (
            <Grid container alignItems='center'>
                {dataLoaded ? (
                    <>
                        {this.getHeadInfo()}
                        <Grid item xs={12}>
                            <IntervenantCard
                                intervenant={preleveur}
                                title={i18n.preleveur}
                                editMode={editMode}
                                onSave={this.onSave}
                                onCancel={this.onCancel}
                                onChange={this.onChangeInfo}
                                toggleEditMode={this.toggleEdit}
                                hideIcon={readMode}
                            />
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )}
            </Grid>
        )
    }
}

Etape1.propTypes = {
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    onChangeDeclaration: PropTypes.func.isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    fetchContributors: PropTypes.func,
    updateDeclarationContributor: PropTypes.func,
    fetchDeclarationContributors: PropTypes.func,
    declarationContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenantDeclaration)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    readMode: PropTypes.bool,
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        declarationContributors: store.AgriReducer.declarationContributors,
        contributors: store.ReferencialReducer.contributors,
        codesSandre: store.ReferencialReducer.codesSandre,
    }
}

const mapDispatchToProps = {
    updateDeclarationContributor: AgriAction.updateDeclarationContributor,
    fetchDeclarationContributors: AgriAction.fetchDeclarationContributors,
    fetchContributors: ReferencialAction.fetchContributors,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(Etape1)
