/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, LinearProgress, Icon } from '@mui/material'
import moment from 'moment'
import HomeAction from '../../offline/actions/HomeAction'
import AgriAction from '../agri/actions/AgriAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import CmsAction from '../cms/actions/CmsAction'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import { hasValue } from '../../../utils/NumberUtil'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import DtoMatEvent from '../referencials/materiels/dto/DtoMatEvent'
import DtoMatEventsType from '../referencials/materiels/dto/DtoMatEventsType'
import StyledCard from '../components/cards/StyledCard'
import { getDayDisplay, getRestrictionIcon, getTextSlot } from '../../../utils/AgriUtils'
import { ReactComponent as NotFavLogo } from '../../../ressources/static/svg/Favori.svg'
import { ReactComponent as InterrogationLogo } from '../../../ressources/static/svg/Interrogation.svg'
import { ReactComponent as CloseLogo } from '../../../ressources/static/svg/Fermer.svg'
import { lightColor } from '../components/styled/theme'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import DtoManagementUnitRestriction from '../referencials/dto/DtoManagementUnitRestriction'
import { groupBy, range } from 'lodash'
import DtoWaterTurnsSlot from '../agri/dto/DtoWaterTurnsSlot'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import { getWeek } from '../../../utils/DateUtil'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from '../components/styled/dialog'
import { Item } from '../components/styled/grid'
import DtoDroughtSectorRestriction from '../agri/dto/DtoDroughtSectorRestriction'
import { getSettingInt } from '../../../utils/FormUtils'

class PointDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            contactsLoaded: false,
            idInstallation: parseInt(props.match.params.id),
            point: {},
            installation: {},
            openModalIndex: false,
            openModalEvent: false,
            chroniclePumpId: null,
            chronicleCounterId: null,
            numberOfLines: 5,
            year: new Date().getFullYear(),
            realConso: null,
            selectedEvent: null,
        }
    }

    componentDidMount() {
        const { exploitation } = this.props
        const { idInstallation, year } = this.state
        this.props.fetchCmsByCategory(5)
        this.props.fetchManagementUnitsRestrictions()
        this.props.fetchDroughtSectorsRestrictions()
        this.props.fetchContacts().then(() => this.setState({ contactsLoaded: true }))
        this.props.fetchInstallation(idInstallation).then(() => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => this.fetchVolumes(idInstallation, year))
            } else {
                this.fetchVolumes(idInstallation, year)
            }
        })
        window.scrollTo(0, 0)
    }

    fetchVolumes = (idInstallation, year) => {
        const { exploitation } = this.props
        this.props.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf()).then((res) => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => this.setState({ realConso: res?.total, dataLoaded: true }))
            } else {
                this.setState({ realConso: res?.total, dataLoaded: true })
            }
            this.props.fetchBookmarks().then(() => this.setTitle(idInstallation))
        })
    }

    setTitle = (idInstallation) => {
        const { installation, citiesIndex, bookmarks } = this.props
        const fav = bookmarks.find((b) => b.stationType === 7 && b.identifiant === `${idInstallation}`)
        const city = citiesIndex[installation.townCode] || {}
        const bookmark = {
            login: getLogin(),
            identifiant: `${idInstallation}`,
            stationType: 7,
        }
        this.props.setTitle([{
            title: `Point ${installation.code || ''}${isLargeWindow() && installation.location ? ` - ${installation.location}` : ''}`,
            subTitle: city.name,
            href: `/consommation/point/${installation.id}`,
            icon: fav ? <Icon onClick={() => this.deleteBookmark(fav)}>start</Icon> : <NotFavLogo fill={lightColor} style={{ height: 20 }} onClick={() => this.createBookmark(bookmark)} />,
        }])
    }

    deleteBookmark = (fav) => {
        this.props.deleteBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
        })
    }

    createBookmark = (fav) => {
        this.props.createBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
        })
    }

    getMateriels = () => {
        const { variousMateriels, exploitation } = this.props
        const { idInstallation } = this.state
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === 7 && m.siteCode === idInstallation) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((c) => [...pumpsIds, ...countersIds].includes(c.id) && (!hasValue(c.administrator) || c.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    editModalIndex = (type, id) => {
        this.setState({ selectedMaterial: id, consoType: type, openModalIndex: true })
    }

    onCancelIndex = () => {
        this.setState({
            selectedMaterial: null,
            consoType: null,
            openModalIndex: false,
        })
    }

    onSaveChronicles = (link_chronicles) => {
        const { exploitation } = this.props
        const updateExploitation = {
            ...exploitation,
            link_chronicles,
        }
        this.setState({
            selectedMaterial: null,
            consoType: null,
            openModalIndex: false,
            dataLoaded: false,
        })
        this.props.updateExploitation(updateExploitation).then(() => {
            this.props.fetchExploitation().then(() => this.setState({ dataLoaded: true }))
        })
    }

    showMore = () => {
        this.setState(({ numberOfLines }) => ({
            numberOfLines: numberOfLines + 5,
        }))
    }

    onDeleteEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, openModalEvent: false, selectedEvent: null })
        this.props.deleteMatEvent(event.id, () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)).then(() => {
            this.setState({ dataLoaded: true })
        })
    }

    onValidateEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, openModalEvent: false, selectedEvent: null })
        if (event.id) {
            this.props.updateMatEvent(event,
                () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)
            ).then(() => {
                this.setState({ dataLoaded: true })
            })
        } else {
            this.props.createMatEvent(event).then(() => {
                this.props.fetchMatEventsByExploitation(exploitation.idExploitation).then(() => {
                    this.setState({ dataLoaded: true })
                })
            })
        }
    }

    getModalRestrictions = (openModalRestrictions) => {
        const { waterTurnsRestrictions } = this.props
        return (
            <DialogMUI open={openModalRestrictions}>
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <b style={{ padding: 10, fontSize: 21 }}>{i18n.waterTurnsRestrictions}</b>
                        <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={() => this.setState({ openModalRestrictions: false })} />
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI>
                    {waterTurnsRestrictions.map((r) => (
                        <Item xs={12} style={{ padding: '8 0' }}>
                            <fieldset>
                                <legend>
                                    <div style={{ backgroundColor: r.color || 'grey', display: 'inline-block', width: '20px', border: '1px solid black' }}>&nbsp;</div>&nbsp;
                                    <b>{r.label}</b>{` (${i18n.level} ${r.level})`}</legend>
                                <p>{r.description}</p>
                                <p>{r.comment}</p>
                            </fieldset>
                        </Item>
                    ))}
                </DialogContentMUI>
            </DialogMUI>
        )
    }

    render() {
        const { dataLoaded, contactsLoaded, openModalRestrictions, year } = this.state
        const { variousMateriels, variousMatSituations, exploitation, installation, managementUnitsRestrictions, applicationSettings, droughtSectorsRestrictions, waterTurnsRestrictions, waterTurnsSlots, exploitationWaterTurns } = this.props
        if (variousMatSituations.length && variousMateriels.length && dataLoaded && contactsLoaded && exploitation.idExploitation) {
            const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
            const sampleCharacteristics = installation?.link_sampleCharacteristics[0] || {}
            const restriction = (() => {
                if (typeRestriction === 1) {
                    return droughtSectorsRestrictions.find((dsR) => dsR.idSector === sampleCharacteristics.droughtSector && sampleCharacteristics.sampleType === dsR.resourceType) || { idRestriction: -1 }
                }
                return managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.subManagementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.managementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || { idRestriction: -1 }
            })()
            const waterTurnRestriction = waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction) || {}
            const restrictionLabel = waterTurnRestriction?.label || i18n.normale
            const restrictionColor = waterTurnRestriction?.color || '#00AF64'

            const data = exploitationWaterTurns.filter((w) => w.idExploitation === exploitation.idExploitation && w.idInstallation === installation.id && w.year === year)
            const groupedSlotData = groupBy(data, 'idSlot')
            return (
                    <Grid container alignItems='center' justifyContent='space-around'>
                        <Grid item xs={12} style={{ padding: 10 }}>
                            <StyledCard
                                title={i18n.restriction}
                                subTitle={(
                                    <Grid container justifyContent='space-between' alignItems='center'>
                                        {restrictionLabel}
                                        {getRestrictionIcon(restriction.idRestriction, { height: 40 }, 'white')}
                                    </Grid>
                                )}
                                styleContainer={{ padding: 0 }}
                                styleContent={{ padding: '8 0 8 0', color: 'black' }}
                                styleTitle={{ backgroundColor: restrictionColor, color: 'white' }}
                                styleSubTitle={{ backgroundColor: restrictionColor, color: 'white', borderTop: '1px solid white', fontWeight: 'bold', fontSize: 21 }}
                            >
                                <Grid container justifyContent='space-between' className='bold padding-top-1'>
                                    <Grid item xs={12} style={{ fontSize: 21 }} className='padding-bottom-2 padding-top-1 padding-left-1'>{waterTurnRestriction.description || ''}</Grid>
                                    <Grid item xs={12} className='padding-bottom-2 padding-top-2 padding-left-1 '>{waterTurnRestriction.comment || ''}</Grid>
                                </Grid>
                                {restriction.exemption && (
                                    <Grid container justifyContent='space-between' className='bold margin-top-1' style={{ color: 'grey', borderTop: '1px solid black' }}>
                                        <Grid item xs={12} className='padding-bottom-1 padding-top-2 padding-left-1  padding-right-1'>{restriction.exemption}</Grid>
                                    </Grid>
                                )}
                            </StyledCard>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledCard
                                subTitle={(
                                    <Grid container justifyContent='space-between' alignItems='center'>
                                        {'Calendrier des tours d\'eau'}
                                        <InterrogationLogo fill='black' style={{ height: 25 }} onClick={() => this.setState({ openModalRestrictions: true })} />
                                    </Grid>
                                )}
                                styleSubTitle={{ borderTop: '1px solid black' }}
                            >
                                {!data?.length && i18n.noWaterTurnDescription}
                                {Object.keys(groupedSlotData).map(idSlotKey => {
                                    const slot = waterTurnsSlots.find(wts => wts.id === parseInt(idSlotKey)) || { nbSlots: 1, id: -1 }
                                    return (
                                        <>
                                            <p><b>{i18n.ranges} : {getTextSlot(slot)}</b></p>
                                            {groupedSlotData[idSlotKey].map(d => {
                                                const slotsDisplay = range(slot.nbSlots || 1)
                                                return (
                                                    <div style={{ overflowX: 'auto', paddingBottom: 8 }}>
                                                        <div className='bold' style={{ paddingBottom: 8 }}>{d.material}</div>
                                                        {data?.length ? getWeek().map((day) => (
                                                            getDayDisplay(d[day.value], day.label, slotsDisplay, waterTurnsRestrictions)
                                                        )) : i18n.noInformation}
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )
                                }).flat()}
                            </StyledCard>
                        </Grid>
                        {this.getModalRestrictions(openModalRestrictions)}
                    </Grid>
            )
        }
        return (
            <Grid container alignItems='center' justifyContent='space-around'>
                <Grid item xs={12} style={{ margin: '16px 0' }}>
                    <LinearProgress />
                </Grid>
            </Grid>
        )
    }
}

PointDashboard.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
    setTitle: PropTypes.func,
    push: PropTypes.func,
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    fetchInstallation: PropTypes.func,
    fetchExploitation: PropTypes.func,
    updateExploitation: PropTypes.func,
    fetchCmsByCategory: PropTypes.func,
    createMatEvent: PropTypes.func,
    fetchMatEventsByExploitation: PropTypes.func,
    calculPointVolumeReal: PropTypes.func,
    deleteMatEvent: PropTypes.func,
    updateMatEvent: PropTypes.func,
    resetInstallation: PropTypes.func,
    fetchContacts: PropTypes.func,
    deleteBookmark: PropTypes.func,
    createBookmark: PropTypes.func,
    fetchBookmarks: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    matEventsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEvent)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
    bookmarks: PropTypes.arrayOf(PropTypes.shape({})),
    watersheds: PropTypes.arrayOf(PropTypes.shape({})),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    managementUnitsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnitRestriction)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    droughtSectorsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoDroughtSectorRestriction)),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    fetchManagementUnitsRestrictions: PropTypes.func,
    fetchDroughtSectorsRestrictions: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        installations: store.InstallationsReducer.installations,
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        cms: store.CmsReducer.cms,
        matEventsExploitation: store.MaterielReducer.matEventsExploitation,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
        bookmarks: store.ReferencialReducer.bookmarks,
        watersheds: store.ReferencialReducer.watersheds,
        citiesIndex: store.CityReducer.citiesIndex,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    updateExploitation: AgriAction.updateExploitation,
    fetchModesIrrigations: AgriAction.fetchModesIrrigations,
    fetchInstallation: InstallationsAction.fetchInstallation,
    resetInstallation: InstallationsAction.resetInstallation,
    setTitle: HomeAction.setTitle,
    fetchCmsByCategory: CmsAction.fetchCmsByCategory,
    createMatEvent: MaterielAction.createMatEvent,
    updateMatEvent: MaterielAction.updateMatEvent,
    deleteMatEvent: MaterielAction.deleteMatEvent,
    fetchMatEventsByExploitation: MaterielAction.fetchMatEventsByExploitation,
    calculPointVolumeReal: AgriAction.calculPointVolumeReal,
    fetchContacts: ContactAction.fetchContacts,
    deleteBookmark: ReferencialAction.deleteBookmark,
    createBookmark: ReferencialAction.createBookmark,
    fetchBookmarks: ReferencialAction.fetchBookmarks,
    fetchManagementUnitsRestrictions: ReferencialAction.fetchManagementUnitsRestrictions,
    fetchDroughtSectorsRestrictions: AgriAction.fetchDroughtSectorsRestrictions,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointDashboard)
