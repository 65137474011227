import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, Icon, InputLabel, Select, MenuItem, styled } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { connect } from 'react-redux'
import { sortBy } from 'lodash'
import DtoIntervenant from '../../referencials/dto/DtoIntervenant'
import { InputRow, FormControlRow } from '../styled/inputs'
import DtoCity from '../../referencials/city/dto/DtoCity'
import ToastrAction from '../toasters/ToastrAction'
import { isEmpty } from '../../../../utils/ObjectUtils'
import { isValidEmail } from '../../../../utils/FormUtils'
import { formatPhone } from '../../../../utils/StringUtil'
import DtoSandreCode from '../../referencials/dto/DtoSandreCode'
import DtoUser from '../../account/dto/DtoUser'
import EditableCard from './EditableCard'

const IconRow = styled(Icon)({
    marginRight: '5px',
})

const Container = styled(Grid)({
    margin: '8 0',
})

class IntervenantCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            intervenant: props.intervenant,
            errors: [],
        }
    }

    onChangeIntervenant = (key, value) => {
        this.setState(({ intervenant }) => ({
            intervenant: {
                ...intervenant,
                [key]: value === '' ? undefined : value,
            }
        }))
    }

    onChangeIntervenantCP = (value) => {
        const { cities } = this.props
        const citiesFiltered = cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(String(value || ''))))
        if (citiesFiltered.length === 1) {
            const city = citiesFiltered[0].code
            this.setState(({ intervenant }) => ({
                intervenant: {
                    ...intervenant,
                    cityCode: city === '' ? undefined : city,
                    postalCode: value === '' ? undefined : value,
                }
            }))
        } else {
            this.onChangeIntervenant('postalCode', value)
        }
    }

    onChangeIntervenantCity = (value) => {
        const { citiesIndex } = this.props
        const city = citiesIndex[value] || {}
        if (city.link_postalCode && city.link_postalCode.length) {
            this.setState(({ intervenant }) => ({
                intervenant: {
                    ...intervenant,
                    postalCode: city.link_postalCode[0],
                    cityCode: value === '' ? undefined : value,
                }
            }))
        } else {
            this.onChangeIntervenant('cityCode', value)
        }
    }

    onCancelIntervenant = () => {
        const { intervenant } = this.props
        this.setState({ intervenant })
        this.props.onCancel()
    }

    onSaveIntervenant = () => {
        const { intervenant } = this.state
        if (this.checkForm()) {
            this.props.onSave(intervenant)
        } else {
            ToastrAction.error(i18n.fillAllFields)
        }
    }

    getSelectedItem = () => {
        const { citiesIndex } = this.props
        const { intervenant } = this.state
        const city = citiesIndex[intervenant.cityCode]
        return city || {}
    }

    checkForm = () => {
        const { selectType, hideAddress } = this.props
        const { intervenant } = this.state
        const newErrors = []
        if (selectType && isNaN(parseInt(intervenant.contributorType))) {
            newErrors.push('contributorType')
        }
        if (!hideAddress) {
            if (!intervenant.name) {
                newErrors.push('name')
            }
            if (!intervenant.postalCode) {
                newErrors.push('postalCode')
            }
            if (!intervenant.cityCode) {
                newErrors.push('cityCode')
            }
        }
        // if (!intervenant.phoneTel && !intervenant.mobile) {
        //     ToastrAction.error('Veuillez renseigner au moins un numéro de téléphone')
        //     newErrors.push('minOneTel')
        // }
        if (intervenant.phoneTel && intervenant.phoneTel.length !== 10) {
            newErrors.push('phoneTel')
        }
        if (intervenant.mobile && intervenant.mobile.length !== 10) {
            newErrors.push('mobile')
        }
        if (intervenant.desktopTel && intervenant.desktopTel.length !== 10) {
            newErrors.push('desktopTel')
        }
        if (intervenant.fax && intervenant.fax.length !== 10) {
            newErrors.push('fax')
        }
        if (intervenant.email && !isValidEmail(intervenant.email)) {
            newErrors.push('email')
        }
        if (!intervenant.siret || intervenant.siret.length !== 14) {
            newErrors.push('siret')
        }
        if (newErrors.length) {
            this.setState({ errors: newErrors })
            return false
        }
        this.setState({ errors: [] })
        return true
    }

    getContent = (editMode, intervenant, selectType, hideAddress) => {
        const { cities, codesSandre, accountUser } = this.props
        const { errors } = this.state
        const city = this.getSelectedItem()
        const typesContributors = codesSandre.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT')
        const citiesFiltered = sortBy(cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(String(intervenant.postalCode || '')))), 'name')
        if (editMode) {
            return (
                <>
                    {selectType && (
                        <FormControlRow
                            variant='outlined'
                            required
                            error={errors.find((e) => e === 'contributorType')}
                        >
                            <InputLabel>{i18n.type}</InputLabel>
                            <Select
                                id='type'
                                label={i18n.type}
                                value={intervenant.contributorType}
                                onChange={(e) => this.onChangeIntervenant('contributorType', e.target.value)}
                            >
                                {typesContributors.map((c) => {
                                    return <MenuItem value={c.code}>{c.name}</MenuItem>
                                })}
                            </Select>
                        </FormControlRow>
                    )}
                    {!hideAddress ? (
                        <>
                            <InputRow
                                id='name'
                                label={i18n.name}
                                type='text'
                                value={intervenant.name}
                                onChange={(e) => this.onChangeIntervenant('name', e.target.value)}
                                variant='outlined'
                                required
                                error={errors.find((e) => e === 'name')}
                                disabled={accountUser.isAdmin !== '1'}
                            />
                            <InputRow
                                id='address'
                                label={i18n.address}
                                type='text'
                                value={intervenant.road}
                                onChange={(e) => this.onChangeIntervenant('road', e.target.value)}
                                variant='outlined'
                            />
                            <InputRow
                                id='addressComplement'
                                label={i18n.complement}
                                type='text'
                                value={intervenant.addressComplement}
                                onChange={(e) => this.onChangeIntervenant('addressComplement', e.target.value)}
                                variant='outlined'
                            />
                            <InputRow
                                id='postalCode'
                                label={i18n.postalCode}
                                type='number'
                                InputProps={{ inputProps: { min: 0, max: 99999 } }}
                                value={intervenant.postalCode}
                                onChange={(e) => this.onChangeIntervenantCP(e.target.value)}
                                variant='outlined'
                                required
                                error={errors.find((e) => e === 'postalCode')}
                            />
                            <Autocomplete
                                id='city'
                                options={citiesFiltered}
                                getOptionLabel={(option) =>
                                    option.name ? option.name : ''
                                }
                                value={this.getSelectedItem()}
                                onChange={(e, value) =>
                                    this.onChangeIntervenantCity(value ? value.code : '')
                                }
                                noOptionsText='Pas de correspondance'
                                renderInput={(params) => (
                                    <InputRow
                                        {...params}
                                        label={i18n.city}
                                        variant='outlined'
                                        required
                                        error={errors.find((e) => e === 'cityCode')}
                                    />
                                )}
                            />
                        </>
                    ) : (
                        <>
                            <p>{intervenant.auteur || ''}</p>
                            <p>{intervenant.name || ''}</p>
                            <p>{intervenant.road || ''}</p>
                            <p>{intervenant.addressComplement || ''}</p>
                            <p>{`${intervenant.postalCode || ''} ${city ? city.name : ''}`}</p>
                        </>
                    )}
                    <InputRow
                        id='phone'
                        label={i18n.phoneTel}
                        value={intervenant.phoneTel}
                        onChange={(e) => this.onChangeIntervenant('phoneTel', e.target.value)}
                        type='number'
                        variant='outlined'
                        error={errors.find((e) => e === 'phoneTel' || e === 'minOneTel')}
                        helperText={errors.find((e) => e === 'phoneTel') ? '10 chiffres obligatoires' : null}
                    />
                    <InputRow
                        id='mobilePhone'
                        label={i18n.portable}
                        value={intervenant.mobile}
                        type='number'
                        onChange={(e) => this.onChangeIntervenant('mobile', e.target.value)}
                        variant='outlined'
                        error={errors.find((e) => e === 'mobile' || e === 'minOneTel')}
                        helperText={errors.find((e) => e === 'mobile') ? '10 chiffres obligatoires' : null}
                    />
                    <InputRow
                        id='fax'
                        label={i18n.fax}
                        value={intervenant.fax}
                        type='number'
                        onChange={(e) => this.onChangeIntervenant('fax', e.target.value)}
                        variant='outlined'
                        error={errors.find((e) => e === 'fax')}
                        helperText={errors.find((e) => e === 'fax') ? '10 chiffres obligatoires' : null}
                    />
                    <InputRow
                        // required
                        id='email'
                        label={i18n.email}
                        type='text'
                        value={intervenant.email}
                        onChange={(e) => this.onChangeIntervenant('email', e.target.value)}
                        variant='outlined'
                        error={errors.find((e) => e === 'email')}
                        helperText={errors.find((e) => e === 'email') ? 'Adresse non valide' : null}
                    />
                    <InputRow
                        required
                        id='siret'
                        label={i18n.siret}
                        type='number'
                        value={intervenant.siret}
                        onChange={(e) => this.onChangeIntervenant('siret', e.target.value)}
                        variant='outlined'
                        error={errors.find((e) => e === 'siret')}
                        helperText={errors.find((e) => e === 'siret') ? '14 chiffres obligatoires' : null}
                        inputProps={{ maxLength: 14 }}
                    />
                    <InputRow
                        id='pacage'
                        label='PACAGE'
                        type='text'
                        value={intervenant.pacage}
                        onChange={(e) => this.onChangeIntervenant('pacage', e.target.value)}
                        variant='outlined'
                    />
                </>
            )
        }
        return (
            isEmpty(intervenant) ? (
                <p>{i18n.noInformation}</p>
            ) : (
                <>
                    <Container>{intervenant.auteur || ''}</Container>
                    <Container>{intervenant.name || ''}</Container>
                    <Container>{intervenant.road || ''}</Container>
                    <Container>{intervenant.addressComplement || ''}</Container>
                    <Container>{`${intervenant.postalCode || ''} ${city && city.name ? city.name : ''}`}</Container>
                    {intervenant.phoneTel && (
                        <Container container alignItems='center'>
                            <IconRow>phone</IconRow>
                            <span>{intervenant.phoneTel ? formatPhone(intervenant.phoneTel) : ''}</span>
                        </Container>
                    )}
                    {intervenant.desktopTel && (
                        <Container container alignItems='center'>
                            <IconRow>phone</IconRow>
                            <span>{intervenant.desktopTel ? formatPhone(intervenant.desktopTel) : ''}</span>
                        </Container>
                    )}
                    {intervenant.mobile && (
                        <Container container alignItems='center'>
                            <IconRow>smartphone</IconRow>
                            <span>{intervenant.mobile ? formatPhone(intervenant.mobile) : ''}</span>
                        </Container>
                    )}
                    {intervenant.fax && (
                        <Container container alignItems='center'>
                            <IconRow>print</IconRow>
                            <span>{intervenant.fax ? formatPhone(intervenant.fax) : ''}</span>
                        </Container>
                    )}
                    {intervenant.email && (
                        <Container container alignItems='center'>
                            <IconRow>mail_outlined</IconRow>
                            <span>{intervenant.email || ''}</span>
                        </Container>
                    )}
                </>
            )
        )
    }

    render() {
        const { title, editTitle, editMode, selectType, hideIcon, hideAddress, accordeon, expanded, style, cities, styleContainer } = this.props
        const { intervenant } = this.state
        if (cities.length) {
            return (
                <EditableCard
                    title={title}
                    editTitle={editTitle}
                    editMode={editMode}
                    toggleEditMode={this.props.toggleEditMode}
                    onSave={this.onSaveIntervenant}
                    onCancel={this.onCancelIntervenant}
                    hideIcon={hideIcon}
                    accordeon={accordeon}
                    expanded={expanded}
                    style={style}
                    styleContainer={styleContainer}
                    color='white'
                    styleTitle={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}
                    newStyle
                >
                    {this.getContent(editMode, intervenant, selectType, hideAddress)}
                </EditableCard>
            )
        }
        return ''
    }
}

IntervenantCard.propTypes = {
    intervenant: PropTypes.instanceOf(DtoIntervenant).isRequired,
    title: PropTypes.string.isRequired,
    editTitle: PropTypes.string,
    editMode: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    selectType: PropTypes.bool,
    hideIcon: PropTypes.bool,
    accordeon: PropTypes.bool,
    expanded: PropTypes.bool,
    hideAddress: PropTypes.bool,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    style: PropTypes.instanceOf(PropTypes.shape({})),
    styleContainer: PropTypes.instanceOf(PropTypes.shape({})),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    accountUser: PropTypes.instanceOf(DtoUser),
}

IntervenantCard.defaultProps = {
    editable: false,
}

const mapStateToProps = (store) => {
    return {
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        codesSandre: store.ReferencialReducer.codesSandre,
        accountUser: store.AccountReducer.accountUser,
    }
}

export default connect(mapStateToProps)(IntervenantCard)
