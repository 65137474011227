import { compact, max, min, orderBy, range } from 'lodash'
import React from 'react'
import i18n from 'simple-react-i18n'
import { ReactComponent as EmptyLogo } from '../ressources/static/svg/Niveau1.svg'
import { ReactComponent as Level2Logo } from '../ressources/static/svg/Niveau2.svg'
import { ReactComponent as Level3Logo } from '../ressources/static/svg/Niveau3.svg'
import { ReactComponent as Level4Logo } from '../ressources/static/svg/Niveau4.svg'
import { ReactComponent as Level5Logo } from '../ressources/static/svg/Niveau5.svg'
import { ReactComponent as Level6Logo } from '../ressources/static/svg/Niveau6.svg'
import { ReactComponent as FullLogo } from '../ressources/static/svg/Niveau7.svg'
import { ReactComponent as RestrictionNormaleLogo } from '../ressources/static/svg/Restriction_Normale.svg'
import { ReactComponent as RestrictionViliganceLogo } from '../ressources/static/svg/Restriction_Viligance.svg'
import { ReactComponent as RestrictionAlerteLogo } from '../ressources/static/svg/Restriction_Alerte.svg'
import { ReactComponent as RestrictionAlerteRenforceeLogo } from '../ressources/static/svg/Restriction_AlerteRenforcee.svg'
import { ReactComponent as RestrictionCriseLogo } from '../ressources/static/svg/Restriction_Crise.svg'
import { Grid } from '@mui/material'
import { CHRONICLES_CONSTANTS } from '../pages/online/agri/constants/AgriConstants'
import moment from 'moment'
import { sieauTooltip } from './FormUtils'

const getWaterLeftIcon = (percentLeft, style = {}, color) => {
    if (percentLeft <= 0) {
        return <EmptyLogo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft <= 20) {
        return <Level2Logo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft <= 40) {
        return <Level3Logo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft <= 60) {
        return <Level4Logo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft <= 80) {
        return <Level5Logo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft < 100) {
        return <Level6Logo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    if (percentLeft === 100) {
        return <FullLogo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
    }
    return <FullLogo {...sieauTooltip(i18n.percentAuthorizedLeft)} style={style} fill={color} />
}

const getRestrictionIcon = (level, style = {}, color) => {
    if (level === -1) {
        return <RestrictionNormaleLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
    }
    if (level === 1) {
        return <RestrictionViliganceLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
    }
    if (level === 2) {
        return <RestrictionAlerteLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
    }
    if (level === 3) {
        return <RestrictionAlerteRenforceeLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
    }
    if (level === 4) {
        return <RestrictionCriseLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
    }
    return <RestrictionNormaleLogo {...sieauTooltip(i18n.restrictionLevel)} style={style} fill={color} />
}

const getWaterTurns = (waterTurns, restrictions, waterTurnsSlots) => {
    const allRestrictions = [
        ...restrictions,
        {
            id: 'X',
            color: '#52D100',
            level: 'X',
        },
    ]
    const date = new Date()
    const startHour = 2
    const day = `day${date.getDay() || 7}`
    const hour = Math.abs(date.getHours() - startHour)
    const allActualLevels = waterTurns.map((w) => {
        const slot = waterTurnsSlots.find(wts => wts.id === w.idSlot) || { nbSlots: 1, id: -1 }
        const slotsDisplay = range(slot.nbSlots || 1)
        const dureePlage = 24 / slot.nbSlots
        const value = w[day][slotsDisplay[Math.trunc(hour / dureePlage)]] || 'X'
        return { ...allRestrictions.find((r) => `${r.level}` === value), slot }
    })
    const x = allActualLevels.filter((r) => r.level === 'x')
    const others = orderBy(allActualLevels.filter((r) => r.level !== 'x'), 'level')
    return [...x, ...others][0]
}

const getColorAndLabelWaterTurn = (waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots, point) => {
    const waterTurns = exploitationWaterTurns.filter((w) => w.idInstallation === point.id && w.year === moment().year())
    if (waterTurns) {
        const hour = new Date().getHours()
        const res = getWaterTurns(waterTurns, waterTurnsRestrictions, waterTurnsSlots)
        if (res) {
            const allLevels = waterTurnsRestrictions.map((r) => r.level)
            const { slot } = res
            const label = compact((slot.slots || '').split('/').map((s) => {
                const startAndEnd = s.split('-')
                if ((hour >= parseInt(startAndEnd[0]) && hour < parseInt(startAndEnd[1])) || startAndEnd[0] === startAndEnd[1]) {
                    return `${startAndEnd[0]}h-${startAndEnd[1]}h${min(allLevels) === res.level ? '' : '*'}`
                }
                return null
            }))[0] || ''
            return { color: max(allLevels) === res.level ? 'red' : res.color, label }
        }
        return { color: 'grey', label: i18n.noWaterTurn }
    }
    return { color: 'grey', label: i18n.noWaterTurn }
}

const getSquare = (color, isLast) => (
    <div
        style={{
            backgroundColor: color || 'white',
            display: 'inline-block',
            width: '20px',
            border: 'solid black 1px',
            borderRight: isLast ? 'solid black 1px' : '',
        }}
    >
        &nbsp;
    </div>
)

const getDayDisplay = (value, dayLabel, slotsDisplay, restrictions) => {
    const allRestrictions = [
        ...restrictions,
        {
            id: 'X',
            color: '#52D100',
            level: 'X',
        },
    ]
    if (value) {
        const slots = value.split('')
        return (
            <Grid container style={{ padding: 8, paddingLeft: 0 }}>
                <Grid item xs={3}>{dayLabel} : </Grid>
                <Grid item xs={9}>{slotsDisplay.map((s) => getSquare((allRestrictions.find(r => `${r.level}` === slots[s]) || {}).color, s === slotsDisplay.length - 1))}</Grid>
            </Grid>
        )
    }
    return null
}

const getTextSlot = (waterTurnSlots) => {
    const slots = waterTurnSlots && waterTurnSlots.slots && waterTurnSlots.slots.split('/')
    return slots && slots.map((slot => {
        const hours = slot && slot.split('-')
        return hours.map(hour => `${hour}h`).join('-') || ''
    })).join('  ') || i18n.notDefined
}

const getEvolValue = (type, chronicles, chronicle, index) => {
    if (type === CHRONICLES_CONSTANTS.TYPE_INDEX) {
        return chronicle.value - chronicles[index + 1].value
    }
    return Math.round(chronicles.slice(index).reduce((acc, d) => acc + d.value, 0))
}

export { getWaterLeftIcon, getRestrictionIcon, getWaterTurns, getDayDisplay, getSquare, getTextSlot, getEvolValue, getColorAndLabelWaterTurn }
