module.exports = {
    RECEIVE_EXPLOITATION: 'RECEIVE_EXPLOITATION',
    RECEIVE_EXPLOITATIONS_DATA: 'RECEIVE_EXPLOITATIONS_DATA',
    RECEIVE_EXPLOITATION_VOLUMES: 'RECEIVE_EXPLOITATION_VOLUMES',
    RECEIVE_EXPLOITATIONS: 'RECEIVE_EXPLOITATIONS',
    RECEIVE_DECLARATION: 'RECEIVE_DECLARATION',
    RECEIVE_SURVEY_VOLUMES_EXPLOITATION: 'RECEIVE_SURVEY_VOLUMES_EXPLOITATION',
    RECEIVE_LAST_DECLARATION: 'RECEIVE_LAST_DECLARATION',
    RECEIVE_DECLARATIONS_STATS_EXPLOITATION: 'RECEIVE_DECLARATIONS_STATS_EXPLOITATION',
    RECEIVE_DECLARATIONS: 'RECEIVE_DECLARATIONS',
    RECEIVE_DECLARATION_CONTRIBUTORS: 'RECEIVE_DECLARATION_CONTRIBUTORS',
    RECEIVE_ENQUETE: 'RECEIVE_ENQUETE',
    RECEIVE_ENQUETES: 'RECEIVE_ENQUETES',
    RECEIVE_OPERATOR: 'RECEIVE_OPERATOR',
    RECEIVE_OPERATORS: 'RECEIVE_OPERATORS',
    RECEIVE_MODES_IRRIGATIONS: 'RECEIVE_MODES_IRRIGATIONS',
    RECEIVE_DROUGHT_SECTORS_RESTRICTIONS: 'RECEIVE_DROUGHT_SECTORS_RESTRICTIONS',
    RECEIVE_RSEAU_DATA: 'RECEIVE_RSEAU_DATA',
    RECEIVE_CALCULATED_VOLUME: 'RECEIVE_CALCULATED_VOLUME',
    RECEIVE_SURVEY: 'RECEIVE_SURVEY',
    RECEIVE_SURVEYS: 'RECEIVE_SURVEYS',
    RECEIVE_WATERTURNS_RESTRICTIONS: 'RECEIVE_WATERTURNS_RESTRICTIONS',
    RECEIVE_WATERTURNS_EXPLOITATION: 'RECEIVE_WATERTURNS_EXPLOITATION',
    RECEIVE_WATERTURNS: 'RECEIVE_WATERTURNS',
    RECEIVE_WATERTURNS_SLOTS: 'RECEIVE_WATERTURNS_SLOTS',
    RECEIVE_TANKS_TYPES: 'RECEIVE_TANKS_TYPES',
    CHRONICLES_CONSTANTS: {
        TYPE_ESTIM: 1,
        TYPE_INDEX: 2,
        TYPE_EVENT: 3,
    },
    POINT_STATUS_DECLARATION: {
        USED: 1,
        REMOVED: 2,
        ADJOURNED: 3,
    },
    SAMPLE_TYPES: {
        UNDERGROUND: 1,
        SUPERFICIAL: 2,
    },
    RESTRICTION_RESOURCE_TYPE: {
        DROUGHT_SECTORS: 1,
        MANAGEMENT_UNITS: 2,
    },
    // AGRI
    AGRI: 'AGRI',
    AGRI_DOCUMENTS: 'AGRI_DOCUMENTS',
    AGRI_DOCUMENTS_LABEL: 'agriDocuments',
    AGRI_SURVEYS: 'AGRI_SURVEYS',
    AGRI_SURVEYS_LABEL: 'agriSurveys',
    AGRI_TERRITORY: 'AGRI_TERRITORY',
    AGRI_TERRITORY_LABEL: 'agriTerritory',
    AGRI_RSEAU: 'AGRI_RSEAU',
    AGRI_RSEAU_LABEL: 'RSEau',
}
