import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { LinearProgress } from '@mui/material'
import HomeAction from '../../../offline/actions/HomeAction'
import { getLogin } from '../../../../utils/UserUtils'
import EditableCard from './EditableCard'

class CGUValidDateCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateValidCgu: {},
            dataLoadedUserDate: false,
        }
    }

    componentDidMount() {
        this.getCGU()
    }

    getCGU = () => {
        this.props.getDateValidCGU(getLogin()).then(() => {
            const { dateValidCgu } = this.props
            this.setState({
                dateValidCgu,
                dataLoadedUserDate: true,
            })
        })
    }

    render() {
        const { dataLoadedUserDate, dateValidCgu } = this.state

        const dateUser = new Date(dateValidCgu).toLocaleDateString()
        return (
            <>
                {dataLoadedUserDate ? (
                    <EditableCard>
                        <>
                            {i18n.CGUvalidDate}&nbsp;{dateUser}
                        </>
                    </EditableCard>
                ) : (
                    <LinearProgress />
                )}
            </>
        )
    }
}

const mapDispatchToProps = {
    getAllCGU: HomeAction.getAllCGU,
    getDateValidCGU: HomeAction.getDateValidCGU,
}

const mapStateToProps = (store) => {
    return {
        dateValidCgu: store.HomeReducer.dateValidCgu,
    }
}

CGUValidDateCard.propTypes = {
    getDateValidCGU: PropTypes.func,
    dateValidCgu: PropTypes.instanceOf(PropTypes.shape({})),
    classes: PropTypes.instanceOf(PropTypes.shape({})),
}

export default connect(mapStateToProps, mapDispatchToProps)(CGUValidDateCard)
