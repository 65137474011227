import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { makeStyles } from '@mui/styles'
import { Card, Modal, TextField } from '@mui/material'
import { MainButton } from '../../components/styled/buttons'

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 1),
        margin: '10px auto',
        bottom: 'inherit',
        height: 'auto',
        width: '90vw',
        overflowY: 'auto',
    },
}))

export default function ModalUpdatePassword({
    open,
    toggleModal,
    handleChangeMdp,
    handleChangeNewMdp,
    handleChangeConfirmation,
    onSavePassword,
    firstLogin,
    title,
    label,
}) {
    const classes = useStyles()

    const onClickButton = () => {
        onSavePassword()
    }

    return (
        <Modal
            open={open}
            onClose={toggleModal}
        >
            <Card className={classes.paper} style={{ padding: '14px' }}>
                {firstLogin ? (
                    <>
                        <h2>{title || 'Première connexion'}</h2>
                        <p>{label || 'Merci de modifier le mot de passe provisoire qui vous a été attribué​'}</p>
                    </>
                ) : (
                    <>
                        <h2>Changer votre mot de passe</h2>
                        <TextField
                            item='true'
                            id='password'
                            label='Mot de passe actuel'
                            type='password'
                            onChange={handleChangeMdp}
                            variant='outlined'
                            style={{ width: '100%', marginTop: '15px' }}
                        />
                    </>
                )}
                <TextField
                    item='true'
                    id='password'
                    label='Nouveau mot de passe'
                    type='password'
                    onChange={handleChangeNewMdp}
                    variant='outlined'
                    style={{ width: '100%', marginTop: '15px' }}
                />
                <TextField
                    item='true'
                    id='password'
                    label='Confirmation'
                    type='password'
                    onChange={handleChangeConfirmation}
                    variant='outlined'
                    style={{ width: '100%', marginTop: '15px' }}
                />
                <MainButton onClick={onClickButton} margintop>
                    {i18n.changePassword}
                </MainButton>
                <MainButton
                    reverse='true'
                    onClick={toggleModal}
                    marginTop
                >
                    {i18n.cancel}
                </MainButton>
            </Card>
        </Modal>
    )
}

ModalUpdatePassword.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSavePassword: PropTypes.func.isRequired,
    handleChangeMdp: PropTypes.func,
    handleChangeNewMdp: PropTypes.func,
    handleChangeConfirmation: PropTypes.func,
    firstLogin: PropTypes.bool,
    title: PropTypes.string,
    label: PropTypes.string,
}

ModalUpdatePassword.defaultProps = {
    firstLogin: false,
}