import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Checkbox, styled } from '@mui/material'
import i18n from 'simple-react-i18n'
import { isEmpty } from '../../../../utils/ObjectUtils'
import { getDate } from '../../../../utils/DateUtil'
import { InputRow } from '../styled/inputs'
import DtoCasingExploit from '../../agri/dto/exploitation/DtoCasingExploit'
import DtoCasingDeclaration from '../../agri/dto/enquete/DtoCasingDeclaration'
import EditableCard from './EditableCard'
import StyledCard from './StyledCard'

const Container = styled(Grid)({
    margin: '8 0',
})

class OuvrageCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ouvrage: props.ouvrage,
        }
    }

    onChangeOuvrage = (key, value) => {
        const { ouvrage } = this.state
        this.setState({
            ouvrage: {
                ...ouvrage,
                [key]: value
            }
        })
    }

    onCancelOuvrage = () => {
        const { ouvrage } = this.props
        this.setState({ ouvrage })
        this.props.onCancel()
    }

    onSaveOuvrage = () => {
        const { ouvrage } = this.state
        this.props.onSave(ouvrage)
    }

    getContent = (editMode) => {
        const { ouvrage } = this.state
        if (editMode) {
            return (
                <>
                    <p>{i18n.depth} : {ouvrage.depth || ''}</p>
                    <p>{i18n.realization} : {ouvrage.completionDate ? getDate(ouvrage.completionDate) : ''}</p>
                    <p>{i18n.declaration} : {ouvrage.declarationDate ? getDate(ouvrage.declarationDate) : ''}</p>
                    <p>{i18n.receiptNum} : {ouvrage.receiptNumber || ''}</p>
                    <p>{i18n.receiptDate} : {ouvrage.receiptDate ? getDate(ouvrage.receiptDate) : ''}</p>
                    <p>{i18n.reserveFlow} : {ouvrage.reserveFlow || ''}</p>
                    <Grid container spacing={1}>
                        <Grid item container xs={12} spacing={3} justifyContent='center' alignItems='center'>
                            <Grid item xs={8}>
                                {i18n.cementing}
                            </Grid>
                            <Grid item xs={4}>
                                <Checkbox
                                    checked={ouvrage.cementing || false}
                                    onChange={(e) => this.onChangeOuvrage('cementing', e.target.checked)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={3} justifyContent='center' alignItems='center'>
                            <Grid item xs={8}>
                                {i18n.concreteCoping}
                            </Grid>
                            <Grid item xs={4}>
                                <Checkbox
                                    checked={ouvrage.concreteCoping || false}
                                    onChange={(e) => this.onChangeOuvrage('concreteCoping', e.target.checked)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={3} justifyContent='center' alignItems='center'>
                            <Grid item xs={8}>
                                {i18n.protectionHead}
                            </Grid>
                            <Grid item xs={4}>
                                <Checkbox
                                    checked={ouvrage.protectionHead || false}
                                    onChange={(e) => this.onChangeOuvrage('protectionHead', e.target.checked)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <InputRow
                                id='copingHeight'
                                label={i18n.copingHeight}
                                type='number'
                                value={ouvrage.copingHeight}
                                onChange={(e) => this.onChangeOuvrage('copingHeight', parseFloat(e.target.value))}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item container xs={12} justifyContent='center' alignItems='center'>
                            <InputRow
                                item
                                id='comment'
                                label={i18n.comment}
                                type='text'
                                multiline
                                value={ouvrage.comment}
                                onChange={(event) => this.onChangeOuvrage('comment', event.target.value)}
                                rows={5}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </>
            )
        }
        return (
            isEmpty(ouvrage) ? (
                <p>{i18n.noInformation}</p>
            ) : (
                <>
                    <Container>{i18n.depth} : {ouvrage.depth || ''}</Container>
                    <Container>{i18n.realization} : {ouvrage.completionDate ? getDate(ouvrage.completionDate) : ''}</Container>
                    <Container>{i18n.declaration} : {ouvrage.declarationDate ? getDate(ouvrage.declarationDate) : ''}</Container>
                    <Container>{i18n.receiptNum} : {ouvrage.receiptNumber || ''}</Container>
                    <Container>{i18n.receiptDate} : {ouvrage.receiptDate ? getDate(ouvrage.receiptDate) : ''}</Container>
                    <Container>{i18n.cementing} : {ouvrage.cementing ? i18n.yes : i18n.no}</Container>
                    <Container>{i18n.concreteCoping} : {ouvrage.concreteCoping ? i18n.yes : i18n.no}</Container>
                    <Container>{i18n.protectionHead} : {ouvrage.protectionHead ? i18n.yes : i18n.no}</Container>
                    <Container>{i18n.modernizedPerimeterASP} : {ouvrage.modernizedPerimeterASP ? i18n.yes : i18n.no}</Container>
                    <Container>{i18n.specificPrescriptions} : {ouvrage.specificPrescriptions ? i18n.yes : i18n.no}</Container>
                    <Container>{i18n.copingHeight} : {ouvrage.copingHeight || ''}</Container>
                    <Container>{i18n.reserveFlow} : {ouvrage.reserveFlow || ''}</Container>
                    <Container>{i18n.comment} : {ouvrage.comment || ''}</Container>
                </>)
        )
    }

    render() {
        const { title, editTitle, editMode, hideIcon, accordeon, expanded, newStyle } = this.props
        if (newStyle) {
            const { ouvrage } = this.state
            const style = { padding: 10, borderTop: '1px solid black' }
            return (
                <StyledCard
                    subTitle={title}
                    styleContainer={{ padding: '5 10' }}
                    styleContent={{ padding: 0 }}
                    styleSubTitle={{ borderTop: '1px solid black', display: 'flex', justifyContent: 'space-between' }}
                >
                    <Grid container style={{ padding: 10 }}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.depth}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.depth || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.realization}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.completionDate ? getDate(ouvrage.completionDate) : '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.declaration}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.declarationDate ? getDate(ouvrage.declarationDate) : '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.receiptNum}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.receiptNumber || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.receiptDate}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.receiptDate ? getDate(ouvrage.receiptDate) : '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.cementing}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.cementing ? i18n.yes : i18n.no || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.concreteCoping}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.concreteCoping ? i18n.yes : i18n.no || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.protectionHead}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.protectionHead ? i18n.yes : i18n.no || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.modernizedPerimeterASP}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.modernizedPerimeterASP ? i18n.yes : i18n.no || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.specificPrescriptions}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.specificPrescriptions ? i18n.yes : i18n.no || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.copingHeight}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.copingHeight || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.reserveFlow}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.reserveFlow || '-'}</Grid>
                    </Grid>
                    <Grid container style={style}>
                        <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.comment}</Grid>
                        <Grid item xs={6} className='bold'>{ouvrage.comment || '-'}</Grid>
                    </Grid>
                </StyledCard>
            )
        }
        return (
            <EditableCard
                title={title}
                editTitle={editTitle}
                editMode={editMode}
                onCancel={this.onCancelOuvrage}
                onSave={this.onSaveOuvrage}
                toggleEditMode={this.props.toggleEditMode}
                hideIcon={hideIcon}
                accordeon={accordeon}
                expanded={expanded}
            >
                {this.getContent(editMode)}
            </EditableCard>
        )
    }
}

OuvrageCard.propTypes = {
    title: PropTypes.string.isRequired,
    editTitle: PropTypes.string,
    ouvrage: PropTypes.instanceOf(DtoCasingDeclaration || DtoCasingExploit).isRequired,
    hideIcon: PropTypes.bool,
    accordeon: PropTypes.bool,
    expanded: PropTypes.bool,
    editMode: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    newStyle: PropTypes.bool,
}

OuvrageCard.defaultProps = {
    editable: false,
    ouvrage: {},
}

export default OuvrageCard
