'use strict'
import DtoUserLight from '../../online/account/dto/DtoUserLight'
import DtoCMSEvent from '../../online/cms/dto/DtoCMSEvent'
import DtoArrest from '../../offline/dto/DtoArrest'
import {
    LOGIN_FAIL,
    DISCONNECTED,
    RECEIVE_CURRENT_LOCATION,
    ACTION,
    MENU,
    RECEIVE_WATCHID,
    POPUP,
    TITLE,
    MENU_RESET,
    RECEIVE_ALL_CGU,
    RECEIVE_DATE_VALID_CGU,
    RECEIVE_PARTENAIRE_CMS,
    RECEIVE_CGU_CMS,
    RECEIVE_APPLICATION_SETTINGS,
    RECEIVE_CMS_HOME,
    RECEIVE_CMS_LOGO,
    RECEIVE_ALL_USERS,
    RECEIVE_ALL_USERS_LIGHT,
    RECEIVE_ARRESTS,
} from '../constants/HomeConstants'

export const store = {
    message: '',
    token: '',
    navhistory: [],
    coords: {
        latitude: 0,
        longitude: 0,
    },
    watchId: null,
    action: '',
    menu: '',
    popup: {
        content: '',
        actions: '',
        header: '',
        trigger: '',
        id: '',
        fixedFooter: false,
        bottomSheet: false,
    },
    title: [],
    menuReset: false,
    cgu: {},
    dateValidCgu: '',
    cms: [],
    cmsCGU: [],
    cmsHome: {},
    cmsLogo: {},
    applicationSettings: [],
    users: [],
    usersLight: [],
    arrests: [],
}

export function HomeReducer(state = {}, action) {
    switch (action.type) {
        case LOGIN_FAIL:
            return {
                ...state,
                message: action.message,
            }
        case RECEIVE_ALL_USERS:
            return {
                ...state,
                users: action.users,
            }
        case RECEIVE_ALL_USERS_LIGHT:
            return {
                ...state,
                usersLight: (action.users || []).map((u) => new DtoUserLight(u)),
            }
        case RECEIVE_CURRENT_LOCATION:
            return {
                ...state,
                coords: {
                    latitude: action.position.coords.latitude,
                    longitude: action.position.coords.longitude,
                    speed: action.position.coords.speed,
                },
            }
        case RECEIVE_ARRESTS:
            return {
                ...state,
                arrests: action.arrests.map((a) => new DtoArrest(a)),
            }
        case DISCONNECTED:
            return {
                ...state,
                message: action.message,
            }
        case ACTION:
            return {
                ...state,
                action: action.action,
            }
        case MENU:
            return {
                ...state,
                menu: action.menu,
            }
        case RECEIVE_WATCHID:
            return {
                ...state,
                watchId: action.watchId,
            }
        case POPUP:
            return {
                ...state,
                popup: action.popup,
            }
        case TITLE:
            return {
                ...state,
                title: action.title,
            }
        case MENU_RESET:
            return {
                ...state,
                menuReset: action.menuReset,
            }
        case RECEIVE_ALL_CGU:
            return {
                ...state,
                cgu: action.cgu,
            }
        case RECEIVE_DATE_VALID_CGU:
            return {
                ...state,
                dateValidCgu: action.date,
            }
        case RECEIVE_PARTENAIRE_CMS:
            return {
                ...state,
                cms: action.cms,
            }
        case RECEIVE_CGU_CMS:
            return {
                ...state,
                cmsCGU: action.cmsCGU.map((c) => new DtoCMSEvent(c)),
            }
        case RECEIVE_CMS_HOME:
            return {
                ...state,
                cmsHome: new DtoCMSEvent(action.cmsHome),
            }
        case RECEIVE_CMS_LOGO:
            return {
                ...state,
                cmsLogo: new DtoCMSEvent(action.cmsLogo),
            }
        case RECEIVE_APPLICATION_SETTINGS:
            return {
                ...state,
                applicationSettings: action.applicationSettings,
            }
        default:
            return state
    }
}
