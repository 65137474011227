/* eslint-disable no-underscore-dangle */
import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import { Checkbox, Divider, Grid, Icon, LinearProgress, Slider } from '@mui/material'
import { orderBy, compact } from 'lodash'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { Cluster, OSM, TileWMS, Vector as VectorSource } from 'ol/source'
import { Point } from 'ol/geom'
import { Style, Icon as Marker, Stroke, Fill, Text } from 'ol/style'
import { fromLonLat } from 'ol/proj'
import { Feature, Map, Overlay, View } from 'ol'
import { ScaleLine, Rotate, defaults as defaultControls } from 'ol/control'
import AgriAction from '../online/agri/actions/AgriAction'
import { InputRow } from '../online/components/styled/inputs'
import { searchAllCharacters } from '../../utils/StringUtil'
import { MainButton } from '../online/components/styled/buttons'
import DtoExploitation from '../online/agri/dto/exploitation/DtoExploitation'
import DtoUser from '../online/account/dto/DtoUser'
import InstallationsAction from '../online/referencials/installations/actions/InstallationsAction'
import { lightColor, mainColor } from '../online/components/styled/theme'
import DtoCMSEvent from '../online/cms/dto/DtoCMSEvent'
import { hasValue } from '../../utils/NumberUtil'
import CityAction from '../online/referencials/city/actions/CityAction'
import DtoCity from '../online/referencials/city/dto/DtoCity'
import DtoInstallationGeo from '../online/referencials/installations/dto/DtoInstallationGeo'
import { createIconStyle, getWGS84Coordinate } from '../../utils/mapUtils/CoordinateUtils'
import markerOrange from '../../ressources/static/media/markers/marker_orange.png'
import markerGreen from '../../ressources/static/media/markers/marker_green.png'
import markerRed from '../../ressources/static/media/markers/marker_red.png'
import markerYellow from '../../ressources/static/media/markers/marker_yellow.png'
import markerCurrent from '../../ressources/static/media/markers/map_current_position.png'
import DtoWaterTurn from '../online/agri/dto/DtoWaterTurn'
import DtoWaterTurnRestriction from '../online/agri/dto/DtoWaterTurnRestriction'
import ToastrAction from '../online/components/toasters/ToastrAction'
import { getWaterTurns } from '../../utils/AgriUtils'
import DtoWaterTurnsSlot from '../online/agri/dto/DtoWaterTurnsSlot'
import { Container, Item } from '../online/components/styled/grid'
import SimpleTabSideList from '../online/components/SimpleTabSideList'
import ModalFolder from './components/ModalFolder'
import ModalPoint from './components/ModalPoint'
import { ReactComponent as ReturnLogo } from '../../ressources/static/svg/Retour.svg'
import CircleStyle from 'ol/style/Circle'

const headersExport = ['updateDate', 'activityEndDate', 'codification', 'structureType', 'civility', 'name', 'address', 'additionnalAddress', 'inseeCode',
    'postalCode', 'city', 'phoneTel', 'mobile', 'fax', 'email', 'comment', 'siret', 'pacage', 'octagri_aura', 'octagri_local', 'muse', 'agence',
    'domestic', 'agri', 'nonAgri', 'adjourned', 'shut', 'legalRepSampler', 'legalRepExploit']


const SearchBar = ({
    onValidate,
}) => {
    const [searchValue, setSearchValue] = useState()
    return (
        <>
            <Grid item xs={10}>
                <InputRow
                    id='login'
                    label={i18n.search}
                    type='text'
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    variant='outlined'
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            onValidate(searchValue)
                        }
                    }}
                />
            </Grid>
            <Grid item xs={2} style={{ display: 'flex' }}>
                <MainButton style={{ margin: '5px 0' }} onClick={() => onValidate(searchValue)}>
                    <Icon>search</Icon>
                </MainButton>
            </Grid>
        </>
    )
}

SearchBar.propTypes = {
    onValidate: PropTypes.func,
}

class Admin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            openModalFolder: false,
            openModalPoint: false,
            searchValue: '',
            exploitationUser: {},
            exploitationSelected: {},
            pointSelected: {},
            panel: 'list',
            opacityArrestUnderground: 0,
            showArrestSurface: false,
            opacityArrestSurface: 0,
        }
    }

    componentDidMount() {
        const { accountUser, cities } = this.props
        if (accountUser.isAdmin === '1' || accountUser.metadata === '1') {
            if (!cities.length) {
                this.props.fetchCities().then(() => {
                    this.getDatas()
                })
            } else {
                this.getDatas()
            }
        } else {
            this.props.push('/')
        }
    }

    getLocation = () => {
        const options = { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(this.success, this.error, options)
        } else {
            // eslint-disable-next-line no-console
            console.warn('Geolocation is not supported by this browser.')
            this.setMap()
        }
    }

    success = (pos) => {
        const crd = pos.coords
        localStorage.setItem('LAT', crd.latitude)
        localStorage.setItem('LONG', crd.longitude)
        this.setMap({ long: parseFloat(crd.longitude), lat: parseFloat(crd.latitude) })
    }

    error = (err) => {
        // eslint-disable-next-line no-console
        console.error(`ERREUR (${err.code}): ${err.message}`)
        ToastrAction.warning('Erreur lors de la récupération de la position')
        this.setMap()
    }

    getDatas = () => {
        const { accountUser } = this.props
        this.props.fetchWaterTurnsRestrictions()
        this.props.fetchAllWaterTurns()
        this.props.fetchInstallations().then(() => this.getLocation())
        if (accountUser.isAdmin === '1') {
            this.props.fetchExploitationsExportFullData().then(() => this.getExploitation())
        } else {
            this.props.fetchExploitationsAvailable().then(() => this.getExploitation())
        }
    }

    getExploitation = () => {
        this.props.fetchExploitation().then(() => {
            const { exploitation, exploitationsExportFullData } = this.props
            if (exploitation && exploitation.idExploitation) {
                const exploitationUser = exploitationsExportFullData.find(({ idExploitation }) => idExploitation === exploitation.idExploitation) || {}
                this.setState({ dataLoaded: true, exploitationUser })
            } else {
                this.setState({ dataLoaded: true })
            }
        })
    }

    getExploitations = () => {
        const { exploitationsExportFullData } = this.props
        const filteredData = this.getFilteredData(exploitationsExportFullData)
        return orderBy(filteredData, 'codification').map((e, i) => (
            <Grid
                key={i}
                className='clickable'
                style={{
                    borderBottom: '1px solid grey',
                    padding: '8px 16px',
                    backgroundColor: 'white',
                }}
                onClick={() => this.openModalFolder(e)}
            >
                <Grid><b>{e.codification || ''}</b></Grid>
                <Grid>
                    {`${e.city || ''} ${e.inseeCode && e.inseeCode !== 'undefined' ?
                        `[${e.inseeCode}]` :
                        ''}`}
                </Grid>
                <Grid>{e.name || ''}</Grid>
            </Grid>
        ))
    }

    openModalFolder = (exploitationSelected) => {
        this.setState({ exploitationSelected, openModalFolder: true })
    }

    openModalPoint = (pointSelected, pointWaterTurns) => {
        this.setState({ pointSelected, pointWaterTurns, openModalPoint: true })
    }

    getFilteredData = (data) => {
        const { searchValue, exploitationUser } = this.state
        const dataFiltered = data.filter(({ idExploitation }) => idExploitation !== exploitationUser.idExploitation)
        const includesValue = searchAllCharacters(searchValue || '')
        return dataFiltered.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (exploitation) => {
        return searchAllCharacters(headersExport.map(key => exploitation[key]))
    }

    onValidate = (searchValueTmp) => {
        this.setState({ searchValue: searchValueTmp })
    }

    getModalFolder = () => {
        const { openModalFolder, exploitationSelected } = this.state
        if (openModalFolder) {
            return (
                <ModalFolder
                    open={openModalFolder}
                    exploitation={exploitationSelected}
                    onCancel={this.onCancelModalFolder}
                    onValidate={this.onValidateModalFolder}
                />
            )
        }
        return null
    }

    getModalPoint = () => {
        const { exploitationsExportFullData } = this.props
        const { openModalPoint, pointSelected, pointWaterTurns } = this.state
        if (openModalPoint) {
            const exploitations = exploitationsExportFullData.filter((e) => e.link_points.find((link) => link.idInstallation === pointSelected.id))
            return (
                <ModalPoint
                    open={openModalPoint}
                    point={pointSelected}
                    pointWaterTurns={pointWaterTurns}
                    exploitations={exploitations}
                    onCancel={this.onCancelModalPoint}
                    onValidate={this.onValidateModalPoint}
                />
            )
        }
        return null
    }

    onCancelModalFolder = () => {
        this.setState({ openModalFolder: false, exploitationSelected: null })
    }

    onCancelModalPoint = () => {
        this.setState({ openModalPoint: false, pointSelected: null, pointWaterTurns: null })
    }

    onValidateModalFolder = () => {
        const { exploitationSelected } = this.state
        this.setState({ openModalFolder: false, exploitationSelected: null })
        this.props.changeExploitationAdministrator(exploitationSelected.idExploitation).then(() => {
            this.props.push('/home')
        })
    }

    onValidateModalPoint = (idExploitation) => {
        this.setState({ openModalPoint: false, pointSelected: null, pointWaterTurns: null })
        this.props.changeExploitationAdministrator(idExploitation).then(() => {
            this.props.push('/home')
        })
    }

    getMarker = (point) => {
        const { waterTurnsRestrictions, allWaterTurns, waterTurnsSlots } = this.props
        if (point.currentPosition) {
            return { marker: markerCurrent, color: 'blue' }
        }
        const pointWaterTurns = allWaterTurns.filter((w) => w.idInstallation === point.id)
        if (pointWaterTurns.length) {
            const res = getWaterTurns(pointWaterTurns, waterTurnsRestrictions, waterTurnsSlots)
            if (res) {
                const values = {
                    color: res.color || 'grey',
                    pointWaterTurns
                }
                switch (res.level) {
                    case 0:
                        return { marker: markerGreen, ...values }
                    case 1:
                        return { marker: markerYellow, ...values }
                    case 2:
                        return { marker: markerOrange, ...values }
                    case 3:
                        return { marker: markerRed, ...values }
                    default:
                        return { marker: markerGreen, ...values }
                }
            } else {
                return { marker: markerGreen, color: '#52D100', pointWaterTurns }
            }
        }
        return { marker: markerGreen, color: '#52D100', pointWaterTurns }
    }

    setMap = (coords) => {
        const { installationsWithGeo, citiesIndex } = this.props
        const { map } = this.state
        if (!map) {
            /**
             * Elements that make up the popup.
             */
            const container = document.getElementById('popup')
            const closer = document.getElementById('popup-closer')

            /**
             * Create an overlay to anchor the popup to the map.
             */
            const overlay = new Overlay({
                element: container,
                autoPan: true,
                autoPanAnimation: {
                    duration: 250
                }
            })

            const layers = [
                new TileLayer({
                    zIndex: 0,
                    source: new OSM()
                }),
            ]
            const olMap = new Map({
                controls: defaultControls().extend([
                    new ScaleLine({
                        units: 'metric',
                    }),
                    new Rotate()
                ]),
                layers,
                overlays: [overlay],
            })
            const allPoints = coords ? [{ x: coords.long, y: coords.lat, projection: 16, currentPosition: true }, ...installationsWithGeo] : installationsWithGeo

            const features = compact(allPoints.map(point => {
                if (hasValue(point.x) && hasValue(point.y)) {
                    const city = citiesIndex[point.townCode]
                    const coordinates = getWGS84Coordinate(point)
                    const { marker, color, pointWaterTurns } = this.getMarker(point)
                    const markerFeature = new Feature({
                        geometry: new Point(fromLonLat(coordinates)),
                        color,
                        name: (
                            <Grid
                                key={point.id}
                                className='clickable'
                                onClick={() => this.openModalPoint({ ...point, marker }, pointWaterTurns)}
                            >
                                {point.currentPosition ? (
                                    <Grid>Ma position</Grid>
                                ) : (
                                    <>
                                        <Grid><b>{city ? city.name : ''}</b></Grid>
                                        <Grid>{point.location || ''}</Grid>
                                        <Grid>{`${point.code || ''} ${`- ${point.parcel || ''} ${point.section || ''}`}`}</Grid>
                                        <Grid>{point.name || ''}</Grid>
                                    </>
                                )}
                            </Grid>
                        ),
                    })
                    markerFeature.setStyle(createIconStyle(marker, point.scale || 0.5, point.markerText))
                    markerFeature.setId(point.id || point.code)
                    return markerFeature
                }
                return null
            }))
            const vec = new VectorLayer({
                source: new Cluster({
                    distance: 30,
                    minDistance: 20,
                    source: new VectorSource({ features }),
                }),
                style: (feature) => {
                    const featuresLink = feature.get('features')
                    const size = (featuresLink || []).length
                    if (size === 1) {
                        const point = allPoints.find((p) => p.id === featuresLink[0].id_)
                        const { marker } = this.getMarker(point)
                        return new Style({
                            image: new Marker({
                                scale: point?.scale || 0.7,
                                anchor: [0.5, 0.75],
                                src: marker,
                            }),
                        })
                    }
                    return new Style({
                        image: new CircleStyle({
                            radius: 10,
                            stroke: new Stroke({
                                color: '#fff',
                            }),
                            fill: new Fill({
                                color: '#3399CC',
                            }),
                        }),
                        text: new Text({
                            text: `${size}`,
                            fill: new Fill({
                                color: '#fff',
                            }),
                        }),
                    })
                },
            })
            olMap.addLayer(vec)
            const wgs84Sites = compact(allPoints.map((point) => {
                if (point.x && point.y) {
                    const coordinates = getWGS84Coordinate(point)
                    return coordinates
                }
                return null
            }))
            const sumX = wgs84Sites.map((coord) => coord[0]).reduce((a, b) => a + b, 0) || 2.549
            const sumY = wgs84Sites.map((coord) => coord[1]).reduce((a, b) => a + b, 0) || 47.233
            const center = coords && coords.long && coords.lat ? fromLonLat([coords.long, coords.lat]) : fromLonLat([sumX / (wgs84Sites.length || 1), sumY / (wgs84Sites.length || 1)])
            olMap.setView(new View({
                center,
                zoom: coords ? 18 : 6,
            }))

            /**
             * Add a click handler to hide the popup.
             * @return {boolean}.
             */
            closer.onclick = () => {
                overlay.setPosition(undefined)
                closer.blur()
                return false
            }
            /**
             * Add a click handler to the map to render the popup.
             */
            olMap.on('singleclick', (evt) => {
                const values = olMap.forEachFeatureAtPixel(evt.pixel, (feature) => {
                    if (feature) {
                        const containedFeatures = feature.get('features')
                        if (containedFeatures?.length === 1) {
                            return { color: containedFeatures[0]?.get('color'), name: containedFeatures[0]?.get('name') }
                        }
                        return { color: undefined, name: undefined }
                    }
                    return { color: undefined, name: undefined }
                })
                if (values?.name) {
                    container.style.display = 'block'
                    const coordinate = evt.coordinate
                    this.setState({ content: values?.name, color: values?.color })
                    overlay.setPosition(coordinate)
                } else {
                    container.style.display = 'none'
                }
            })
            this.setState({ map: olMap })
        }
    }

    onSelectLayer = (layer, show) => {
        const { map, opacityArrestSurface, opacityArrestUnderground } = this.state
        const now = '2022-08-08' || new Date().toISOString().slice(0, 10)
        if (layer === 'sup') {
            if (show) {
                map.addLayer(new TileLayer({
                    className: 'Zones_Niveau_Sup',
                    zIndex: 50,
                    opacity: opacityArrestSurface || 1,
                    visible: true,
                    source: new TileWMS({
                        projection: 'EPSG:2154',
                        url: `https://eau.agriculture.sieau.fr:444/WMS-T.php?map=/export/home/rimboval/geomap/mapfiles/propluvia.map&DATE=${now}`,
                        params: { LAYERS: 'Zones_Niveau_Sup', TILED: true },
                        serverType: 'geoserver',
                    }),
                }))
                this.setState({ opacityArrestSurface: opacityArrestSurface || 1 })
            } else {
                map.getLayers().forEach((l) => {
                    if (l && l.className_ === 'Zones_Niveau_Sup') {
                        map.removeLayer(l)
                    }
                })
            }
            this.setState({ showArrestSurface: show })
        } else if (layer === 'sout') {
            if (show) {
                map.addLayer(new TileLayer({
                    className: 'Zones_Niveau_Sou',
                    zIndex: 50,
                    opacity: opacityArrestUnderground || 1,
                    visible: true,
                    source: new TileWMS({
                        projection: 'EPSG:2154',
                        url: `https://eau.agriculture.sieau.fr:444/WMS-T.php?map=/export/home/rimboval/geomap/mapfiles/propluvia.map&DATE=${now}`,
                        params: { LAYERS: 'Zones_Niveau_Sou', TILED: true },
                        serverType: 'geoserver',
                    }),
                }))
                this.setState({ opacityArrestUnderground: opacityArrestUnderground || 1 })
            } else {
                map.getLayers().forEach((l) => {
                    if (l && l.className_ === 'Zones_Niveau_Sou') {
                        map.removeLayer(l)
                    }
                })
            }
            this.setState({ showArrestUnderground: show })
        }
    }

    onChangeLayerOpacity = (layer, opacity) => {
        const { map } = this.state
        if (layer === 'sup') {
            map.getLayers().forEach((l) => {
                if (l.className_ === 'Zones_Niveau_Sup') {
                    l.setOpacity(opacity)
                    this.setState({ opacityArrestSurface: opacity })
                }
            })
        } else if (layer === 'sout') {
            map.getLayers().forEach((l) => {
                if (l.className_ === 'Zones_Niveau_Sou') {
                    l.setOpacity(opacity)
                    this.setState({ opacityArrestUnderground: opacity })
                }
            })
        }
    }

    getMapPanel = () => {
        const {
            panel,
            content,
            color,
            showArrestSurface,
            showArrestUnderground,
            opacityArrestSurface,
            opacityArrestUnderground,
        } = this.state
        const { waterTurnsRestrictions } = this.props

        const tabs = [{
            icon: 'layers',
            title: 'Arrêtés',
            constant: 'LAYERS',
            content: (
                <Container
                    style={{ padding: 5, height: 150 }}
                >
                    <Container justifyContent='flex-start' alignItems='center'>
                        <Item xs={2}>
                            <Checkbox
                                checked={showArrestSurface}
                                onChange={(e) => this.onSelectLayer('sup', e.target.checked)}
                            />
                        </Item>
                        <Container item xs={10} justifyContent='center' alignItems='space-around'>
                            <Item xs={12}>
                                Arrêtés sécheresse Eau Sup
                            </Item>
                            <Item xs={11}>
                                <Slider
                                    disabled={!showArrestSurface}
                                    value={opacityArrestSurface}
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    onChange={(_, v) => this.onChangeLayerOpacity('sup', v)}
                                    aria-labelledby='continuous-slider'
                                />
                            </Item>
                        </Container>
                    </Container>
                    <Container justifyContent='flex-start' alignItems='center'>
                        <Item xs={2}>
                            <Checkbox
                                checked={showArrestUnderground}
                                onChange={(e) => this.onSelectLayer('sout', e.target.checked)}
                            />
                        </Item>
                        <Container item xs={10} justifyContent='center' alignItems='space-around'>
                            <Item xs={12}>
                                Arrêtés sécheresse Eau Sout
                            </Item>
                            <Item xs={11}>
                                <Slider
                                    disabled={!showArrestUnderground}
                                    value={opacityArrestUnderground}
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    onChange={(_, v) => this.onChangeLayerOpacity('sout', v)}
                                    aria-labelledby='continuous-slider'
                                />
                            </Item>
                        </Container>
                    </Container>
                </Container>
            ),
        }, {
            icon: 'invert_colors',
            title: 'Tours d\'eau',
            constant: 'WATERTURNS',
            content: (
                <Container
                    className='overflowY'
                    style={{ padding: 5 }}
                >
                    <Item xs={12} style={{ padding: '0 16px' }}>
                        <h3>{i18n.waterTurnsRestrictions}</h3>
                    </Item>
                    {waterTurnsRestrictions.map((r) => {
                        return (
                            <Item xs={12} style={{ padding: '8 0' }}>
                                <fieldset>
                                    <legend>
                                        <div style={{ backgroundColor: r.color || 'grey', display: 'inline-block', width: '20px', border: '1px solid black' }}>&nbsp;</div>&nbsp;
                                        <b>{r.label}</b>{` (${i18n.level} ${r.level})`}</legend>
                                    <p>{r.description}</p>
                                    <p>{r.comment}</p>
                                </fieldset>
                            </Item>
                        )
                    })}
                </Container>
            )
        }]

        return (
            <>
                <div id='ol-admin-map' className='ol-admin-map'>
                    <div
                        id='popup'
                        className='ol-popup'
                        style={{
                            background: `linear-gradient(to right, ${color}, ${color} 1rem, white 1rem, white 100%)`
                        }}
                    >
                        <a href='#' id='popup-closer' className='ol-popup-closer' />
                        <div id='popup-content'>{content}</div>
                    </div>
                    <div style={panel === 'map' ? { height: 'calc(100% - 70px)', width: '100%', overflow: 'hidden', position: 'absolute' } : { height: 0, width: 0 }}>
                        {panel === 'map' && (
                            <SimpleTabSideList
                                position='right'
                                defaultTab={'LAYERS'}
                                tabs={tabs}
                            >
                                {tab => tabs.find((t) => t.constant === tab).content}
                            </SimpleTabSideList>
                        )}
                    </div>
                </div>
            </>
        )
    }

    onClickIcon = () => {
        const { panel, map } = this.state
        if (panel === 'list' && map) {
            this.setState({ panel: 'map' })
            map.setTarget('ol-admin-map')
        } else {
            this.setState({ panel: 'list' })
            map.setTarget(null)
        }
    }

    render() {
        const { panel, map, dataLoaded, exploitationUser } = this.state
        const hasExploit = hasValue(exploitationUser.idExploitation)

        return (
            <>
                <Grid
                    container
                    direction='column'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{
                        height: '100%',
                        margin: '-70px 0',
                        overflow: 'hidden',
                        backgroundColor: 'white',
                    }}
                >
                    <Grid
                        item
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        style={{
                            borderBottom: '1px solid grey',
                            height: '70px',
                            backgroundColor: mainColor,
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '1.3rem',
                            zIndex: '1000',
                            padding: '0 10px',
                            width: 'calc(100% - 20px)',
                            borderRadius: '0 0 5px 5px',
                            boxShadow: 'cyan 0px 0px 50px -10px',
                        }}
                    >
                        <Grid item xs={1} className='clickable' style={{ paddingRight: '8' }} onClick={() => this.props.push('/home')}>
                            <ReturnLogo alt='return' fill={lightColor} style={{ height: 25 }} />
                        </Grid>
                        <Grid item xs={11} style={{ paddingLeft: 8 }}>
                            <Grid style={{ fontSize: 21, color: 'white' }} container justifyContent='space-between' alignItems='center'>
                                <Grid item style={{ fontSize: 21 }}>Recherche d'exploitations</Grid>
                                <Grid item>
                                    {map && <Icon className='clickable' onClick={this.onClickIcon} style={{ fontSize: '2rem' }}>{panel === 'list' ? 'map' : 'list'}</Icon>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ height: 'calc(100% - 70px)', width: '100%', overflow: 'hidden' }}>
                        {panel === 'list' && (
                            <>
                                <Grid
                                    container
                                    justifyContent='space-between'
                                    alignItems='stretch'
                                    spacing={1}
                                    style={{ padding: '0 16px' }}
                                >
                                    <Grid item xs={12} style={{ fontSize: '1.3em', marginTop: 8 }}>
                                        <b>{i18n.actualFolder} :</b>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={10}
                                        className={`padding-1 ${hasExploit ? 'clickable' : ''}`}
                                        onClick={hasExploit ? () => this.openModalFolder(exploitationUser) : () => { }}
                                        style={{
                                            backgroundColor: mainColor,
                                            color: 'white',
                                            borderRadius: 5,
                                        }}
                                    >
                                        {hasExploit ? (
                                            <>
                                                <Grid><b>{exploitationUser.codification || ''}</b></Grid>
                                                <Grid>
                                                    {`${exploitationUser.city || ''} ${exploitationUser.inseeCode && exploitationUser.inseeCode !== 'undefined' ?
                                                        `[${exploitationUser.inseeCode}]` :
                                                        ''}`}
                                                </Grid>
                                                <Grid>{exploitationUser.name || ''}</Grid>
                                            </>
                                        ) : (
                                            <h4>{i18n.noFolder}</h4>
                                        )}
                                    </Grid>
                                    <Grid item xs={2} style={{ display: 'flex', padding: '0 4' }}>
                                        {hasExploit && (
                                            <MainButton style={{ margin: 0 }} onClick={() => this.props.push('/home')}>
                                                <Icon>arrow_forward</Icon>
                                            </MainButton>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: '10 0 5' }}>
                                        <Divider style={{ backgroundColor: 'grey' }} />
                                    </Grid>
                                    <SearchBar onValidate={(v) => this.onValidate(v)} />
                                </Grid>
                                <div style={{ height: '80%', overflowY: 'auto', padding: '0 16px' }}>
                                    {dataLoaded ? this.getExploitations() : (
                                        <LinearProgress />
                                    )}
                                </div>
                            </>
                        )}
                        {this.getMapPanel()}
                        {this.getModalFolder()}
                        {this.getModalPoint()}
                    </Grid>
                </Grid>
            </>
        )
    }
}

Admin.propTypes = {
    cmsLogo: PropTypes.instanceOf(DtoCMSEvent),
    accountUser: PropTypes.instanceOf(DtoUser),
    push: PropTypes.func,
    fetchExploitationsExportFullData: PropTypes.func,
    fetchExploitationsAvailable: PropTypes.func,
    fetchExploitation: PropTypes.func,
    changeExploitationAdministrator: PropTypes.func,
    fetchWaterTurnsRestrictions: PropTypes.func,
    fetchAllWaterTurns: PropTypes.func,
    fetchCities: PropTypes.func,
    fetchInstallations: PropTypes.func,
    exploitationsExportFullData: PropTypes.arrayOf(PropTypes.shape({})),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationGeo)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    citiesIndex: PropTypes.objectOf(PropTypes.instanceOf(DtoCity)),
    allWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    waterTurnsRestrictions: PropTypes.objectOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
}

const mapDispatchToProps = {
    fetchExploitationsExportFullData: AgriAction.fetchExploitationsExportFullData,
    fetchExploitationsAvailable: AgriAction.fetchExploitationsAvailable,
    fetchExploitation: AgriAction.fetchExploitation,
    changeExploitationAdministrator: AgriAction.changeExploitationAdministrator,
    fetchWaterTurnsRestrictions: AgriAction.fetchWaterTurnsRestrictions,
    fetchAllWaterTurns: AgriAction.fetchAllWaterTurns,
    fetchCities: CityAction.fetchCities,
    fetchInstallations: InstallationsAction.fetchInstallations,
    push,
}

const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
        exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
        exploitation: store.AgriReducer.exploitation,
        allWaterTurns: store.AgriReducer.allWaterTurns,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        cmsLogo: store.HomeReducer.cmsLogo,
        cities: store.CityReducer.cities,
        installationsWithGeo: store.InstallationsReducer.installationsWithGeo,
        citiesIndex: store.CityReducer.citiesIndex,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)