import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { useMemo } from 'react'
import { getDocumentTypeIcon, getFileExtension, getFileName, getFileNatureLabel } from '../../../utils/FileUtils'
import { getDate } from '../../../utils/DateUtil'
import { SANDRE } from '../referencials/constants/ReferencialConstants'
import { DOCUMENT, PICTURE } from '../referencials/documents/constants/FileConstants'
import DtoFile from '../referencials/documents/dto/DtoFile'
import StyledCard from '../components/cards/StyledCard'
import { ReactComponent as DownloadLogo } from '../../../ressources/static/svg/Download.svg'
import { sieauTooltip } from '../../../utils/FormUtils'

const CardFile = ({
    file = {},
    fileType = PICTURE,
}) => {
    const {
        exploitation,
        codesSandre,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)
    const fileExtension = getFileExtension(file.name)
    const FILE_TYPE = {
        picture: {
            sandreCode: SANDRE.TYPE_PHOTO,
            fileTypeName: i18n.pictureType,
            name: PICTURE,
            content: <img src={file.url} style={{ objectFit: 'cover', maxWidth: '100%', height: '100%', width: 'auto' }} />,
            textAlign: 'left',
        },
        document: {
            sandreCode: SANDRE.TYPE_DOCUMENT,
            fileTypeName: i18n.documentType,
            name: DOCUMENT,
            content: <img src={getDocumentTypeIcon(fileExtension)} style={{ width: '50%', height: '100%' }} />,
            textAlign: 'center',
        },
    }
    const fileTypeConstant = FILE_TYPE[file.fileType || PICTURE]
    const fileNatures = useMemo(() => codesSandre.filter(c => c.field === fileTypeConstant.sandreCode))
    const displayName = file.isAllDataName && exploitation.codification === file.stationCode ? file.shortName : getFileName(file.name)
    const fileNature = getFileNatureLabel(file.name, fileNatures)
    return (
        <a className='valign-wrapper' href={file.url} target='_system' data-fancybox={fileType == PICTURE ? 'images' : undefined} data-caption={displayName} style={{ width: '100%', textDecoration: 'none', position: 'relative' }}>
            <StyledCard className='row valign-wrapper' styleContainer={{ height: 155, padding: '5 0' }} styleContent={{ borderTop: '1px solid black', padding: 0 }}>
                <Grid container direction='row' style={{ height: 155 }}>
                    <Grid item xs={11} style={{ borderBottom: '1px solid black' }}>
                        <Typography
                            style={{
                                fontWeight: 'bold',
                                padding: '5 0 0 10',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontSize: 21,
                            }}
                            variant='subtitle1'
                            component='div'
                        >
                            {displayName}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderBottom: '1px solid black' }}>
                        <DownloadLogo {...sieauTooltip(i18n.open)} style={{ height: 25 }} />
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container style={{ padding: 10 }}>
                            <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.type}</Grid>
                            <Grid item xs={6} className='bold'>{fileExtension}</Grid>
                        </Grid>
                        <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                            <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.nature}</Grid>
                            <Grid item xs={6} className='bold'>{fileNature}</Grid>
                        </Grid>
                        <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                            <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.date}</Grid>
                            <Grid item xs={6} className='bold'>{getDate(parseInt(file.date))}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', height: 112 }}>
                        {fileTypeConstant.content}
                    </Grid>
                </Grid>
            </StyledCard>
        </a>
    )
}

CardFile.propTypes = {
    file: PropTypes.instanceOf(DtoFile),
    name: PropTypes.string,
    content: PropTypes.string,
    onClickDelete: PropTypes.func,
    onUpdate: PropTypes.func,
    editable: PropTypes.bool,
    fileType: PropTypes.string,
}

export default CardFile
