import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid, LinearProgress } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import PointsCard from '../components/cards/PointsCard'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import { orderPointsList } from '../../../utils/ObjectUtils'
import DtoCity from '../referencials/city/dto/DtoCity'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import { formatDate } from '../../../utils/DateUtil'
import { formatMilliers } from '../../../utils/StringUtil'
import DtoDeclarationWithStats from '../agri/dto/enquete/DtoDeclarationWithStats'
import { hasValue } from '../../../utils/NumberUtil'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import DtoWaterTurnsSlot from '../agri/dto/DtoWaterTurnsSlot'
import { getColorAndLabelWaterTurn, getRestrictionIcon, getWaterLeftIcon } from '../../../utils/AgriUtils'
import { getDistance } from '../../../utils/mapUtils/CoordinateUtils'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import moment from 'moment'
import { omit, round, uniqWith } from 'lodash'
import { ReactComponent as CycleLogo } from '../../../ressources/static/svg/Meteo_CycleIrrigation.svg'
import { ReactComponent as MapLogo } from '../../../ressources/static/svg/Vue_Carte.svg'
import { ReactComponent as ListLogo } from '../../../ressources/static/svg/Vue_Liste.svg'
import { ReactComponent as SoleilLogo } from '../../../ressources/static/svg/Meteo_Soleil.svg'
import DtoManagementUnitRestriction from '../referencials/dto/DtoManagementUnitRestriction'
import DtoDroughtSectorRestriction from '../agri/dto/DtoDroughtSectorRestriction'
import { getSettingInt, sieauTooltip } from '../../../utils/FormUtils'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { FormatColorReset } from '@mui/icons-material'

class MyConsumption extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            pointsLoaded: false,
        }
    }

    componentDidMount() {
        this.props.setTitle([{
            title: i18n.myPoints,
            href: 'consommation',
            icon: (<>
                <MapLogo className='clickable' {...sieauTooltip(i18n.mapView)} fill='grey' style={{ height: 30, paddingRight: 16 }} onClick={() => this.props.push('/pointsMap')} />
                <ListLogo className='clickable' {...sieauTooltip(i18n.listView)} fill='white' style={{ height: 30 }} />
            </>),
        }])
        this.getExploitation()
    }

    getExploitation = () => {
        this.props.fetchManagementUnitsRestrictions()
        this.props.fetchDroughtSectorsRestrictions()
        this.props.fetchExploitation().then(() => {
            const { exploitation, installations } = this.props
            this.props.fetchLastDeclarationByExploitationId(exploitation.idExploitation).then(() => {
                this.setState({ dataLoaded: true })
            })
            if (!installations.length) {
                this.props.fetchInstallationsByExploitationId(exploitation.idExploitation).then(() => {
                    this.setState({ pointsLoaded: true })
                })
            } else {
                this.setState({ pointsLoaded: true })
            }
        })
    }

    getMateriels = (pointId) => {
        const { variousMatSituations, variousMateriels, exploitation } = this.props
        const pointPumps = variousMatSituations.filter((m) => m.siteType === 7 && m.siteCode === pointId) || []
        const pointCounters = variousMatSituations.filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((m) => [...pumpsIds, ...countersIds].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    getLastChronicle = (point) => {
        const { exploitation } = this.props
        const { materiels } = this.getMateriels(point.id)
        const pointChronicles = exploitation.link_chronicles.filter(({ idMat, measureDate }) => materiels.map(({ id }) => id).includes(idMat) &&
            (!point.startDate || measureDate >= point.startDate) &&
            (!point.endDate || measureDate < point.endDate)
        )
        const lastChronicle = pointChronicles.length ? pointChronicles.reduce((acc, chronique) => (acc.measureDate > chronique.measureDate) ? acc : chronique) : null
        if (lastChronicle) {
            const matFound = materiels.find((m) => m.id === lastChronicle.idMat) || {}
            return (
                <>
                    {lastChronicle.measureType === 1 ? (
                        <>
                            <b>{matFound.reference || i18n.unknown}</b>
                            <span>{` - ${formatDate(lastChronicle.measureDate)} -> ${formatDate(lastChronicle.endDate)} : ${formatMilliers(lastChronicle.value) || 0} m3`}</span>
                        </>
                    ) : (
                        <>
                            <b>{matFound.reference || i18n.unknown}</b>
                            <span>{` - ${formatDate(lastChronicle.measureDate)} : ${formatMilliers(lastChronicle.value) || 0}`}</span>
                        </>
                    )}
                </>
            )
        }
        return <span>{i18n.noConsumptionIndicated}</span>
    }

    getPoints = () => {
        const { exploitation, installations, citiesIndex, exploitationVolumes, managementUnitsRestrictions, droughtSectorsRestrictions, waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots, applicationSettings } = this.props
        const pointsUsed = []
        const pointsNotUsed = []
        const pointsDeleted = []
        const year = moment().year()
        exploitation.link_samplings.forEach((linkPoint) => {
            const isUsed = linkPoint.stateCode === 1
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const volumeAuth = exploitationVolumes.filter((v) => v.askedYear === year && v.idInstallation === point.id && hasValue(v.authorizedVolume)).reduce((acc, v) => acc + v.authorizedVolume, 0)
                const volumeConsumed = uniqWith(exploitation.link_conso_real.filter((c) => c.idInstallation === point.id && c.year === moment().year()).map(c => omit(c, 'idInstallation')), (a, b) => a.idMat === b.idMat && a.year === b.year && a.volume === b.volume).reduce((acc, v) => acc + (v.volume || 0), 0)
                const percentLeft = ((volumeAuth - volumeConsumed) / volumeAuth) * 100
                const city = citiesIndex[point.townCode] || {}
                const long = localStorage.getItem('LONG')
                const lat = localStorage.getItem('LAT')
                const { color, label } = getColorAndLabelWaterTurn(waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots, point)
                const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
                const restriction = (() => {
                    if (typeRestriction === 1) {
                        return droughtSectorsRestrictions.find((dsR) => dsR.idSector === point.droughtSector && point.sampleType === dsR.resourceType) || { idRestriction: -1 }
                    }
                    return managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.subManagementCode && point.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.managementCode && point.sampleType === ugeR.resourceType) || { idRestriction: -1 }
                })()
                const waterTurnsRestriction = waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)
                const restrictionLabel = waterTurnsRestriction?.label || i18n.normale
                const restrictionColor = waterTurnsRestriction?.color || '#00AF64'
                const pointFormatted = {
                    ...point,
                    cityName: city.name,
                    distance: point.x && point.y && point.projection && lat && long ? getDistance({
                        projection: 16,
                        x: long,
                        y: lat
                    }, point) : null,
                    footerContent: (
                        <Grid container justifyContent='space-between'>
                            <Grid item xs={2.5} container direction='column' alignItems='flex-start' className={isUsed ? 'padding-left-1' : ''}>
                                {isUsed ? getWaterLeftIcon(round(percentLeft), { height: 30, paddingBottom: 8 }) : <FormatColorReset {...sieauTooltip(i18n.noUsedPoint)} style={{ fontSize: 35, color: 'black' }} />}
                                <b>{volumeAuth ? `${percentLeft <= 0 ? 0 : round(percentLeft)}%` : ''}</b>
                            </Grid>
                            {/* <Grid item xs>{this.getLastChronicle(point)}</Grid> */}
                            <Grid item xs={5} container direction='column' alignItems='flex-start'>
                                {getRestrictionIcon(waterTurnsRestriction?.level, { height: 30 }, restrictionColor)}
                                <b className='padding-top-2' style={{ color: restrictionColor }}>{restrictionLabel}</b>
                            </Grid>
                            <Grid item xs={2} container justifyContent='center'><SoleilLogo {...sieauTooltip(`${i18n.weather}\n${i18n.functionalityToCome}`)} fill='black' style={{ height: 35 }} /></Grid>
                            <Grid item xs={2.5} container direction='column' alignItems='flex-end'>
                                <CycleLogo {...sieauTooltip(`${i18n.waterTurn}\n${label || i18n.noRestriction}`)} fill={color} style={{ height: 35, paddingBottom: 8 }} />
                                <b style={{ color }}>{label}</b>
                            </Grid>
                        </Grid>
                    )
                }
                switch (linkPoint.stateCode) {
                    case 1:
                        pointsUsed.push(pointFormatted)
                        break
                    case 2:
                        pointsNotUsed.push(pointFormatted)
                        break
                    case 3:
                        pointsDeleted.push(pointFormatted)
                        break
                    default:
                        pointsNotUsed.push(pointFormatted)
                }
            }
        })
        return {
            pointsUsed: orderPointsList(pointsUsed),
            pointsNotUsed: orderPointsList(pointsNotUsed),
            pointsDeleted: orderPointsList(pointsDeleted),
        }
    }

    render() {
        const { variousMatSituations } = this.props
        const { pointsLoaded, dataLoaded } = this.state
        if (pointsLoaded && variousMatSituations.length && dataLoaded) {
            const {
                pointsUsed,
                pointsNotUsed,
                pointsDeleted,
            } = this.getPoints()
            return (
                <div style={{ padding: '5 10' }}>
                    <PointsCard
                        pointsUsed={pointsUsed}
                        pointsNotUsed={pointsNotUsed}
                        pointsDeleted={pointsDeleted}
                        onClickPoint={(p) => this.props.push(`/consommation/point/${p.id}`)}
                    />
                </div>
            )
        }
        return (
            <Grid
                container
                alignItems='stretch'
                style={{ backgroundColor: 'white' }}
            >
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            </Grid>
        )
    }
}

MyConsumption.propTypes = {
    setTitle: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    fetchLastDeclarationByExploitationId: PropTypes.func,
    push: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    lastDeclaration: PropTypes.instanceOf(DtoDeclarationWithStats),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    managementUnitsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnitRestriction)),
    droughtSectorsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoDroughtSectorRestriction)),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    fetchManagementUnitsRestrictions: PropTypes.func,
    fetchDroughtSectorsRestrictions: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        codesSandre: store.ReferencialReducer.codesSandre,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        applicationSettings: store.HomeReducer.applicationSettings,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        lastDeclaration: store.AgriReducer.lastDeclaration,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchLastDeclarationByExploitationId: AgriAction.fetchLastDeclarationByExploitationId,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    fetchManagementUnitsRestrictions: ReferencialAction.fetchManagementUnitsRestrictions,
    fetchDroughtSectorsRestrictions: AgriAction.fetchDroughtSectorsRestrictions,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyConsumption)
