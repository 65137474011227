'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_MAT_EVENTS_EXPLOITATION,
    RECEIVE_MAT_EVENTS_TYPE,
} from '../constants/MaterielConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization } from '../../../../../utils/ActionUtils'
import ToastrAction from '../../../components/toasters/ToastrAction'
import LogAction from '../../../../../utils/log/actions/LogAction'
import InstallationsAction from '../../installations/actions/InstallationsAction'

const MaterielAction = {
    receiveMatEventsByExploitation(matEventsExploitation) {
        return { type: RECEIVE_MAT_EVENTS_EXPLOITATION, matEventsExploitation }
    },

    promiseMatEventsByExploitation(exploitationId) {
        return fetch(ApplicationConf.materiel.eventsExploitation(exploitationId), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchMatEventsByExploitation(exploitationId) {
        return (dispatch) => {
            return MaterielAction.promiseMatEventsByExploitation(exploitationId)
                .then((json = []) => {
                    dispatch(MaterielAction.receiveMatEventsByExploitation(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.events} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.events)
                })
        }
    },

    updateMatEvent(event, callback = () => { }) {
        return () => {
            return fetch(ApplicationConf.materiel.event(event.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            })
                .then(() => {
                    ToastrAction.success(i18n.elementUpdateSuccess)
                    callback()
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.event} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.event)
                })
        }
    },

    deleteMatEvent(eventId, callback = () => { }) {
        return () => {
            return fetch(ApplicationConf.materiel.event(eventId), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(() => {
                    ToastrAction.success(i18n.elementDeleteSuccess)
                    callback()
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.deleteError + i18n.event} : ${err}`)
                    ToastrAction.error(i18n.deleteError + i18n.event)
                })
        }
    },

    receiveMatEventsTypes(matEventsTypes) {
        return { type: RECEIVE_MAT_EVENTS_TYPE, matEventsTypes }
    },

    promiseMatEventsTypes() {
        return fetch(ApplicationConf.materiel.eventsTypes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchMatEventsTypes() {
        return (dispatch) => {
            return MaterielAction.promiseMatEventsTypes()
                .then((json = []) => {
                    dispatch(MaterielAction.receiveMatEventsTypes(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.eventsTypes} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.eventsTypes)
                })
        }
    },

    createMatEvent(event) {
        return () => {
            return fetch(ApplicationConf.materiel.events(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    return json.id || null
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.createError + i18n.event} : ${err}`)
                    ToastrAction.error(i18n.createError + i18n.event)
                })
        }
    },

    updateVariousMateriel(variousMateriel) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.variousMateriel(variousMateriel.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(variousMateriel),
            })
                .then(checkAuth)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1) {
                        dispatch(InstallationsAction.fetchAllVariousMateriels())
                        ToastrAction.success(i18n.elementUpdateSuccess)
                        return true
                    }
                    throw new Error()
                })
                .catch(err => {
                    const label = i18n.updateError + i18n.materiel
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },
}

export default MaterielAction
