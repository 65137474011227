/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import { Grid, Icon } from '@mui/material'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { MainButton, RedButton } from '../components/styled/buttons'
import DtoMatEventsType from '../referencials/materiels/dto/DtoMatEventsType'
import { InputRow } from '../components/styled/inputs'
import SimpleSelect from '../components/forms/SimpleSelect'
import ToastrAction from '../components/toasters/ToastrAction'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoMatEvent from '../referencials/materiels/dto/DtoMatEvent'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../components/styled/dialog'
import { ReactComponent as CloseLogo } from '../../../ressources/static/svg/Fermer.svg'
import StyledCard from '../components/cards/StyledCard'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMatType from '../referencials/installations/dto/DtoVariousMatType'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'

const styles = (theme) => ({
    paper: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
        overflowY: 'auto',
    },
})

const emptyEvent = {
    materielType: 'divers',
    idMateriel: null,
    idType: null,
    eventDate: null,
    comment: null,
}

const STEP_MATERIEL = 1
const STEP_EVENT = 2

class ModalMatEvent extends Component {
    constructor(props) {
        super(props)
        const idMateriel = props.materiels.length ? props.materiels[0].id : null
        this.state = {
            step: STEP_MATERIEL,
            event: props.event || { ...emptyEvent, idMateriel, idType: 1 },
        }
    }

    handleChange = (obj) => {
        this.setState(({ event }) => ({ event: { ...event, ...obj } }))
    }

    onCancel = () => {
        this.setState({ event: { ...emptyEvent } })
        this.props.onCancel()
    }

    onDelete = () => {
        const { event } = this.props
        this.setState({ event: { ...emptyEvent } })
        this.props.onDelete(event)
    }

    onValidate = () => {
        const { event } = this.state
        if (event.idMateriel && event.idType && event.eventDate) {
            this.setState({ event: { ...emptyEvent } })
            this.props.onValidate({ ...event, eventDate: new Date(event.eventDate).getTime() })
        } else {
            ToastrAction.warning(i18n.fillAllFields)
        }
    }

    getCard = (color = 'white', title, elements = [], footer) => (
        <StyledCard
            subTitle={title}
            styleSubTitle={{ backgroundColor: color }}
            styleContainer={{ padding: 0, borderTop: '1px solid black' }}
            styleContent={{ padding: 0 }}
        >
            {elements.map((e, i) => (
                <Grid container style={{ padding: 10, borderTop: i === 0 ? '' : '1px solid black', backgroundColor: color }}>
                    <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{e.title}</Grid>
                    <Grid item xs={6} className='bold'>{e.value || '-'}</Grid>
                </Grid>
            ))}
            {footer}
        </StyledCard>
    )

    getPumps = (pumps) => {
        const { variousMatSituations, codesSandre, variousMatTypes } = this.props
        const { event } = this.state
        return pumps.map((pompeMat) => {
            const pompeSituation = variousMatSituations.find((m) => m.siteType === 8 && m.siteCode === pompeMat.id)
            const pompe = pompeMat.pump || {}
            const pompeInfos = {
                ...pompeSituation,
                ...pompeMat,
                ...pompe,
            }
            const pumpNature = variousMatTypes.find((t) => t.materielType === pompeInfos.materielType)
            const pumpCategory = codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === pompeInfos.mobilityCode)
            const pumpType = codesSandre.find((c) => c.field === 'POMPES.TYPE' && c.code === pompeInfos.pumpType)
            const color = event.idMateriel === pompeInfos.id ? '#CCDCE4' : 'white'
            return (
                <Grid
                    item
                    xs={12}
                    md={3}
                    className='clickable padding-top-2'
                    key={pompeInfos.id}
                    onClick={() => {
                        this.handleChange({ idMateriel: pompeInfos.id })
                        this.setState({ step: STEP_EVENT })
                    }}
                >
                    {this.getCard(color, `${i18n.id} : ${pompeInfos.reference || ''}`, [{
                        title: i18n.name,
                        value: pompeInfos?.name,
                    }, {
                        title: i18n.nature,
                        value: pumpNature?.name,
                    }, {
                        title: i18n.categ,
                        value: pumpCategory?.name,
                    }, {
                        title: i18n.type,
                        value: pumpType?.name,
                    }, {
                        title: i18n.debitMax,
                        value: pompeInfos.maxFlow ? `${pompeInfos.maxFlow} m3/h` : '-',
                    }, {
                        title: i18n.debitFonctionnement,
                        value: pompeInfos.operatingFlow ? `${pompeInfos.operatingFlow} m3/h` : '-',
                    }])}
                </Grid>
            )
        })
    }

    getCounters = (counters) => {
        const { variousMatTypes } = this.props
        const { event } = this.state
        return counters.map((matFind) => {
            const counter = matFind.counter || {}
            const compteurInfos = {
                ...matFind,
                ...counter,
            }
            const counterType = variousMatTypes.find((t) => t.materielType === compteurInfos.materielType)
            const color = event.idMateriel === compteurInfos.id ? '#CCDCE4' : 'white'
            return (
                <Grid
                    item
                    xs={12}
                    md={3}
                    className='clickable padding-top-2'
                    key={compteurInfos.id}
                    onClick={() => {
                        this.handleChange({ idMateriel: compteurInfos.id })
                        this.setState({ step: STEP_EVENT })
                    }}
                >
                    {this.getCard(color, `${i18n.id} : ${compteurInfos.reference || ''}`, [{
                        title: i18n.name,
                        value: compteurInfos?.name,
                    }, {
                        title: i18n.type,
                        value: counterType?.name,
                    }, {
                        title: i18n.coefLecture,
                        value: compteurInfos?.readingCoefficient,
                    }, {
                        title: i18n.marque,
                        value: compteurInfos?.brand,
                    }, {
                        title: i18n.poseYear,
                        value: compteurInfos?.installationYear,
                    }, {
                        title: i18n.revisionDate,
                        value: compteurInfos?.revisionDate,
                    }])}
                </Grid>
            )
        })
    }

    render() {
        const { event, step } = this.state
        const { idMateriel, idType, eventDate, comment } = event
        const { open, matEventsTypes, materiels } = this.props

        return (
            <DialogMUI open={open}>
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <b style={{ padding: 10, fontSize: 21 }}>{i18n.reportIncident}</b>
                        <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={this.onCancel} />
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI style={{ paddingTop: 8, paddingBottom: 16 }}>
                    {step === STEP_MATERIEL && (
                        <>
                            <h3>
                                {i18n.selectMaterial}
                            </h3>
                            <Grid container spacing={1} alignItems='stretch'>
                                {[...this.getPumps(materiels.filter((m) => m.pump)), ...this.getCounters(materiels.filter((m) => m.counter))]}
                            </Grid>
                        </>
                    )}
                    {step === STEP_EVENT && (
                        <StyledCard styleContainer={{ padding: 0, borderTop: '1px solid black' }}>
                            <SimpleSelect
                                id='idType'
                                required
                                noNullValue
                                label={i18n.eventType}
                                value={idType}
                                onChange={(v) => this.handleChange({ idType: v })}
                                integerValue
                                options={matEventsTypes}
                                keyValue='id'
                            />
                            <InputRow
                                id='eventDate'
                                label={i18n.date}
                                type='date'
                                required
                                variant='outlined'
                                value={moment(eventDate).format('YYYY-MM-DD')}
                                inputProps={{
                                    max: '9999-12-31'
                                }}
                                onChange={(e) => this.handleChange({ eventDate: e.target.value })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <InputRow
                                item
                                id='comment'
                                type='text'
                                variant='outlined'
                                placeholder={i18n.comment}
                                value={comment}
                                onChange={(e) => this.handleChange({ comment: e.target.value })}
                                multiline
                                rows={10}
                                rowsMax={Infinity}
                                InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                            />
                        </StyledCard>
                    )}
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        {event.id && (
                            <RedButton onClick={this.onDelete}>
                                {i18n.delete}
                            </RedButton>
                        )}
                        <Grid item xs={4}>
                            {step === STEP_EVENT && (
                                <MainButton onClick={() => this.setState({ step: STEP_MATERIEL })}>
                                    <Icon>keyboard_arrow_left</Icon>
                                    {i18n.previous}
                                </MainButton>
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            {step === STEP_MATERIEL ? (
                                <MainButton onClick={() => this.setState({ step: STEP_EVENT })} disabled={!idMateriel}>
                                    {i18n.next}
                                    <Icon>keyboard_arrow_right</Icon>
                                </MainButton>
                            ) : (
                                <MainButton onClick={this.onValidate}>
                                    {i18n.validate}
                                </MainButton>
                            )}
                        </Grid>
                    </Grid>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }
}

ModalMatEvent.propTypes = {
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    event: PropTypes.instanceOf(DtoMatEvent),
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    onValidate: PropTypes.func,
    open: PropTypes.bool,
    materiels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    variousMatTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatType)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        codesSandre: store.ReferencialReducer.codesSandre,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ModalMatEvent))
