module.exports = {
    RECEIVE_ACCOUNT: 'RECEIVE_ACCOUNT',
    RECEIVE_ACCOUNT_HABILITATION: 'RECEIVE_ACCOUNT_HABILITATION',
    RECEIVE_ACCOUNT_USER_KEYFIGURES: 'RECEIVE_ACCOUNT_USER_KEYFIGURES',
    RECEIVE_ACCOUNT_USER_SETTINGS: 'RECEIVE_ACCOUNT_USER_SETTINGS',
    RECEIVE_ACCOUNT_STATISTICS: 'RECEIVE_ACCOUNT_STATISTICS',
    RECEIVE_ACCOUNT_STATION_STATISTICS: 'RECEIVE_ACCOUNT_STATION_STATISTICS',
    RECEIVE_ACCOUNT_USAGE_STATISTICS: 'RECEIVE_ACCOUNT_USAGE_STATISTICS',
    RECEIVE_ACCOUNT_STATIONS: 'RECEIVE_ACCOUNT_STATIONS',
    CCE_TOKEN: 'CCE_TOKEN',
    SAVED_USER: 'SAVED_USER',
    RECEIVE_ACCOUNT_USER_CMS_STATISTICS: 'RECEIVE_ACCOUNT_USER_CMS_STATISTICS',
}
