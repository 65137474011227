import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { compact, orderBy, uniq } from 'lodash'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { sieauTooltip } from '../../../utils/FormUtils'
import AgriAction from '../agri/actions/AgriAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import HomeAction from '../../offline/actions/HomeAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import CityAction from '../referencials/city/actions/CityAction'

import CmsAction from '../cms/actions/CmsAction'
import AccountAction from '../account/actions/AccountAction'
import DtoAccountStatistic from '../account/dto/DtoAccountStatistic'
import { getLogin } from '../../../utils/UserUtils'
// import ModalActualities from '../home/ModalActualities'
import DtoUser from '../account/dto/DtoUser'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import { loadWMSProjections } from '../../../utils/mapUtils/CoordinateUtils'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import DtoCity from '../referencials/city/dto/DtoCity'
import DtoInstallationGeo from '../referencials/installations/dto/DtoInstallationGeo'
import FileAction from '../referencials/documents/actions/FileAction'
import { mainColor, lightColor } from './styled/theme'
import { ReactComponent as ReturnLogo } from '../../../ressources/static/svg/Retour.svg'
import { AGRI, AGRI_RSEAU } from '../agri/constants/AgriConstants'
import DtoAccountHabilitation from '../account/dto/DtoAccountHabilitation'
import Notification from './Notifications'

class NavBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModalActu: false,
            // actuToShow: [],
        }
    }

    componentDidMount() {
        const { cities } = this.props
        this.props.fetchBookmarks()
        this.props.fetchWatersheds()
        this.props.fetchCultures()
        this.props.fetchCulturesFamilies()
        this.props.fetchCodesSandre()
        this.props.fetchMatEventsTypes()
        this.props.fetchTanksTypes()
        this.props.fetchContributors()
        this.props.fetchManagementUnitsRestrictions()
        this.props.fetchDroughtSectorsRestrictions()
        this.props.fetchExploitation().then(() => {
            const { exploitation } = this.props
            if (exploitation.idExploitation) {
                const { idExploitation } = exploitation
                this.props.fetchPictures(exploitation.codification.replaceAll(' ', ''), 10)
                this.props.fetchFiles(exploitation.codification.replaceAll(' ', ''), 10)
                this.props.fetchWaterTurnsExploitation(idExploitation)
                this.props.fetchInstallationsByExploitationId(idExploitation)
                this.props.fetchContributor(exploitation.operatorCode).then(() => {
                })
                if (exploitation?.cities) {
                    this.props.fetchArrests(uniq(exploitation.cities.map((c) => c.slice(0, 2))).toString())
                }
                this.props.fetchExploitationVolumes(idExploitation)
                this.props.fetchMatEventsByExploitation(idExploitation)
            }
        })
        if (!cities.length) {
            this.props.fetchCities()
        }
        this.props.fetchAllVariousMatTypes()
        this.props.fetchSurveys()
        this.props.fetchApplicationSettings().then(() => {
            const { applicationSettings } = this.props
            const applicationName = applicationSettings.find(({ parameter }) => parameter === 'applicationName') || {}
            if (applicationName.value) {
                document.title = applicationName.value
            }
        })
        this.props.fetchCmsByCategory(5)
        this.props.fetchCMSEvents()
        this.props.fetchCMSLogo()
        this.props.fetchAllVariousMateriels()
        this.props.fetchVariousMatSituations()
        this.props.fetchManagementUnits()
        this.props.fetchWaterTurnsRestrictions()
        this.props.fetchWaterTurnsSlots()
        this.props.fetchUserData(getLogin()).then(() => {
            this.props.fetchCmsActualities().then(() => {
                const { accountUser, accountStatistics, actualities } = this.props
                const orderedStats = orderBy(accountStatistics.filter((s) => s.eventType === 'LOGIN'), 'statisticDate', 'desc')
                const lastConnection = orderedStats.length >= 2 ? orderedStats[1] : orderedStats[0]
                const actuNotSeen = !lastConnection ? [...actualities] : compact(actualities.map((c) => {
                    if (lastConnection.statisticDate < c.updateDate) {
                        if (c.status === 1 || accountUser.isAdmin === '1') {
                            return c
                        }
                    }
                    return null
                }))
                const actuToShow = orderBy(actuNotSeen, 'updateDate')
                this.setState({ openModalActu: !!actuToShow.length })
            })
        })
        loadWMSProjections()
        if (this.hasHabilitation(AGRI) && this.hasHabilitation(AGRI_RSEAU)) {
            this.props.fetchRSEauData()
        }
    }

    hasHabilitation = hab => this.props.accountHabilitations.some(h => h.habilitation === hab)

    getTitle = () => {
        const { title, subTitle, icon } = (this.props.title || [])[0] || {}
        if (subTitle) {
            return (
                <Grid container direction='column' style={{ color: 'white' }} alignItems='stretch'>
                    <Grid item style={{ fontSize: 21, borderBottom: '1px solid white', padding: '0 8 4 0' }} container justifyContent='space-between' alignItems='flex-end'>{title}{icon}</Grid>
                    <Grid item style={{ fontSize: 14, paddingTop: 10 }}>{subTitle}</Grid>
                </Grid>
            )
        }
        return (
            <Grid style={{ fontSize: 21, color: 'white' }} container justifyContent='space-between' alignItems='center'>
                <Grid item style={{ fontSize: 21 }}>{title}</Grid>
                <Grid item>{icon}</Grid>
            </Grid>
        )
    }

    back = () => {
        this.props.push(this.props.title[0].href)
        window.scrollTo(0, 0)
    }

    toggleModal = () => {
        this.setState(({ openModalActu }) => ({ openModalActu: !openModalActu }))
    }

    render() {
        // const { openModalActu, actuToShow } = this.state
        const { title } = this.props
        const showBackButton = window.location.href.split('#/')[1].split('/').length !== 1 || (window.location.href.split('#/')[1].includes('enquete'))
        return (
            <>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{
                        position: 'fixed',
                        top: '0',
                        height: '70px',
                        backgroundColor: mainColor,
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1.3rem',
                        zIndex: '1000',
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: '0 0 5px 5px',
                        width: 'calc(100% - 20px)',
                        boxShadow: 'cyan 0px 0px 50px -10px',
                        ...title[0]?.style,
                    }}
                >
                    {showBackButton && (
                        <Grid item xs={1} className='clickable' style={{ paddingRight: '8', color: lightColor }} onClick={this.back}>
                            <ReturnLogo {...sieauTooltip(i18n.back)} alt='return' fill={lightColor} style={{ height: 25 }} />
                        </Grid>
                    )}
                    <Grid item xs={showBackButton ? 11 : 12} style={{ paddingLeft: 8, paddingRight: showBackButton ? 0 : 10 }}>
                        {this.getTitle()}
                    </Grid>
                </Grid>
                {/* {openModalActu &&
                    <ModalActualities
                        open={openModalActu}
                        toggleModal={this.toggleModal}
                        cms={actuToShow}
                    />
                } */}
                <Notification />
            </>
        )
    }
}

NavBar.propTypes = {
    push: PropTypes.func,
    title: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.shape({}))).isRequired,
    fetchBookmarks: PropTypes.func,
    fetchWatersheds: PropTypes.func,
    fetchCultures: PropTypes.func,
    fetchCulturesFamilies: PropTypes.func,
    fetchCodesSandre: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchExploitationVolumes: PropTypes.func,
    fetchApplicationSettings: PropTypes.func,
    fetchCMSLogo: PropTypes.func,
    fetchAllVariousMateriels: PropTypes.func,
    fetchVariousMatSituations: PropTypes.func,
    fetchContributor: PropTypes.func,
    fetchCities: PropTypes.func,
    fetchManagementUnits: PropTypes.func,
    fetchManagementUnitsRestrictions: PropTypes.func,
    fetchDroughtSectorsRestrictions: PropTypes.func,
    fetchSurveys: PropTypes.func,
    fetchAllVariousMatTypes: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    fetchCmsActualities: PropTypes.func,
    fetchUserData: PropTypes.func,
    fetchMatEventsByExploitation: PropTypes.func,
    fetchMatEventsTypes: PropTypes.func,
    fetchWaterTurnsRestrictions: PropTypes.func,
    fetchWaterTurnsExploitation: PropTypes.func,
    fetchWaterTurnsSlots: PropTypes.func,
    fetchTanksTypes: PropTypes.func,
    fetchPictures: PropTypes.func,
    fetchFiles: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchArrests: PropTypes.func,
    fetchCmsByCategory: PropTypes.func,
    fetchCMSEvents: PropTypes.func,
    fetchRSEauData: PropTypes.func,
    contributor: PropTypes.instanceOf(DtoIntervenant),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    accountUser: PropTypes.instanceOf(DtoUser),
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountStatistic)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationGeo)),
    actualities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
}

const mapStateToProps = (store) => ({
    title: store.HomeReducer.title,
    exploitation: store.AgriReducer.exploitation,
    contributor: store.ReferencialReducer.contributor,
    accountUser: store.AccountReducer.accountUser,
    accountStatistics: store.AccountReducer.accountStatistics,
    actualities: store.CmsReducer.actualities,
    applicationSettings: store.HomeReducer.applicationSettings,
    cities: store.CityReducer.cities,
    installations: store.InstallationsReducer.installations,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    fetchBookmarks: ReferencialAction.fetchBookmarks,
    fetchCultures: ReferencialAction.fetchCultures,
    fetchCulturesFamilies: ReferencialAction.fetchCulturesFamilies,
    fetchCodesSandre: ReferencialAction.fetchCodesSandre,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchExploitationVolumes: AgriAction.fetchExploitationVolumes,
    fetchRSEauData: AgriAction.fetchRSEauData,
    fetchArrests: HomeAction.fetchArrests,
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    fetchCMSLogo: HomeAction.fetchCMSLogo,
    fetchCmsByCategory: CmsAction.fetchCmsByCategory,
    fetchAllVariousMateriels: InstallationsAction.fetchAllVariousMateriels,
    fetchAllVariousMatTypes: InstallationsAction.fetchAllVariousMatTypes,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    fetchVariousMatSituations: InstallationsAction.fetchVariousMatSituations,
    fetchContributor: ReferencialAction.fetchContributor,
    fetchCities: CityAction.fetchCities,
    fetchManagementUnits: ReferencialAction.fetchManagementUnits,
    fetchManagementUnitsRestrictions: ReferencialAction.fetchManagementUnitsRestrictions,
    fetchDroughtSectorsRestrictions: AgriAction.fetchDroughtSectorsRestrictions,
    fetchSurveys: AgriAction.fetchSurveys,
    fetchWaterTurnsRestrictions: AgriAction.fetchWaterTurnsRestrictions,
    fetchCmsActualities: CmsAction.fetchCmsActualities,
    fetchUserData: AccountAction.fetchUserData,
    fetchMatEventsByExploitation: MaterielAction.fetchMatEventsByExploitation,
    fetchMatEventsTypes: MaterielAction.fetchMatEventsTypes,
    fetchWaterTurnsExploitation: AgriAction.fetchWaterTurnsExploitation,
    fetchWaterTurnsSlots: AgriAction.fetchWaterTurnsSlots,
    fetchTanksTypes: ReferencialAction.fetchTanksTypes,
    fetchContributors: ReferencialAction.fetchContributors,
    fetchPictures: FileAction.fetchPictures,
    fetchFiles: FileAction.fetchFiles,
    fetchWatersheds: ReferencialAction.fetchWatersheds,
    fetchCMSEvents: CmsAction.fetchCMSEvents,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
