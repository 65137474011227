import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/system'
import { darkColor, mainColor, mainRed, lightColor } from './theme'

const defaultStyle = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    margin: '5px 0',
}

export const MainButton = styled((props) => (
    <Button
        variant='contained'
        sx={{
            height: props.height || 'auto',
            width: props.noFullWidth ? '' : '100%',
            border: props.reverse ? `1px solid ${darkColor}` : 'none',
            color: `${props.reverse || props.disabled ? 'grey' : lightColor} !important`,
            backgroundColor: props.disabled ? '' : `${props.reverse ? 'white' : darkColor} !important`,
            marginTop: props.margintop ? '1rem' : '0',
            minHeight: props.little ? 35 : 45,
            // minWidth: props.little ? 325 : 340,
            fontSize: props.little ? 16 : 18,
            ...defaultStyle,
        }}
        style={{
            borderRadius: 5,
        }}
        {...props}
    />
))({})

export const ColoredButton = styled((props) => (
    <Button
        variant='contained'
        sx={{
            height: props.height || 'auto',
            width: props.noFullWidth ? '' : '100%',
            border: 'none',
            color: 'white !important',
            backgroundColor: `${props.bgColor} !important`,
            marginTop: props.margintop ? '1rem' : '0',
            minHeight: props.little ? 35 : 45,
            // minWidth: props.little ? 325 : 340,
            fontSize: props.little ? 16 : 18,
            ...defaultStyle,
        }}
        style={{
            borderRadius: 5,
        }}
        {...props}
    />
))({})

export const RedButton = styled((props) => (
    <Button
        variant='contained'
        sx={{
            height: props.height || 'auto',
            width: props.noFullWidth ? '' : '100%',
            border: props.reverse ? `1px solid ${mainRed}` : 'none',
            color: `${props.reverse || props.disabled ? 'grey' : 'white'} !important`,
            backgroundColor: props.disabled ? '' : `${props.reverse ? 'white' : mainRed} !important`,
            marginTop: props.margintop ? '1rem' : '0',
            minHeight: props.little ? 35 : 45,
            // minWidth: props.little ? 325 : 340,
            fontSize: props.little ? 16 : 18,
            ...defaultStyle,
        }}
        style={{
            borderRadius: 5,
        }}
        {...props}
    />
))({})

export const OrangeButton = styled((props) => (
    <Button
        variant='contained'
        sx={{
            height: props.height || 'auto',
            width: props.noFullWidth ? '' : '100%',
            border: props.reverse ? `1px solid ${mainColor}` : 'none',
            color: `${props.reverse || props.disabled ? 'grey' : 'white'} !important`,
            backgroundColor: props.disabled ? '' : `${props.reverse ? 'white' : mainColor} !important`,
            marginTop: props.margintop ? '1rem' : '0',
            minHeight: props.little ? 35 : 45,
            // minWidth: props.little ? 325 : 340,
            fontSize: props.little ? 16 : 18,
            ...defaultStyle,
        }}
        style={{
            borderRadius: 5,
        }}
        {...props}
    />
))({})