/* eslint-disable camelcase */
export default class DtoTankDeclaration {
    constructor(object) {
        this.idSurvey = object.idSurvey // Long,
        this.idExploitation = object.idExploitation // Long,
        this.idInstallation = object.idInstallation // Long,
        this.capacity = object.capacity // Option[Double],
        this.recoveryFlow = object.recoveryFlow // Option[Double],
        this.fillingMode = object.fillingMode // Option[Long],
        this.fillingType = object.fillingType // Option[Long],
        this.fillingPeriod = object.fillingPeriod // Option[Long],
        this.comment = object.comment // Option[String],
        this.sharedRetention = object.sharedRetention // Option[Boolean],
        this.usedPercentage = object.usedPercentage // Option[Double],
        this.idTank = object.idTank // Option[Long]
        this.reserveFlow = object.reserveFlow // Option[Double],
        this.tankType = object.tankType // Option[Long]

        this.link_periodes = object.link_periodes || [] // Option[Seq[SampleTankPeriode]]
    }
}
