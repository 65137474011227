import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid, Icon, useMediaQuery } from '@mui/material'
import { FormatColorReset } from '@mui/icons-material'
import { CMS_PATH } from '../../../conf/basepath'
import HomeAction from '../../offline/actions/HomeAction'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import AgriAction from '../agri/actions/AgriAction'
import DtoDeclaration from '../agri/dto/enquete/DtoDeclaration'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import ModalEnquete from './ModalEnquete'
import { getDateAndHour, getDateLetter, getMiniDateLetter } from '../../../utils/DateUtil'
import { omit, orderBy, round, uniqWith } from 'lodash'
import DtoArrest from '../../offline/dto/DtoArrest'
import StyledCard from '../components/cards/StyledCard'
import { ColoredButton, MainButton } from '../components/styled/buttons'
import { ACTU_TO_HIDE, LAST_TIME_HOME } from '../../offline/constants/HomeConstants'
import moment from 'moment'
import PointsCard from '../components/cards/PointsCard'
import { orderPointsList } from '../../../utils/ObjectUtils'
import { getDistance } from '../../../utils/mapUtils/CoordinateUtils'
import { getColorAndLabelWaterTurn, getRestrictionIcon, getWaterLeftIcon } from '../../../utils/AgriUtils'
import { ReactComponent as CycleLogo } from '../../../ressources/static/svg/Meteo_CycleIrrigation.svg'
import { ReactComponent as SoleilLogo } from '../../../ressources/static/svg/Meteo_Soleil.svg'
import { hasValue } from '../../../utils/NumberUtil'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import DtoManagementUnitRestriction from '../referencials/dto/DtoManagementUnitRestriction'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoWaterTurnsSlot from '../agri/dto/DtoWaterTurnsSlot'
import ModalActualities from './ModalActualities'
import { getSettingInt, sieauTooltip } from '../../../utils/FormUtils'
import DtoDroughtSectorRestriction from '../agri/dto/DtoDroughtSectorRestriction'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { RESTRICTION_RESOURCE_TYPE, SAMPLE_TYPES } from '../agri/constants/AgriConstants'
import { getSandreLabel } from '../../../utils/StringUtil'

const Home = ({ }) => {
    const [openModal, setOpenModal] = useState(false)
    const [actuToHide, setActuToHide] = useState(JSON.parse(localStorage.getItem(ACTU_TO_HIDE)) || [])
    const [openModalInfo, setOpenModalInfo] = useState(false)

    const {
        declaration,
        surveys,
        exploitation,
        actualities,
        exploitationWaterTurns,
        citiesIndex,
        exploitationVolumes,
        managementUnitsRestrictions,
        waterTurnsRestrictions,
        installations,
        waterTurnsSlots,
        applicationSettings,
        cmsEvents,
        droughtSectorsRestrictions,
        managementUnits,
        codesSandre,
    } = useSelector(store => ({
        declaration: store.AgriReducer.declaration,
        surveys: store.AgriReducer.surveys,
        exploitation: store.AgriReducer.exploitation,
        actualities: store.CmsReducer.actualities,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
        citiesIndex: store.CityReducer.citiesIndex,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        installations: store.InstallationsReducer.installations,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
        applicationSettings: store.HomeReducer.applicationSettings,
        cmsEvents: store.CmsReducer.cmsEvents,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        managementUnits: store.ReferencialReducer.managementUnits,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)

    const dispatch = useDispatch()

    const navigate = (path) => {
        dispatch(push(path))
        window.scrollTo(0, 0)
    }

    const onNavigateEnquete = () => {
        const updateDeclaration = {
            ...declaration,
            statusCode: declaration.statusCode || 1,
        }
        dispatch(AgriAction.updateDeclaration(updateDeclaration)).then(() => {
            setOpenModal(false)
            navigate('/enquete')
        })
    }

    useEffect(() => {
        dispatch(ReferencialAction.fetchManagementUnitsRestrictions())
        dispatch(AgriAction.fetchDroughtSectorsRestrictions())
        localStorage.setItem(LAST_TIME_HOME, moment().valueOf())
        dispatch(HomeAction.setTitle([
            {
                title: i18n.actualities,
                href: '/home',
                icon: (
                    <Icon
                        {...sieauTooltip(i18n.actualities)}
                        fontSize='large'
                        className='clickable'
                        onClick={() => setOpenModalInfo(true)}
                    >
                        info
                    </Icon>
                ),
            },
        ]))
        if (!exploitation.idExploitation) {
            dispatch(AgriAction.fetchExploitation())
        }
    }, [])

    useEffect(() => {
        if (exploitation.idExploitation) {
            dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then((find) => {
                if (find && !find.statusCode) {
                    setOpenModal(true)
                }
            })
        }
    }, [exploitation])

    const toggleModal = () => {
        const updateDeclaration = {
            ...declaration,
            statusCode: -1,
        }
        dispatch(AgriAction.updateDeclaration(updateDeclaration)).then(() => {
            setOpenModal(!openModal)
        })
    }

    const enquete = useMemo(() => {
        return surveys.find((s) => s.idSurvey === declaration.idSurvey) || {}
    }, [surveys, declaration])

    const openDocument = (document) => {
        window.open(CMS_PATH + document.name, '_system')
    }

    const getArrestsCards = () => {
        const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
        const allPoints = exploitation?.link_samplings?.map((linkPoint) => {
            return installations.find((i) => i.id === linkPoint.idInstallation) || {}
        }) || []

        const restrictions = (() => {
            if (typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS) {
                return droughtSectorsRestrictions.filter((dsR) => allPoints.find((point) => dsR.idSector === point.droughtSector && point.sampleType === dsR.resourceType))
            }
            return managementUnitsRestrictions.filter((ugeR) => allPoints.find((point) => ugeR.managementCode === point.subManagementCode && point.sampleType === ugeR.resourceType)) || managementUnitsRestrictions.filter((ugeR) => allPoints.find((point) => ugeR.managementCode === point.managementCode && point.sampleType === ugeR.resourceType))
        })()

        return restrictions.filter((r) => r.idRestriction !== -1 && !actuToHide.some((rHide) => rHide.idRestriction === r.idRestriction &&
            rHide.resourceType === r.resourceType &&
            (typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS ? (rHide.idSector === r.idSector) : (rHide.managementCode === r.managementCode)) &&
            rHide.date > r.updateDate
        )).map((r) => {
            const resourceLabel = (() => {
                if (typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS) {
                    return getSandreLabel(codesSandre, 'PREL_AGRI.SECTEUR_SECHERESSE', r.idSector)
                }
                return managementUnits.find((m) => m.managementCode === r.managementCode)?.codeWithLabel
            })()
            const waterTurnsRestriction = waterTurnsRestrictions.find((wtR) => wtR.id === r.idRestriction) || {}
            const actuToSave = {
                ...r,
                date: moment().valueOf(),
            }
            return {
                date: r.updateDate,
                content: (
                    <StyledCard
                        title={`${getMiniDateLetter(r.updateDate)} - ${waterTurnsRestriction.label} - ${resourceLabel}`}
                        subTitle={`${waterTurnsRestriction.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial} - ${waterTurnsRestriction.description}`}
                        color={waterTurnsRestriction.color}
                    >
                        {waterTurnsRestriction.comment || ''}
                        <p>{r.exemption || ''}</p>
                        <ColoredButton
                            little
                            bgColor={waterTurnsRestriction.color}
                            onClick={() => {
                                localStorage.setItem(ACTU_TO_HIDE, JSON.stringify([ ...actuToHide, actuToSave ]))
                                setActuToHide([...actuToHide, actuToSave ])
                            }}
                        >
                            {'J\'ai compris'}
                        </ColoredButton>
                    </StyledCard>
                )
            }
        })
    }

    const getActualitiesCards = () => {
        return actualities.filter((a) => a.status === 1).map((a) => ({
            date: a.dateDebut || a.updateDate,
            content: (
                <StyledCard
                    title={getDateLetter(a.dateDebut || a.updateDate)}
                    subTitle={a.title}
                    styleContent={{ paddingTop: 0 }}
                    otherIcon={!!a?.document?.length && 'insert_drive_file'}
                    onClickOtherIcon={() => openDocument(a.document[0])}
                    tooltipContentOtherIcon={i18n.openAttachedDoc}
                >
                    <div dangerouslySetInnerHTML={{ __html: a.comment || '' }} />
                    {a.link && a.link !== '' && (
                        <p
                            className='blue-text clickable'
                            onClick={() => window.open(a.link, '_system')}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {a.link}
                            <Icon>launch</Icon>
                        </p>
                    )}
                </StyledCard>
            )
        }))
    }

    const getPoints = () => {
        const pointsUsed = []
        const pointsNotUsed = []
        const pointsDeleted = []
        const year = moment().year()
        exploitation.link_samplings.forEach((linkPoint) => {
            const isUsed = linkPoint.stateCode === 1
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const volumeAuth = exploitationVolumes.filter((v) => v.askedYear === year && v.idInstallation === point.id && hasValue(v.authorizedVolume)).reduce((acc, v) => acc + v.authorizedVolume, 0)
                const volumeConsumed = uniqWith(exploitation.link_conso_real.filter((c) => c.idInstallation === point.id && c.year === moment().year()).map(c => omit(c, 'idInstallation')), (a, b) => a.idMat === b.idMat && a.year === b.year && a.volume === b.volume).reduce((acc, v) => acc + (v.volume || 0), 0)
                const percentLeft = ((volumeAuth - volumeConsumed) / volumeAuth) * 100
                const city = citiesIndex[point.townCode] || {}
                const long = localStorage.getItem('LONG')
                const lat = localStorage.getItem('LAT')
                const { color, label } = getColorAndLabelWaterTurn(waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots, point)
                const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
                const restriction = (() => {
                    if (typeRestriction === 1) {
                        return droughtSectorsRestrictions.find((dsR) => dsR.idSector === point.droughtSector && point.sampleType === dsR.resourceType) || { idRestriction: -1 }
                    }
                    return managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.subManagementCode && point.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.managementCode && point.sampleType === ugeR.resourceType) || { idRestriction: -1 }
                })()
                const waterTurnsRestriction = waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)
                const restrictionLabel = waterTurnsRestriction?.label || i18n.normale
                const restrictionColor = waterTurnsRestriction?.color || '#00AF64'
                const pointFormatted = {
                    ...point,
                    cityName: city.name,
                    distance: point.x && point.y && point.projection && lat && long ? getDistance({
                        projection: 16,
                        x: long,
                        y: lat
                    }, point) : null,
                    footerContent: (
                        <Grid container justifyContent='space-between'>
                            <Grid item xs={2.5} container direction='column' alignItems='flex-start' className='padding-left-1'>
                                {isUsed ? getWaterLeftIcon(round(percentLeft), { height: 30, paddingBottom: 8 }) : <FormatColorReset {...sieauTooltip(i18n.noUsedPoint)} style={{ fontSize: 35, color: 'black' }} />}
                                <b>{volumeAuth ? `${percentLeft <= 0 ? 0 : round(percentLeft)}%` : ''}</b>
                            </Grid>
                            <Grid item xs={5} container direction='column' alignItems='flex-start'>
                                {getRestrictionIcon(restriction.idRestriction, { height: 30 }, restrictionColor)}
                                <b className='padding-top-2' style={{ color: restrictionColor }}>{restrictionLabel}</b>
                            </Grid>
                            <Grid item xs={2} container justifyContent='center'><SoleilLogo {...sieauTooltip(`${i18n.weather}\n${i18n.functionalityToCome}`)} fill='black' style={{ height: 35 }} /></Grid>
                            <Grid item xs={2.5} container direction='column' alignItems='flex-end'>
                                <CycleLogo {...sieauTooltip(`${i18n.waterTurn}\n${label || i18n.noRestriction}`)} fill={color} style={{ height: 35, paddingBottom: 8 }} />
                                <b style={{ color }}>{label}</b>
                            </Grid>
                        </Grid>
                    )
                }
                switch (linkPoint.stateCode) {
                    case 1:
                        pointsUsed.push(pointFormatted)
                        break
                    case 2:
                        pointsNotUsed.push(pointFormatted)
                        break
                    case 3:
                        pointsDeleted.push(pointFormatted)
                        break
                    default:
                        pointsNotUsed.push(pointFormatted)
                }
            }
        })
        return [
            ...orderPointsList(pointsUsed),
            ...orderPointsList(pointsNotUsed),
            ...orderPointsList(pointsDeleted),
        ]
    }

    const idCmsInfo = useMemo(() => {
        return openModalInfo ? getSettingInt(applicationSettings, 'iryquaCmsInformation') : undefined
    }, [openModalInfo, applicationSettings])

    const cmsInfo = useMemo(() => {
        return idCmsInfo ? cmsEvents.find((c) => c.id === idCmsInfo) : undefined
    }, [idCmsInfo, cmsEvents])

    const lgMatches = useMediaQuery((t) => t.breakpoints.up('lg'))

    return (
        <div style={{ padding: '8px 0', overflow: 'clip' }}>
            {lgMatches && declaration?.idDeclaration && (
                <StyledCard
                    title={`${i18n.enquete} ${enquete.year}`}
                >
                    La déclaration de vos prélèvements d'eau prévisionnels et réels est maintenant
                    disponible. Vous avez jusqu'au {getDateAndHour(enquete.endDate)} pour renseigner votre dossier.
                    <MainButton
                        little
                        onClick={onNavigateEnquete}
                    >
                        {i18n.doMyDeclaration}
                    </MainButton>
                </StyledCard>
            )}
            {exploitation.idExploitation && (
                <div style={{ padding: '0 10 5 10' }}>
                    <PointsCard
                        pointsUsed={getPoints()}
                        pointsNotUsed={[]}
                        pointsDeleted={[]}
                        onClickPoint={(p) => dispatch(push(`/consommation/point/${p.id}`))}
                        onlyFavs
                    />
                </div>
            )}
            {!lgMatches && (
                <div style={{ position: 'sticky', top: 75 }}>
                    {orderBy(getArrestsCards(), 'date', 'desc').map((a) => (a.content))}
                </div>
            )}
            {orderBy(getActualitiesCards(), 'date', 'desc').map((a) => (a.content))}
            {openModal && (
                <ModalEnquete
                    open={openModal}
                    toggleModal={toggleModal}
                    onClick={onNavigateEnquete}
                    enquete={enquete}
                />
            )}
            {openModalInfo && cmsInfo && (
                <ModalActualities
                    open={openModalInfo}
                    toggleModal={() => setOpenModalInfo(!openModalInfo)}
                    cms={[cmsInfo]}
                />
            )}
        </div>
    )
}

Home.propTypes = {
    fetchExploitation: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    updateDeclaration: PropTypes.func,
    setTitle: PropTypes.func,
    push: PropTypes.func,
    cmsLogo: PropTypes.instanceOf(DtoCMSEvent),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    arrests: PropTypes.arrayOf(PropTypes.instanceOf(DtoArrest)),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    surveys: PropTypes.arrayOf(PropTypes.shape({})),
    actualities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    managementUnitsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnitRestriction)),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    droughtSectorsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoDroughtSectorRestriction)),
    fetchManagementUnitsRestrictions: PropTypes.func,
    fetchDroughtSectorsRestrictions: PropTypes.func,
}

export default Home
