import { useState, useEffect } from 'react'
import ToastrAction from './toasters/ToastrAction'
import { getTokenFCM, onMessageListener } from '../../../utils/firebase'
import { isIOS } from 'react-device-detect'

const Notification = () => {
    const [notification, setNotification] = useState({ title: '', body: '' })

    if (isIOS) {
        return ''
    }

    useEffect(() => {
        if (notification?.title) {
            ToastrAction.info(notification.body, null, null, notification.title)
        }
    }, [notification])

    getTokenFCM(false)

    onMessageListener()
        .then((payload) => {
            setNotification({ title: payload?.notification?.title, body: payload?.notification?.body })
        })
        .catch((err) => console.error('failed: ', err))

    return ''
}

export default Notification