/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { omit, round, uniqWith } from 'lodash'
import { Grid, LinearProgress, Icon } from '@mui/material'
import moment from 'moment'
import { MainButton } from '../components/styled/buttons'
import HomeAction from '../../offline/actions/HomeAction'
import AgriAction from '../agri/actions/AgriAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import { formatMilliers } from '../../../utils/StringUtil'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import CmsAction from '../cms/actions/CmsAction'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import { hasValue } from '../../../utils/NumberUtil'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import DtoMatEvent from '../referencials/materiels/dto/DtoMatEvent'
import DtoMatEventsType from '../referencials/materiels/dto/DtoMatEventsType'
import ModalMatEvent from './ModalMatEvent'
import StyledCard from '../components/cards/StyledCard'
import { getRestrictionIcon, getWaterLeftIcon } from '../../../utils/AgriUtils'
import { ReactComponent as EnBaisseLogo } from '../../../ressources/static/svg/EnBaisse.svg'
import { ReactComponent as EnHausseLogo } from '../../../ressources/static/svg/EnHausse.svg'
import { ReactComponent as StableLogo } from '../../../ressources/static/svg/Stable.svg'
import { ReactComponent as NotFavLogo } from '../../../ressources/static/svg/Favori.svg'
import { ReactComponent as FavLogo } from '../../../ressources/static/svg/FavoriPlein.svg'
import { lightColor } from '../components/styled/theme'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import DtoManagementUnitRestriction from '../referencials/dto/DtoManagementUnitRestriction'
import { STATION_TYPE_CONSTANT } from '../referencials/installations/constants/InstallationsConstants'
import DtoAssociatedStation from '../referencials/installations/dto/DtoAssociatedStation'
import DtoMapSituationResult from '../referencials/installations/dto/DtoMapSituationResult'
import DtoMapSituation from '../referencials/installations/dto/DtoMapSituation'
import DtoDroughtSectorRestriction from '../agri/dto/DtoDroughtSectorRestriction'
import { getSettingInt } from '../../../utils/FormUtils'
import { FormatColorReset } from '@mui/icons-material'
import { AGRI, AGRI_RSEAU, SAMPLE_TYPES } from '../agri/constants/AgriConstants'
import ModalNewEntry from './ModalNewEntry'
import DtoAccountHabilitation from '../account/dto/DtoAccountHabilitation'
import RSEauPanel from '../waterManagement/RSEauPanel'

class PointDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            contactsLoaded: false,
            idInstallation: parseInt(props.match.params.id),
            point: {},
            installation: {},
            openModalIndex: false,
            openModalEvent: false,
            chroniclePumpId: null,
            chronicleCounterId: null,
            numberOfLines: 5,
            year: new Date().getFullYear(),
            realConso: null,
            selectedEvent: null,
        }
    }

    componentDidMount() {
        const { mapSituations } = this.props
        this.props.fetchCmsByCategory(5)
        this.props.fetchManagementUnitsRestrictions()
        this.props.fetchDroughtSectorsRestrictions()
        this.props.fetchContacts().then(() => this.setState({ contactsLoaded: true }))
        if (!mapSituations.length) {
            this.props.fetchMapSituations('piezometry').then(() => this.getInstallation())
        } else {
            this.getInstallation()
        }
        window.scrollTo(0, 0)
    }

    getInstallation = () => {
        const { exploitation, mapSituations } = this.props
        const { idInstallation, year } = this.state
        this.props.fetchInstallation(idInstallation).then(() => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => this.fetchVolumes(idInstallation, year))
            } else {
                this.fetchVolumes(idInstallation, year)
            }
            const { installation } = this.props
            this.props.fetchLinkedStations(installation.code, STATION_TYPE_CONSTANT.installation).then(() => {
                const { linkedStations } = this.props
                const piezo = linkedStations.find((s) => s.stationType === STATION_TYPE_CONSTANT.installation)
                const mapId = mapSituations.find((m) => m.default)?.id
                if (piezo && mapId) {
                    this.props.fetchMapSituationsStation('piezometry', mapId, piezo.stationLinkedId)
                }
            })
        })
    }

    fetchVolumes = (idInstallation, year) => {
        const { exploitation } = this.props
        this.props.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf()).then((res) => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => this.setState({ realConso: res?.total, dataLoaded: true }))
            } else {
                this.setState({ realConso: res?.total, dataLoaded: true })
            }
            this.props.fetchBookmarks().then(() => this.setTitle(idInstallation))
        })
    }

    setTitle = (idInstallation) => {
        const { installation, citiesIndex, bookmarks } = this.props
        const fav = bookmarks.find((b) => b.stationType === 7 && b.identifiant === `${idInstallation}`)
        const city = citiesIndex[installation.townCode] || {}
        const bookmark = {
            login: getLogin(),
            identifiant: `${idInstallation}`,
            stationType: 7,
        }
        this.props.setTitle([{
            title: installation.name || (installation.code ? `${i18n.point} ${installation.code || ''}` : ''),
            subTitle: `${city.name}${installation.location ? ` - ${installation.location || ''}` : ''}`,
            href: '/consommation',
            icon: fav ? <FavLogo style={{ height: 20, paddingBottom: 4 }} fill='white' onClick={() => this.deleteBookmark(fav)} /> : <NotFavLogo fill={lightColor} style={{ height: 20, paddingBottom: 4 }} onClick={() => this.createBookmark(bookmark)} />,
        }])
    }

    deleteBookmark = (fav) => {
        this.props.deleteBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
        })
    }

    createBookmark = (fav) => {
        this.props.createBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
        })
    }

    getMateriels = () => {
        const { variousMateriels, exploitation } = this.props
        const { idInstallation } = this.state
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === 7 && m.siteCode === idInstallation) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((c) => [...pumpsIds, ...countersIds].includes(c.id) && (!hasValue(c.administrator) || c.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    editModalIndex = (type, id) => {
        this.setState({ selectedMaterial: id, consoType: type, openModalIndex: true })
    }

    onCancelIndex = () => {
        this.setState({
            selectedMaterial: null,
            consoType: null,
            openModalIndex: false,
        })
    }

    onSaveChronicles = (link_chronicles) => {
        const { exploitation } = this.props
        const updateExploitation = {
            ...exploitation,
            link_chronicles,
        }
        this.setState({
            selectedMaterial: null,
            consoType: null,
            openModalIndex: false,
            dataLoaded: false,
        })
        this.props.updateExploitation(updateExploitation).then(() => {
            this.props.fetchExploitation().then(() => this.setState({ dataLoaded: true }))
        })
    }

    showMore = () => {
        this.setState(({ numberOfLines }) => ({
            numberOfLines: numberOfLines + 5,
        }))
    }

    onDeleteEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, openModalEvent: false, selectedEvent: null })
        this.props.deleteMatEvent(event.id, () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)).then(() => {
            this.setState({ dataLoaded: true })
        })
    }

    onValidateEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, openModalEvent: false, selectedEvent: null })
        if (event.id) {
            this.props.updateMatEvent(event,
                () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)
            ).then(() => {
                this.setState({ dataLoaded: true })
            })
        } else {
            this.props.createMatEvent(event).then(() => {
                this.props.fetchMatEventsByExploitation(exploitation.idExploitation).then(() => {
                    this.setState({ dataLoaded: true })
                })
            })
        }
    }

    getIPSLabel = (level) => {
        switch (level) {
            case 'up':
                return i18n.improvement
            case 'right':
                return i18n.stagnation
            case 'down':
                return i18n.degradation
            default:
                return i18n.unknown
        }
    }

    getIPSIcon = (level, color) => {
        switch (level) {
            case 'up':
                return <EnHausseLogo fill={color} style={{ height: 60 }} />
            case 'right':
                return <StableLogo fill={color} style={{ height: 60 }} />
            case 'down':
                return <EnBaisseLogo fill={color} style={{ height: 60 }} />
            default:
                return ''
        }
    }

    hasHabilitation = hab => this.props.accountHabilitations.some(h => h.habilitation === hab)

    render() {
        const {
            openModalIndex,
            openModalEvent,
            dataLoaded, contactsLoaded,
            selectedEvent,
            year, idInstallation,
        } = this.state
        const { variousMateriels, variousMatSituations, exploitation, exploitationVolumes, installation, watersheds, managementUnitsRestrictions, droughtSectorsRestrictions, applicationSettings, waterTurnsRestrictions, mapSituationStation } = this.props
        if (variousMatSituations.length && variousMateriels.length && dataLoaded && contactsLoaded && exploitation.idExploitation) {
            const { materiels } = this.getMateriels()
            const linkPoint = exploitation.link_samplings.find((p) => p.idInstallation === idInstallation)
            // const exploitationMatIds = materiels.map(({ id }) => id)
            const volumeAuth = (exploitationVolumes || []).filter((v) => v.askedYear === year && v.idInstallation === idInstallation).reduce((acc, v) => acc + (v.authorizedVolume || 0), 0)
            const volumeConsumed = uniqWith((exploitation.link_conso_real || []).filter((c) => c.idInstallation === idInstallation && c.year === moment().year()).map(c => omit(c, 'idInstallation')), (a, b) => a.idMat === b.idMat && a.year === b.year && a.volume === b.volume).reduce((acc, v) => acc + (v.volume || 0), 0)
            const volumeLeft = (volumeAuth - volumeConsumed)
            const percentLeft = (volumeLeft / volumeAuth) * 100
            const sampleCharacteristics = installation?.link_sampleCharacteristics[0] || {}
            const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
            const restriction = (() => {
                if (typeRestriction === 1) {
                    return droughtSectorsRestrictions.find((dsR) => dsR.idSector === sampleCharacteristics.droughtSector && sampleCharacteristics.sampleType === dsR.resourceType) || { idRestriction: -1 }
                }
                return managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.subManagementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.managementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || { idRestriction: -1 }
            })()
            const waterTurnsRestriction = waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)
            const restrictionLabel = waterTurnsRestriction?.label || i18n.normale
            const restrictionColor = waterTurnsRestriction?.color || '#00AF64'
            const ipsColor = (mapSituationStation || [])[0]?.trendColor
            const ipsLabel = (mapSituationStation || [])[0]?.trendLabel
            const sampleTypeLabel = sampleCharacteristics?.sampleType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial

            return (
                <>
                    <Grid container alignItems='center' justifyContent='space-around'>
                        <Grid item xs={12} style={{ padding: 10 }}>
                            <StyledCard
                                styleContainer={{ padding: 0, borderTop: '1px solid black' }}
                                styleContent={{ padding: '8 0 8 0' }}
                            >
                                <Grid container className='bold' justifyContent='space-between'>
                                    <Grid item xs={8.5} style={{ borderBottom: '1px solid black' }} className='padding-bottom-2 padding-top-1 padding-left-1 '>Ressource prélevée</Grid>
                                    <Grid item xs={3} className='padding-bottom-2 padding-top-1' style={{ borderBottom: `1px solid ${ipsColor || 'black'}`, color: ipsColor }}>{i18n.level}</Grid>
                                    <Grid item xs={8.5} className='padding-bottom-2 padding-top-2  padding-left-1 ' style={{ fontSize: 21 }}>{watersheds.find((w) => w.id === sampleCharacteristics.bvCode)?.name || sampleTypeLabel}</Grid>
                                    <Grid item xs={3} className='padding-bottom-2 padding-top-2' style={{ color: ipsColor }} container direction='column' alignItems='flex-start'>
                                        {this.getIPSIcon(ipsLabel, ipsColor)}
                                        <span className='padding-top-2'>{this.getIPSLabel(ipsLabel)}</span>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent='space-between'
                                    className='bold padding-top-1 clickable'
                                    style={{ borderTop: '1px solid black' }}
                                    onClick={() => this.props.push(`/consommation/point/${idInstallation}/restriction`)}
                                >
                                    <Grid item xs={8.5} style={{ borderBottom: '1px solid black' }} className='padding-bottom-2 padding-top-1 padding-left-1 '>Possibilités de prélèvements</Grid>
                                    <Grid item xs={3} className='padding-bottom-2 padding-top-1' style={{ borderBottom: `1px solid ${restrictionColor}`, color: restrictionColor }}>Restriction</Grid>
                                    <Grid item xs={8.5} className='padding-bottom-2 padding-top-2 padding-left-1 ' style={{ fontSize: 21 }}>{(waterTurnsRestriction?.description || '') + (restriction?.exemption ? ' *' : '') || ''}</Grid>
                                    <Grid item xs={3} className='padding-top-2' style={{ color: restrictionColor }} container direction='column' alignItems='flex-start'>
                                        {getRestrictionIcon(waterTurnsRestriction?.level, { height: 50 }, restrictionColor)}
                                        <span className='padding-top-2'>{restrictionLabel}</span>
                                    </Grid>
                                </Grid>
                                {restriction.exemption && (
                                    <Grid container justifyContent='space-between' className='bold margin-top-1' style={{ color: 'grey' }}>
                                        <Grid item xs={12} style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }} className='padding-2'>{`* ${i18n.exemption}`}</Grid>
                                        <Grid item xs={12} className='padding-bottom-1 padding-top-2 padding-left-1  padding-right-1'>{restriction.exemption}</Grid>
                                    </Grid>
                                )}
                            </StyledCard>
                        </Grid>
                        <Grid item xs={12} style={{ padding: 10 }}>
                            <StyledCard
                                className='clickable'
                                styleContainer={{ padding: 0, borderTop: '1px solid black' }}
                                styleContent={{ padding: '8 0' }}
                                onClick={() => this.props.push(`/consommation/point/${idInstallation}/details`)}
                            >
                                <Grid container justifyContent='space-between'>
                                    <Grid item xs={8.5} container className='bold' justifyContent='space-between'>
                                        <Grid item xs={8} className='padding-bottom-2 padding-top-1 padding-left-1' style={{ borderBottom: '1px solid black' }}>Volume restant</Grid>
                                        <Grid item xs={3} className='padding-bottom-2 padding-top-1' style={{ borderBottom: '1px solid black' }}>Restant</Grid>
                                        <Grid item xs={8} className='padding-bottom-2 padding-top-2 padding-left-1' style={{ fontSize: 21 }}>{`${volumeLeft <= 0 ? 0 : formatMilliers(volumeLeft)} m3`}</Grid>
                                        <Grid item xs={3} className='padding-bottom-2 padding-top-2' style={{ fontSize: 21 }}>{volumeAuth ? `${percentLeft <= 0 ? 0 : round(percentLeft)}%` : ''}</Grid>
                                    </Grid>
                                    <Grid item xs={3} container alignItems='center' justifyContent='center'>
                                        {linkPoint?.stateCode === 1 ? getWaterLeftIcon(round(percentLeft), { height: 60 }) : <FormatColorReset style={{ fontSize: 65, color: 'black' }}/>}
                                    </Grid>
                                </Grid>
                            </StyledCard>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '0 10 5 10' }}>
                            <MainButton onClick={() => this.toggleState('openModalIndex')} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Icon>warning</Icon>
                                {i18n.enterIndex}
                                <Icon>warning</Icon>
                            </MainButton>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '0 10 10 10' }}>
                            <MainButton onClick={() => this.toggleState('openModalEvent')}>
                                {i18n.reportIncident}
                            </MainButton>
                        </Grid>
                        {this.hasHabilitation(AGRI) && this.hasHabilitation(AGRI_RSEAU) && (
                            <RSEauPanel idInstallation={idInstallation} />
                        )}
                    </Grid>
                    {openModalIndex && (
                        <ModalNewEntry
                            open={openModalIndex}
                            onClose={() => this.toggleState('openModalIndex')}
                        />
                    )}
                    {openModalEvent && (
                        <ModalMatEvent
                            open={openModalEvent}
                            event={selectedEvent}
                            materiels={materiels}
                            onCancel={() => this.setState({ openModalEvent: false, selectedEvent: null })}
                            onDelete={this.onDeleteEvent}
                            onValidate={this.onValidateEvent}
                        />
                    )}
                </>
            )
        }
        return (
            <Grid container alignItems='center' justifyContent='space-around'>
                <Grid item xs={12} style={{ margin: '16px 0' }}>
                    <LinearProgress />
                </Grid>
            </Grid>
        )
    }
}

PointDashboard.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
    setTitle: PropTypes.func,
    push: PropTypes.func,
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    fetchInstallation: PropTypes.func,
    fetchExploitation: PropTypes.func,
    updateExploitation: PropTypes.func,
    fetchCmsByCategory: PropTypes.func,
    createMatEvent: PropTypes.func,
    fetchMatEventsByExploitation: PropTypes.func,
    calculPointVolumeReal: PropTypes.func,
    deleteMatEvent: PropTypes.func,
    updateMatEvent: PropTypes.func,
    resetInstallation: PropTypes.func,
    fetchContacts: PropTypes.func,
    deleteBookmark: PropTypes.func,
    createBookmark: PropTypes.func,
    fetchBookmarks: PropTypes.func,
    fetchMapSituationsStation: PropTypes.func,
    fetchLinkedStations: PropTypes.func,
    fetchMapSituations: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    mapSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoMapSituation)),
    linkedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAssociatedStation)),
    mapSituationStation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMapSituationResult)),
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    matEventsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEvent)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
    bookmarks: PropTypes.arrayOf(PropTypes.shape({})),
    watersheds: PropTypes.arrayOf(PropTypes.shape({})),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    managementUnitsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnitRestriction)),
    droughtSectorsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoDroughtSectorRestriction)),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    fetchManagementUnitsRestrictions: PropTypes.func,
    fetchDroughtSectorsRestrictions: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        installations: store.InstallationsReducer.installations,
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        mapSituations: store.InstallationsReducer.mapSituations,
        linkedStations: store.InstallationsReducer.linkedStations,
        mapSituationStation: store.InstallationsReducer.mapSituationStation,
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        cms: store.CmsReducer.cms,
        matEventsExploitation: store.MaterielReducer.matEventsExploitation,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
        bookmarks: store.ReferencialReducer.bookmarks,
        watersheds: store.ReferencialReducer.watersheds,
        citiesIndex: store.CityReducer.citiesIndex,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        applicationSettings: store.HomeReducer.applicationSettings,
        accountHabilitations: store.AccountReducer.accountHabilitations,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    updateExploitation: AgriAction.updateExploitation,
    fetchModesIrrigations: AgriAction.fetchModesIrrigations,
    fetchInstallation: InstallationsAction.fetchInstallation,
    fetchMapSituations: InstallationsAction.fetchMapSituations,
    fetchMapSituationsStation: InstallationsAction.fetchMapSituationsStation,
    fetchLinkedStations: InstallationsAction.fetchLinkedStations,
    resetInstallation: InstallationsAction.resetInstallation,
    setTitle: HomeAction.setTitle,
    fetchCmsByCategory: CmsAction.fetchCmsByCategory,
    createMatEvent: MaterielAction.createMatEvent,
    updateMatEvent: MaterielAction.updateMatEvent,
    deleteMatEvent: MaterielAction.deleteMatEvent,
    fetchMatEventsByExploitation: MaterielAction.fetchMatEventsByExploitation,
    calculPointVolumeReal: AgriAction.calculPointVolumeReal,
    fetchContacts: ContactAction.fetchContacts,
    deleteBookmark: ReferencialAction.deleteBookmark,
    createBookmark: ReferencialAction.createBookmark,
    fetchBookmarks: ReferencialAction.fetchBookmarks,
    fetchManagementUnitsRestrictions: ReferencialAction.fetchManagementUnitsRestrictions,
    fetchDroughtSectorsRestrictions: AgriAction.fetchDroughtSectorsRestrictions,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointDashboard)
