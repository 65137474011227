import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { MainButton } from '../components/styled/buttons'
import DtoEnquete from '../agri/dto/enquete/DtoEnquete'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from '../components/styled/dialog'
import { Dialog } from '@mui/material'
import { ReactComponent as CloseLogo } from '../../../ressources/static/svg/Fermer.svg'

const ModalEnquete = ({ open, toggleModal, onClick, enquete = {} }) => (
    <Dialog open={open}>
        <DialogTitleMUI>
            <b style={{ padding: 10, fontSize: 21 }}>{`${i18n.enquete} ${enquete.year}`}</b>
            <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={toggleModal} />
        </DialogTitleMUI>
        <DialogContentMUI>
                La déclaration de vos prélèvements d'eau prévisionnels et réels est maintenant
                disponible. Vous avez jusqu'au {new Date(enquete.endDate).toLocaleString()} pour renseigner votre dossier.
            <p id='modal-description' className='italic'>Pour tout renseignement, veuillez contacter votre administrateur.</p>
        </DialogContentMUI>
        <DialogActionsMUI>
            <MainButton reverse='true' onClick={toggleModal} margintop>
                {i18n.later}
            </MainButton>
            <MainButton onClick={onClick}>
                {i18n.start}
            </MainButton>
        </DialogActionsMUI>
    </Dialog>
)

ModalEnquete.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    enquete: PropTypes.instanceOf(DtoEnquete),
}

export default ModalEnquete