/* eslint-disable indent */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { compact, uniqBy } from 'lodash'
import { Grid, LinearProgress } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import LocalizationCard from '../components/cards/LocalizationCard'
import EquipmentCard from '../components/cards/EquipmentCard'
import ComptageCard from '../components/cards/ComptageCard'
import AssociatedResourceCard from '../components/cards/AssociatedResourceCard'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import DtoDeclaration from '../agri/dto/enquete/DtoDeclaration'
import OuvrageCard from '../components/cards/OuvrageCard'
import RetenueCard from '../components/cards/RetenueCard'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import Accordeon from '../components/Accordeon'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import ContactAction from '../contact/actions/ContactAction'
import DtoContact from '../referencials/dto/DtoContact'
import ContactCard from '../components/cards/ContactCard'
import ToastrAction from '../components/toasters/ToastrAction'
import AgriAction from '../agri/actions/AgriAction'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import { pointStatus } from '../referencials/installations/constants/InstallationsConstants'
import { Item } from '../components/styled/grid'
import ModalEditPoint from '../points/ModalEditPoint'
import LittleMap from '../points/LittleMap'
import { hasValue } from '../../../utils/NumberUtil'
import ModalEditCounter from '../referencials/materiels/components/ModalEditCounter'
import ModalEditPump from '../referencials/materiels/components/ModalEditPump'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import StyledCard from '../components/cards/StyledCard'
import { mainColor } from '../components/styled/theme'
import { ReactComponent as PointLogo } from '../../../ressources/static/svg/Vue_Carte.svg'
import { ReactComponent as EditLogo } from '../../../ressources/static/svg/Editer.svg'
import { sieauTooltip } from '../../../utils/FormUtils'

class PointDetailsExploit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            point: props.installation || {},
            pointLoaded: false,
            owner: new DtoIntervenant({}),
            editPoint: false,
        }
    }

    componentDidMount() {
        const { match } = this.props
        this.props.fetchInstallation(match.params.id).then(() => {
            this.setInstallation()
        })
    }

    componentWillUnmount() {
        this.props.resetInstallation()
    }

    setInstallation = () => {
        const { installation, contacts, exploitation, contributors } = this.props
        if (!contacts.length) {
            this.props.fetchContacts().then(() => {
                this.setContributors(installation)
            })
        } else {
            this.setContributors(installation)
        }
        const preleveur = contributors.find((c) => c.id === exploitation.operatorCode)
        this.props.setTitle([
            {
                title: preleveur?.name,
                subTitle: exploitation.codification,
                href: '/account',
            },
        ])
        window.scrollTo(0, 0)
    }

    setContributors = (point) => {
        const { exploitation, contacts, declaration } = this.props
        if (!declaration || declaration.idExploitation !== exploitation.idExploitation) {
            this.props.fetchDeclarationByExploitationId(exploitation.idExploitation).then((find) => {
                if (find) {
                    ToastrAction.info(i18n.modifSurveyNotShow)
                }
            })
        }
        const linkPoint = exploitation.link_samplings ? exploitation.link_samplings.find((p) => p.idInstallation === point.id) : null
        const owner = contacts.find((c) => c.id === point.ownerCode)
        this.setState({ point, linkPoint, pointLoaded: true, owner: owner || new DtoIntervenant({}) })
    }

    getAssociatedResources = () => {
        const {
            point,
            linkPoint,
        } = this.state
        let associatedResources = linkPoint ? linkPoint?.link_samples[0] : {}
        associatedResources = {
            ...associatedResources,
            managementCode: point.managementCode,
            subManagementCode: point.subManagementCode,
        }
        return associatedResources
    }

    getTypePrel = (key) => {
        switch (key) {
            case 1:
                return 'PREL_AGRI.NATURE_PRELEVEMENT_ESO'
            case 2:
                return 'PREL_AGRI.NATURE_PRELEVEMENT_ESU'
            default:
                return 'PREL_AGRI.NATURE_PRELEVEMENT'
        }
    }

    getNature = () => {
        const associatedResources = this.getAssociatedResources()
        if (associatedResources) {
            const { codesSandre } = this.props
            const codeType = this.getTypePrel(associatedResources.sampleType)
            const codeFind = codesSandre.find((c) => c.field === codeType && c.code === associatedResources.sampleNature)
            return codeFind ? codeFind.name : ''
        }
        return ''
    }

    getStatus = () => {
        const { linkPoint } = this.state
        if (linkPoint) {
            const status = pointStatus.find(({ code }) => code === linkPoint.stateCode) || {}
            return status.label || ''
        }
        return ''
    }

    getOuvrages = () => {
        const { linkPoint } = this.state
        if (linkPoint?.link_sampleCasings?.length) {
            return (
                linkPoint.link_sampleCasings.map((ouvrage) => (
                    <Item xs={12} md={4}>
                        <OuvrageCard
                            title={i18n.struct}
                            ouvrage={ouvrage}
                            newStyle
                            hideIcon
                        />
                    </Item>
                ))
            )
        }
        return ''
    }

    getRetenues = () => {
        const { linkPoint } = this.state
        const length = linkPoint?.link_sampleTanks?.length
        const title = length > 1 ? `${i18n.waterRetentions} (${length} ${i18n.elements})` : `${i18n.waterRetention} (${length} ${i18n.element})`
        if (length) {
            return (
                <Item xs={12} style={{ padding: '5 10' }}>
                    <Accordeon
                        noShadow
                        title={title}
                        headStyle={{ backgroundColor: 'white', border: 0, borderTop: '1px solid black', borderBottom: '1px solid black' }}
                    >
                        <Grid container>
                            {linkPoint.link_sampleTanks.map((retenue) => (
                                <Item xs={12} md={4}>
                                    <RetenueCard
                                        title={`${i18n.id} ${retenue?.id}`}
                                        retenue={retenue}
                                        hideIcon
                                        newStyle
                                    />
                                </Item>
                            ))}
                        </Grid>
                    </Accordeon>
                </Item>
            )
        }
        return ''
    }

    getEquipments = () => {
        const { point, editPump, editCounter } = this.state
        const { variousMatSituations, variousMateriels, exploitation } = this.props
        const data = uniqBy(variousMatSituations.filter((m) => m.siteType === 7 && m.siteCode === point.id), v => [v.idVarious, v.siteCode, v.siteCode, v.siteType, v.statusCode].join())
        const length = data.length
        const title = length > 1 ? `${i18n.materiels} (${length} ${i18n.elements})` : `${i18n.materiel} (${length} ${i18n.element})`
        if (length && variousMatSituations.length && variousMateriels.length) {
            return (
                <Item xs={12} style={{ padding: '5 10' }}>
                    <Accordeon
                        noShadow
                        title={title}
                        headStyle={{ backgroundColor: 'white', border: 0, borderTop: '1px solid black', borderBottom: '1px solid black' }}
                    >
                        <Grid container>
                            {compact(data.map((d) => {
                                const pompeSituation = variousMatSituations.find((m) => m.siteType === 8 && m.siteCode === d.idVarious)
                                const pompeMat = variousMateriels.find((m) => m.id === d.idVarious)
                                const pumpRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 1 && m.idElement1 === parseInt(point.id) && m.idElement2 === d.idVarious) || {}
                                if (pompeSituation && pompeMat) {
                                    const pompeInfos = {
                                        ...pompeSituation,
                                        ...pompeMat,
                                        ...pompeMat.pump,
                                        assignmentRate: pumpRep.repartition,
                                    }
                                    const compteur = variousMateriels.find((c) => c.id === pompeSituation.idVarious && (!hasValue(c.administrator) || c.administrator === exploitation.operatorCode))
                                    const counterRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 2 && m.idElement1 === d.idVarious && m.idElement2 === pompeSituation.id) || {}
                                    const compteurInfos = {
                                        ...compteur,
                                        ...compteur?.counter,
                                        assignmentRate: counterRep?.repartition,
                                    }
                                    return (
                                        <>
                                            <Item xs={12} md={4}>
                                                <EquipmentCard
                                                    title={`${i18n.id} : ${pompeInfos?.siteName}`}
                                                    data={pompeInfos}
                                                    toggleEditMode={() => this.setState({ editPump: !editPump, pump: pompeInfos })}
                                                    newStyle
                                                />
                                            </Item>
                                            {compteur && (
                                                <Item xs={12} md={4}>
                                                    <ComptageCard
                                                        title={`${i18n.id} : ${compteurInfos?.reference}`}
                                                        data={compteurInfos}
                                                        toggleEditMode={() => this.setState({ editCounter: !editCounter, counter: compteurInfos })}
                                                        newStyle
                                                    />
                                                </Item>
                                            )}
                                        </>
                                    )
                                }
                                return null
                            }))}
                        </Grid>
                    </Accordeon>
                </Item>
            )
        }
        return ''
    }

    onChangePoint = (obj) => {
        this.setState(({ point }) => ({ point: { ...point, ...obj } }))
    }

    onSavePoint = () => {
        const { point } = this.state
        const { match } = this.props
        this.props.updateInstallation(point).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.setInstallation()
                this.setState({ editPoint: false })
            })
        })
    }

    getModalEditPoint = () => {
        const { point, editPoint, linkPoint } = this.state
        if (editPoint) {
            return (
                <ModalEditPoint
                    open={editPoint}
                    toggleModal={() => this.setState({ editPoint: false })}
                    onChange={this.onChangePoint}
                    onSave={this.onSavePoint}
                    point={point}
                    nature={this.getNature()}
                    stateCode={linkPoint.stateCode}
                />
            )
        }
        return null
    }

    onChangePump = (obj) => {
        this.setState(({ pump }) => ({ pump: { ...pump, ...obj } }))
    }

    onSavePump = () => {
        const { pump } = this.state
        const { match } = this.props
        this.props.updateVariousMateriel(pump).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.setInstallation()
                this.setState({ editPump: false, pump: null })
            })
        })
    }

    getModalEditPump = () => {
        const { pump, editPump } = this.state
        if (editPump) {
            return (
                <ModalEditPump
                    open={editPump}
                    toggleModal={() => this.setState({ editPump: false, pump: null })}
                    onChange={this.onChangePump}
                    onSave={this.onSavePump}
                    pump={pump}
                />
            )
        }
        return null
    }

    onChangeCounter = (obj) => {
        this.setState(({ counter }) => ({ counter: { ...counter, ...obj } }))
    }

    onSaveCounter = () => {
        const { counter } = this.state
        const { match } = this.props
        this.props.updateVariousMateriel(counter).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.setInstallation()
                this.setState({ editCounter: false, counter: null })
            })
        })
    }

    getModalEditCounter = () => {
        const { counter, editCounter } = this.state
        if (editCounter) {
            return (
                <ModalEditCounter
                    open={editCounter}
                    toggleModal={() => this.setState({ editCounter: false, counter: null })}
                    onChange={this.onChangeCounter}
                    onSave={this.onSaveCounter}
                    counter={counter}
                />
            )
        }
        return null
    }

    render() {
        const {
            owner,
            point,
            linkPoint,
            pointLoaded,
            editPoint,
        } = this.state

        return (
            <Grid container alignItems='stretch' justifyContent='flex-start' className='padding-top-2'>
                {pointLoaded ? (
                    <>
                        <Grid item xs={12} container direction='column' style={{ margin: '0 10 5 10', padding: 14, backgroundColor: mainColor, color: 'white' }} alignItems='stretch' className='bold'>
                            <Grid item style={{ fontSize: 21, borderBottom: '1px solid white', paddingBottom: 7 }} container justifyContent='space-between' alignItems='center'>
                                {point?.location}
                                <PointLogo fill='white' style={{ height: 30 }} />
                            </Grid>
                            <Grid item style={{ fontSize: 14, paddingTop: 10 }}>{`${i18n.point} ${point?.code}`}</Grid>
                        </Grid>
                        <Item xs={12} md={4}>
                            <StyledCard
                                subTitle={(
                                    <Grid container justifyContent='space-between' alignItems='center'>
                                        {i18n.descriptive}
                                        <EditLogo {...sieauTooltip(i18n.edit)} fill='black' className='clickable' style={{ height: 30 }} onClick={() => this.setState({ editPoint: !editPoint })} />
                                    </Grid>
                                )}
                                styleSubTitle={{ borderTop: '1px solid black' }}
                                styleContainer={{ padding: '5 10' }}
                                styleContent={{ padding: 0 }}
                            >
                                <Grid container style={{ padding: 10 }}>
                                    <Item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.name}</Item>
                                    <Item xs={6} className='bold'>{point?.name || '-'}</Item>
                                </Grid>
                                <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                                    <Item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.code}</Item>
                                    <Item xs={6} className='bold'>{point?.code || '-'}</Item>
                                </Grid>
                                <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                                    <Item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.naturePrel}</Item>
                                    <Item xs={6} className='bold'>{this.getNature()}</Item>
                                </Grid>
                                <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                                    <Item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.status}</Item>
                                    <Item xs={6} className='bold'>{this.getStatus()}</Item>
                                </Grid>
                                <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                                    <Item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.descriptive}</Item>
                                    <Item xs={6} className='bold'>{point?.descriptive || '-'}</Item>
                                </Grid>
                                <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                                    <Item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.comment}</Item>
                                    <Item xs={6} className='bold'>{point?.comments || '-'}</Item>
                                </Grid>
                            </StyledCard>
                        </Item>
                        <Item xs={12} md={4}>
                            <LocalizationCard
                                data={point}
                                title={i18n.localisation}
                                styleContainer={{ height: 'calc(100% - 12px)' }}
                            />
                        </Item>
                        <Item xs={12} md={4} style={{ padding: '5 10' }}>
                            <LittleMap
                                point={point}
                                stateCode={linkPoint?.stateCode}
                                height={isLargeWindow() ? 'auto' : 200}
                                styleContainer={{ padding: 0, height: 'calc(100% - 2px)' }}
                                style={{ height: '100%', borderTop: '1px solid black', borderBottom: '1px solid black' }}
                            />
                        </Item>
                        <Item xs={12} md={4}>
                            <ContactCard
                                contact={owner}
                                subTitle={i18n.ownerOfPlot}
                                styleContainer={{ height: 'calc(100% - 12px)' }}
                                style={{ height: '100%' }}
                                hideIcon
                                newStyle
                            />
                        </Item>
                        <Item xs={12} md={4}>
                            <AssociatedResourceCard
                                data={this.getAssociatedResources()}
                                title={i18n.associatedResource}
                                styleContainer={{ height: 'calc(100% - 12px)' }}
                            />
                        </Item>
                        {this.getOuvrages()}
                        {this.getRetenues()}
                        {this.getEquipments()}
                    </>
                ) : (
                    <Item xs={12}>
                        <LinearProgress />
                    </Item>
                )}
                {this.getModalEditPoint()}
                {this.getModalEditPump()}
                {this.getModalEditCounter()}
            </Grid>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        contacts: store.ContactReducer.contacts,
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
        codesSandre: store.ReferencialReducer.codesSandre,
        contributors: store.ReferencialReducer.contributors,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchInstallation: InstallationsAction.fetchInstallation,
    updateInstallation: InstallationsAction.updateInstallation,
    fetchContacts: ContactAction.fetchContacts,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    updateVariousMateriel: MaterielAction.updateVariousMateriel,
    resetInstallation: InstallationsAction.resetInstallation,
    push,
}

PointDetailsExploit.propTypes = {
    installation: PropTypes.instanceOf(DtoInstallation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoContact)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
    setTitle: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    fetchInstallation: PropTypes.func,
    updateInstallation: PropTypes.func,
    updateVariousMateriel: PropTypes.func,
    resetInstallation: PropTypes.func,
    push: PropTypes.func,
    match: PropTypes.instanceOf(PropTypes.shape({})),
    location: PropTypes.instanceOf(PropTypes.shape({})),
    fetchContacts: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointDetailsExploit)
