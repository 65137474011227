/* eslint-disable indent */
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { mainColor, screenColor } from './theme'

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction='up' ref={ref} {...props} />
})

export const DialogMUI = styled((other) => (
    <Dialog
        fullWidth
        maxWidth='lg'
        fullScreen={useMediaQuery(useTheme().breakpoints.down('md'))}
        TransitionComponent={Transition}
        PaperProps={!useMediaQuery(useTheme().breakpoints.down('md')) ? {
            style: {
                minHeight: '90%',
                maxHeight: '90%',
            }
        } : {}}
        {...other}
    />
))({})

export const DialogTitleMUI = styled((other) => (
    <DialogTitle
        {...other}
    />))(() => ({
        borderBottom: 'solid 1px grey',
        fontSize: '20px',
        lineHeight: '20px',
        backgroundColor: mainColor,
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
    }))

export const DialogContentMUI = styled((other) => (
    <DialogContent
        {...other}
    />))({
        backgroundColor: screenColor,
        padding: '8px 10px !important',
    })

export const DialogActionsMUI = styled((other) => (
    <DialogActions
        {...other}
    />))({
        borderTop: 'solid 1px grey',
    })