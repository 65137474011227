import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { useSelector } from 'react-redux'
import DtoManagementUnit from '../../referencials/dto/DtoManagementUnit'
import DtoSandreCode from '../../referencials/dto/DtoSandreCode'
import StyledCard from './StyledCard'
import { Grid } from '@mui/material'

const AssociatedResourceCard = ({ title, styleContainer, data }) => {
    const {
        codesSandre,
        managementUnits,
    } = useSelector(store => ({
        codesSandre: store.ReferencialReducer.codesSandre,
        managementUnits: store.ReferencialReducer.managementUnits,
    }))

    const zre = codesSandre.find((c) => c.field === 'AGRI_PREL_ZRE' && c.code === data.codeZRE) || {}
    const ug = managementUnits.find((u) => u.managementCode === data.managementCode) || {}
    const sug = managementUnits.find((u) => u.managementCode === data.subManagementCode) || {}

    return (
        <StyledCard
            subTitle={title}
            styleContainer={styleContainer}
            styleSubTitle={{ borderTop: '1px solid black' }}
            styleContent={{ padding: 0 }}
        >
            <Grid container style={{ padding: 10 }}>
                <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.zre}</Grid>
                <Grid item xs={6} className='bold'>{zre?.name || '-'}</Grid>
            </Grid>
            <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.miocene}</Grid>
                <Grid item xs={6} className='bold'>{data.miocenezpr ? i18n.yes : i18n.no}</Grid>
            </Grid>
            <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{`${i18n.ug} - ID `}</Grid>
                <Grid item xs={6} className='bold'>{ug?.id || '-'}</Grid>
            </Grid>
            <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{i18n.ug}</Grid>
                <Grid item xs={6} className='bold'>{ug?.name || '-'}</Grid>
            </Grid>
            <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{`S${i18n.ug} - ID `}</Grid>
                <Grid item xs={6} className='bold'>{sug?.id || '-'}</Grid>
            </Grid>
            <Grid container style={{ padding: 10, borderTop: '1px solid black' }}>
                <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{`S${i18n.ug}`}</Grid>
                <Grid item xs={6} className='bold'>{sug?.name || '-'}</Grid>
            </Grid>
        </StyledCard>
    )
}

AssociatedResourceCard.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.instanceOf(PropTypes.shape({})),
    style: PropTypes.instanceOf(PropTypes.shape({})),
    styleContainer: PropTypes.instanceOf(PropTypes.shape({})),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

export default AssociatedResourceCard
