import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import { LinearProgress } from '@mui/material'
import Paper from '@mui/material/Paper'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { orderBy } from 'lodash'
import ToastrAction from '../components/toasters/ToastrAction'
import DtoContact from '../referencials/dto/DtoContact'
import { formatPhone } from '../../../utils/StringUtil'
import { getLogin } from '../../../utils/UserUtils'
import HomeAction from '../../offline/actions/HomeAction'
import ContactAction from './actions/ContactAction'
import DtoUserLight from '../account/dto/DtoUserLight'
import { mainColor } from '../components/styled/theme'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import { ReactComponent as EnvoiLogo } from '../../../ressources/static/svg/Envoi.svg'
import { getDateAndHour } from '../../../utils/DateUtil'

class MessagesApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            messages: [],
            dataLoaded: false,
            message: '',
        }
    }

    componentDidMount() {
        const { contacts, usersLight, exploitation, contributors } = this.props
        if (!contacts.length) {
            this.props.fetchContacts()
        }
        if (!usersLight.length) {
            this.props.fetchUsersLight()
        }
        const preleveur = contributors.find((c) => c.id === exploitation.operatorCode)
        this.props.setTitle([
            {
                title: preleveur?.name,
                subTitle: exploitation.codification,
            },
        ])
        this.getAllMessages()
    }

    componentDidUpdate() {
        window.scrollTo(0, document.body.scrollHeight)
    }

    getAllMessages = () => {
        this.props.getMessagesOf(getLogin()).then(() => {
            const { listOfMessages } = this.props
            const newMessages = !listOfMessages.length ? [] : listOfMessages
            this.setState({
                messages: newMessages,
                dataLoaded: true,
            })
        })
    }

    handleChangeMessage = (e) => {
        const value = e.target.value
        this.setState({ message: value })
    }

    sendMessage = () => {
        const { message } = this.state
        if (message) {
            const login = getLogin()
            const userMessage = { login, message }
            this.props.sendMessage(userMessage).then(() => {
                this.getAllMessages()
                this.setState({
                    message: '',
                })
            })
        } else {
            ToastrAction.warning('Pas de message à envoyer')
        }
    }

    showMessages = () => {
        const { messages } = this.state
        const { contacts, usersLight } = this.props

        return orderBy(messages, 'updateDate').map((m) => {
            const user = usersLight.find((u) => u.login === m.updateLogin) || {}
            const contact = contacts.find((c) => c.id === user.contactCode) || {}
            const phone = contact.phoneTel ? `${formatPhone(contact.phoneTel)} - ` : ''
            const mobile = contact.mobile ? `${formatPhone(contact.mobile)}` : ''
            return (
                <Grid container direction='column'>
                    <Grid
                        container
                        direction='column'
                        alignItems={m.updateLogin === m.login ? 'flex-end' : 'flex-start'}
                    >
                        <Grid
                            container
                            style={{
                                backgroundColor: m.updateLogin === m.login ? '#7CA5BC' : 'white',
                                width: '90%',
                                padding: '10px',
                                margin: '10px 0 10px 0',
                                borderRadius: '10px',
                            }}
                            direction='column'
                            alignItems='flex-start'
                        >
                            {m.updateLogin !== m.login && (
                                <>
                                    <div className='bold padding-bottom-2'>{`${m.updateLogin} ${contact?.name ? `(${contact?.name})` : ''}`}</div>
                                    <div className='padding-bottom-2'>
                                        {contact.phoneTel || contact.mobile ? <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{phone} {mobile}</span> : null}
                                        {contact.email ? <span style={{ fontSize: '14px', fontStyle: 'italic', color: '#5691be' }}>{contact.email}</span> : null}
                                    </div>
                                </>
                            )}
                            <Paper
                                style={{
                                    background: 'none',
                                    boxShadow: 'none',
                                    color: m.updateLogin === m.login ? 'white' : 'grey',
                                }}
                            >
                                {getDateAndHour(m.updateDate)}
                            </Paper>
                            <Paper style={{ background: 'none', boxShadow: 'none', whiteSpace: 'pre-wrap', borderTop: '1px solid lightgrey', width: '100%', marginTop: 8, paddingTop: 8 }}>
                                {m.message}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            )
        })
    }

    showInput = () => {
        return (
            <FormControl
                style={{
                    textfield: '25ch',
                    width: '100%',
                    marginTop: '20px',
                }}
                variant='outlined'
            >
                <InputLabel htmlFor='renseignement'>{i18n.anyInformation}</InputLabel>
                <OutlinedInput
                    id='renseignement'
                    type='text'
                    label={i18n.anyInformation}
                    value={this.state.message}
                    onChange={this.handleChangeMessage}
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={this.sendMessage}
                            >
                                <EnvoiLogo style={{ height: 30 }} />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        )
    }

    render() {
        const { dataLoaded } = this.state

        return (
            <Grid style={{ padding: 10 }}>
                <Grid item xs={12} className='bold' style={{ padding: 14, backgroundColor: mainColor, color: 'white', fontSize: 21 }}>{i18n.messaging}</Grid>
                {dataLoaded ? this.showMessages() : <LinearProgress />}
                {this.showInput()}
            </Grid>
        )
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    getMessagesOf: ContactAction.getMessagesOf,
    sendMessage: ContactAction.sendMessage,
    fetchContacts: ContactAction.fetchContacts,
    fetchUsersLight: HomeAction.fetchUsersLight,
    push,
}

const mapStateToProps = (store) => {
    return {
        listOfMessages: store.ContactReducer.listOfMessages,
        contacts: store.ContactReducer.contacts,
        usersLight: store.HomeReducer.usersLight,
        exploitation: store.AgriReducer.exploitation,
        contributors: store.ReferencialReducer.contributors,
    }
}

MessagesApp.propTypes = {
    setTitle: PropTypes.func,
    getMessagesOf: PropTypes.func,
    sendMessage: PropTypes.func,
    fetchContacts: PropTypes.func,
    fetchUsersLight: PropTypes.func,
    listOfMessages: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.string)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoContact)),
    usersLight: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserLight)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
    push: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesApp)
