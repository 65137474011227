import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import { compact, sortBy } from 'lodash'
import { MainButton } from '../online/components/styled/buttons'
import { InputRow } from '../online/components/styled/inputs'
import { CMS_PATH } from '../../conf/basepath'
import DtoCMSEvent from '../online/cms/dto/DtoCMSEvent'
import { isRunningApp } from '../../utils/LocalStorageUtils'
import ToastrAction from '../online/components/toasters/ToastrAction'
import ModalUpdatePassword from '../online/account/components/ModalUpdatePassword'
import HomeAction from './actions/HomeAction'
import { getSettingInt } from '../../utils/FormUtils'
import CmsAction from '../online/cms/actions/CmsAction'
import defaultHeader from '../../ressources/static/media/login_header.jpg'
import defaultLogo from '../../ressources/static/media/iryqua.png'
import LogAction from '../../utils/log/actions/LogAction'

const styles = () => ({
    logoTop: {
        textAlign: 'center',
        width: '100%',
        height: '15%',
    },
    img: {
        height: '100%',
        width: 'auto',
    },
})

class Password extends Component {
    constructor(props) {
        super(props)
        this.state = {
            identifiant: '',
            openModal: false,
            images: JSON.parse(localStorage.getItem('IRYQUA_images')) || [],
            backgroundUrl: localStorage.getItem('IRYQUA_backgroundUrl') || defaultHeader,
            logoUrl: localStorage.getItem('IRYQUA_logoUrl') || defaultLogo,
            hasResetCode: false,
            needLogin: false,
            resetCode: null,
            settingsLoaded: false,
            newMdp: null,
            mdpConfirmation: null,
        }
    }

    componentDidMount() {
        const { applicationSettings, cmsEvents } = this.props
        if (!cmsEvents.length) {
            this.props.fetchCMSEvents().then(() => {
                if (!applicationSettings.length) {
                    this.props.fetchApplicationSettings().then(() => this.setLogos())
                } else {
                    this.setLogos()
                }
            })
        } else {
            this.setLogos()
        }
    }

    setLogos = () => {
        const { applicationSettings, cmsEvents } = this.props
        const applicationName = applicationSettings.find(({ parameter }) => parameter === 'applicationName') || {}
        if (applicationName.value) {
            document.title = applicationName.value
        }
        const cmsHomeId = getSettingInt(applicationSettings, 'iryquaCmsAccueil')
        const cmsHome = cmsEvents.find((c) => c.id === cmsHomeId)
        const logoUrl = cmsHome?.document?.length ? CMS_PATH + cmsHome.document[0]?.name : ''
        const cmsBackgroundId = getSettingInt(applicationSettings, 'iryquaCmsFondAccueil')
        const cmsBackground = cmsEvents.find((c) => c.id === cmsBackgroundId)
        const backgroundUrl = cmsBackground?.document?.length ? CMS_PATH + cmsBackground.document[0]?.name : ''
        const idCategPartenaires = getSettingInt(applicationSettings, 'iryquaCategorieCmsPartenaires')
        const partenaires = cmsEvents.filter((c) => c.idCategory === idCategPartenaires)
        const images = compact(sortBy(partenaires, 'subtitle').map((c) => {
            if (c?.document[0]) {
                return {
                    link: c.link,
                    docName: CMS_PATH + c.document[0].name,
                }
            }
            return null
        }))
        localStorage.removeItem('IRYQUA_backgroundUrl')
        localStorage.removeItem('IRYQUA_logoUrl')
        localStorage.removeItem('IRYQUA_images')
        localStorage.setItem('IRYQUA_backgroundUrl', backgroundUrl)
        localStorage.setItem('IRYQUA_logoUrl', logoUrl)
        localStorage.setItem('IRYQUA_images', JSON.stringify(images))
        this.setState({
            backgroundUrl,
            logoUrl,
            settingsLoaded: true,
            images,
        })
    }

    handleChangeValue = (value) => {
        this.setState({ identifiant: value })
    }

    toggleModal = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onSubmit = () => {
        const { identifiant } = this.state
        if (identifiant) {
            this.props.logJournal(identifiant, `Demande de mdp oublié pour l'identifiant : ${identifiant}`, 'FORGET_PASSWORD')
            this.props.resetPassword(identifiant, false).then((json) => {
                if (!json) {
                    ToastrAction.info(i18n.sendEmail)
                    this.setState({ hasResetCode: true, needLogin: false })
                }
            })
        } else {
            ToastrAction.error(i18n.fillAllFields, true)
        }
    }

    openWebSite = (link) => {
        if (!isRunningApp() && link) {
            window.open(link, '_blank')
        }
    }

    getLogoPartenaires = () => {
        const { classes } = this.props
        const { images } = this.state
        // const size = images.length % 2 === 0 ? 6 : 4
        return images.map((i, index) => {
            const img = (
                <img
                    src={i.docName}
                    alt={i.link}
                    className={`${classes.img} clickable`}
                    onClick={() => this.openWebSite(i.link)}
                    style={{
                        height: '100%',
                        width: 'auto',
                        maxWidth: '100%',
                    }}
                />
            )
            return (
                <Grid item key={index} style={{ height: '10vw', minHeight: '40%' }}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        style={{ height: '100%' }}
                    >
                        {img}
                    </Grid>
                </Grid>
            )
        })
    }

    onValidateResetCode = (login, resetCode) => {
        if (login) {
            if (resetCode) {
                this.props.verifyResetCode(login, resetCode.toString()).then(valide => {
                    if (valide) {
                        this.setState({ openModal: true })
                    }
                })
            } else {
                ToastrAction.error('Veuillez renseigner le code qui vous a été envoyé par mail', true)
            }
        } else {
            ToastrAction.error('Veuillez renseigner votre identifiant', true)
        }
    }

    toggleModalPassword = () => {
        this.setState(({ openModal }) => ({ openModal: !openModal }))
    }

    onUpdatePassword = () => {
        const { newMdp, mdpConfirmation, resetCode } = this.state
        const { applicationSettings } = this.props
        if (
            newMdp !== null &&
            newMdp.length &&
            mdpConfirmation !== null &&
            mdpConfirmation.length
        ) {
            if (newMdp === mdpConfirmation) {
                const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                if (regex.test(newMdp)) {
                    this.props.updatePassword(newMdp, resetCode).then(() => {
                        ToastrAction.success(i18n.passwordUpdatedSuccessfully)
                        this.props.push('/login')
                    })
                } else {
                    ToastrAction.error(regexHelp, true)
                }
            } else {
                ToastrAction.error('Les mots de passe ne sont pas identiques', true)
            }
        }
    }

    getPanel = () => {
        const { hasResetCode, identifiant, resetCode, needLogin } = this.state
        if (hasResetCode) {
            return (
                <Grid
                    container
                    className='overflowY'
                    direction='row'
                    justifyContent='space-evenly'
                    alignItems='center'
                    style={{ height: '40%', padding: '0 20px' }}
                >
                    <Grid item container direction='column' justifyContent='center' alignItems='center'>
                        <p style={{ textAlign: 'center' }}>
                            Saisissez le code à 4 chiffres reçu par email pour finaliser votre réinitialisation de mot de passe.
                        </p>
                    </Grid>
                    {needLogin && (
                        <Grid item xs={12}>
                            <InputRow
                                item='true'
                                id='identifiant'
                                label={i18n.id}
                                type='text'
                                value={identifiant}
                                onChange={(event) => this.handleChangeValue(event.target.value)}
                                variant='outlined'
                                required
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <InputRow
                            item='true'
                            id='password'
                            label={i18n.code}
                            type='number'
                            inputProps={{ min: 0, max: 9999 }}
                            value={resetCode}
                            onChange={(event) => this.setState({ resetCode: event.target.value })}
                            variant='outlined'
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MainButton onClick={() => this.onValidateResetCode(identifiant, resetCode)}>
                            {i18n.validate}
                        </MainButton>
                    </Grid>
                    <Grid item xs={12}>
                        <MainButton
                            id='backToLogin'
                            reverse='true'
                            onClick={() => this.setState({ hasResetCode: false })}
                        >
                            {i18n.back}
                        </MainButton>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid
                container
                className='overflowY'
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
                style={{ height: '40%', padding: '0 20px' }}
            >
                <Grid item container direction='column' justifyContent='center' alignItems='center'>
                    <h4 style={{ margin: '0' }}>Mot de passe oublié ?</h4>
                    <p style={{ textAlign: 'center' }}>
                        Pour renouveler votre mot de passe, saisissez votre identifiant
                        ci-dessous.
                    </p>
                </Grid>
                <Grid item xs={12}>
                    <InputRow
                        item='true'
                        id='password'
                        label={i18n.id}
                        type='text'
                        value={identifiant}
                        onChange={(event) => this.handleChangeValue(event.target.value)}
                        variant='outlined'
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <MainButton onClick={this.onSubmit}>
                        Envoyer
                    </MainButton>
                </Grid>
                <Grid item xs={12}>
                    <MainButton id='hasCode' onClick={() => this.setState({ hasResetCode: true, needLogin: true })}>
                        J'ai déjà un code
                    </MainButton>
                </Grid>
                <Grid item xs={12}>
                    <MainButton
                        id='cancel'
                        reverse='true'
                        onClick={() => this.props.push('/login')}
                    >
                        {i18n.cancel}
                    </MainButton>
                </Grid>
            </Grid>
        )
    }

    render() {
        const { backgroundUrl, logoUrl, settingsLoaded, openModal } = this.state
        const { classes, applicationSettings } = this.props
        const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription') || {}
        return (
            <div
                style={{
                    height: '100%',
                    margin: '-70px 0',
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', height: '15%' }}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='center'
                        alignItems='flex-end'
                        style={{
                            backgroundImage: `url(${backgroundUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: 'calc(100% - 20px)',
                            boxShadow: 'cyan 0px 0px 50px -10px',
                            borderRadius: '0 0 5px 5px',
                        }}
                    />
                </div>
                <Grid className={classes.logoTop}>
                    <img src={logoUrl} alt='' width='190px' />
                </Grid>
                {this.getPanel()}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '30%', }}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        style={{
                            borderRadius: '5px 5px 0 0',
                            width: 'calc(100% - 20px)',
                            overflowY: 'auto',
                            backgroundColor: 'white',
                            boxShadow: 'cyan 0px 0px 50px -10px',
                        }}
                    >
                        {this.getLogoPartenaires()}
                    </Grid>
                </div>
                {settingsLoaded && openModal && (
                    <ModalUpdatePassword
                        title={i18n.changePassword}
                        label={regexHelp.value}
                        open={openModal}
                        toggleModal={this.toggleModalPassword}
                        handleChangeNewMdp={(e) => this.setState({ newMdp: e.target.value })}
                        handleChangeConfirmation={(e) => this.setState({ mdpConfirmation: e.target.value })}
                        onSavePassword={this.onUpdatePassword}
                        firstLogin
                    />
                )}
            </div>
        )
    }
}

const mapDispatchToProps = {
    resetPassword: HomeAction.resetPassword,
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    verifyResetCode: HomeAction.verifyResetCode,
    updatePassword: HomeAction.updatePassword,
    fetchCMSEvents: CmsAction.fetchCMSEvents,
    logJournal: LogAction.logJournal,
    push,
}

const mapStateToProps = (store) => {
    return {
        applicationSettings: store.HomeReducer.applicationSettings,
        cmsEvents: store.CmsReducer.cmsEvents,
    }
}

Password.propTypes = {
    updatePassword: PropTypes.func,
    resetPassword: PropTypes.func,
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    fetchApplicationSettings: PropTypes.func,
    verifyResetCode: PropTypes.func,
    push: PropTypes.func,
    fetchCMSEvents: PropTypes.func,
    logJournal: PropTypes.func,
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    classes: PropTypes.instanceOf(PropTypes.shape({})),
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Password))
