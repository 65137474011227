'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_INSTALLATION,
    RECEIVE_ALL_VARIOUS_MATERIELS,
    RECEIVE_VARIOUS_MAT_SITUATIONS,
    RECEIVE_VARIOUS_MAT_TYPES,
    RECEIVE_ALL_INSTALLATIONS_GEO,
    RECEIVE_INSTALLATIONS,
    RESET_INSTALLATION,
    RECEIVE_MAP_SITUATIONS_STATION,
    RECEIVE_MAP_SITUATIONS_STATION_LAST,
    RECEIVE_LINKED_STATIONS,
    RECEIVE_MAP_SITUATIONS,
} from '../constants/InstallationsConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import { checkAuth, checkError, genericFetch, genericPromise, getAuthorization } from '../../../../../utils/ActionUtils'
import ToastrAction from '../../../components/toasters/ToastrAction'
import LogAction from '../../../../../utils/log/actions/LogAction'

const InstallationsAction = {
    receiveInstallations(installationsWithGeo) {
        return { type: RECEIVE_ALL_INSTALLATIONS_GEO, installationsWithGeo }
    },

    promiseInstallations() {
        return fetch(ApplicationConf.installation.getAllWithGeo(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchInstallations() {
        return (dispatch) => {
            return InstallationsAction.promiseInstallations()
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveInstallations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.installations} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.installations)
                })
        }
    },

    updateInstallation(installation) {
        return () => {
            return fetch(ApplicationConf.installation.path(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(installation),
            })
                .then(checkAuth)
                .then(checkError)
                .then(json => {
                    if (json.update >= 0) {
                        ToastrAction.success(i18n.elementUpdateSuccess)
                    } else {
                        throw new Error()
                    }
                }).catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.installation} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.installation)
                })
        }
    },

    receiveInstallationsByExploitations(installations) {
        return { type: RECEIVE_INSTALLATIONS, installations }
    },

    promiseInstallationsByExploitationId(id) {
        return fetch(ApplicationConf.installation.getExploitationInstallations(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchInstallationsByExploitationId(id) {
        return (dispatch) => {
            return InstallationsAction.promiseInstallationsByExploitationId(id)
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveInstallationsByExploitations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.installations} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.installations)
                })
        }
    },

    receiveInstallation(installation) {
        return { type: RECEIVE_INSTALLATION, installation }
    },

    fetchInstallation(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.get(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveInstallation(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.installation} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.installation)
                })
        }
    },

    receiveAllVariousMateriels(variousMateriels) {
        return { type: RECEIVE_ALL_VARIOUS_MATERIELS, variousMateriels }
    },

    promiseAllVariousMateriels() {
        return fetch(ApplicationConf.materiel.variousMateriels(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchAllVariousMateriels() {
        return (dispatch) => {
            return InstallationsAction.promiseAllVariousMateriels()
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveAllVariousMateriels(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.variousMateriels} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.variousMateriels)
                })
        }
    },

    receiveAllVariousMatTypes(variousMatTypes) {
        return { type: RECEIVE_VARIOUS_MAT_TYPES, variousMatTypes }
    },

    promiseAllVariousMatTypes() {
        return fetch(ApplicationConf.materiel.variousMaterielTypes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchAllVariousMatTypes() {
        return (dispatch) => {
            return InstallationsAction.promiseAllVariousMatTypes()
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveAllVariousMatTypes(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.variousMatTypes} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.variousMatTypes)
                })
        }
    },

    receiveVariousMatSituations(variousMatSituations) {
        return { type: RECEIVE_VARIOUS_MAT_SITUATIONS, variousMatSituations }
    },

    promiseVariousMatSituations() {
        return fetch(ApplicationConf.materiel.variousMaterielsLastSituations(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchVariousMatSituations() {
        return (dispatch) => {
            return InstallationsAction.promiseVariousMatSituations()
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveVariousMatSituations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.variousMateriels} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.variousMateriels)
                })
        }
    },

    promiseMapSituations(stationType) {
        return genericPromise(ApplicationConf.piezometer.mapSituation(stationType))
    },

    fetchMapSituations(stationType) {
        return genericFetch(InstallationsAction.promiseMapSituations(stationType), RECEIVE_MAP_SITUATIONS)
    },

    promiseMapSituationStation(stationType, mapId, stationId) {
        return genericPromise(ApplicationConf.piezometer.mapSituationStation(stationType, mapId, stationId))
    },

    fetchMapSituationsStation(stationType, mapId, stationId) {
        return genericFetch(InstallationsAction.promiseMapSituationStation(stationType, mapId, stationId), RECEIVE_MAP_SITUATIONS_STATION)
    },

    promiseMapSituationStationLast(stationType, stationId, maxDate = null) {
        return genericPromise(ApplicationConf.piezometer.mapSituationStationLast(stationType, stationId, maxDate))
    },

    fetchMapSituationsStationLast(stationType, stationId, maxDate) {
        return genericFetch(InstallationsAction.promiseMapSituationStationLast(stationType, stationId, maxDate), RECEIVE_MAP_SITUATIONS_STATION_LAST)
    },

    promiseLinkedStations(code, stationType) {
        return genericPromise(ApplicationConf.station.associatedLinksLight(code, stationType))
    },

    fetchLinkedStations(code, stationType) {
        return genericFetch(InstallationsAction.promiseLinkedStations(code, stationType), RECEIVE_LINKED_STATIONS)
    },

    resetInstallation() {
        return { type: RESET_INSTALLATION }
    },
}

export default InstallationsAction
